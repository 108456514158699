import * as React from 'react';
import { Stack } from '@fluentui/react';

import { classes } from '../../utils';

interface ISeparatorProps {
  icon: any;
  children: any;
}

const Separator = ({ icon = React.Fragment, children }: ISeparatorProps) => (
  <Stack.Item className={classes.title}>
    <div className={classes.titleBar} />
    <div className={classes.titleContent}>
      {icon}
      {children}
    </div>
  </Stack.Item>
);

export default Separator;

import { useAuth0 } from '@auth0/auth0-react';
import {
  ICommandBarItemProps,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  IconButton,
  PrimaryButton,
} from '@fluentui/react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import React, { useState, useEffect, useCallback } from 'react';
import {
  useMutation,
  useQuery,
  useLazyQuery,
  ApolloError,
} from '@apollo/react-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { CommandBarSticky } from '../../components/parts';
import { commandBarTheme } from '../../theme';
import useDebounce from '../../components/hooks/useDebounce';
import {
  ADD_PURCHASE_ORDER,
  GET_MEASURING_UNITS,
  GET_PURCHASE_ORDER_BY_ID,
  GET_PURCHASE_ORDER_TEMPLATES_ALL_IN,
  GET_SUPPLIERS,
  GET_CONSTRUCTION_SITES,
  GET_PURCHASE_ORDER_STATUSES,
  IPurchaseOrderTemplate,
  IConstructionSite,
  IPurchaseOrder,
  IPurchaseOrderLine,
  IPurchaseOrderLineCreate,
  IPurchaseOrderLineUpdate,
  stackTokens15,
  stackTokens5,
  UPDATE_PURCHASE_ORDER,
  iconButtonStyles,
  cancelIcon,
} from '../../utils';
import { useAppDispatch } from '../../redux/hooks';
import { throwError, SeverityLevel } from '../../redux/error/errorSlice';
import {
  sendNotification,
  dismissNotification,
} from '../../redux/notification/notificationSlice';
import { GET_SUPPLIER_BY_ID, ISupplier } from '../../utils/Supplier';
import {
  getDefaultLineItem,
  IPurchaseOrderLineDelete,
} from '../../utils/PurchaseOrderLine';
import PurchaseOrderDownloadTemplate from './components/PurchaseOrderDownloadTemplate';
import PurchaseOrderHeader from './components/PurchaseOrderHeader';
import PurchaseOrderFooter from './components/PurchaseOrderFooter';
import PurchaseOrderBody from './components/PurchaseOrderBody';

const addEmptyOrderLine = (purchaseOrder: IPurchaseOrder) => {
  const newPurchaseOrder = _.cloneDeep(purchaseOrder);
  const purchaseOrderLines = newPurchaseOrder.line_items || [];
  if (
    newPurchaseOrder &&
    newPurchaseOrder.line_items &&
    newPurchaseOrder.line_items.length === 0
  ) {
    newPurchaseOrder.line_items.push(getDefaultLineItem({}));
  } else if (newPurchaseOrder.line_items) {
    const arrayLength = newPurchaseOrder.line_items.length;
    const lastItem = newPurchaseOrder.line_items[arrayLength - 1];
    if ((lastItem && !lastItem.isNew) || (lastItem.isNew && lastItem.isDirty)) {
      const newWeight = (lastItem.weight || 0) + 1;
      newPurchaseOrder.line_items.push(
        getDefaultLineItem({ weight: newWeight }),
      );
    }
  }

  newPurchaseOrder.line_items = purchaseOrderLines;
  return newPurchaseOrder;
};

const sortLineItems = (data: IPurchaseOrder) => {
  const newPurchaseOrder = _.cloneDeep(data);
  if (newPurchaseOrder.line_items) {
    newPurchaseOrder.line_items.sort(
      (a, b) => (a.weight || 0) - (b.weight || 0),
    );
  } else {
    newPurchaseOrder.line_items = [];
  }

  for (let i = 0; i < newPurchaseOrder.line_items.length; i++) {
    const currentWeight = newPurchaseOrder.line_items[i].weight;
    newPurchaseOrder.line_items[i].weight = i + 1;
    if (currentWeight !== newPurchaseOrder.line_items[i].weight) {
      newPurchaseOrder.line_items[i].isDirty = true;
    }
  }

  return newPurchaseOrder;
};

const removeEmptyLine = (purchaseOrder: IPurchaseOrder) => {
  const newPurchaseOrder = { ...purchaseOrder };
  if (newPurchaseOrder && newPurchaseOrder.line_items) {
    const purchaseOrderLines = newPurchaseOrder.line_items;
    if (purchaseOrderLines.length > 0) {
      newPurchaseOrder.line_items = purchaseOrderLines.slice(
        0,
        purchaseOrderLines.length - 1,
      );
    }
  }
  return newPurchaseOrder;
};

export type IPurchaseOrderState = {
  // constructionSite?: IConstructionSite;
  purchaseOrder: IPurchaseOrder;
  template?: IPurchaseOrderTemplate;
  templateKey?: any;
};

// Start component
const PurchaseOrder = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const purchaseOrderId = id && id !== 'add' ? parseInt(id, 10) : undefined;

  const [showPDF, setShowPDF] = useState(false);

  const [supplierSearch, setSupplierSearch] = useState<string | undefined>();
  const [constructionSiteSearch, setConstructionSiteSearch] = useState<
    string | undefined
  >();
  const [suppliers, setSuppliers] = useState<ISupplier[] | undefined>();
  const [constructionSites, setConstructionSites] = useState<
    IConstructionSite[] | undefined
  >();

  const debouncedSearchTermConstructionSite = useDebounce(
    constructionSiteSearch,
    500,
  );
  const debouncedSearchTerm = useDebounce(supplierSearch, 500);

  const supplierQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTerm
        ? {
            OR: [
              { name: { contains: debouncedSearchTerm } },
              { city: { contains: debouncedSearchTerm } },
              { email: { contains: debouncedSearchTerm } },
              { phone_v2: { contains: debouncedSearchTerm } },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTerm]);

  const constructionSiteQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTermConstructionSite
        ? {
            OR: [
              { name: { contains: debouncedSearchTermConstructionSite } },
              { city: { contains: debouncedSearchTermConstructionSite } },
              { address: { contains: debouncedSearchTermConstructionSite } },
              // { email: { contains: debouncedSearchTermConstructionSite } },
              // { phone: { contains: debouncedSearchTermConstructionSite } },
              {
                zip_code: Number.isNaN(
                  parseInt(debouncedSearchTermConstructionSite, 10),
                )
                  ? undefined
                  : {
                      equals: parseInt(debouncedSearchTermConstructionSite, 10),
                    },
              },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTermConstructionSite]);

  // Hooks
  const [purchaseOrderState, setPurchaseOrderState] =
    useState<IPurchaseOrderState>({
      purchaseOrder: {
        delivery_to_other_supplier: false,
        is_on_call: false,
        is_purchase_order: true,
        is_default_contact_person: true,
        line_items: [],
        status: {
          id: 1,
          label: 'Nieuw', // hard coded -> to change @TP
        },
      },
    });

  const [status, setStatus] = useState<'new' | 'saving' | 'error' | 'saved'>(
    'new',
  );

  const dispatch = useAppDispatch();

  // Queries
  const [addPurchaseOrder] = useMutation(ADD_PURCHASE_ORDER, {
    onError: error => {
      dispatch(
        throwError({
          module: 'purchaseOrder.addPurchaseOrder',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
      console.log(error);
    },
  });
  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER, {
    onError: error => {
      dispatch(
        throwError({
          module: 'purchaseOrder.updatePurchaseOrder',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
      console.log(error);
    },
  });

  const { loading, error, data } = useQuery(GET_PURCHASE_ORDER_BY_ID, {
    variables: {
      where: {
        id: purchaseOrderId,
      },
    },
    skip: purchaseOrderId === undefined,
    onCompleted: (x: any) => {
      if (x && x.findOnePurchaseOrder) {
        let purchaseOrder: IPurchaseOrder = x.findOnePurchaseOrder;
        purchaseOrder = sortLineItems(purchaseOrder);
        const hasSupplier = !!(
          purchaseOrder.supplier && purchaseOrder.supplier.id
        );
        console.log('loadPO');
        setPurchaseOrderState(prevState => ({
          ...prevState,
          purchaseOrder,
          hasSupplier,
        }));
      }
    },
  });

  const [getSupplier] = useLazyQuery(GET_SUPPLIER_BY_ID, {
    onCompleted: (x: any) => {
      console.log('getSupplier');
      setPurchaseOrderState(prevState => {
        const newState = _.clone(prevState);
        newState.purchaseOrder.supplier = x.findOneSupplier;
        return newState;
      });
    },
  });

  useEffect(() => {
    if (
      purchaseOrderState &&
      purchaseOrderState.purchaseOrder.supplier &&
      !purchaseOrderState.purchaseOrder.supplier.contact_persons
    ) {
      console.log('fetch supplier');
      getSupplier({
        variables: {
          where: { id: purchaseOrderState.purchaseOrder.supplier.id },
        },
      });
    }
  }, [purchaseOrderState.purchaseOrder.supplier]);

  useEffect(() => {
    const currentPurchaseOrder = purchaseOrderState.purchaseOrder;
    const newPurchaseOrder = addEmptyOrderLine(currentPurchaseOrder);

    if (
      JSON.stringify(currentPurchaseOrder) !== JSON.stringify(newPurchaseOrder)
    ) {
      setPurchaseOrderState(currentState => ({
        ...currentState,
        purchaseOrder: newPurchaseOrder,
      }));
    }
  }, [purchaseOrderState.purchaseOrder]);

  const {
    loading: loadingTemplates,
    error: errorTemplates,
    data: dataTemplates,
  } = useQuery(GET_PURCHASE_ORDER_TEMPLATES_ALL_IN, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
  });

  const {
    loading: loadingMeasuringUnit,
    error: errorMeasuringUnit,
    data: dataMeasuringUnits,
  } = useQuery(GET_MEASURING_UNITS, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
  });

  // Queries
  const { loading: loadingSuppliers, error: errorSuppliers } = useQuery(
    GET_SUPPLIERS,
    {
      variables: {
        ...supplierQuery(),
      },
      onCompleted: (x: any) => {
        if (x && x.findManySuppliers) {
          console.log('set supplier');
          setSuppliers(x.findManySuppliers);
        }
      },
      // skip: order && order.id && order.id > 0,
    },
  );

  /*
  const { error: errorContactPersons, data: dataContactPersons } = useQuery(
    GET_CONTACT_PERSONS,
    {
      variables: {
        orderBy: {
          name: 'asc',
        },
      },
      //skip: order && order.id && order.id > 0,
    },
  );
  */

  const { error: errorConstructionSites, loading: loadingConstructionSites } =
    useQuery(GET_CONSTRUCTION_SITES, {
      variables: {
        ...constructionSiteQuery(),
      },
      onCompleted: (x: any) => {
        if (x && x.findManyConstructionSites) {
          setConstructionSites(x.findManyConstructionSites);
        }
      },
    });

  const { data: dataStatuses, error: errorStatuses } = useQuery(
    GET_PURCHASE_ORDER_STATUSES,
  );

  // Functions
  const { isAuthenticated } = useAuth0();

  const commandBarFaritems = (purchaseOrder: any) => {
    const items: ICommandBarItemProps[] = [
      {
        key: 'back',
        text: 'Terug naar overzicht',
        iconProps: { iconName: 'Back' },
        href: '/purchase-orders',
        theme: commandBarTheme,
      },
      {
        key: 'download',
        text: 'PDF',
        iconProps: { iconName: 'Download' },
        onClick: () => {
          if (purchaseOrder) setShowPDF(true);
        },
        theme: commandBarTheme,
      },
      {
        key: 'save',
        text: 'Opslaan',
        iconProps: { iconName: 'Save' },
        onClick: () => savePurchaseOrder(),
        theme: commandBarTheme,
      },
    ];

    if (purchaseOrder && purchaseOrder.construction_site) {
      items.splice(
        1,
        0,
        {
          key: 'backToConstructionSite',
          text: 'Terug naar werffiche',
          iconProps: { iconName: 'Back' },
          href: `/construction-sites/${purchaseOrder.construction_site.id}/site-overview`,
          theme: commandBarTheme,
        },
        {
          key: 'backToExecutionList',
          text: 'Terug naar uitvoeringslijst',
          iconProps: { iconName: 'Back' },
          href: `/construction-sites/${purchaseOrder.construction_site.id}/implementation-list`,
          theme: commandBarTheme,
        },
      );
    }

    return items;
  };

  const commandBarItems = (): ICommandBarItemProps[] => {
    const items: ICommandBarItemProps[] = [];

    if (status) {
      switch (status) {
        case 'saved':
          items.push({
            key: 'saved',
            text: 'Opgeslagen',
            iconProps: { iconName: 'CheckMark' },
            theme: commandBarTheme,
          });
          break;
        case 'saving':
          items.push({
            key: 'saving',
            text: 'Opslaan',
            theme: commandBarTheme,
            onRender: () => (
              <Stack horizontal tokens={stackTokens5} verticalAlign='center'>
                <StackItem>
                  <Spinner size={SpinnerSize.small} />
                </StackItem>
                <StackItem>Opslaan</StackItem>
              </Stack>
            ),
          });
          break;
        default:
          break;
      }
    }

    return items;
  };

  // Save and update area
  const getContactPersonValues = (order: IPurchaseOrder) => {
    let result = {};

    if (order.is_default_contact_person) {
      result = {
        ...result,
        is_default_contact_person: true,
      };
    } else if (order.contact_person && order.contact_person.id) {
      result = {
        ...result,
        is_default_contact_person: false,
        contact_person: {
          connect: {
            id: order.contact_person.id,
          },
        },
      };
    }

    return result;
  };

  const getRemarksValues = (order: IPurchaseOrder) => {
    if (order.remarks) return { remarks: order.remarks };
    return { remarks: '' };
  };

  const getDeliveryValues = (order: IPurchaseOrder) => {
    let result = {};

    if (order.delivery_to_other_supplier) {
      if (order.deliver_to_supplier && order.deliver_to_supplier.id) {
        result = {
          ...result,
          deliver_to_supplier: {
            connect: {
              id: order.deliver_to_supplier.id,
            },
          },
        };

        if (
          data &&
          data.findOnePurchaseOrder &&
          data.findOnePurchaseOrder.construction_site &&
          data.findOnePurchaseOrder.construction_site.id
        ) {
          result = {
            ...result,
          };
        }
      }
    }
    if (order.construction_site && order.construction_site.id) {
      result = {
        ...result,
        construction_site: {
          connect: {
            id: order.construction_site.id,
          },
        },
      };

      if (
        data &&
        data.findOnePurchaseOrder &&
        data.findOnePurchaseOrder.deliver_to_supplier &&
        data.findOnePurchaseOrder.deliver_to_supplier.id
      ) {
        result = {
          ...result,
        };
      }
    }

    return result;
  };

  const createPurchaseOrder = (order: IPurchaseOrder) => {
    dispatch(
      sendNotification({
        message: 'bezig met opslaan',
        level: 0,
        module: 'purchaseOrder.createPurchaseOrder',
        spinner: true,
      }),
    );
    let createValues: any = {
      delivery_date: order.delivery_date,
      is_on_call: order.is_on_call,
      no_delivery_hour: order.no_delivery_hour,
      on_call_value: order.on_call_value,
      delivery_to_other_supplier: order.delivery_to_other_supplier,
      is_purchase_order: order.is_purchase_order,
      construction_site: order.construction_site
        ? {
            connect: {
              id: order.construction_site.id,
            },
          }
        : undefined,
      supplier:
        purchaseOrderState.purchaseOrder &&
        purchaseOrderState.purchaseOrder.supplier &&
        purchaseOrderState.purchaseOrder.supplier.id
          ? {
              connect: {
                id: purchaseOrderState.purchaseOrder.supplier.id,
              },
            }
          : undefined,
      status: {
        connect: {
          id: order.status.id,
        },
      },
    };

    const contactPersonValues = getContactPersonValues(order);
    const remarksValues = getRemarksValues(order);
    const deliveryValues = getDeliveryValues(order);

    if (order && order.construction_site && order.construction_site.id) {
      createValues = {
        ...createValues,
        construction_site: {
          connect: {
            id: order.construction_site.id,
          },
        },
      };
    }

    if (order.line_items && order.line_items.length > 0) {
      const created: IPurchaseOrderLineCreate[] = [];
      order.line_items.forEach((item: IPurchaseOrderLine) => {
        if (item.isNew && item.isDirty && !item.delete) {
          created.push({
            name: item.name,
            comment: item.comment ? item.comment : undefined,
            quantity: item.quantity ? parseFloat(item.quantity) : 0,
            unit_price: item.unit_price ? parseFloat(item.unit_price) : 0,
            measuring_unit: {
              connect: {
                id: item.measuring_unit.id,
              },
            },
            weight: item.weight,
          });
        }
      });

      let lineValues = {};

      if (created.length > 0) {
        lineValues = {
          ...lineValues,
          create: created,
        };
      }

      createValues = {
        ...createValues,
        line_items: {
          ...lineValues,
        },
      };
    }

    addPurchaseOrder({
      variables: {
        data: {
          ...createValues,
          ...contactPersonValues,
          ...remarksValues,
          ...deliveryValues,
        },
      },
    })
      .then((x: any) => {
        // history.push(`/purchase-order/${x.data.createPurchaseOrder.id}`);
        if (x.data.createPurchaseOrder) {
          setPurchaseOrderState(prevState => ({
            ...prevState,
            purchaseOrder: x.data.createPurchaseOrder,
          }));
          navigate(`/purchase-order/${x.data.createPurchaseOrder.id}`, {
            replace: true,
          });
          dispatch(
            sendNotification({
              module: 'purchaseOrder.createPurchaseOrder',
              message: 'De wijzigingen zijn opgeslagen',
              level: 1,
              timeout: 2500,
            }),
          );
        } else if (x.errors) {
          dispatch(
            throwError({
              module: 'purchaseOrder.createPurchaseOrder',
              message: x.errors,
              level: SeverityLevel.Critical,
            }),
          );
          dispatch(dismissNotification());
        }
        console.log(x);
      })
      .catch(error => {
        console.log(error);
        if (typeof error === 'string') {
          dispatch(
            throwError({
              module: 'purchaseOrder.createPurchaseOrder',
              message: error,
              level: SeverityLevel.Critical,
            }),
          );
        } else if (error instanceof Error || error instanceof ApolloError) {
          dispatch(
            throwError({
              module: 'purchaseOrder.createPurchaseOrder',
              message: error.message,
              level: SeverityLevel.Critical,
            }),
          );
        }
      });
  };

  const modifyPurchaseOrder = (order: IPurchaseOrder) => {
    let updatedValues = {};

    dispatch(
      sendNotification({
        message: 'bezig met opslaan',
        level: 0,
        module: 'purchaseOrder.updatePurchaseOrder',
        spinner: true,
      }),
    );

    updatedValues = {
      ...updatedValues,
      delivery_date: order.delivery_date,
      is_on_call: order.is_on_call,
      no_delivery_hour: order.no_delivery_hour,
      on_call_value: order.on_call_value,
      delivery_to_other_supplier: order.delivery_to_other_supplier,
      is_purchase_order: order.is_purchase_order,
      construction_site: order.construction_site
        ? {
            connect: {
              id: order.construction_site.id,
            },
          }
        : undefined,
      supplier:
        purchaseOrderState.purchaseOrder &&
        purchaseOrderState.purchaseOrder.supplier &&
        purchaseOrderState.purchaseOrder.supplier.id
          ? {
              connect: {
                id: purchaseOrderState.purchaseOrder.supplier.id,
              },
            }
          : undefined,
      status: {
        connect: {
          id: order.status.id,
        },
      },
    };

    const contactPersonValues = getContactPersonValues(order);
    const remarksValues = getRemarksValues(order);
    const deliveryValues = getDeliveryValues(order);

    if (order.line_items && order.line_items.length > 0) {
      const created: IPurchaseOrderLineCreate[] = [];
      const updated: IPurchaseOrderLineUpdate[] = [];
      const deleted: IPurchaseOrderLineDelete[] = [];

      order.line_items.forEach((item: IPurchaseOrderLine) => {
        if (item.isNew && !item.delete && item.isDirty) {
          let lineItem = {};
          if (item && item.id && item.id > 0) {
            lineItem = {
              connect: {
                id: item.id,
              },
            };
          } else {
            lineItem = {
              create: {
                name: item.name,
                sku: 'x',
              },
            };
          }

          created.push({
            name: item.name,
            comment: item.comment ? item.comment : undefined,
            quantity: item.quantity ? parseFloat(item.quantity) : 0,
            unit_price: item.unit_price ? parseFloat(item.unit_price) : 0,
            measuring_unit: {
              connect: {
                id: item.measuring_unit.id,
              },
            },
            weight: item.weight,
          });
        } else if (item.isDirty && !item.delete) {
          updated.push({
            data: {
              name: item.name,
              comment: item.comment ? item.comment : undefined,
              quantity: item.quantity ? parseFloat(item.quantity) : 0,
              unit_price: item.unit_price ? parseFloat(item.unit_price) : 0,
              measuring_unit: {
                connect: {
                  id: item.measuring_unit.id,
                },
              },
              weight: item.weight,
            },
            where: {
              id: item.id,
            },
          });
        } else if (item.delete && !item.isNew) {
          deleted.push({
            id: item.id,
          });
        }
      });

      let lineValues = {};

      if (created.length > 0) {
        lineValues = {
          ...lineValues,
          create: created,
        };
      }

      if (updated.length > 0) {
        lineValues = {
          ...lineValues,
          update: updated,
        };
      }

      if (deleted.length > 0) {
        lineValues = {
          ...lineValues,
          delete: deleted,
        };
      }

      updatedValues = {
        ...updatedValues,
        line_items: {
          ...lineValues,
        },
      };
    }

    updatePurchaseOrder({
      variables: {
        id: order.id,
        data: {
          ...updatedValues,
          ...contactPersonValues,
          ...remarksValues,
          ...deliveryValues,
        },
      },
    })
      .then((x: any) => {
        setPurchaseOrderState(prevState => {
          let newPurchaseOrder = x.data.updatePurchaseOrder;
          newPurchaseOrder = sortLineItems(newPurchaseOrder);
          return {
            ...prevState,
            purchaseOrder: newPurchaseOrder,
          };
        });
        dispatch(
          sendNotification({
            module: 'purchaseOrder.updatePurchaseOrder',
            message: 'De wijzigingen zijn opgeslagen',
            level: 1,
            timeout: 2500,
          }),
        );
      })
      .catch(error => {
        console.log(error);
        if (typeof error === 'string') {
          dispatch(
            throwError({
              module: 'purchaseOrder.updatePurchaseOrder',
              message: error,
              level: SeverityLevel.Critical,
            }),
          );
        } else if (error instanceof Error || error instanceof ApolloError) {
          dispatch(
            throwError({
              module: 'purchaseOrder.updatePurchaseOrder',
              message: error.message,
              level: SeverityLevel.Critical,
            }),
          );
        }
      });
  };

  const savePurchaseOrder = () => {
    if (purchaseOrderState) {
      const purchaseOrder = purchaseOrderState.purchaseOrder;
      const hasSupplier =
        purchaseOrderState.purchaseOrder &&
        purchaseOrderState.purchaseOrder.supplier;

      if (!hasSupplier) {
        dispatch(
          throwError({
            level: SeverityLevel.Warning,
            message: 'Leverancier is verplicht',
            module: 'purchaseorder.save',
            timeout: 3000,
          }),
        );
        return;
      }

      if (purchaseOrder && purchaseOrder.id && purchaseOrder.id > 0) {
        modifyPurchaseOrder(purchaseOrder);
      } else {
        createPurchaseOrder(purchaseOrder);
      }
    }
  };

  const getFileName = useCallback(() => {
    const purchaseOrder = purchaseOrderState.purchaseOrder;
    const fileName = 'bestelbon{orderNo}.pdf'.replace(
      '{orderNo}',
      purchaseOrder?.purchase_order_no
        ? `-${purchaseOrder?.purchase_order_no}`
        : '',
    );
    return fileName;
  }, [purchaseOrderState.purchaseOrder]);

  const getPDFPurchaseOrder = useCallback(() => {
    let purchaseOrder = purchaseOrderState.purchaseOrder;

    purchaseOrder = removeEmptyLine(purchaseOrder);

    return purchaseOrder;
  }, [purchaseOrderState.purchaseOrder]);

  // Save and update area --- end

  if (!isAuthenticated) return <p>Verboden</p>;
  if (
    loading
    // loadingTemplates ||
    // loadingMeasuringUnit ||
    // loadingSuppliers
  ) {
    return <p>Laden...</p>;
  }

  if (
    error ||
    errorTemplates ||
    errorMeasuringUnit ||
    errorSuppliers ||
    errorConstructionSites ||
    errorStatuses
  ) {
    return <p>Er ging iets fout :(</p>;
  }

  document.title = `3bouw | Bestelbon${
    purchaseOrderState.purchaseOrder.purchase_order_no
      ? ` - ${purchaseOrderState.purchaseOrder.purchase_order_no}`
      : ''
  }`;

  return (
    <Stack tokens={stackTokens15}>
      <CommandBarSticky
        items={commandBarItems()}
        farItems={commandBarFaritems(purchaseOrderState.purchaseOrder)}
        ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
        theme={commandBarTheme}
        width='1020px'
        maxWidth='1020px'
      />
      <PurchaseOrderHeader
        order={purchaseOrderState}
        templates={
          dataTemplates ? dataTemplates.findManyPurchaseOrderTemplates : []
        }
        setPurchaseOrderState={setPurchaseOrderState}
        constructionSites={constructionSites}
        constructionSiteLoading={loadingConstructionSites}
        constructionSiteSearch={constructionSiteSearch}
        suppliers={suppliers}
        statuses={
          dataStatuses ? dataStatuses.findManyPurchaseOrderStatuses : undefined
        }
        setSupplierSearch={setSupplierSearch}
        setConstructionSiteSearch={setConstructionSiteSearch}
        supplierSearch={supplierSearch}
        suppliersLoading={loadingSuppliers}
        isNew={!purchaseOrderId}
      />
      <PurchaseOrderBody
        purchaseOrder={purchaseOrderState.purchaseOrder}
        measuringUnits={
          dataMeasuringUnits ? dataMeasuringUnits.findManyMeasuringUnits : []
        }
        setPurchaseOrderState={newPurchaseOrder => {
          setPurchaseOrderState(prevState => ({
            ...prevState,
            purchaseOrder: newPurchaseOrder,
          }));
        }}
      />

      <PurchaseOrderFooter
        order={purchaseOrderState}
        setPurchaseOrderState={setPurchaseOrderState}
        constructionSite={purchaseOrderState.purchaseOrder.construction_site}
        suppliers={suppliers}
      />
      {showPDF && (
        <Stack
          styles={{
            root: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              marginTop: '0!important',
              display: 'flex',
              padding: '20px',
              zIndex: 2,
            },
          }}
        >
          <Stack
            horizontal
            horizontalAlign='space-between'
            styles={{ root: { marginBottom: 10 } }}
          >
            <Stack.Item>
              <h2 style={{ marginTop: 0 }}>PDF Bekijken</h2>
              <Stack tokens={stackTokens5} horizontal>
                <Stack.Item>
                  <Stack horizontalAlign='end'>
                    <PDFDownloadLink
                      document={
                        <PurchaseOrderDownloadTemplate
                          purchaseOrder={getPDFPurchaseOrder()}
                        />
                      }
                      fileName={getFileName()}
                    >
                      {({ loading }) => {
                        if (loading) return '';
                        return <PrimaryButton text='Pdf downloaden' />;
                      }}
                    </PDFDownloadLink>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel='Sluit venster'
                onClick={() => {
                  setShowPDF(false);
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <PurchaseOrderDownloadTemplate
                purchaseOrder={getPDFPurchaseOrder()}
              />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
};

export default PurchaseOrder;

import React from 'react';
import { IComboBoxOption, Stack } from '@fluentui/react';
import _ from 'lodash';
import { ComboboxWithFilter as ComboBox } from '../../../components/parts';
import {
  convertSuggestedValueCategoriesToComboBoxOptions,
  ISuggestedValueCategory,
  IProperty,
  stackTokens5,
} from '../../../utils';

interface ITemplateSuggestedValueItem {
  item: IProperty;
  callBack: (item: IProperty) => void;
  categories: ISuggestedValueCategory[] | undefined;
}

const TemplateSuggestedValueItem: React.FC<ITemplateSuggestedValueItem> = ({
  item,
  callBack,
  categories,
}) => {
  const selectedKey = item && item.remote_category && categories?.filter((category) => category.category_code === item.remote_category)[0].id
    ? categories?.filter((category) => category.category_code === item.remote_category)[0].id
    : 0;

  const setSuggestedValueID = (key: string | number) => {
    if (categories) {
      const currentItem = _.cloneDeep(item);
      for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === key) { currentItem.remote_category = categories[i].category_code; }
        currentItem.suggested_value = undefined;
      }

      callBack(currentItem);
    }
  };

  return (
    <Stack tokens={stackTokens5}>
      <ComboBox
        label='Voorgestelde waarde categorie'
        options={
          categories
            ? convertSuggestedValueCategoriesToComboBoxOptions(categories)
            : []
        }
        callBack={(value: IComboBoxOption[]) => {
          if (value && value.length > 0) setSuggestedValueID(value[0].key);
        }}
        required
        value={[selectedKey]}
      />
    </Stack>
  );
};

export default TemplateSuggestedValueItem;

import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import {
  IComboBoxOption, Stack, Text,
} from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { ISuggestedValue } from '../../../utils/SuggestedValue';

import {
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
  convertSuggestedValuesToComboBoxOptions,
} from '../../../utils';
import Combobox from '../../../components/parts/ComboBox';
import { IProperty } from '../../../utils/Property';

interface ISuggestedValueView {
  property: IProperty;
  callBack: (item: IProperty) => void;
}

const SuggestedValueView: React.FC<ISuggestedValueView> = ({
  property,
  callBack,
}) => {
  const [suggestedValueCategory, setSuggestedValueCategory] = useState<
    string | undefined
  >();

  const { loading, error, data } = useQuery(
    GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
    {
      variables: {
        filter: {
          suggested_value_category: {
            category_code: {
              contains: property?.remote_category,
            },
          },
          active: true,
        },
      },
      skip: property === undefined,
      onCompleted: (d) => {
        if (
          d
          && d.findManySuggestedValues
          && d.findManySuggestedValues.length > 0
        ) {
          const firstValue = d.findManySuggestedValues[0];
          if (
            firstValue.suggested_value_category
            && firstValue.suggested_value_category.category_description
          ) {
            setSuggestedValueCategory(
              firstValue.suggested_value_category.category_description,
            );
          }
        }
      },
    },
  );

  // const comboboxSuggestedValue = useRef<IComboBox>(null);

  if (loading) return <p>Laden...</p>;
  if (error) return <p>Fout :(</p>;

  const comboboxSuggestedValueOptions: IComboBoxOption[] = convertSuggestedValuesToComboBoxOptions(
    data.findManySuggestedValues,
  );

  if (!property || !property.type) {
    return <Stack>property niet goed geladen.</Stack>;
  }

  return (
    <Stack>
      <Text style={{ fontWeight: 'bold', marginBottom: '10px' }}>
        {suggestedValueCategory}
      </Text>
      <Combobox
        label={property.type.id === 8 && property.name}
        options={comboboxSuggestedValueOptions}
        selectedKey={
          property && property.suggested_value
            ? property.suggested_value.id
            : undefined
        }
        onSelect={(key) => {
          if (key && !Array.isArray(key)) {
            let newValue: undefined | string;
            let newCategory: undefined | string;
            if (data.findManySuggestedValues) {
              const filterValues = data.findManySuggestedValues.filter(
                (item: ISuggestedValue) => {
                  if (item && item.id && item.id === key) return true;
                  return false;
                },
              );

              if (filterValues && filterValues[0]) newValue = filterValues[0].suggested_value;
              newCategory = filterValues[0].suggested_value_category
                ? filterValues[0].suggested_value_category.category_description
                : undefined;
            }
            if (newValue) {
              const result = _.cloneDeep(property);
              if (typeof key === 'number') {
                result.suggested_value = {
                  id: key,
                  suggested_value: newValue,
                };

                result.isDirty = true;

                if (newCategory) {
                  result.suggested_value.suggested_value_category = {
                    category_description: newCategory,
                    id: moment().unix(),
                  };
                }
                callBack(result);
              }
            }
          }
        }}
      />
    </Stack>
  );
};

export default SuggestedValueView;

import React, { useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import _ from 'lodash';
import {
  IConstructionSheetRemark,
  IProperty,
  IPropertyListType,
} from '../../utils';
import ExecutionListOverview from '../implementation-list/components/ExecutionListOverview';
import WaterPoints from './components/WaterPoints';
import ConstructionListRemarks from './components/ConstructionListRemarks';

interface IProps {
  allTypes: any,
  callBackProperties: any,
  callBackRemarks: any,
  callBackWaterPoints: any,
  constructionSite: any,
  remarks: any,
  waterpoints: any,
  selectedKey: any,
  setSelectedPropertyID: any,
  setSelection: any,
  onLinkClick: any,
  editItem: any,
  setFilter: React.Dispatch<string>;
  filter?: string;
  moveItem: (a: IProperty, b: IProperty) => void;
}
const PropertyFilterView = ({
  allTypes,
  callBackProperties,
  callBackRemarks,
  callBackWaterPoints,
  constructionSite,
  remarks,
  waterpoints,
  selectedKey = 'Toon alles',
  setSelectedPropertyID,
  setSelection,
  onLinkClick,
  editItem,
  setFilter,
  filter,
  moveItem,
}: IProps) => {
  // Constants

  const SHOW_ALL = 'Toon alles';
  const TAP_POINTS = 'Tappunten';

  const filterRemarks = () => remarks?.filter(
    (el: IConstructionSheetRemark) => el.property_list_type.name === selectedKey,
  );

  // Functions
  const filterProperties = () => {
    const selection: IProperty[] = selectedKey === SHOW_ALL
      ? constructionSite?.properties.filter(
        (el: IProperty) => el.weight_for_execution_list > 0,
      )
      : constructionSite?.properties.filter(
        (el: IProperty) => el.list_types.filter(
          (ele: any) => ele.list_type.name === selectedKey,
        ).length > 0 && el.weight_for_execution_list > 0,
      );

    selection.sort((a, b) => a.weight_for_execution_list - b.weight_for_execution_list);

    return selection;
  };

  return (
    <Pivot
      aria-label='Werflijsten'
      onLinkClick={onLinkClick}
      selectedKey={selectedKey}
    >
      {allTypes.map((el: IPropertyListType) => (
        <PivotItem headerText={el.name} itemKey={el.name} key={el.name}>
          {constructionSite
              && (selectedKey === TAP_POINTS ? (
                <WaterPoints
                  properties={waterpoints}
                  callBackWaterPoints={callBackWaterPoints}
                  // setSelection={setSelection}
                  setSelectedPropertyID={setSelectedPropertyID}
                  editItem={editItem}
                />
              ) : (
                <div>
                  <ExecutionListOverview
                    // id={constructionSite?.id}
                    properties={filterProperties()}
                    dragAndDropEnabled={selectedKey === SHOW_ALL}
                    callBackProperties={callBackProperties}
                    // saveConstructionSite={saveConstructionSite}
                    setSelectedPropertyID={setSelectedPropertyID}
                    setSelection={setSelection}
                    editItem={editItem}
                    setFilter={setFilter}
                    filter={filter}
                    moveItem={moveItem}
                  />
                  &nbsp;
                  <ConstructionListRemarks
                    remarks={filterRemarks()}
                    callBackRemarks={callBackRemarks}
                  />
                </div>
              ))}
        </PivotItem>
      ))}
    </Pivot>
  );
};

export default PropertyFilterView;

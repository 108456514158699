import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import moment from 'moment';
import {
  IComboBoxOption,
  PrimaryButton,
  Stack,
  StackItem,
  Toggle,
} from '@fluentui/react';
import { useAppDispatch } from '../../redux/hooks';
import { throwError, SeverityLevel } from '../../redux/error/errorSlice';
import {
  convertEmployeesToComboBoxOptions,
  default_page_size,
  GET_EMPLOYEES,
  IEmployee,
  stackTokens15,
} from '../../utils';
import { GET_LOG_ITEMS, ILogItem } from '../../utils/LogItem';
import { ComboboxWithFilter } from '../../components/parts';
import useDebounce from '../../components/hooks/useDebounce';
import DateView from '../../components/parts/DateView';
import LogItemsOverview from './components/LogItemsOverview';

interface IPagedLogItem {
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const LogItems = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, user } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedLogItem>({
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'date_created',
  });

  const [dateFilter, setDateFilter] = useState({
    start_date: moment().subtract(1, 'year').toISOString(),
    end_date: '',
  });
  const [actionRequired, setActionRequired] = useState(true);
  const [currentUser, setCurrentUser] = useState<IEmployee>();
  const [salesRepFilter, setSalesRepFilter] = useState<number | undefined>();

  const { data: salesRepsData } = useQuery(GET_EMPLOYEES, {
    variables: {
      filter: {
        sales_rep: true,
      },
    },
    onCompleted: x => {
      console.log(x);
    },
  });

  useQuery(GET_EMPLOYEES, {
    variables: {
      filter: {
        app_user: {
          auth0_id: {
            equals: user && user.sub,
          },
        },
      },
    },
    skip: !user || !user.sub,
    onCompleted: x => {
      if (x && x.findManyEmployees && x.findManyEmployees.length > 0) {
        setCurrentUser(x.findManyEmployees[0]);
        setSalesRepFilter(x.findManyEmployees[0].id);
      }
    },
  });

  const debouncedDate = useDebounce(dateFilter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, skip: 0 }));
  }, [dateFilter, actionRequired, salesRepFilter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string

    if (pagedState.sortedField === 'sales_rep') {
      orderByObject.sales_rep = {
        first_name: pagedState.isSortedAsc ? 'asc' : 'desc',
      };
    } else if (pagedState.sortedField === 'lead') {
      orderByObject.lead = {
        first_name1: pagedState.isSortedAsc ? 'asc' : 'desc',
      };
    } else {
      orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
        ? 'asc'
        : 'desc';
    }

    return orderByObject;
  };

  const getFilters = (
    dateFilter: { end_date: string; start_date: string },
    actionRequired: boolean,
  ) => {
    const filterObject: any = {
      AND: [
        { action_required: actionRequired },
        {
          sales_rep: salesRepFilter
            ? {
                id: {
                  equals: salesRepFilter,
                },
              }
            : undefined,
          action_required_by:
            dateFilter.start_date && !dateFilter.end_date
              ? { gte: dateFilter.start_date }
              : dateFilter.end_date && !dateFilter.start_date
              ? { lte: dateFilter.end_date }
              : {},
          AND:
            dateFilter.start_date && dateFilter.end_date
              ? [
                  {
                    action_required_by: {
                      gte: moment(
                        new Date(dateFilter.start_date),
                      ).toISOString(),
                    },
                  },
                  {
                    action_required_by: {
                      lte: moment(new Date(dateFilter.end_date)).toISOString(),
                    },
                  },
                ]
              : [],
        },
      ],
    };

    return filterObject;
  };

  const {
    data: logItemsData,
    loading,
    error,
    fetchMore,
  } = useQuery(GET_LOG_ITEMS, {
    variables: {
      filter: getFilters(debouncedDate, actionRequired),
      take: pagedState.take,
      skip: 0,
      orderBy: getOrderBy(),
    },
    skip: !currentUser,
    onError: (error: any) => {
      dispatch(
        throwError({
          module: 'constructionsiteOverview',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });

  const onChangeFilterActionRequired = () => {
    setActionRequired(!actionRequired);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const fetchMoreLogItems = () => {
    fetchMore({
      variables: {
        filter: getFilters(debouncedDate, actionRequired),
        take: pagedState.take,
        skip: pagedState.skip,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Log items</h3>

      {/* <TextField
        label='Zoeken...'
        value={filter}
        styles={textFieldStyles300}
        onChange={onSearchValueChange}
      /> */}

      {currentUser &&
        currentUser.app_user &&
        currentUser.app_user.app_user_roles &&
        currentUser.app_user.app_user_roles.filter(
          item => item.name === 'SuperAdmin' || item.name === 'Administrator',
        ).length > 0 && (
          <Stack style={{ flexDirection: 'row' }}>
            {salesRepsData &&
              salesRepsData.findManyEmployees &&
              salesRepsData.findManyEmployees.length > 0 && (
                <StackItem style={{ width: 300, marginRight: 25 }}>
                  <ComboboxWithFilter
                    label='Verkoper'
                    options={convertEmployeesToComboBoxOptions(
                      salesRepsData.findManyEmployees,
                    )}
                    value={salesRepFilter}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        setSalesRepFilter(newValue[0].key as number);
                      }
                    }}
                  />
                </StackItem>
              )}

            <StackItem style={{ paddingTop: 29 }}>
              {currentUser && (
                <PrimaryButton
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    setSalesRepFilter(currentUser.id);
                  }}
                >
                  Mijn log items
                </PrimaryButton>
              )}

              <PrimaryButton
                onClick={() => {
                  setSalesRepFilter(undefined);
                }}
              >
                Filter leegmaken
              </PrimaryButton>
            </StackItem>
          </Stack>
        )}

      <Stack style={{ flexDirection: 'row' }}>
        <Stack style={{ flexDirection: 'row', marginRight: 100 }}>
          <Stack style={{ marginBottom: 5, marginRight: 15 }}>
            <DateView
              item={dateFilter}
              setItem={setDateFilter}
              date={dateFilter && dateFilter.start_date}
              label='Startdatum'
              field='start_date'
            />
          </Stack>

          <Stack style={{ marginBottom: 5 }}>
            <DateView
              item={dateFilter}
              setItem={setDateFilter}
              date={dateFilter && dateFilter.end_date}
              label='Einddatum'
              field='end_date'
            />
          </Stack>
        </Stack>

        <Toggle
          label='Actie nodig?'
          defaultChecked={actionRequired}
          onText='Actie nodig'
          offText='Alles tonen'
          onChange={onChangeFilterActionRequired}
        />
      </Stack>

      <LogItemsOverview
        logItems={
          logItemsData && logItemsData.findManyLogItems
            ? logItemsData.findManyLogItems
            : []
        }
        loading={loading}
        loadMore
        showLoadMore
        lazyLoading
        loadMoreCallback={fetchMoreLogItems}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
      />
    </Stack>
  );
};

export default LogItems;

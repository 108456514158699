import { gql } from '@apollo/client';
import { IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { IAppUser } from './AppUser';

export const GET_EMPLOYEES = gql`
  query getEmployees(
    $filter: EmployeeFilterInput
    $skip: Int
    $take: Int
    $orderBy: EmployeeOrderByInput
  ) {
    findManyEmployees(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      active
      first_name
      email
      function
      is_external
      last_name
      epb_reporter
      safety_coordinator
      construction_site_manager
      sales_rep
      customer_manager
      mobile
      mobile_v2
      private_mobile
      private_mobile_v2
      app_user {
        id
        email
        auth0_id
        date_created
        email
        id
        last_updated
        username
        version
        app_user_roles {
          id
          name
          description
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_BY_ID = gql`
  query getEmployeeById($where: EmployeeWhereInput) {
    findOneEmployee(where: $where) {
      active
      address
      bank_account_number
      bic
      birthday
      birthday_partner
      birth_place
      category
      children
      city
      civil_class
      civil_class_date
      comments
      construction_site_manager
      customer_manager
      dependants
      dependent_children
      diploma
      driver
      email
      employment_method
      end_date
      epb_reporter
      fax
      first_name
      function
      hourly_rate
      hourly_rate_for_calculation
      iban
      id
      is_external
      jacket_size
      last_name
      mobile
      mobile_v2
      mobile_partner
      mobile_partner_v2
      monthly_rate
      name_partner
      pants_size
      passport_no
      phone
      phone_v2
      private_mobile
      private_mobile_v2
      safety_coordinator
      sales_rep
      salary_category
      shirt_size
      shoe_size
      sis_no
      start_date
      sweater_size
      version
      zip_code

      app_user {
        account_locked
        auth0_id
        date_created
        email
        id
        last_updated
        username
        version
        app_user_roles {
          id
          name
          description
        }
      }

      employee_dependent_children {
        id
        current_status
        end_date
        number_of_dependent_children
        start_date
      }

      civil_class_statuses {
        id
        civil_class
        current_status
        end_date
        start_date
      }
    }
  }
`;

export const ADD_EMPLOYEE = gql`
  mutation addEmployee($data: EmployeeCreateInput!) {
    createEmployee(data: $data) {
      active
      address
      bank_account_number
      bic
      birthday
      birthday_partner
      birth_place
      category
      children
      city
      civil_class
      civil_class_date
      comments
      construction_site_manager
      customer_manager
      dependants
      dependent_children
      diploma
      driver
      email
      employment_method
      end_date
      epb_reporter
      fax
      first_name
      function
      hourly_rate
      hourly_rate_for_calculation
      iban
      id
      is_external
      jacket_size
      last_name
      mobile
      mobile_v2
      mobile_partner
      mobile_partner_v2
      monthly_rate
      name_partner
      pants_size
      passport_no
      phone
      phone_v2
      private_mobile
      private_mobile_v2
      safety_coordinator
      sales_rep
      salary_category
      shirt_size
      shoe_size
      sis_no
      start_date
      sweater_size
      version
      zip_code

      app_user {
        account_locked
        auth0_id
        date_created
        email
        id
        last_updated
        username
        version
        app_user_roles {
          id
          name
          description
        }
      }
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($id: Int!, $data: EmployeeUpdateInput!) {
    updateEmployee(id: $id, data: $data) {
      active
      address
      bank_account_number
      bic
      birthday
      birthday_partner
      birth_place
      category
      children
      city
      civil_class
      civil_class_date
      comments
      construction_site_manager
      customer_manager
      dependants
      dependent_children
      diploma
      driver
      email
      employment_method
      end_date
      epb_reporter
      fax
      first_name
      function
      hourly_rate
      hourly_rate_for_calculation
      iban
      id
      is_external
      jacket_size
      last_name
      mobile
      mobile_v2
      mobile_partner
      mobile_partner_v2
      monthly_rate
      name_partner
      pants_size
      passport_no
      phone
      phone_v2
      private_mobile
      private_mobile_v2
      safety_coordinator
      sales_rep
      salary_category
      shirt_size
      shoe_size
      sis_no
      start_date
      sweater_size
      version
      zip_code

      app_user {
        account_locked
        auth0_id
        date_created
        email
        id
        last_updated
        username
        version
        app_user_roles {
          id
          name
          description
        }
      }

      employee_dependent_children {
        id
        current_status
        end_date
        number_of_dependent_children
        start_date
      }

      civil_class_statuses {
        id
        civil_class
        current_status
        end_date
        start_date
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createAppUser($data: AppUserCreateInput!) {
    createAppUser(data: $data) {
      account_locked
      auth0_id
      date_created
      email
      id
      last_updated
      sales_rep_id
      username
      version
      app_user_roles {
        id
        name
        description
      }
      password
    }
  }
`;

export interface IEmployee {
  active?: boolean;
  isDirty?: boolean;
  auth_id?: number;
  app_user?: IAppUser;
  address?: string;
  bank_account_number?: string;
  bic?: string;
  birthday?: Date;
  birthday_partner?: Date;
  birth_place?: string;
  category?: string;
  children?: number;
  city?: string;
  civil_class?: string;
  civil_class_date?: Date;
  comments?: string;
  construction_site_manager?: boolean;
  customer_manager?: boolean;
  dependants?: string;
  dependent_children?: number;
  diploma?: string;
  driver?: boolean;
  email?: string;
  employment_method?: string;
  end_date?: Date;
  epb_reporter?: boolean;
  fax?: string;
  first_name?: string;
  function?: string;
  hourly_rate?: number;
  hourly_rate_for_calculation?: number;
  iban?: string;
  id: number;
  is_external?: boolean;
  jacket_size?: string;
  last_name?: string;
  mobile?: string;
  mobile_v2?: string;
  mobile_partner?: string;
  mobile_partner_v2?: string;
  monthly_rate?: number;
  name_partner?: string;
  pants_size?: string;
  passport_no?: string;
  phone?: string;
  phone_v2?: string;
  private_mobile?: string;
  private_mobile_v2?: string;
  safety_coordinator?: boolean;
  salary_category?: string;
  sales_rep?: boolean;
  shirt_size?: string;
  shoe_size?: string;
  sis_no?: string;
  start_date?: Date;
  sweater_size?: string;
  version?: number;
  zip_code?: number;
}

export const displayNameEmployee = (employee: IEmployee) =>
  '{first_name}{last_name}'
    .replace(
      '{first_name}',
      employee.first_name && employee.first_name !== null
        ? `${employee.first_name} `
        : '',
    )
    .replace(
      '{last_name}',
      employee.last_name && employee.last_name !== null
        ? `${employee.last_name} `
        : '',
    );

export const convertEmployeesToComboBoxOptions = (employees: IEmployee[]) => {
  if (employees) {
    const result: IComboBoxOption[] = employees.map(
      (employee: IEmployee): IComboBoxOption => ({
        key: employee.id,
        text: displayNameEmployee(employee),
      }),
    );
    return result;
  }

  return [];
};

export const convertEmployeesToDropdownOptions = (employees: IEmployee[]) => {
  const result: IDropdownOption[] = employees.map(
    (employee: IEmployee): IDropdownOption => ({
      key: employee.id,
      text: displayNameEmployee(employee),
    }),
  );
  return result;
};

import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';

export const GET_PROPERTY_TYPES = gql`
  query GetPropertyTypes($orderBy: PropertyTypeOrderBy) {
    findManyPropertyTypes(orderBy: $orderBy) {
      id
      name
      label
    }
  }
`;

export const convertPropertyTypeToComboBoxOptions = (
  propertyTypes: IPropertyType[],
) => {
  const result: IComboBoxOption[] = propertyTypes.map(
    (propertyType: IPropertyType): IComboBoxOption => ({
      key: propertyType.id,
      text: propertyType.label,
    }),
  );
  return result;
};

export interface IPropertyType {
  id: number;
  name: string;
  label: string;
}

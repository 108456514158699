import React from 'react';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import Briefpapier from '../../../Sjabloon-briefpapier.jpg';
import fontSegoe from '../../../fonts/Segoe_UI.ttf';
import fontSegoeBold from '../../../fonts/Segoe_UI_Bold.ttf';
import { ICustomerInvoice } from '../../../utils/CustomerInvoice';
import { ICustomerInvoiceLineItem } from '../../../utils/CustomerInvoiceLineItem';
import { displayNameLead } from '../../../utils/Lead';

Font.register({
  family: 'Segoe UI',
  fonts: [{ src: fontSegoe }, { src: fontSegoeBold, fontStyle: 'bold' }],
});

const CustomerInvoiceDownloadTemplate = ({
  customerInvoice,
}: {
  customerInvoice: ICustomerInvoice;
}) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100vw',
          height: '100vh',
        }}
      >
        <Image src={Briefpapier} style={{ width: '100%', height: '100%' }} />
      </View>

      {/* Header repeated on every page */}
      <PageHeader customerInvoice={customerInvoice} />

      <View style={styles.pageHeader}>
        <PageSupplierInfo customerInvoice={customerInvoice} />
      </View>

      <View>
        <Text
          style={{
          paddingTop: 40,
          paddingRight: 5,
          fontFamily: 'Segoe UI',
          fontSize: 10,
          lineHeight: '120%',
        }}
        >{customerInvoice.intro}
        </Text>
      </View>
      {/* CustomerInvoice */}
      <PageCustomerInvoiceLines customerInvoice={customerInvoice} />
      <PageTotal customerInvoice={customerInvoice} />

      {/* <PageCustomerInvoiceDeliveryDetails customerInvoice={customerInvoice} /> */}

      {/* Footer repeated on every page */}
      {/* Legal information */}
      {/* <PageLegalInfo /> */}
      <Text style={styles.now} fixed>
        {moment(new Date()).format('DD/MM/YYYY')}
      </Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Pagina ${pageNumber} van ${totalPages}`
        }
        fixed
      />
    </Page>
  </Document>
);

const PageHeader = ({
  customerInvoice,
}: {
  customerInvoice: ICustomerInvoice;
}) => (
  <View style={styles.pageHeaderWrapper} fixed>
    <View style={styles.pageHeader}>
      {/* <Image src={Logo} style={styles.image} /> */}
      <View>
        <Text style={styles.pageHeaderTitle}>
          Factuur {customerInvoice.invoice_no}
        </Text>
        <Text style={styles.pageHeaderName}>
          {customerInvoice && customerInvoice.construction_site && (
            <>Werf: {customerInvoice.construction_site.name}</>
          )}
        </Text>
      </View>
    </View>
  </View>
);

// const PageLegalInfo = () => (
//   <View style={[styles.legalInfo]} fixed wrap={false}>
//     <View style={styles.legalInfoRow}>
//       <Text>
//         <Text style={{ fontStyle: 'bold' }}>3Bouw BV</Text> Zone Reme 1, 2260
//         Westerlo <Text style={{ fontStyle: 'bold' }}>T</Text> 014/70 47 47{' '}
//         <Text style={{ fontStyle: 'bold' }}>E</Text> info@3bouw.be{' '}
//         <Text style={{ fontStyle: 'bold' }}>BTW</Text> BE 0884.733.931
//       </Text>
//     </View>
//     <View style={styles.legalInfoRow}>
//       <Text>
//         RPR Antwerpen, afd. Turnhout &bull; Erkend aannemer klasse 5 onder
//         erkenningsnr.: 32.454 <Text style={{ fontStyle: 'bold' }}>KBC</Text>{' '}
//         iban BE16 7310 2542 3974
//       </Text>
//     </View>
//   </View>
// );

const PageTotal = ({
  customerInvoice,
}: {
  customerInvoice: ICustomerInvoice;
}) => {
  const uniqueVatPercentages = [
    ...new Set(
      customerInvoice.customer_invoice_line_items.map(
        item => item.percentage_vat,
      ),
    ).values(),
  ];

  return (
    <View
      style={{
        paddingTop: 40,
        paddingRight: 5,
        fontFamily: 'Segoe UI',
        fontSize: 10,
        lineHeight: '120%',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: '65%',
        }}
      >
        <Text style={{ width: '50%' }}>Totaal excl. BTW:</Text>
        <Text style={{ width: '50%', textAlign: 'right' }}>
          &euro;{' '}
          {customerInvoice.customer_invoice_line_items
            .reduce(
              (previousValue, currentValue) =>
                previousValue + currentValue.unit_price,
              0,
            )
            .toFixed(2)}
        </Text>
      </View>

      {uniqueVatPercentages.map((percentage, i: number) => (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 10,
            paddingLeft: '65%',
          }}
          key={`unique-vat-${i}`}
        >
          <Text style={{ width: '50%' }}>
            Totaal BTW {percentage > 1 ? percentage : percentage * 100}%:
          </Text>
          <Text style={{ width: '50%', textAlign: 'right' }}>
            &euro;{' '}
            {customerInvoice.customer_invoice_line_items
              .filter(x => x.percentage_vat === percentage)
              .reduce(
                (previousValue, currentValue) =>
                  previousValue +
                  (!currentValue.percentage_vat ||
                  +currentValue.percentage_vat === 0
                    ? 0
                    : +currentValue.percentage_vat < 1
                    ? currentValue.unit_price * currentValue.percentage_vat
                    : currentValue.unit_price *
                      (currentValue.percentage_vat / 100)),
                0,
              )
              .toFixed(2)}
          </Text>
        </View>
      ))}

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: 20,
          fontStyle: 'bold',
          paddingLeft: '65%',
        }}
      >
        <Text style={{ width: '50%' }}>Totaal incl. BTW:</Text>
        <Text style={{ width: '50%', textAlign: 'right' }}>
          &euro;{' '}
          {customerInvoice.customer_invoice_line_items
            .reduce(
              (previousValue, currentValue) =>
                previousValue +
                (!currentValue.percentage_vat ||
                +currentValue.percentage_vat === 0
                  ? currentValue.unit_price * currentValue.units
                  : +currentValue.percentage_vat < 1
                  ? currentValue.unit_price *
                    currentValue.units *
                    (1 + currentValue.percentage_vat)
                  : currentValue.unit_price *
                    currentValue.units *
                    (1 + currentValue.percentage_vat / 100)),
              0,
            )
            .toFixed(2)}
        </Text>
      </View>

      <View>
        <Text
          style={{
          paddingTop: 40,
          paddingRight: 5,
          fontFamily: 'Segoe UI',
          fontSize: 10,
          lineHeight: '120%',
        }}
        >{customerInvoice.outro}
        </Text>
      </View>

      <View style={{ paddingTop: 30 }}>
        <Text>
          Gelieve te betalen op onderstaande bankrekeningnummer met
          vermelding van het factuurnummer.
        </Text>
      </View>
    </View>
  );
};

const PageCustomerInvoiceLines = ({
  customerInvoice,
}: {
  customerInvoice: ICustomerInvoice;
}) => (
  <View style={styles.table}>
    {/* Table header repeated on every page */}
    <View style={styles.tableRowHeader} fixed>
      <Text style={styles.tableColDescription}>Omschrijving</Text>
      <Text style={styles.tableColBTW}>BTW %</Text>
      <Text style={styles.tableColPrice}>Prijs excl. BTW</Text>
    </View>

    {/* Lines */}
    {customerInvoice.customer_invoice_line_items &&
    customerInvoice.customer_invoice_line_items.length > 0
      ? customerInvoice.customer_invoice_line_items.map(
          (line: ICustomerInvoiceLineItem, i: number) => (
            <View key={`line-${i}`} style={styles.tableRow} wrap={false}>
              <Text style={styles.tableColDescription}>{line.description}</Text>
              <Text style={styles.tableColBTW}>
                {line.percentage_vat
                  ? line.percentage_vat > 1
                    ? `${line.percentage_vat}%`
                    : `${line.percentage_vat * 100}%`
                  : null}
              </Text>
              <Text style={styles.tableColPrice}>
                {line.unit_price ? `€ ${line.unit_price.toFixed(2)}` : null}
              </Text>
            </View>
          ),
        )
      : null}
  </View>
);

const PageSupplierInfo = ({
  customerInvoice,
}: {
  customerInvoice: ICustomerInvoice;
}) => (
  <View style={{ paddingTop: '40px' }}>
    <View style={styles.supplierDetailsRow}>
      <View style={{
 flexDirection: 'column', width: '50%', overflow: 'hidden',
}}
      >
        {customerInvoice.lead && (
          <Text>
            {displayNameLead(customerInvoice.lead)}
            {'\n'}
            {customerInvoice.lead.address1}
            {'\n'}
            {customerInvoice.lead.zip_code1} {customerInvoice.lead.city1}
          </Text>
        )}
      </View>

      {customerInvoice.lead && (
        <View style={styles.supplierDetailsRight}>
          <PageSupplierInfoRow
            label='Tel.'
            value={
              customerInvoice.lead &&
              (customerInvoice.lead.mobile1_v2 ||
                customerInvoice.lead.mobile1 ||
                customerInvoice.lead.mobile2_v2 ||
                customerInvoice.lead.mobile2 ||
                customerInvoice.lead.phone1_v2 ||
                customerInvoice.lead.phone1 ||
                customerInvoice.lead.phone2_v2 ||
                customerInvoice.lead.phone2)
              // (customerInvoice.lead.mobile1_v2 ||
              //   customerInvoice.lead.mobile2_v2 ||
              //   customerInvoice.lead.phone1_v2 ||
              //   customerInvoice.lead.phone2_v2)
            }
          />

          <PageSupplierInfoRow
            label='E-mail'
            value={customerInvoice.lead.email1 || customerInvoice.lead.email2}
          />
          <PageSupplierInfoRow
            label='Factuurnummer'
            value={customerInvoice.invoice_no}
          />
          <PageSupplierInfoRow
            label='Factuurdatum'
            value={moment(new Date(customerInvoice.invoice_date)).format(
            'DD/MM/YYYY',
          )}
          />
          <PageSupplierInfoRow
            label='Vervaldatum'
            value={moment(new Date(customerInvoice.invoice_due_date)).format(
            'DD/MM/YYYY',
          )}
          />
          {customerInvoice.lead.vat && (
            <PageSupplierInfoRow
              label='BTW-nr'
              value={customerInvoice.lead.vat}
            />
          )}
        </View>
      )}
    </View>
  </View>
);

const PageSupplierInfoRow = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <View style={styles.supplierDetailsRow}>
    <Text style={styles.supplierDetailsLabelAlt}>{label}:</Text>
    <Text style={styles.supplierDetailsValueAlt}>{value}</Text>
  </View>
);

const PageInvoiceInfoRow = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <View style={styles.invoiceDetailsRow}>
    <Text style={styles.invoiceDetailsLabelAlt}>{label}:</Text>
    <Text style={styles.invoiceDetailsValueAlt}>{value}</Text>
  </View>
);

const styles = StyleSheet.create({
  image: {
    width: '100px',
    objectFit: 'scale-down',
    marginRight: 15,
  },
  invoiceDetailsRow: {
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
  },
  invoiceDetailsLabelAlt: {
    fontStyle: 'bold',
    width: '20%',
  },
  invoiceDetailsValueAlt: {
    width: '80%',
  },
  legalInfo: {
    fontFamily: 'Segoe UI',
    fontSize: 8,
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 140,
    paddingTop: 2,
  },
  legalInfoRow: {
    flexDirection: 'row',
  },
  now: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 55,
    right: 40,
  },
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 60,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 95,
  },
  pageHeaderWrapper: {
    position: 'absolute',
    top: 35,
    left: 45,
    right: 45,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  pageHeaderName: {
    fontFamily: 'Segoe UI',
    fontSize: 12,
  },
  pageHeaderTitle: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
    paddingBottom: 5,
  },
  pageNumber: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 43,
    left: 0,
    right: 40,
    textAlign: 'right',
  },
  supplierDetailsLabel: {
    fontStyle: 'bold',
    width: '11%',
  },
  supplierDetailsLabelAlt: {
    fontStyle: 'bold',
    width: '40%',
  },
  supplierDetailsRight: {
    width: '49%',
    borderLeft: '1px solid black',
    paddingLeft: 10,
  },
  supplierDetailsRow: {
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
  },
  supplierDetailsValueAlt: {
    width: '59%',
  },
  table: {
    display: 'flex',
    width: 'auto',
    paddingTop: '15px',
  },
  tableColDescription: {
    width: '60%',
  },
  tableColBTW: {
    width: '5%',
  },
  tableColPrice: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 5,
  },
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#D2D4D1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
  tableRowHeader: {
    backgroundColor: '#D2D4D1',
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    justifyContent: 'space-between',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
});

export default CustomerInvoiceDownloadTemplate;

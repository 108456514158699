import { gql } from '@apollo/client';
import { ICostCenter } from './CostCenter';
import { ILead } from './Lead';
import { ICustomer, IEmployee } from '.';

export interface ITimesheetEntry {
  id: number;
  cost_center: ICostCenter;
  customer: ICustomer;
  lead: ILead;
  employee: IEmployee;
  status: string;
  values: [ITimesheetEntry];
}

export const GET_TIMESHEETS = gql`
  query getTimeSheets($duration: Int, $start: String, $employee: Int) {
    findTimesheetsByEmployee(
      duration: $duration
      start: $start
      employee: $employee
    ) {
      customer {
        id
        first_name1
        last_name1
        first_name2
        last_name2
      }
      lead {
        id
        first_name1
        last_name1
        first_name2
        last_name2
      }
      employee {
        id
        first_name
        last_name
        hourly_rate_for_calculation
      }
      cost_center {
        id
      }
      construction_site {
        id
        name
      }
      status
      values {
        id
        entry_date
        hours
      }
    }
  }
`;

export const GET_TIMESHEETS_BY_CUSTOMER = gql`
  query getTimeSheets(
    $duration: Int
    $start: String
    $customer: Int
    $construction_site: Int
    $lead: Int
  ) {
    findTimesheetsByCustomer(
      duration: $duration
      start: $start
      customer: $customer
      construction_site: $construction_site
      lead: $lead
    ) {
      customer {
        id
        first_name1
        last_name1
        first_name2
        last_name2
      }
      lead {
        id
        first_name1
        last_name1
        first_name2
        last_name2
      }
      employee {
        id
        first_name
        last_name
        hourly_rate_for_calculation
      }
      cost_center {
        id
      }
      construction_site {
        id
      }
      status
      values {
        id
        entry_date
        hours
      }
    }
  }
`;

export const SAVE_TIMESHEETS = gql`
  mutation saveTimesheets($data: [SaveTimesheet]!) {
    saveTimesheetObject(data: $data) {
      customer {
        id
        first_name1
        last_name1
        first_name2
        last_name2
      }
      lead {
        id
        first_name1
        last_name1
        first_name2
        last_name2
      }
      employee {
        id
        first_name
        last_name
        hourly_rate_for_calculation
      }
      cost_center {
        id
      }
      construction_site {
        id
      }
      status
      values {
        id
        entry_date
        hours
      }
    }
  }
`;

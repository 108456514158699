import React, {
  useState,
  useRef,
  ReactElement,
  PropsWithChildren,
  useEffect,
} from 'react';
import { Stack, Text, IStackItemStyles } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { getTheme } from '@fluentui/react/lib/Styling';
import { iconProps } from '../../utils';

const theme = getTheme();

type IAccordion = {
  selectedKey?: number | string;
  defaultKey?: number | string;
  toggleItem: (id: number | string) => void;
};

type IAccordionItem = {
  isActive?: boolean;
  title?: string | React.ReactNode;
  toggleItem?: (id: number | string) => void;
  id: any;
};
const Accordion: React.FC<IAccordion> = ({
  children,
  selectedKey,
  defaultKey,
  toggleItem,
}) => {
  const childrenWithProps = React.Children.map(children, (child, index) => {
    const item = child as ReactElement<PropsWithChildren<IAccordionItem>>;
    const activeKey = selectedKey || selectedKey === 0 ? selectedKey : defaultKey;

    if (item && item.type === AccordionItem) {
      return React.cloneElement(item, {
        isActive:
          item && item.props && item.props.id && item.props.id === activeKey,
        toggleItem,
      });
    }
  });
  return <Stack>{childrenWithProps}</Stack>;
};

const AccordionItem: React.FC<IAccordionItem> = ({
  children,
  isActive = false,
  toggleItem = (id) => {},
  id,
  title,
}) => {
  const subItemRef = useRef<HTMLDivElement>(null);
  const [elementHeight, setElementHeight] = useState<number | undefined>();
  useEffect(() => {
    if (subItemRef) {
      const element = subItemRef.current;
      const scrollHeight = element ? element.scrollHeight : undefined;

      setElementHeight(scrollHeight);
    }
  }, [subItemRef, isActive, children]);

  const styleTitle: IStackItemStyles = {
    root: {
      background: theme.palette.neutralLight,
      padding: '10px',
      color: 'black',
      fontSize: '12px',
      ':hover': {
        cursor: 'pointer',
      },
    },
  };
  return (
    <Stack.Item style={{ marginTop: '10px' }}>
      <Stack>
        <Stack
          // onClick={() => toggleItem(id)}
          styles={styleTitle}
          horizontal
          horizontalAlign='space-between'
          onClick={() => toggleItem(id)}
        >
          {title}
          <Text onClick={() => toggleItem(id)}>
            {isActive ? (
              <Icon iconName='StatusCircleBlock2' styles={iconProps} />
            ) : (
              <Icon iconName='NumberedListTextMirrored' styles={iconProps} />
            )}
          </Text>
        </Stack>
        {isActive && (
        <Stack.Item
          styles={{ root: { background: theme.palette.neutralLighter } }}
        >
          <div ref={subItemRef} style={{ padding: '18px' }}>
            {children}
          </div>
        </Stack.Item>
        )}

      </Stack>
    </Stack.Item>
  );
};

export default Accordion;

export { Accordion, AccordionItem };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export declare enum NotificationLevel {
    Information = 0,
    Success = 1,
    Warning = 2,
}

// for more info => https://github.com/reduxjs/cra-template-redux-typescript

export interface NotificationState {
    module?: string;
    message?: string;
    level?: number;
    active: boolean;
    timeout?: number;
    spinner?: boolean;
}

export interface SendNotification {
    module: string;
    message: string;
    level: NotificationLevel;
    timeout?: number;
    spinner?: boolean;
}

const initialState: NotificationState = {
  active: false,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    sendNotification: (state, action: PayloadAction<SendNotification>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const {
        level, message, module, timeout, spinner,
      } = action.payload;
      state.message = message;
      state.module = module;
      state.level = level;
      state.active = true;
      state.timeout = timeout;
      state.spinner = spinner;
    },
    dismissNotification: (state) => {
      state.active = false;
    },
  },
});

export const { sendNotification, dismissNotification } = notificationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getNotification = (state: RootState) => state.notification;

export default notificationSlice.reducer;

import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSearchParams } from 'react-router-dom';
import {
  GET_PURCHASE_ORDERS,
  IPurchaseOrder,
  stackTokens15,
} from '../../utils';
import PurchaseOrdersOverview from './components/PurchaseOrdersOverview';

function PurchaseOrders() {
  const [searchParams] = useSearchParams();

  const construction_site = searchParams.get('construction-site');
  const redirect = searchParams.get('redirect');
  const getQueryParams = () => {
    if (construction_site) {
      return {
        filter: {
          construction_site: { id: +construction_site },
        },
        orderBy: {
          creation_date: 'desc',
        },
      };
    }
    return {
      orderBy: {
        creation_date: 'desc',
      },
    };
  };

  const { isAuthenticated } = useAuth0();

  const [purchaseOrders, setPurchaseOrders] = useState<IPurchaseOrder[]>([]);

  const { loading, error, refetch } = useQuery(GET_PURCHASE_ORDERS, {
    variables: getQueryParams(),
    onCompleted: (x: any) => setPurchaseOrders(x.findManyPurchaseOrders),
  });

  if (!isAuthenticated) return <p>Verboden</p>;
  if (loading) return <p>Laden...</p>;
  if (error) return <p>Fout :(</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Bestelbonnen</h3>

      <PurchaseOrdersOverview
        orders={purchaseOrders}
        refetch={refetch}
        setPurchaseOrders={setPurchaseOrders}
        showBack={!!construction_site}
        constructionSite={construction_site || null}
        redirectTarget={redirect || null}
      />
    </Stack>
  );
}

export default PurchaseOrders;

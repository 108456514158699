import React, { FormEvent } from 'react';
import { Stack, TextField } from '@fluentui/react';

import { property_types, IProperty, stackTokens5 } from '../../../utils';
import TextView from './TextView';
import DocumentView from './DocumentView';
import SuggestedValueView from './SuggestedValueView';

const ParentView = ({ callBack, property }: any) => {
  const updateProperty = (value?: string) => {
    const result = property;
    result.isDirty = true;
    result.value = value;
    return result;
  };

  const updateChildProperties = (childProperty: IProperty) => {
    const result = property;
    childProperty.isDirty = true;
    result.isDirty = true;
    result.children = result.children.map((otherChildProperty: IProperty) => (otherChildProperty.id === childProperty.id
      ? childProperty
      : otherChildProperty));
    callBack(result);
  };

  const renderChildren = () => property?.children.map((childProperty: IProperty) => {
    switch (childProperty.type.name) {
      case property_types.STRING:
      case property_types.DATE:
        return (
          <TextView
            key={childProperty.id}
            property={childProperty}
            callBack={updateChildProperties}
            edit={false}
          />
        );
      case property_types.DOCUMENT:
        return (
          <DocumentView
            key={childProperty.id}
            property={childProperty}
            callBack={updateChildProperties}
            edit={false}
          />
        );
      case property_types.MULTI_VALUE:
        console.log(childProperty);
        return <span>{childProperty.name}</span>;
      case property_types.NOTE:
        return <span>{childProperty.value}</span>;
      case property_types.SUGGESTED_VALUE:
        return (
          <SuggestedValueView
            key={childProperty.id}
            property={childProperty}
            callBack={updateChildProperties}
          />
        );
      default:
        console.log(childProperty.type.name);
        return <span>Error</span>;
    }
  });

  if (property?.isEdit) {
    return (
      <Stack tokens={stackTokens5} styles={{ root: { paddingBottom: '7px' } }}>
        <TextField
          label='Opmerkingen'
          value={property.value}
          multiline
          rows={3}
          onChange={(
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            callBack(updateProperty(newValue));
          }}
        />

        {renderChildren()}
      </Stack>
    );
  }
  return (
    <Stack tokens={stackTokens5}>
      {property?.value ? <span>{property?.value}</span> : null}

      {renderChildren()}
    </Stack>
  );
};

export default ParentView;

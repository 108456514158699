import {
  IComboBoxOption,
  Icon,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  TextField,
  DayOfWeek,
  defaultDatePickerStrings,
  DatePicker,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/nl-be';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ComboboxWithFilter } from '../../../components/parts';
import {
  convertEmployeesToComboBoxOptions,
  GET_CONSTRUCTION_SITES,
  GET_EMPLOYEES,
  iconLastProps,
  IConstructionSite,
  ICustomer,
} from '../../../utils';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';
import {
  convertCostCentersToComboBoxOptions,
  GET_COST_CENTERS,
  ICostCenter,
} from '../../../utils/CostCenter';
import { SAVE_TIMESHEETS } from '../../../utils/Timesheet';
import { GET_LEADS, ILead } from '../../../utils/Lead';
import SuggestedViewStatus from './SuggestedViewStatus';
import ComboboxProject from './ComboboxProject';

// moment.locale('nl-be');
interface ITimesheetEntryWithType extends ITimesheetEntry {
  type: string;
  values: any[];
  customers: ICustomer;
  lead: ILead;
  cost_center: ICostCenter;
  construction_site: IConstructionSite;
}

function NewTimesheetEmployee({ timesheet, setTimesheet, getTimesheet }: any) {
  const { loading: employeesLoading, data: employeesData } = useQuery(
    GET_EMPLOYEES,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const { loading: customersLoading, data: customersData } = useQuery(
    GET_LEADS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        filter: { AND: [{ status: { contains: 'SOLD' } }] },
        take: 10,
      },
    },
  );

  const { loading: projectsLoading, data: projectsData } = useQuery(
    GET_CONSTRUCTION_SITES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        take: 10,
      },
    },
  );

  const { data: costCentersData } = useQuery(GET_COST_CENTERS, {
    fetchPolicy: 'no-cache',
  });

  const [currentEmployee, setCurrentEmployee] = useState<number | string>();
  const [loader, setLoader] = useState(false);

  const [currentDate, setCurrentDate] = useState(moment().startOf('week'));
  // const now = moment().startOf('d');
  const [days, setDays] = useState<Moment[]>([]);

  useEffect(() => {
    const startMoment = moment(currentDate).startOf('week');
    for (let i = 0; i < 7; i++) {
      setDays(prevArray => [...prevArray, moment(startMoment).add(i, 'd')]);
    }
  }, [currentDate]);

  const [workedHours, setWorkedHours] = useState(0);
  const [firstSortingDone, setfirstSortingDone] = useState(false);

  useEffect(() => {
    let hours = 0;
    timesheet.map((entry: any, i: number) => {
      entry.values.map((value: any, i: number) => {
        hours += value.hours;
      });
    });
    setWorkedHours(hours);

    if (!firstSortingDone && timesheet && timesheet.length > 0) {
      // Sort timesheet
      timesheet.sort((obj1: ITimesheetEntry, obj2: ITimesheetEntry) => {
        if (
          (obj1.construction_site || obj1.lead || obj1.customer) &&
          (obj2.construction_site || obj2.lead || obj2.customer)
        ) {
          const x1 = obj1.construction_site
            ? obj1.construction_site.name
            : obj1.lead
            ? obj1.lead.first_name1
            : obj1.customer.first_name1;
          const x2 = obj2.construction_site
            ? obj2.construction_site.name
            : obj2.lead
            ? obj2.lead.first_name1
            : obj2.customer
            ? obj2.customer.first_name1
            : undefined;
          if (x2) {
            return x1.localeCompare(x2);
          }
          return 1;
        }
        return obj1.status.localeCompare(obj2.status);
      });
      setfirstSortingDone(true);
    }
  }, [timesheet]);

  const [saveTimesheetEntries] = useMutation(SAVE_TIMESHEETS);

  const getValues = (values: any) => {
    const valuesWithoutType: any[] = [];
    values.map((value: any, i: number) => {
      valuesWithoutType.push({
        id: value.id || undefined,
        hours: value.hours,
        entry_date: value.entry_date,
      });
    });

    return valuesWithoutType;
  };

  const saveTimesheet = () => {
    setLoader(true);
    if (timesheet) {
      const allInput: any[] = [];
      timesheet.map((timesheetEntry: ITimesheetEntryWithType, i: number) => {
        allInput.push({
          customer: timesheetEntry.customer
            ? { id: timesheetEntry.customer.id }
            : undefined,
          lead: timesheetEntry.lead
            ? { id: timesheetEntry.lead.id }
            : undefined,
          employee: { id: currentEmployee },
          cost_center: timesheetEntry.cost_center
            ? { id: timesheetEntry.cost_center.id }
            : undefined,
          construction_site:
            timesheetEntry.construction_site &&
            timesheetEntry.construction_site.id
              ? { id: +timesheetEntry.construction_site.id }
              : undefined,
          status: timesheetEntry.status,
          values: getValues(timesheetEntry.values),
        });
      });

      saveTimesheetEntries({
        variables: { data: allInput },
        update: (cache, data: any) => {
          setTimesheet(data.data.saveTimesheetObject);
          setLoader(false);
        },
      });
    }
  };

  return (
    <Stack style={{ width: 1200, flexDirection: 'column' }}>
      <Stack
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
          padding: '0px 10px',
        }}
      >
        <Stack>
          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginRight: 8, minWidth: '100px' }}>Datum:</Label>
            <DatePicker
              firstDayOfWeek={DayOfWeek.Monday}
              placeholder='Select a date...'
              ariaLabel='Select a date'
              // DatePicker uses English strings by default. For localized apps, you must override this prop.
              strings={defaultDatePickerStrings}
              onSelectDate={date => {
                setDays([]);
                setCurrentDate(moment(date).startOf('week'));
                getTimesheet({
                  variables: {
                    start: moment(date).startOf('week').toISOString(),
                    employee: currentEmployee,
                  },
                });
              }}
            />
          </Stack>

          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginRight: 8, minWidth: '100px' }}>
              Medewerker:
            </Label>
            <StackItem style={{ width: 220 }}>
              {employeesLoading ? (
                <Stack>
                  <Spinner size={SpinnerSize.medium} />
                </Stack>
              ) : employeesData &&
                employeesData.findManyEmployees &&
                employeesData.findManyEmployees.length > 0 ? (
                // eslint-disable-next-line react/jsx-indent
                <ComboboxWithFilter
                  options={convertEmployeesToComboBoxOptions(
                    employeesData.findManyEmployees,
                  )}
                  value={currentEmployee}
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      setCurrentEmployee(newValue[0].key);
                      getTimesheet({
                        variables: {
                          duration: 6,
                          employee: newValue[0].key,
                          start: moment(currentDate).weekday(1).toISOString(),
                        },
                      });
                    }
                  }}
                />
              ) : (
                <Stack>&nbsp;</Stack>
              )}
            </StackItem>
          </Stack>
        </Stack>
        <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Label style={{ marginRight: 5 }}>Aantal gewerkte uren:</Label>
          <StackItem>{workedHours.toFixed(2)} / 40</StackItem>
        </Stack>
      </Stack>
      <Stack
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
          alignSelf: 'flex-end',
          alignItems: 'center',
        }}
      >
        <StackItem style={{ fontWeight: 700 }}>
          <PrimaryButton
            disabled={!currentEmployee}
            onClick={() => {
              setDays([]);
              getTimesheet({
                variables: {
                  start: moment(currentDate).add(-1, 'w').toISOString(),
                  employee: currentEmployee,
                },
              });
              setCurrentDate(moment(currentDate).add(-1, 'w'));
            }}
          >
            &lt;
          </PrimaryButton>
        </StackItem>
        <StackItem style={{ fontWeight: 700, fontSize: 15 }}>
          Week {moment(currentDate).isoWeek()}:{' '}
          {currentDate.format('dd DD/MM/YYYY')} -{' '}
          {moment(currentDate).add(6, 'd').format('dd DD/MM/YYYY')}
        </StackItem>
        <StackItem style={{ fontWeight: 700 }}>
          <PrimaryButton
            disabled={!currentEmployee}
            onClick={() => {
              setDays([]);
              getTimesheet({
                variables: {
                  start: moment(currentDate).add(1, 'w').toISOString(),
                  employee: currentEmployee,
                },
              });
              setCurrentDate(moment(currentDate).add(1, 'w'));
            }}
          >
            &gt;
          </PrimaryButton>
        </StackItem>
      </Stack>

      <Stack>
        <Stack
          style={{
            flexDirection: 'row',
            borderBottom: '1px solid rgb(229, 229, 229)',
            fontWeight: 600,
          }}
        >
          <StackItem
            style={{
              width: '25%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Project / Taak
          </StackItem>
          <StackItem
            style={{
              width: '18%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Post
          </StackItem>
          {days.map((day, i: number) => (
            <StackItem
              style={{
                width: '8%',
                padding: 10,
                fontWeight: 600,
              }}
              key={`timesheet-days-${i}`}
            >
              {moment(day).format('ddd DD/MM')}
            </StackItem>
          ))}
          <StackItem style={{ width: '2%' }}>&nbsp;</StackItem>
        </Stack>
        {timesheet &&
          timesheet.length > 0 &&
          timesheet.map(
            (timesheetEntry: ITimesheetEntryWithType, i: number) => (
              <Stack
                style={{
                  flexDirection: 'row',
                  borderBottom: '1px solid rgb(239, 239, 239)',
                  alignItems: 'top',
                }}
                key={`timesheet-lines-${i}`}
              >
                <StackItem
                  style={{
                    width: '25%',
                    padding: 10,
                  }}
                >
                  {timesheetEntry.type === 'absence' ||
                  timesheetEntry.status !== 'tse_stat_3136' ? (
                    <div>
                      <SuggestedViewStatus
                        item={timesheet}
                        setItem={setTimesheet}
                        code={
                          timesheetEntry &&
                          timesheetEntry.status &&
                          timesheetEntry.status.substr(
                            timesheetEntry.status.length - 4,
                          )
                        }
                        timesheetLine={i}
                        required
                        errorMessage={
                          timesheetEntry &&
                          !timesheetEntry.status &&
                          timesheetEntry.status !== 'tse_stat_3136'
                            ? 'Dit veld is verplicht'
                            : ''
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      {customersLoading || projectsLoading ? (
                        <Stack>
                          <Spinner size={SpinnerSize.medium} />
                        </Stack>
                      ) : (customersData &&
                          customersData.findManyLeads &&
                          customersData.findManyLeads.length > 0) ||
                        (projectsData &&
                          projectsData.findManyConstructionSites &&
                          projectsData.findManyConstructionSites.length > 0) ? (
                        // eslint-disable-next-line react/jsx-indent
                        <ComboboxProject
                          initCustomers={customersData.findManyLeads
                            .filter(
                              (x: any) =>
                                (timesheetEntry.cost_center.id !== 73 &&
                                  x.status === 'NIHIL') ||
                                timesheetEntry.cost_center.id === 73,
                            )
                            .sort((obj1: ILead, obj2: ILead) => {
                              const compareFirstName =
                                obj1.first_name1.localeCompare(
                                  obj2.first_name1,
                                );
                              const compareLastName =
                                obj1.last_name1.localeCompare(obj2.last_name1);

                              return compareFirstName || compareLastName;
                            })}
                          initProjects={projectsData.findManyConstructionSites.sort(
                            (
                              obj1: IConstructionSite,
                              obj2: IConstructionSite,
                            ) => obj1.name.localeCompare(obj2.name),
                          )}
                          value={
                            timesheetEntry.construction_site
                              ? `constructionsite_${timesheetEntry.construction_site.id}`
                              : timesheetEntry.lead
                              ? `lead_${timesheetEntry.lead.id}`
                              : timesheetEntry.customer
                              ? `customer_${timesheetEntry.customer.id}`
                              : undefined
                          }
                          data={[
                            timesheetEntry.customer,
                            timesheetEntry.construction_site,
                            timesheetEntry.lead,
                          ]}
                          callBack={(newValue: IComboBoxOption[]) => {
                            if (newValue && newValue.length > 0) {
                              const result = [...timesheet];
                              const split = newValue[0].key
                                .toString()
                                .split('_');

                              if (split[0] === 'constructionsite') {
                                result[i].construction_site = {
                                  id: +split[1],
                                };
                              } else if (split[0] === 'customer') {
                                result[i].customer = {
                                  id: +split[1],
                                };
                              } else {
                                result[i].lead = {
                                  id: +split[1],
                                };
                              }
                              setTimesheet(result);
                            }
                          }}
                        />
                      ) : (
                        <Stack>&nbsp;</Stack>
                      )}
                    </div>
                  )}
                </StackItem>
                <StackItem
                  style={{
                    width: '18%',
                    padding: 10,
                  }}
                >
                  {timesheetEntry.type === 'absence' ||
                  timesheetEntry.status !== 'tse_stat_3136' ? (
                    <div />
                  ) : (
                    <ComboboxWithFilter
                      options={convertCostCentersToComboBoxOptions(
                        costCentersData &&
                          costCentersData.findManyCostCenters.sort(
                            (obj1: ICostCenter, obj2: ICostCenter) =>
                              obj1.name.localeCompare(obj2.name),
                          ),
                      )}
                      required
                      errorMessage={
                        timesheetEntry &&
                        timesheetEntry.status === 'tse_stat_3136' &&
                        !timesheetEntry.cost_center.id
                          ? 'Dit veld is verplicht'
                          : ''
                      }
                      value={
                        timesheetEntry &&
                        timesheetEntry.cost_center &&
                        timesheetEntry.cost_center.id
                      }
                      multiline={false}
                      callBack={(newValue: IComboBoxOption[]) => {
                        if (newValue && newValue.length > 0) {
                          const result = [...timesheet];
                          result[i].cost_center.id = +newValue[0].key;
                          setTimesheet(result);
                        }
                      }}
                    />
                  )}
                </StackItem>
                {days.map((day, key: number) => {
                  const entry: any = timesheetEntry.values.find(
                    (x: any) => moment(x.entry_date).isSame(day, 'day'),
                    // eslint-disable-next-line function-paren-newline
                  );
                  const entryIndex: number = timesheetEntry.values.findIndex(
                    (x: any) => moment(x.entry_date).isSame(day, 'day'),
                  );
                  return (
                    <StackItem
                      style={{
                        width: '8%',
                        padding: 10,
                        backgroundColor:
                          key === 5 || key === 6
                            ? 'rgb(239, 239, 239)'
                            : 'transparent',
                      }}
                      key={`day-${i}-${key}`}
                    >
                      <TextField
                        name={entry ? entry.id : `day-${i}`}
                        type='number'
                        value={entry ? entry.hours : '0'}
                        min={0}
                        onChange={(
                          event: React.FormEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >,
                          newValue?: string,
                        ) => {
                          if (entry) {
                            const result = [...timesheet];
                            result[i].values[entryIndex].hours = newValue
                              ? +newValue
                              : 0;
                            setTimesheet(result);
                          } else {
                            const result = [...timesheet];
                            result[i].values.push({
                              hours: newValue ? +newValue : 0,
                              entry_date: moment(day).toISOString(),
                            });
                            setTimesheet(result);
                          }
                        }}
                      />
                    </StackItem>
                  );
                })}
                <StackItem
                  style={{
                    alignSelf: 'center',
                    width: '2%',
                    textAlign: 'right',
                  }}
                  onClick={() => {
                    const result = [...timesheet];
                    result[i].values.map((value: any, x: number) => {
                      result[i].values[x].hours = 0;
                    });
                    setTimesheet(result);
                  }}
                >
                  <Icon iconName='Delete' styles={iconLastProps} />
                </StackItem>
              </Stack>
            ),
          )}
      </Stack>
      <Stack
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack style={{ flexDirection: 'row', padding: 10 }}>
          <PrimaryButton
            style={{ marginRight: 5 }}
            disabled={!currentEmployee}
            onClick={() => {
              setTimesheet((prevState: any) => [
                ...prevState,
                {
                  type: 'project',
                  customer: undefined,
                  cost_center: {},
                  status: 'tse_stat_3136',
                  employee: {
                    id: currentEmployee,
                  },
                  values: [],
                },
              ]);
            }}
          >
            Project toevoegen
          </PrimaryButton>
          <PrimaryButton
            disabled={!currentEmployee}
            onClick={() => {
              setTimesheet((prevState: any) => [
                ...prevState,
                {
                  type: 'absence',
                  customer: undefined,
                  cost_center: undefined,
                  employee: {
                    id: currentEmployee,
                  },
                  values: [],
                },
              ]);
            }}
          >
            Afwezigheid toevoegen
          </PrimaryButton>
        </Stack>
        <Stack style={{ padding: 10 }}>
          <PrimaryButton
            disabled={!currentEmployee}
            onClick={() => saveTimesheet()}
          >
            Werkuren opslaan
            {loader && (
              <Spinner style={{ marginLeft: 10 }} size={SpinnerSize.small} />
            )}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default NewTimesheetEmployee;

import { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  ADD_ARCHITECT,
  displayNameArchitect,
  GET_ARCHITECT_BY_ID,
  UPDATE_ARCHITECT,
  IArchitect,
} from '../../../utils/Architect';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { GET_SALES_REPS } from '../../../utils';
import ArchitectDetail from './ArchitectDetail';

const ArchitectsOverview = ({
  architects,
  setArchitects,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  refetchArchitects,
  clearArchitects,
}: any) => {
  const [architect, setArchitect] = useState<IArchitect | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [salesReps, setSalesReps] = useState([]);

  const [addArchitect] = useMutation(ADD_ARCHITECT);
  const [modifyArchitect] = useMutation(UPDATE_ARCHITECT);

  useQuery(GET_SALES_REPS, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setSalesReps(x.findManySalesReps);
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    IArchitect | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openArchitectDetail = (newArchitect?: boolean) => {
    if (selectionDetails && !newArchitect) {
      // getArchitect({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setArchitect(undefined);
    }
    setIsPanelOpen(true);
  };

  useQuery(GET_ARCHITECT_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setArchitect(data.findOneArchitect);
    },
  });

  const saveArchitect = () => {
    if (architect) {
      const allInput = {
        first_name: architect.first_name,
        last_name: architect.last_name,
        company: architect.company,
        address: architect.adress,
        zip_code: architect.zip_code ? +architect.zip_code : undefined,
        city: architect.city,
        mobile_v2: architect.mobile_v2,
        phone_v2: architect.phone_v2,
        email: architect.email,
        website: architect.website,
        comments: architect.comments,
      };

      if (architect.id) {
        modifyArchitect({
          variables: {
            id: architect.id,
            data: allInput,
          },
        }).then(() => {
          clearArchitects();
          refetchArchitects();
          setIsPanelOpen(false);
        });
      } else {
        addArchitect({
          variables: {
            data: allInput,
          },
        }).then(() => {
          clearArchitects();
          refetchArchitects();
          setIsPanelOpen(false);
        });
      }
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'last_name',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (architect: IArchitect) => <span>{architect.last_name}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Voornaam',
      fieldName: 'first_name',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (architect: IArchitect) => <span>{architect.first_name}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Bedrijf',
      fieldName: 'company',
      minWidth: 250,
      maxWidth: 250,
      isRowHeader: true,
      onRender: (architect: IArchitect) => <span>{architect.company}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Stad/Gemeente',
      fieldName: 'city',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (architect: IArchitect) => <span>{architect.city}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'E-mail',
      fieldName: 'email',
      minWidth: 250,
      maxWidth: 600,
      isRowHeader: true,
      onRender: (architect: IArchitect) => <span>{architect.email}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'GSM',
      fieldName: 'mobile',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (architect: IArchitect) => <span>{architect.mobile_v2}</span>,
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openArchitectDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openArchitectDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (architect && isPanelOpen) {
      document.title = `3bouw | Architect - ${displayNameArchitect(architect)}`;
    } else {
      document.title = '3bouw | Architecten';
    }
  }, [architect, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />

      <ArchitectDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        architect={architect}
        saveArchitect={saveArchitect}
        setArchitect={setArchitect}
        salesReps={salesReps}
      />

      {architects && (
        <>
          <ShimmeredDetailsList
            items={architects}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selection={selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && architects.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default ArchitectsOverview;

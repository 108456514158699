import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  ADD_SUPPLIER,
  GET_SUPPLIER_BY_ID,
  ISupplier,
  UPDATE_SUPPLIER,
} from '../../../utils/Supplier';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { IContactPerson } from '../../../utils';
import SupplierDetail from './SupplierDetail';

const SuppliersOverview = ({
  suppliers,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  refetchSuppliers,
}: any) => {
  const [supplier, setSupplier] = useState<ISupplier | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [addSupplier] = useMutation(ADD_SUPPLIER);
  const [modifySupplier] = useMutation(UPDATE_SUPPLIER);

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ISupplier | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  useQuery(GET_SUPPLIER_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setSupplier(data.findOneSupplier);
    },
  });

  const openSupplierDetail = (newSupplier?: boolean) => {
    if (selectionDetails && !newSupplier) {
      //  getSupplier({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setSupplier(undefined);
    }
    setIsPanelOpen(true);
  };

  const saveSupplier = () => {
    if (supplier) {
      const drafts =
        supplier.contact_persons &&
        supplier.contact_persons
          .filter((item: IContactPerson) => item.draft && item.dirty)
          .map((item: IContactPerson) => ({
            email: item.email,
            fax: item.fax,
            first_name: item.first_name,
            last_name: item.last_name,
            mobile_v2: item.mobile_v2,
            notes: item.notes,
            phone_v2: item.phone_v2,
          }));

      const updates =
        supplier.contact_persons &&
        supplier.contact_persons
          .filter((item: IContactPerson) => !item.draft && item.dirty)
          .map((item: IContactPerson) => ({
            where: { id: item.id },
            data: {
              email: item.email,
              fax: item.fax,
              first_name: item.first_name,
              last_name: item.last_name,
              mobile_v2: item.mobile_v2,
              notes: item.notes,
              phone_v2: item.phone_v2,
            },
          }));

      const deletes =
        supplier.contact_persons &&
        supplier.contact_persons
          .filter((item: IContactPerson) => item.delete && !item.draft)
          .map((item: IContactPerson) => ({
            id: item.id,
          }));

      const allInput = {
        name: supplier.name,
        address: supplier.address,
        city: supplier.city,
        comments: supplier.comments,
        zip_code: supplier.zip_code ? +supplier.zip_code : null,
        payment_terms: supplier.payment_terms ? +supplier.payment_terms : null,
        iban: supplier.iban,
        bic: supplier.bic,
        vat_code: supplier.vat_code,
        wings_code: supplier.wings_code ? +supplier.wings_code : null,
        sector: supplier.sector,
        phone_v2: supplier.phone_v2,
        mobile_v2: supplier.mobile_v2,
        suggested_value: supplier.suggested_value
          ? {
              connect: {
                id: supplier?.suggested_value?.id,
              },
            }
          : undefined,
        contact_persons: {
          create: drafts,
          update: updates,
          delete: deletes,
        },
      };

      if (supplier.id) {
        modifySupplier({
          variables: {
            id: supplier.id,
            data: allInput,
          },
        }).then(() => {
          refetchSuppliers();
          setIsPanelOpen(false);
        });
      } else {
        addSupplier({
          variables: {
            data: allInput,
          },
        }).then(x => {
          setSupplier(x.data.createSupplier);
          selection.setItems(x.data.createSupplier);
          refetchSuppliers();
        });
      }
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 350,
      isRowHeader: true,
      isSorted: true,
      onColumnClick,
      onRender: (supplier: ISupplier) => <span>{supplier.name}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Adres',
      fieldName: 'address',
      minWidth: 200,
      maxWidth: 350,
      isRowHeader: true,
      onRender: (supplier: ISupplier) => (
        <span>
          {supplier.address}
          <br />
          {supplier.zip_code} {supplier.city}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Telefoon',
      fieldName: 'phone',
      minWidth: 90,
      maxWidth: 350,
      isRowHeader: true,
      onRender: (supplier: ISupplier) => <span>{supplier.phone_v2}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'GSM',
      fieldName: 'mobile',
      minWidth: 90,
      maxWidth: 350,
      isRowHeader: true,
      onRender: (supplier: ISupplier) => <span>{supplier.mobile_v2}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'BTW nr',
      fieldName: 'vat',
      minWidth: 110,
      maxWidth: 350,
      isRowHeader: true,
      onRender: (supplier: ISupplier) => <span>{supplier.vat_code}</span>,
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openSupplierDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openSupplierDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (supplier && isPanelOpen) {
      document.title = `3bouw | Leverancier - ${supplier.name}`;
    } else {
      document.title = '3bouw | Leveranciers';
    }
  }, [supplier, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1034px'
        maxWidth='1034px'
      />

      <SupplierDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        supplier={supplier}
        saveSupplier={saveSupplier}
        setSupplier={setSupplier}
      />

      {suppliers && (
        <>
          <ShimmeredDetailsList
            items={suppliers}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selection={selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                // display: items.length === suppliers.length ? 'block' : 'none',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && suppliers.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default SuppliersOverview;

import { gql } from '@apollo/client';
import { ITemplateReference } from './Template';
import { ITemplateCreateInput, ITemplateItem } from './TemplateItem';

export const DELETE_TEMPLATE_TEMPLATE_ITEM = gql`
  mutation DeleteTemplateTemplateItem($where: TemplateTemplateItemWhereInput) {
    deleteTemplateTemplateItem(where: $where) {
      template_id
      template_item_id
      weight_for_construction_sheet
      weight_for_execution_list
    }
  }
`;

export const sortTemplateTemplateItemOnExecutionList = (
  a: ITemplateTemplateItem,
  b: ITemplateTemplateItem,
) => {
  if (a.weight_for_execution_list && b.weight_for_execution_list) {
    if (a.weight_for_execution_list < b.weight_for_execution_list) {
      return -1;
    }
    if (a.weight_for_execution_list > b.weight_for_execution_list) {
      return 1;
    }
  }
  return 0;
};

export const sortTemplateTemplateItemOnConstructionSheet = (
  a: ITemplateTemplateItem,
  b: ITemplateTemplateItem,
) => {
  if (a.weight_for_construction_sheet && b.weight_for_construction_sheet) {
    if (a.weight_for_construction_sheet < b.weight_for_construction_sheet) {
      return -1;
    }
    if (a.weight_for_construction_sheet > b.weight_for_construction_sheet) {
      return 1;
    }
  }
  return 0;
};

export interface ITemplateTemplateItem {
  weight_for_construction_sheet: number;
  weight_for_execution_list: number;

  template: ITemplateReference;
  template_item: ITemplateItem;
}

export interface ITemplateTemplateItemCreateInput {
  weight_for_construction_sheet?: number;
  weight_for_execution_list?: number;

  template_item: {
    create?: ITemplateCreateInput;
    connect?: {
      id: number;
    };
  };
}

export interface ITemplateTemplateItemUpdateInput {
  data: {
    weight_for_construction_sheet?: number;
    weight_for_execution_list?: number;

    template_item?: {
      update: {
        type: {
          connect: {
            id: number;
          };
        };

        is_reporting_param?: boolean;
        name?: string;
        needs_water_points?: boolean;
        list_types?: string;
        reporting_label?: string;
        value?: string;

        suggested_value_category?: {
          connect: {
            id: number;
          };
        };

        document?: {
          update: {
            name: string;
            src: string;
            extension: string;
          };
        };
      };
    };
  };
  where: {
    AND: {
      template_id: number;
      template_item_id: number;
    };
  };
}

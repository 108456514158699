import { FormEvent, useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { Stack, TextField } from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  GET_SUPPLIERS,
  ISupplier,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import SuppliersOverview from './components/SuppliersOverview';

interface IPagedSuppliers {
  suppliers: ISupplier[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const Suppliers = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedSuppliers>({
    suppliers: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'name',
  });

  const [filter, setFilter] = useState('');

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, suppliers: [], skip: 0 }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { name: { contains: filterArray[i] } },
          { address: { contains: filterArray[i] } },
          { city: { contains: filterArray[i] } },
          { vat_code: { contains: filterArray[i] } },
          { iban: { contains: filterArray[i] } },
          { bic: { contains: filterArray[i] } },
          { comments: { contains: filterArray[i] } },
          { email: { contains: filterArray[i] } },
          { phone_v2: { contains: filterArray[i] } },
          { mobile_v2: { contains: filterArray[i] } },
          { fax: { contains: filterArray[i] } },
          { website: { contains: filterArray[i] } },
        ],
      };

      const numericValue = parseInt(filterArray[i], 10);
      if (numericValue) filterValue.OR.push({ zip_code1: +numericValue });

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const { loading, error } = useQuery(GET_SUPPLIERS, {
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onError: (e: any) => {
      console.log(e);
    },
    onCompleted: (x: any) => {
      if (x.findManySuppliers && x.findManySuppliers.length > 0) {
        setPagedState(prevState => ({
          ...prevState,
          suppliers: pagedState.suppliers.concat(x.findManySuppliers),
          initialLoad: false,
          showLoadMore: !(x.findManySuppliers.length < default_page_size),
        }));
      } else if (x.findManySuppliers) {
        setPagedState(prevState => ({
          ...prevState,
          suppliers: x.findManySuppliers,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [fetchSuppliers] = useLazyQuery(GET_SUPPLIERS, {
    onError: (e: any) => {
      console.log(e);
    },
    onCompleted: (x: any) => {
      setPagedState(prevState => ({
        ...prevState,
        suppliers: x.findManySuppliers,
        initialLoad: false,
        showLoadMore: !(x.findManySuppliers.length < default_page_size),
      }));
    },
    fetchPolicy: 'no-cache',
  });

  const refetchSuppliers = () => {
    setPagedState(prevState => ({ ...prevState, skip: 0 }));
    fetchSuppliers({
      variables: {
        filter: getFilters(debouncedValue),
        orderBy: getOrderBy(),
        skip: 0,
        take: pagedState.take,
      },
    });
  };

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearSuppliers();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearSuppliers = () => {
    setPagedState(prevState => ({
      ...prevState,
      suppliers: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.suppliers.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Leveranciers</h3>

      <TextField
        label='Zoeken...'
        value={filter}
        styles={textFieldStyles300}
        onChange={onSearchValueChange}
      />

      <SuppliersOverview
        suppliers={pagedState.suppliers}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchSuppliers={refetchSuppliers}
      />
    </Stack>
  );
};

export default Suppliers;

import { eachOf } from 'async';
import React, {
  useReducer, useRef, useCallback, Reducer,
  useEffect,
} from 'react';
import './DraggableList.css';

type Props = {
  classes?: {
    dragItem: string;
    dragged: string;
    dragOver: string;
  };
  noDragEventsBubbling?: boolean;
  onDrop: (dragItemIndex: number, dragTargetIndex: number) => void;
};

const defaultClasses = {
  dragItem: 'dragItem',
  dragged: 'dragged',
  dragOver: 'dragOver',
};

type DraggableListState<T> = {
  isFocused: boolean;
  isDragActive: boolean;
  dragTarget?: T;
  dragTargetIndex?: number;
  dragElement?: T;
  dragElementIndex?: number;
};

type DragAction<T> = {
  dragTarget?: T;
  dragTargetIndex?: number;
  dragElement?: T;
  dragElementIndex?: number;
  type:
    | 'setDraggedElement'
    | 'setDragTarget'
    | 'endDragState'
    | 'removeDragTarget'
    | 'reset';
};

const initialState = {
  isFocused: false,
  isDragActive: false,
};

function reducer<T>(
  state: DraggableListState<T>,
  action: DragAction<T>,
): DraggableListState<T> {
  /* istanbul ignore next */
  switch (action.type) {
    case 'endDragState':
      return {
        ...state,
        isDragActive: false,
      };
    case 'setDragTarget':
      return {
        ...state,
        dragTarget: action.dragTarget,
        dragTargetIndex: action.dragTargetIndex,
      };
    case 'setDraggedElement':
      return {
        ...state,
        isDragActive: true,
        dragElementIndex: action.dragElementIndex,
        dragElement: action.dragElement,
      };
    case 'removeDragTarget':
      return {
        ...state,
        dragTarget: undefined,
        dragTargetIndex: undefined,
      };
    case 'reset':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

const DraggableList: React.FC<Props> = ({
  children,
  noDragEventsBubbling,
  onDrop,
  classes = defaultClasses,
}) => {
  const [state, dispatch] = useReducer<
    Reducer<DraggableListState<HTMLDivElement>, DragAction<HTMLDivElement>>
  >(reducer, initialState);
  const rootRef = useRef<HTMLDivElement>(null);
  const dragTargetsRef = useRef<(EventTarget & HTMLDivElement)[]>([]);
  const stopPropagation = (event: React.DragEvent<HTMLDivElement>) => {
    if (noDragEventsBubbling) {
      event.stopPropagation();
    }
  };

  const resetClasses = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref && ref.current) {
      [...ref.current.children].map((child) => {
        if (child) {
          eachOf(classes, (value, key, cb) => {
            if (key !== 'dragItem') child.classList.remove(value);
            cb();
          });
        }
      });
    }
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    dispatch({
      type: 'reset',
    });

    resetClasses(rootRef);
    event.currentTarget.classList.add(classes.dragged);
    if (rootRef.current) {
      rootRef.current.classList.add('dragging');
    }

    let index = -1;

    if (event && event.currentTarget && event.currentTarget.parentElement) {
      index = Array.from(event.currentTarget.parentElement.children).indexOf(
        event.currentTarget,
      );
    }
    dispatch({
      dragElement: event.currentTarget,
      dragElementIndex: index,
      type: 'setDraggedElement',
    });
  };
  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove(classes.dragged);
    if (rootRef.current) {
      rootRef.current.classList.remove('dragging');
    }
    // setTarget(undefined);
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    return false;
  };

  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      // Persist here because we need the event later after getFilesFromEvent() is done
      event.persist();
      stopPropagation(event);

      if (
        event
        && event.currentTarget.classList
        && event.currentTarget.classList.contains(classes.dragItem)
      ) {
        event.currentTarget.classList.add(classes.dragOver);
      }

      dragTargetsRef.current = [...dragTargetsRef.current, event.currentTarget];

      let index = -1;

      if (event && event.currentTarget && event.currentTarget.parentElement) {
        index = Array.from(event.currentTarget.parentElement.children).indexOf(
          event.currentTarget,
        );
      }

      dispatch({
        type: 'setDragTarget',
        dragTarget: event.currentTarget,
        dragTargetIndex: index,
      });
    },
    [],
  );

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.persist();
    stopPropagation(event);

    // Only deactivate once the dropzone and all children have been left
    const targets = dragTargetsRef.current.filter(
      (target) => state.dragTarget && state.dragTarget.contains(target),
    );
    // Make sure to remove a target present multiple times only once
    // (Firefox may fire dragenter/dragleave multiple times on the same element)

    const targetIdx = targets.indexOf(event.currentTarget);

    if (targetIdx !== -1) {
      targets.splice(targetIdx, 1);
    }
    dragTargetsRef.current = targets;
    if (targets.length > 0) {
      return;
    }

    event.currentTarget.classList.remove(classes.dragOver);

    dispatch({
      type: 'removeDragTarget',
    });
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (
      (state.dragElementIndex || state.dragElementIndex === 0)
      && (state.dragTargetIndex || state.dragTargetIndex === 0)
    ) onDrop(state.dragElementIndex, state.dragTargetIndex);
  };

  const childrenWithProps = React.Children.map(children, (child, index) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        onDragStart: handleDragStart,
        onDragEnd: handleDragEnd,
        onDragOver: handleDragOver,
        onDragEnter: handleDragEnter,
        onDragLeave: handleDragLeave,
        onDrop: handleDrop,
        className: classes.dragItem,
        draggable: true,
      });
    }
    return child;
  });
  return <div ref={rootRef}>{childrenWithProps}</div>;
};

export default DraggableList;

import React from 'react';
import { Spinner } from '@fluentui/react';

const SpinnerDefault = () => (
  <Spinner
    label='Wachten, a.u.b....'
    ariaLive='assertive'
    labelPosition='right'
  />
);

export default SpinnerDefault;

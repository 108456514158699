import React, { CSSProperties } from 'react';

function Logo({ size = 150 }) {
  const factor = 134.12 / 99.5;

  let width: any = 'auto';

  if (Array.isArray(size)) {
    width = [];
    for (let i = 0; i < size.length; i++) {
      width.push(factor * size[i]);
    }
  } else if (!Number.isNaN(size)) {
    width = factor * size;
  }

  const style1: CSSProperties = {
    fill: '#fff',
  };
  const style2: CSSProperties = {
    fill: '#6d1f80',
  };

  const style3: CSSProperties = {
    fill: '#7ab51d',
  };
  const style4: CSSProperties = {
    fill: 'none',
    stroke: '#7ab51d',
    strokeWidth: '1.5px',
  };
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 134.12 99.5'
      width={width}
      height={size}
    >
      <g id='Laag_2' data-name='Laag 2'>
        <g id='Layer_1' data-name='Layer 1'>
          <path
            style={style1}
            d='M54.89,4.6a697.75,697.75,0,0,1,78.26,36.11c-9.36,20.68-39.49,52.72-71.27,57.47C29.76,103.46,7.3,71.31.78,30.1,15.05.55,30.38-3.94,54.89,4.6'
          />
          <path
            style={style2}
            d='M52.07,4.32C73.8,6.55,102.71,19,127.84,28c-2.26,17.52-25.9,46.71-56.44,60.62C40.7,102.76,14,86.32,3.29,51,15.22,15.82,30.2,2.37,52.07,4.32'
          />
          <path
            style={style3}
            d='M58.77,11.8c22.87,6.23,50.7,18.08,69.47,29.79C118.55,61,90.88,89.37,62,94.57,32.78,100.22,12.34,74.2,6,39.05c13.77-27,29.66-33,52.78-27.25'
          />
          <path
            style={style4}
            d='M54.89,4.6a697.75,697.75,0,0,1,78.26,36.11c-9.36,20.68-39.49,52.72-71.27,57.47C29.76,103.46,7.3,71.31.78,30.1,15.05.55,30.38-3.94,54.89,4.6Z'
          />
          <path
            style={style1}
            d='M23.36,49.52a2.78,2.78,0,0,0,2.8-2.62c0-1.56-1.38-2.4-3.94-2.4a13.18,13.18,0,0,0-1.5.11V41.19a16.76,16.76,0,0,0,1.89.18c2.23,0,3.58-.91,3.58-2.38a2.4,2.4,0,0,0-2.47-2.35c-1.2,0-2.1.55-4,2.32l-2-2.29a7.26,7.26,0,0,1,3.31-2.73,6.67,6.67,0,0,1,2.61-.54,5.7,5.7,0,0,1,5.87,5.65,4.53,4.53,0,0,1-1.92,3.88,4.71,4.71,0,0,1,1.92,4,5.93,5.93,0,0,1-6.2,5.84,6.39,6.39,0,0,1-5-2.26,14.84,14.84,0,0,1-1.08-1.38l2.5-2.23c1.32,2,2.22,2.65,3.57,2.65'
          />
          <path
            style={style1}
            d='M40.6,38.84a6.88,6.88,0,0,1,6.92,6.92,7,7,0,0,1-13,3.55,8.33,8.33,0,0,1-1-4.25V32.49H37V40a5.07,5.07,0,0,1,3.64-1.2m0,3.31a3.72,3.72,0,0,0-3.73,3.67,3.64,3.64,0,0,0,7.28,0,3.69,3.69,0,0,0-3.55-3.64'
          />
          <path
            style={style1}
            d='M63.71,45.79a7.1,7.1,0,0,1-7,7.07,7,7,0,0,1-7-7,7,7,0,1,1,14.05-.12M53,45.85a3.6,3.6,0,0,0,3.58,3.64,3.72,3.72,0,0,0,3.73-3.64,3.65,3.65,0,0,0-3.7-3.67A3.61,3.61,0,0,0,53,45.85'
          />
          <path
            style={style1}
            d='M69.75,39.14v8c0,2,.43,2.56,2,2.56s2.11-.48,2.11-2.26l0-8.33h3.4v9.29c0,2.65-2.26,4.49-5.51,4.49-2.1,0-3.82-.76-4.57-2.11-.63-1.08-.78-1.92-.78-4V39.14Z'
          />
          <polygon
            style={style1}
            points='82.69 39.14 85.94 46.54 89.19 39.14 91.29 39.14 94.3 46.72 98.09 39.14 101.85 39.14 95.23 52.5 93.28 52.5 90.09 44.46 86.69 52.5 84.83 52.5 78.96 39.14 82.69 39.14'
          />
          <path
            style={style1}
            d='M100.05,51.59a.48.48,0,0,1,.48.47.47.47,0,0,1-.48.47.45.45,0,0,1-.47-.45v0a.45.45,0,0,1,.47-.46'
          />
          <path
            style={style1}
            d='M102,46.65v2.59a1.52,1.52,0,0,1,1.29-.6,1.94,1.94,0,0,1,2,1.94,2,2,0,0,1-2,2,2,2,0,0,1-1.7-.93,2.54,2.54,0,0,1-.24-1.32V46.65Zm0,4a1.29,1.29,0,0,0,1.32,1.29,1.33,1.33,0,0,0,0-2.65A1.36,1.36,0,0,0,102,50.61'
          />
          <path
            style={style1}
            d='M107.87,51.93a2.06,2.06,0,0,0,1.25-.59l0,.83a1.8,1.8,0,0,1-1.2.39,2,2,0,0,1-2-1.94,2,2,0,0,1,2-2,1.87,1.87,0,0,1,1.31.53,3.18,3.18,0,0,0,.26.26l-2.35,2.27a1.23,1.23,0,0,0,.74.25m0-2.68a1.35,1.35,0,0,0-1.32,1.36,1.27,1.27,0,0,0,.21.72l1.86-1.81a1.16,1.16,0,0,0-.75-.27'
          />
          <path
            style={style1}
            d='M17.15,64.31h.68l.05-7.06H17v-1h2.23l0,2.91A7.82,7.82,0,0,1,19,60.54h0a2.09,2.09,0,0,1,2.13-1.48c1.42,0,2.11.9,2.11,2.34,0,1,0,1.94,0,2.91h.79v1H21.27v-1H22c0-.89.05-1.79.05-2.67s-.4-1.48-1.23-1.48A1.68,1.68,0,0,0,19.18,62l0,2.31H20v1H17.14v-1Z'
          />
          <path
            style={style1}
            d='M27.92,59.06c2.24,0,3,1.71,3,3.21a2.93,2.93,0,0,1-3.12,3.13,2.82,2.82,0,0,1-3-3,3.09,3.09,0,0,1,3.18-3.3m-.12,5.25c1.19,0,1.71-1,1.71-2.19,0-1.36-.71-2-1.62-2-1.29,0-1.77,1.08-1.77,2.15s.5,2,1.68,2'
          />
          <path
            style={style1}
            d='M31.69,59.2H33.8c0,1.2,0,2.41,0,3.62,0,.86.39,1.48,1.19,1.48a1.66,1.66,0,0,0,1.56-1.84l0-2.31h-.79V59.2h2.1l0,5.1h.81v1h-2.1a8.51,8.51,0,0,1,.12-1.34h0A2.15,2.15,0,0,1,34.5,65.4c-1.29,0-2-.9-2-2.32,0-1,0-2,0-2.93h-.8V59.2Z'
          />
          <path
            style={style1}
            d='M39.4,59.2h.92l0-1.37,1.32-.09,0,1.46h1.74v1H41.61c0,1,0,1.92,0,2.83,0,1.11.25,1.39.85,1.39a2.88,2.88,0,0,0,1-.24l-.05,1a3.23,3.23,0,0,1-1.34.3c-1.28,0-1.79-.5-1.79-1.86,0-.79,0-2.33,0-3.38H39.4Z'
          />
          <path
            style={style1}
            d='M47.42,60.94l0-.73a2.05,2.05,0,0,0-1-.19c-.6,0-1.22.14-1.22.73s.4.6.77.68l1.48.29a1.54,1.54,0,0,1,1.47,1.67c0,1.59-1.38,2-2.72,2A6.62,6.62,0,0,1,44,65l0-1.64h1.16l0,.8a3.7,3.7,0,0,0,1.29.25c.62,0,1.22-.23,1.22-.85s-.38-.68-.87-.77l-1-.18c-.93-.17-2-.52-2-1.68,0-1.42,1.25-1.91,2.58-1.91a6.3,6.3,0,0,1,2.11.4l0,1.48Z'
          />
          <polygon
            style={style1}
            points='49.92 64.31 50.66 64.31 50.71 57.25 49.65 57.25 49.65 56.3 52.01 56.3 51.96 61.83 54.11 60.18 54.11 60.16 53.25 60.16 53.25 59.2 56.09 59.2 56.09 60.16 55.49 60.16 53.32 61.95 55.66 64.31 56.35 64.31 56.35 65.26 53.44 65.26 53.44 64.31 54.15 64.31 51.96 62.18 51.96 64.31 52.7 64.31 52.7 65.26 49.92 65.26 49.92 64.31'
          />
          <path
            style={style1}
            d='M62.07,65a5.66,5.66,0,0,1-2.13.42,3,3,0,0,1-3.19-3.15,2.88,2.88,0,0,1,3-3.19c1.82,0,2.74,1.33,2.74,3.2v.18H58.12a1.92,1.92,0,0,0,2.08,1.93,4.32,4.32,0,0,0,1.95-.46Zm-1-3.43v-.11A1.39,1.39,0,0,0,59.65,60a1.47,1.47,0,0,0-1.53,1.54Z'
          />
          <polygon
            style={style1}
            points='63.32 64.31 64.06 64.31 64.1 57.25 63.04 57.25 63.04 56.3 65.41 56.3 65.36 64.31 66.18 64.31 66.18 65.26 63.32 65.26 63.32 64.31'
          />
          <path
            style={style1}
            d='M72.23,65a5.77,5.77,0,0,1-2.14.42,3,3,0,0,1-3.19-3.15,2.88,2.88,0,0,1,3-3.19c1.82,0,2.74,1.33,2.74,3.2v.18H68.27a1.92,1.92,0,0,0,2.08,1.93,4.28,4.28,0,0,0,2-.46Zm-1-3.43v-.11A1.4,1.4,0,0,0,69.8,60a1.47,1.47,0,0,0-1.53,1.54Z'
          />
          <path
            style={style1}
            d='M73.31,59.2h.92l0-1.37,1.32-.09,0,1.46h1.73v1H75.53c0,1,0,1.92,0,2.83,0,1.11.25,1.39.85,1.39a2.83,2.83,0,0,0,1-.24l-.05,1a3.23,3.23,0,0,1-1.34.3c-1.28,0-1.78-.5-1.78-1.86,0-.79,0-2.33,0-3.38h-.92Z'
          />
          <path
            style={style1}
            d='M77.32,56.3h2.29l0,2.88c0,.43-.06.85-.11,1.28h0a2.15,2.15,0,0,1,2.07-1.4c1.59,0,2.46,1.13,2.46,2.75,0,1.81-.81,3.59-3.43,3.59a5.94,5.94,0,0,1-2.33-.46l0-7.69h-1v-1Zm3.84,3.86a1.57,1.57,0,0,0-1.58,1.73l0,2.16a2.53,2.53,0,0,0,1.2.26c1.37,0,1.9-1.15,1.9-2.41,0-1.06-.49-1.74-1.48-1.74'
          />
          <path
            style={style1}
            d='M88,59.06c2.24,0,3,1.71,3,3.21A2.93,2.93,0,0,1,87.8,65.4a2.82,2.82,0,0,1-3-3A3.09,3.09,0,0,1,88,59.06m-.11,5.25c1.18,0,1.71-1,1.71-2.19,0-1.36-.71-2-1.62-2-1.29,0-1.77,1.08-1.77,2.15s.5,2,1.68,2'
          />
          <path
            style={style1}
            d='M91.73,59.2h2.11c0,1.2,0,2.41,0,3.62,0,.86.4,1.48,1.19,1.48a1.66,1.66,0,0,0,1.56-1.84l0-2.31h-.8V59.2h2.1l0,5.1h.81v1H96.57a8.41,8.41,0,0,1,.11-1.34h0a2.16,2.16,0,0,1-2.12,1.48c-1.3,0-2.06-.9-2.06-2.32,0-1,0-2,0-2.93h-.8V59.2Z'
          />
          <path
            style={style1}
            d='M104.5,62.88c-.08-.21-.12-.43-.18-.65h0c-.06.22-.11.44-.19.65l-1,2.38h-1.31l-2-5.1h-.64v-1h2.71v1h-.69l1.12,3.07c.1.28.17.57.25.87h0a8.07,8.07,0,0,1,.29-.87l1-2.27-.3-.8h-.67v-1h2.71v1h-.73l1.11,3.12c.09.26.15.55.22.82h0a7.66,7.66,0,0,1,.23-.82l1.14-3.12h-.85v-1h2.7v1h-.62l-2,5.1h-1.37Z'
          />
        </g>
      </g>
    </svg>
  );
}

export default Logo;

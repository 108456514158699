import { inherits } from 'util';
import React, { useEffect, useState } from 'react';
import {
  TextField, ITextFieldProps, Stack, IComboBoxOption, Spinner,
} from '@fluentui/react';
import { useDimensions } from '../hooks/useDimensions';

interface Props extends ITextFieldProps {
    boxWidth?: string | number;
    dropdownStyles?: {
        zIndex?: string | number;
        border?: string;
    }
    options?: IComboBoxOption[];
    emptyResult?: boolean;
    loading?: boolean;
    select?: (key: string | number) => void;
}

const TextFieldDropdown: React.FC<Props> = ({
  boxWidth,
  options,
  emptyResult = false,
  loading,
  dropdownStyles = {
    zIndex: 4000,
    border: '1px solid rgba(0,0,0,0.)',
    boxShadow: '3px 3px 12px -4px rgba(0, 0, 0, 0.2);',
    maxWidth: '100vw',
  },
  select = () => { },
  ...props
}) => {
  const [ref, parentDimensions] = useDimensions();

  const [focus, setFocus] = useState(false);

  const [removeOverflow, setRemoveOverflow] = useState(true);

  useEffect(() => {
    if (focus) setRemoveOverflow(false);
    else {
      setTimeout(() => { setRemoveOverflow(true); }, 100);
    }
  }, [focus]);

  return (
    <div
      style={{
        width: boxWidth,
        position: 'relative',
        marginTop: 0,
      }}
      onFocus={() => {
        setFocus(true);
      }}
      onBlur={() => {
        setFocus(false);
      }}
            // @ts-ignore
      ref={ref}
    >
      <TextField
        {...props}
      />
      <Stack styles={{
        root: {
          position: 'absolute', overflow: focus ? 'hidden' : 'inherit', height: focus ? 'inherit' : '0px', backgroundColor: 'white', minwidth: parentDimensions ? parentDimensions.clientWidth : '100%', top: 'calc( 100% + 5px )', opacity: focus ? 1 : 0, ...dropdownStyles,
        },
      }}
      >
        <div style={{
          minWidth: parentDimensions ? parentDimensions.clientWidth : undefined,
          overflow: removeOverflow ? 'hidden' : 'inherit',
        }}
        >
          {emptyResult && !loading && (
          <Stack.Item styles={{ root: { paddingLeft: '10px', paddingRight: '10px' } }}><p>Geen resultaten.</p></Stack.Item>
          )}
          {loading && (
          <Stack.Item styles={{ root: { paddingLeft: '10px', paddingRight: '10px' } }}><p><Spinner /></p></Stack.Item>
          )}
          {options && options.length > 0 && options.map((item) => (
            <Stack.Item
              key={item.key}
              onClick={() => { select(item.key); }}
              styles={{
                root: {
                  paddingTop: '5px', paddingBottom: '5px', paddingLeft: '10px', paddingRight: '10px', '&:hover': { cursor: 'pointer', backgroundColor: 'rgb(239, 239, 239)' },
                },
              }}
            >
              {item.text}
            </Stack.Item>
          ))}
        </div>

      </Stack>

    </div>

  );
};

export default TextFieldDropdown;

import React, { useEffect } from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { Stack, IStackStyles } from '@fluentui/react/lib/Stack';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { getError, dismissError } from '../../redux/error/errorSlice';

const stackStyles: IStackStyles = {
  root: {
    position: 'fixed',
    bottom: '20px;',
    left: 0,
    right: 0,
  },
};

const Error = () => {
  const error = useAppSelector(getError);
  const dispatch = useAppDispatch();

  const getMessageBarType = (type?: number) => {
    switch (type) {
      case 0:
        return MessageBarType.info;
      case 1:
        return MessageBarType.info;
      case 2:
        return MessageBarType.warning;
      case 3:
        return MessageBarType.error;
      case 4:
        return MessageBarType.severeWarning;
      default:
        return MessageBarType.warning;
    }
  };

  useEffect(() => {
    if (error && error.active && error.timeout) {
      setTimeout(() => {
        dispatch(dismissError());
      }, error.timeout);
    }
  }, [error]);

  if (error.active) {
    return (
      <Stack styles={stackStyles}>
        <MessageBar
          messageBarType={getMessageBarType(error.level)}
          isMultiline
          onDismiss={() => dispatch(dismissError())}
          dismissButtonAriaLabel='Close'
          styles={{
            root: {
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '750px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingTop: '10px',
              paddingBottom: '10px',
              zIndex: 9000,
            },
          }}
        >
          {error.message}
        </MessageBar>
      </Stack>
    );
  }

  return null;
};

export default Error;

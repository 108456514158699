/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router-dom';
import {
  initializeIcons,
  Spinner,
  SpinnerSize,
  IStackStyles,
} from '@fluentui/react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/client/link/error';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { reactPlugin } from './AppInsights';
import { useClientHeight } from './components/hooks';
import { Container, Section, Row } from './components/layout';
import TopBarWithNavigation from './components/parts/TopBarWithNavigation';
import Error from './components/parts/Error';
import Notification from './components/parts/Notification';
import router from './Router';
import './App.css';
import './styles.css';

initializeIcons(/* optional base url */);

const wrapperStyle = {
  minHeight: '100vh',
};

const loadingStackStyle: IStackStyles = {
  root: {
    minHeight: '100vh',
  },
};

const backEndUri = process.env.REACT_APP_BACKEND_URI || '';

function App() {
  // const [showNavigation, setShowNavigation] = useState(false);
  const [headerRef, headerHeight] = useClientHeight();
  const [token, setToken] = useState<string | undefined>();
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  // Setup link with data api
  const httpLink = createHttpLink({
    uri: backEndUri,
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            findManyConstructionSites: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyEmployees: {
               ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
               merge(existing = [], incoming) { // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                 return [...existing, ...incoming];
               },
            },
            findManyCars: {
              ...offsetLimitPagination(['filter', 'orderBy']),
             // Concatenate the incoming list items with
             // the existing list items.
             // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) { // MERGE DOES NOT REPLACE DUPLICATE __REF
               // console.log('incoming', incoming);
               // console.log('existing', incoming);
               // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
           },
            findManyLogItems: {
               ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
               merge(existing = [], incoming) {
                 return [...existing, ...incoming];
               },
             },
             findManyTemplateItems: {
              ...offsetLimitPagination(['filter', 'orderBy']),
             // Concatenate the incoming list items with
             // the existing list items.
             // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
  });

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  }

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setToken(token);
    });
  }, []);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ApolloProvider client={client}>
        <div style={wrapperStyle}>
          <TopBarWithNavigation
            headerRef={headerRef}
            headerHeight={headerHeight}
          />
          <AppInsightsErrorBoundary
            onError={() => <h1>I believe something went wrong</h1>}
            appInsights={reactPlugin}
          >
            <>
              {(isAuthenticated || isLoading) && (
                <Container wrapperStyle={{ top: headerHeight || 41 }}>
                  <Section>
                    <Row id='content'>
                      <RouterProvider router={router} />
                    </Row>
                  </Section>
                </Container>
              )}
              {isLoading && (
                <Container
                  verticalAlign='center'
                  styles={loadingStackStyle}
                  wrapperStyle={{ minHeight: '100vh' }}
                >
                  <Spinner size={SpinnerSize.large} />
                </Container>
              )}
            </>
          </AppInsightsErrorBoundary>
          <Error />
          <Notification />
        </div>
      </ApolloProvider>
    </AppInsightsContext.Provider>
  );
}

export default App;

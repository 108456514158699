import { gql } from '@apollo/client';
import { ILead } from './Lead';
import { ICostCenter } from './CostCenter';
import { IConstructionSite, ICustomer, IEmployee } from '.';

export interface ITimesheetEntry {
  comments: string;
  cost_center_id: number;
  customer_id: number;
  employee_id: number;
  entry_date: Date;
  hourly_rate: number;
  hours: number;
  id: number;
  status: string;
  version: number;

  cost_center: ICostCenter;
  customer: ICustomer;
  employee: IEmployee;
  construction_site: IConstructionSite;
  lead: ILead;
}

export const GET_TIMESHEET_ENTRIES = gql`
  query getTimeSheetEntries(
    $filter: String
    $orderBy: TimeSheetEntryOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyTimeSheetEntries(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      comments
      cost_center_id
      customer_id
      employee_id
      entry_date
      hourly_rate
      hours
      id
      status
      travel_allowance
      version

      cost_center {
        id
        name
      }
      customer {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const GET_TIMESHEET_ENTRY = gql`
  query getTimeSheetEntry($where: TimeSheetEntryWhereInput) {
    findOneTimeSheetEntry(where: $where) {
      comments
      cost_center_id
      customer_id
      employee_id
      entry_date
      hourly_rate
      hours
      id
      status
      travel_allowance
      version

      customer {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const ADD_TIMESHEET_ENTRY = gql`
  mutation AddTimeSheetEntry($data: TimeSheetEntryCreateInput!) {
    createTimeSheetEntry(data: $data) {
      id
    }
  }
`;

export const UPDATE_TIMESHEET_ENTRY = gql`
  mutation UpdateTimeSheetEntry($id: Int!, $data: TimeSheetEntryUpdateInput!) {
    updateTimeSheetEntry(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_TIMESHEET_ENTRY = gql`
  mutation deleteTimeSheetEntry($where: TimeSheetEntryWhereInput) {
    deleteTimeSheetEntry(where: $where) {
      id
    }
  }
`;

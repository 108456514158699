import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { RootState } from '../store';
import { appInsights } from '../../AppInsights';

// for more info => https://github.com/reduxjs/cra-template-redux-typescript

export interface ErrorState {
  module?: string;
  message?: string | Error;
  level?: number;
  active: boolean;
  timeout?: number;
}

export interface ThrowError {
  module: string;
  message: string | Error;
  level: SeverityLevel;
  timeout?: number;
}

const initialState: ErrorState = {
  active: false,
};

const logError = (severityLevel: SeverityLevel, error: Error | string) => {
  const extra = {
    geoLocation: JSON.stringify(navigator.geolocation),
    language: navigator.language,
    userAgent: navigator.userAgent,
    // @ts-ignore
    userAgentData: JSON.stringify(navigator.userAgentData),
    vendor: navigator.vendor,
  };

  switch (severityLevel) {
    case 0:
      appInsights.trackException({
        severityLevel,
        exception: typeof error === 'string' ? new Error(error) : error,
      });
      break;
    case 1:
      appInsights.trackException({
        severityLevel,
        exception: typeof error === 'string' ? new Error(error) : error,
      });
      break;
    case 2:
      appInsights.trackException({
        severityLevel,
        exception: typeof error === 'string' ? new Error(error) : error,
      });
      break;
    case 3:
      appInsights.trackException({
        severityLevel,
        exception: typeof error === 'string' ? new Error(error) : error,
      });
      break;
    case 4:
      appInsights.trackException({
        severityLevel,
        exception: typeof error === 'string' ? new Error(error) : error,
      });
      break;
    default:
      appInsights.trackException({
        severityLevel,
        exception: typeof error === 'string' ? new Error(error) : error,
      });
  }
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    throwError: (state, action: PayloadAction<ThrowError>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const {
        level, message, module, timeout,
      } = action.payload;
      logError(level, message);
      // appInsights.trackException({ severityLevel: level, error: { name: module, message } })
      state.message = message;
      state.module = module;
      state.timeout = timeout;
      state.level = level;
      state.active = true;
    },
    dismissError: (state) => {
      state.active = false;
    },
  },
});

export const { throwError, dismissError } = errorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getError = (state: RootState) => state.error;

export default errorSlice.reducer;

export { SeverityLevel };

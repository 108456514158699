import { gql } from '@apollo/client';
import { IDocument } from './Document';
import { IPropertyType } from './PropertyType';
import { ISuggestedValueCategory } from './SuggestedValueCategory';
import { IProperty, ITemplateTemplateItem } from '.';

export const GET_TEMPLATES_ITEMS = gql`
  query GetTemplateItems(
    $filter: TemplateItemFilterInput
    $take: Int
    $skip: Int
    $orderBy: TemplateItemOrderByInput
  ) {
    findManyTemplateItems(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      name
      value
      type {
        id
        name
      }
      document {
        id
        name
        src
        extension
        is_external
        blob_name
      }
      suggested_value_category_id
      suggested_value_category {
        id
        category_code
        category_description
      }
      nested_type {
        id
        name
        label
      }
      children {
        id
        name
        value
        type {
          id
          name
        }
        suggested_value_category {
          id
          category_description
        }
        default_multi_value_children {
          id
          name
          remote_category
          value
          order_date
          is_ordered
          is_not_applicable
          send_alert
          due_date
          execution_date
          weight_for_construction_sheet
          weight_for_execution_list
          type {
            id
            name
          }
          list_types {
            id
            list_type {
              id
              name
            }
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }

          children {
            id
            name
            value
            parent {
              id
            }
            weight_for_construction_sheet
            weight_for_execution_list
            remote_category
            type {
              id
              name
            }
            suggested_value {
              id
              suggested_value
              suggested_value_code
            }
            document {
              id
              name
              src
              extension
              is_external
              blob_name
            }
            reporting_item {
              id
              document {
                id
              }
              reporting_label
              template_template_items {
                template {
                  id
                  name
                }
              }
            }
          }
          suggested_value {
            id
            suggested_value
            suggested_value_code
          }
          reporting_item {
            id
            document {
              id
            }
            reporting_label
            template_template_items {
              template {
                id
                name
              }
            }
          }
        }
      }
      default_multi_value_children {
        id
        name
        remote_category
        value
        order_date
        is_ordered
        is_not_applicable
        send_alert
        due_date
        execution_date
        weight_for_construction_sheet
        weight_for_execution_list
        type {
          id
          name
        }
        list_types {
          id
          list_type {
            id
            name
          }
        }
        document {
          id
          name
          src
          extension
          is_external
          blob_name
        }
        children {
          id
          name
          value
          parent {
            id
          }
          weight_for_construction_sheet
          weight_for_execution_list
          remote_category
          type {
            id
            name
          }
          suggested_value {
            id
            suggested_value
            suggested_value_code
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          reporting_item {
            id
            document {
              id
            }
            reporting_label
            template_template_items {
              template {
                id
                name
              }
            }
          }
        }
        suggested_value {
          id
          suggested_value
          suggested_value_code
        }
        reporting_item {
          id
          document {
            id
          }
          reporting_label
          template_template_items {
            template {
              id
              name
            }
          }
        }
      }
      properties {
        id
      }

      template_template_items {
        template {
          id
          name
        }
      }
    }
  }
`;

export const ADD_TEMPLATE_ITEM = gql`
  mutation AddTemplateItem($data: TemplateItemCreateInput!) {
    createTemplateItem(data: $data) {
      id
      is_reporting_param
      list_types
      name
      needs_water_points
      reporting_label
      parent_id
      suggested_value_category_id
      document_id
      value
      weight_for_construction_sheet
      weight_for_execution_list
      document {
        id
        name
        src
        extension
      }
      children {
        id
        is_reporting_param
        name
        parent_id
        reporting_label
        suggested_value_category_id
        value
        weight_for_construction_sheet
        weight_for_execution_list
        document {
          id
          name
          src
          extension
        }
      }
    }
  }
`;

export const UPDATE_TEMPLATE_ITEM = gql`
  mutation UpdateTemplateItem($id: Int!, $data: TemplateItemUpdateInput) {
    updateTemplateItem(id: $id, data: $data) {
      id
      is_reporting_param
      list_types
      name
      needs_water_points
      reporting_label
      parent_id
      suggested_value_category_id
      value
      weight_for_construction_sheet
      weight_for_execution_list
      document {
        id
        name
        src
        extension
      }
    }
  }
`;

export const DELETE_TEMPLATE_ITEM = gql`
  mutation deleteTemplateItem($where: TemplateItemWhereInput) {
    deleteTemplateItem(where: $where) {
      document_id
      id
      is_reporting_param
      list_types
      name
      needs_water_points
      reporting_label
      parent_id
      suggested_value_category_id
      value
      child_pos_construction_sheet
      child_pos_execution_list
    }
  }
`;

export const DELETE_TEMPLATE_ITEM_CHILDREN = gql`
  mutation deleteTemplateItemChildren($where: [Int], $parent_id: Int) {
    deleteTemplateItemChildren(data: $data, parent_id: $parentId) {
      count
    }
  }
`;

export const DELETE_TEMPLATE_ITEM_FROM_TEMPLATE = gql`
  mutation deleteTemplateItemFromTemplate($template_id: Int!, $id: Int!) {
    deleteTemplateItemFromTemplate(template_id: $template_id, id: $id) {
      count
    }
  }
`;

export const sortTemplateItemChildrenOnExecutionList = (
  a: ITemplateItem,
  b: ITemplateItem,
) => {
  if (a.child_pos_execution_list && b.child_pos_execution_list) {
    if (a.child_pos_execution_list < b.child_pos_execution_list) {
      return -1;
    }
    if (a.child_pos_execution_list > b.child_pos_execution_list) {
      return 1;
    }
  }
  return 0;
};

export const sortTemplateItemChildrenOnConstructionSheet = (
  a: ITemplateItem,
  b: ITemplateItem,
) => {
  if (a.child_pos_construction_sheet && b.child_pos_construction_sheet) {
    if (a.child_pos_construction_sheet < b.child_pos_construction_sheet) {
      return -1;
    }
    if (a.child_pos_construction_sheet > b.child_pos_construction_sheet) {
      return 1;
    }
  }
  return 0;
};

// ITemplateItem --> list_types: ik kies hier voor een string (id's gescheiden door een komma) in plaats van objecten, omdat het een koppeltabel zou betreffen.
// Deze zou een samengestelde primaire sleutel moeten hebben, iets wat nog niet lukt in Prisma.
// Hierdoor is het bijwerken van een koppeltabel iets lastiger, maar het heeft hier weinig meerwaarde om met een effectieve koppeltabel te werken.

interface ITemplateItemDocument extends IDocument {
  draft?: boolean;
  dirty?: boolean;
}

export interface ITemplateItem {
  document_id?: number;
  id: number;
  is_reporting_param: boolean;
  list_types?: string;
  name: string;
  needs_water_points?: boolean;
  parent_id?: number;
  reporting_label?: string;
  suggested_value_category_id?: number;
  value?: string;
  child_pos_construction_sheet: number;
  child_pos_execution_list: number;
  draft?: boolean; // Keep track of new items
  dirty?: boolean; // keep track of any changes to items
  delete?: boolean; // delete when saving template
  editType?: boolean;
  existing?: boolean; // if it is an item from a different template
  children?: ITemplateItem[];
  document?: ITemplateItemDocument;
  parent?: ITemplateItem;
  suggested_value_category?: ISuggestedValueCategory;
  template_template_items: ITemplateTemplateItem[];
  type: IPropertyType;
  nested_type?: IPropertyType;
  properties?: IProperty[];
  default_multi_value_children?: IProperty[];
}

export const defaultTemplateItem: ITemplateItem = {
  id: 0,
  is_reporting_param: false,
  name: '',
  type: {
    id: 9,
    name: 'string',
    label: 'Tekst',
  },
  child_pos_construction_sheet: 0,
  child_pos_execution_list: 0,
  template_template_items: [],
  default_multi_value_children: [],
  draft: true,
};

export interface ITemplateItemWhereInput {
  id: number;
}

export interface ITemplateCreateInput {
  name: string;
  type: {
    connect: {
      id: number;
    };
  };

  is_reporting_param?: boolean;
  needs_water_points?: boolean;
  list_types?: string;
  reporting_label?: string;
  value?: string;

  suggested_value_category?: {
    connect: {
      id: number;
    };
  };

  document?: {
    create: {
      name: string;
      src: string;
      extension: string;
    };
  };

  children?: {
    create: ITemplateCreateInput[];
  };
}

export interface ITemplateUpdateInput {
  type: {
    connect: {
      id: number;
    };
  };

  name?: string;
  is_reporting_param?: boolean;
  needs_water_points?: boolean;
  list_types?: string;
  reporting_label?: string;
  value?: string;

  suggested_value_category?: {
    connect: {
      id: number;
    };
  };

  document?: {
    create: {
      name: string;
      src: string;
      extension: string;
    };
  };

  children?: {
    create: ITemplateUpdateInput[];
  };
}

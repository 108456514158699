import { IComboBoxOption } from '@fluentui/react';
import moment from 'moment';
import { gql } from '@apollo/client';
import { ISupplier } from './Supplier';

// For now there are only 20 odd contact persons in the database.
// It doesn't really make sense of having a more specified query to retrieve them.
// But it can be an optimisation in the future.
export const GET_CONTACT_PERSONS = gql`
  query getContactPersons {
    findManyContactPersons {
      id
      first_name
      last_name
      supplier {
        id
        name
      }
    }
  }
`;

export interface IContactPerson {
  id: number;
  first_name: string;
  last_name: string;
  supplier?: ISupplier;
  mobile?: string;
  mobile_v2?: string;
  phone?: string;
  phone_v2?: string;
  email?: string;
  notes?: string;
  fax?: string;
  draft?: boolean;
  dirty?: boolean;
  delete?: boolean;
}

export const convertContactPersonsToComboBoxOptions = (
  contactPersons: IContactPerson[],
): IComboBoxOption[] => {
  const result: IComboBoxOption[] = contactPersons.map(
    (contactPerson: IContactPerson): IComboBoxOption => ({
      key: contactPerson.id,
      text: `${contactPerson.first_name} ${contactPerson.last_name}`,
    }),
  );

  return result;
};

export const getDefaultContactPerson = (): IContactPerson => ({
  id: moment().unix(),
  first_name: '',
  last_name: '',
  draft: true,
});

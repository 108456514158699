import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import errorReducer from './error/errorSlice';
import notificationReducer from './notification/notificationSlice';

export const store = configureStore({
  reducer: combineReducers({
    error: errorReducer,
    notification: notificationReducer,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

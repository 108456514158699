import React, { useCallback } from 'react';
import {
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  SpinButton,
  ISelection,
  IObjectWithKey,
} from '@fluentui/react';
import _ from 'lodash';
import { IProperty } from '../../../utils';

interface Props {
  properties: IProperty[];
  callBackTap: (id: number, value?: string) => void;
  callBackRain: (id: number, value?: string) => void;
  selection: ISelection<IObjectWithKey> | undefined
}

const WaterPointOverview: React.FC<Props> = ({
  properties,
  callBackTap,
  callBackRain,
  selection,
}) => {
  const sumWaterPoints = (item: IProperty) => {
    const waterPoints = item.tap_water_points || 0;
    const rainWaterPoints = item.rain_water_points || 0;

    return waterPoints + rainWaterPoints;
  };
  const columns = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      onRender: (item: IProperty) => <span>{item.name}</span>,
      data: 'number',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Regenwater',
      fieldName: 'rain_water_points',
      minWidth: 125,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: true,
      onRender: (item: IProperty) => (
        <SpinButton
          value={item.rain_water_points.toFixed(0)}
          onChange={(e, value) => callBackRain(item.id, value)}
        />
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Tappunt leidingwater',
      fieldName: 'tap_water_points',
      minWidth: 125,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: true,
      onRender: (item: IProperty) => (
        <SpinButton
          value={item.tap_water_points.toFixed(0)}
          onChange={(e, value) => callBackTap(item.id, value)}
        />
      ),
      data: 'number',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Tappunt (leidingwater + Regenwater)',
      fieldName: '',
      minWidth: 300,
      maxWidth: 300,
      isRowHeader: true,
      isResizable: true,
      onRender: (item: IProperty) => <span>{sumWaterPoints(item)}</span>,
      data: 'number',
      isPadded: true,
    },
  ];

  return (
    <DetailsList
      items={properties}
      compact
      columns={columns}
      selectionMode={SelectionMode.single}
      selection={selection}
      // onItemInvoked={editItem}
      setKey='none'
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible
    />
  );
};

export default WaterPointOverview;

import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import React from 'react';
import { SpinnerDefault } from '../../../components/parts';

const ConstructionSitesMessageBar = ({
  showWarning = false,
  deleteConfirm,
  deleteCancel,
  isLoaded = false,
}: any) => (
  showWarning && (
  <MessageBar
    messageBarType={MessageBarType.severeWarning}
    actions={
          // eslint-disable-next-line react/jsx-wrap-multilines
      <div>
        <MessageBarButton onClick={() => deleteConfirm()}>
          Ja
          {isLoaded ? <SpinnerDefault /> : null}
        </MessageBarButton>
        <MessageBarButton onClick={() => deleteCancel()}>
          Nee
        </MessageBarButton>
      </div>
        }
  >
    Ben je zeker dat je de geselecteerde werf/werven wilt verwijderen?
  </MessageBar>
  )
);

export default ConstructionSitesMessageBar;

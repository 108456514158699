import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  ADD_LOG_ITEM,
  GET_LOG_ITEM_BY_ID,
  ILogItem,
  UPDATE_LOG_ITEM,
} from '../../../utils/LogItem';
import { displayNameLead, GET_LEADS, ILead } from '../../../utils/Lead';
import { GET_SUGGESTED_VALUE } from '../../../utils/SuggestedValue';
import { displayNameSalesRep, IEmployee } from '../../../utils';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import LogItemDetail from './LogItemDetail';

const LogItemTitle = ({ suggestedValue }: any) => {
  const id = +suggestedValue.substr(suggestedValue.length - 4);
  const { loading, error, data } = useQuery(GET_SUGGESTED_VALUE, {
    variables: {
      where: {
        id,
      },
    },
  });

  if (data && data.findOneSuggestedValue) {
    return <span>{data.findOneSuggestedValue.suggested_value}</span>;
  }
  return <span>{suggestedValue}</span>;
};

type Props = {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  logItems: ILogItem[];
  // filter,
  // filterChange?: (filter: string) => void,
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField: string;
  showLoadMore?: boolean;
  currentUser?: IEmployee;
};

const LogItemsOverview = ({
  logItems,
  loading,
  loadMore,
  loadMoreCallback = () => ({}),
  lazyLoading,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  currentUser,
}: Props) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [logItem, setLogItem] = useState<ILogItem>();
  const [leads, setLeads] = useState<ILead[]>([]);

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ILogItem | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  useQuery(GET_LOG_ITEM_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setLogItem(data.findOneLogItem);
    },
  });

  const openLogItemDetail = (newLogItem?: boolean) => {
    if (selectionDetails && !newLogItem) {
      // getLogItem({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setLogItem(undefined);
    }
    setIsPanelOpen(true);
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Save log item
  const [addLogItem] = useMutation(ADD_LOG_ITEM);
  const [modifyLogItem] = useMutation(UPDATE_LOG_ITEM);

  const saveLogItem = () => {
    if (logItem) {
      const allInput = {
        title: logItem.title,
        action_required: logItem.action_required,
        action_required_by: logItem.action_required_by,
        text: logItem.text,
        sales_rep: logItem.sales_rep.id
          ? { connect: { id: logItem.sales_rep.id } }
          : currentUser && currentUser.id
          ? { connect: { id: currentUser.id } }
          : undefined,
      };

      if (logItem.id) {
        modifyLogItem({
          variables: {
            id: logItem.id,
            data: allInput,
          },
        }).then(() => {
          // clearLogItems();
          // refetchLogItems();
          setIsPanelOpen(false);
        });
      } else {
        addLogItem({
          variables: {
            data: allInput,
          },
        }).then(() => {
          // clearLogItems();
          // refetchLogItems();
          setIsPanelOpen(false);
        });
      }
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Prospect',
      fieldName: 'lead',
      minWidth: 250,
      maxWidth: 250,
      isRowHeader: true,
      onRender: (logItem: ILogItem) => (
        <span>{displayNameLead(logItem.lead)}</span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorteer van laag tot hoog',
      sortDescendingAriaLabel: 'Sorteer van hoog tot laag',
    },
    {
      key: 'column2',
      name: 'Verkoper',
      fieldName: 'sales_rep',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (logItem: ILogItem) => (
        <span>
          {logItem.sales_rep || logItem.app_user
            ? displayNameSalesRep(logItem.sales_rep || logItem.app_user)
            : ''}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Laatst gewijzigd',
      fieldName: 'last_updated',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (logItem: ILogItem) => (
        <span>
          {logItem.last_updated &&
            moment(logItem.last_updated).format('DD-MM-YYYY')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Omschrijving',
      fieldName: 'title',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (logItem: ILogItem) => (
        <span>
          <LogItemTitle suggestedValue={logItem.title} />
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Vervaldatum',
      fieldName: 'action_required_by',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (logItem: ILogItem) => (
        <span>
          {logItem.action_required_by &&
            logItem.action_required &&
            moment(logItem.action_required_by).format('DD-MM-YYYY')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) => {
    const cols = columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    return cols;
  };

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openLogItemDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (logItem && isPanelOpen) {
      document.title = `3bouw | Log item - ${logItem.title}`;
    } else {
      document.title = '3bouw | Log items';
    }
  }, [logItem, isPanelOpen]);

  return (
    logItems && (
      <>
        <CommandBarSticky
          items={commandBaritems}
          theme={commandBarTheme}
          width='1200px'
          maxWidth='1200px'
        />

        <LogItemDetail
          isOpen={isPanelOpen}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          logItem={logItem || {}}
          saveLogItem={saveLogItem}
          setLogItem={setLogItem}
        />

        <ShimmeredDetailsList
          items={logItems}
          columns={columns}
          getKey={getKey}
          enableShimmer={loading}
          ariaLabelForShimmer='Content is being fetched'
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible
          selection={selection}
          selectionMode={SelectionMode.single}
          selectionPreservedOnEmptyClick
        />
        {!loading && !showLoadMore && logItems.length === 0 && (
          <div
            style={{
              textAlign: 'center',
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Geen resultaten
          </div>
        )}
        {showLoadMore && (
          <Stack
            style={{
              marginTop: '15px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            horizontal
            horizontalAlign='center'
          >
            <PrimaryButton
              text='Toon meer'
              onClick={() => loadMoreCallback()}
            />
          </Stack>
        )}
        <Stack style={{ minHeight: '50px' }} />
      </>
    )
  );
};

export default LogItemsOverview;

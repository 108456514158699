import React, { useState, useCallback } from 'react';
import {
  Stack, Label, PrimaryButton, Selection,
} from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { IProperty, stackTokens5, stackTokens50 } from '../../../utils';
import { defaultProperty } from '../../../utils/Property';
import WaterPointOverview from './WaterPointOverview';

const WaterPoints = ({
  callBackWaterPoints,
  properties,
  editItem,
  setSelectedPropertyID,
}: any) => {
  const onSelectionChanged = () => {
    const selection: any = _selection.getSelection();
    if (selection && selection.length > 0) {
      if (selection[0].id) setSelectedPropertyID(selection[0].id);
    } else {
      setSelectedPropertyID(undefined);
    }
  };

  const [_selection] = useState(
    new Selection({
      onSelectionChanged,
    }),
  );

  const calculateTotalWaterPoints = (props: IProperty[]) => props
    .map(
      (el: IProperty) => (el.rain_water_points || 0) + (el.tap_water_points || 0),
    )
    .reduce((a: number, b: number) => a + b, 0);

  const calculateTotaRainlWaterPoints = (props: IProperty[]) => props
    .map((el: IProperty) => el.rain_water_points || 0)
    .reduce((a: number, b: number) => a + b, 0);

  const [totalWaterPoints, setTotalWaterPoints] = useState(
    calculateTotalWaterPoints(properties),
  );
  const [totalRainWaterPoints, setTotalRainWaterPoints] = useState(
    calculateTotaRainlWaterPoints(properties),
  );
  const callBackRain = useCallback(
    (id: number, value?: string) => {
      // Update properties

      callBackWaterPoints((prevState: IProperty[]) => {
        const newProperties = _.cloneDeep(prevState);

        // Update properties
        prevState.map((el: IProperty, index: number) => {
          if (el.id === id) {
            newProperties[index].rain_water_points = value ? parseInt(value, 10) : 0;
            newProperties[index].isDirty = true;
          }
        });

        setTotalWaterPoints(calculateTotalWaterPoints(newProperties));
        setTotalRainWaterPoints(calculateTotaRainlWaterPoints(newProperties));

        return newProperties;
      });
    },
    [
      properties,
      setTotalRainWaterPoints,
      setTotalWaterPoints,
      callBackWaterPoints,
    ],
  );

  const callBackTap = useCallback(
    (id: number, value?: string) => {
      callBackWaterPoints((prevState: IProperty[]) => {
        const newProperties = _.cloneDeep(prevState);

        // Update properties
        prevState.map((el: IProperty, index: number) => {
          if (el.id === id) {
            console.log(value);
            newProperties[index].tap_water_points = value ? parseInt(value, 10) : 0;
            newProperties[index].isDirty = true;
          }
        });

        setTotalWaterPoints(calculateTotalWaterPoints(newProperties));
        setTotalRainWaterPoints(calculateTotaRainlWaterPoints(newProperties));

        return newProperties;
      });
    },
    [
      properties,
      setTotalRainWaterPoints,
      setTotalWaterPoints,
      callBackWaterPoints,
    ],
  );

  const sumWaterPoints = (item: IProperty) => {
    if (
      (item.rain_water_points || item.rain_water_points === 0)
      && (item.tap_water_points || item.tap_water_points === 0)
    ) {
      return item.rain_water_points + item.tap_water_points;
    } if (item.rain_water_points || item.rain_water_points === 0) {
      return item.rain_water_points;
    } if (item.tap_water_points || item.tap_water_points === 0) {
      return item.tap_water_points;
    }

    return 0;
  };

  const addWaterPoint = () => {
    const newProperty: IProperty = { ...defaultProperty };

    newProperty.weight_for_construction_sheet = -1;
    newProperty.weight_for_execution_list = -1;
    newProperty.show_in_construction_sheet = false;
    newProperty.show_in_execution_list = false;
    newProperty.needs_water_points = true;
    newProperty.tap_water_points = 0;
    newProperty.rain_water_points = 0;
    newProperty.id = moment().unix();

    const newProperties = _.cloneDeep(properties);

    newProperties.push(newProperty);

    callBackWaterPoints(newProperties);
  };

  return (
    <Stack tokens={stackTokens5}>
      <Label>TAPPUNTEN</Label>
      <Stack horizontal disableShrink tokens={stackTokens50}>
        <Stack.Item>Totaal aantal tappunten voorzien:</Stack.Item>
        <Stack.Item>{totalWaterPoints}</Stack.Item>
      </Stack>
      <Stack horizontal disableShrink tokens={stackTokens50}>
        <Stack.Item>Tappunten hiervan op regenwater:</Stack.Item>
        <Stack.Item>{totalRainWaterPoints}</Stack.Item>
      </Stack>
      <Stack styles={{ root: { paddingTop: '20px' } }}>
        <Stack.Item>
          <PrimaryButton
            onClick={() => {
              addWaterPoint();
            }}
          >
            Tappunt toevoegen
          </PrimaryButton>
        </Stack.Item>
      </Stack>
      {/* <Stack horizontal disableShrink tokens={stackTokens50}>
                <Stack.Item><Label>SANITAIRE TOESTELLEN GELEVERD DOOR:</Label></Stack.Item>
                <Stack.Item>
                    <TextField />
                </Stack.Item>
            </Stack> */}
      <WaterPointOverview
        properties={properties}
        callBackRain={callBackRain}
        callBackTap={callBackTap}
        selection={_selection}
      />
    </Stack>
  );
};

export default WaterPoints;

import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react';
import React, { FormEvent, useState } from 'react';

const InlineComboBox = ({
  isEdit,
  value,
  options,
  callBack,
  ...props
}: any) => {
  const [selectedKey, setSelectedKey] = useState(value);

  const onChange = (event: FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option) {
      setSelectedKey(+option.key);
      callBack(+option.key);
    }
  };

  const getValue = () => {
    if (options && options.length > 0) {
      const SelectedValue = options.filter(
        (item: IComboBoxOption) => item.key === value,
      );
      if (SelectedValue && SelectedValue[0] && SelectedValue[0].text) {
        return SelectedValue[0].text;
      }
    }
    return '';
  };

  return isEdit ? (
    <ComboBox
      selectedKey={selectedKey}
      allowFreeform
      autoComplete='on'
      options={options}
      onChange={onChange}
      tabIndex={0}
      {...props}
    />
  ) : (
    <span>{getValue()}</span>
  );
};

export default InlineComboBox;

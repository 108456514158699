import React, { useState } from 'react';
import {
  Icon, Stack, Link, StackItem,
} from '@fluentui/react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { getTheme } from '@fluentui/react/lib/Styling';
import {
  callOutClasses,
  GET_STORAGE_TOKEN,
  iconLastProps,
  stackTokens15,
  stackTokens5,
} from '../../../utils';
import { BlobStorage, UploadFile } from '../../blob-storage';
import { CalloutDefault } from '../../../components/parts';
import { IProperty } from '../../../utils/Property';

const theme = getTheme();

interface IDocumentView {
  property: IProperty;
  callBack: (item: IProperty) => void;
  edit?: boolean;
  compact?: boolean;
}

const DocumentView: React.FC<IDocumentView> = ({
  property,
  callBack,
  edit,
  compact = false,
}) => {
  // Constants
  const name = property && property.document ? property.document.name : '';
  const blobName = property && property.document ? property.document.blob_name : '';

  const { isAuthenticated } = useAuth0();
  // Queries
  const { loading, error, data } = useQuery(GET_STORAGE_TOKEN);

  // Hooks
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  // Functions
  const downloadFile = (url?: string) => {
    if (url) {
      fileStore.downloadBlob('temp', url);
    }
  };

  const toggleCallOut = () => {
    setIsCalloutVisible(!isCalloutVisible);
  };

  const updateBlobName = (blobName?: string): IProperty => {
    const newProperty = _.cloneDeep(property);

    if (newProperty && newProperty.document) {
      newProperty.isDirty = true;
      if (blobName !== undefined) {
        newProperty.document.blob_name = blobName;
        newProperty.document.name = blobName;
      }
      return newProperty;
    } if (newProperty) {
      newProperty.isDirty = true;
      if (blobName !== undefined) {
        newProperty.document = {
          blob_name: blobName,
          name: blobName,
        };
      }
    }
    return newProperty;
  };

  const deleteDocument = () => {
    setIsCalloutVisible(false);
    const newProperty = _.cloneDeep(property);
    newProperty.document = {
      is_external: false,
      blob_name: '',
      name: '',
      dirty: true,
      draft: !(newProperty.document && newProperty.document.id),
      id: newProperty.document ? newProperty.document.id : undefined,
    };
    return callBack(newProperty);
  };

  if (loading) return <div>Loading</div>;

  const fileStore = new BlobStorage(
    data.createStorageToken.accountName,
    data.createStorageToken.sasToken,
  );

  return (
    <Stack tokens={stackTokens5}>
      {blobName && (
        <div>
          <Stack
            horizontal
            tokens={stackTokens15}
            style={{ padding: '10px 0px' }}
          >
            <StackItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                width: '100%',
              }}

            >
              <Link
                href='#'
                onClick={(e) => { e.preventDefault(); downloadFile(blobName); }}
                id={`deleteDocument-${property.id}-${(property.document ? property.document.id : '')}`}
                style={{
                  padding: '3px',
                  backgroundColor: theme.palette.themePrimary,
                  color: 'white',
                  maxWidth: '80%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: 'inline-block',
                  borderRadius: '5px',
                }}
              >
                {blobName}
              </Link>
              <div style={{ display: 'inline-block', maxWidth: '15%' }}>
                <Icon
                  iconName='Cancel'
                  styles={iconLastProps}
                  onClick={() => toggleCallOut()}
                />
              </div>
            </StackItem>
            <StackItem>
              <CalloutDefault
                actionConfirm={() => deleteDocument()}
                actionCancel={() => toggleCallOut()}
                actionText='Verwijderen'
                message='Bent u zeker het bestand te willen verwijderen? Het bestand zal niet meer gekoppeld worden aan het huidige item.'
                target={`#${`deleteDocument-${property.id}-${(property.document ? property.document.id : '')}`}`}
                title='Bestand verwijderen?'
                visible={isCalloutVisible}
              />
            </StackItem>
          </Stack>
        </div>
      )}
      <UploadFile
        container='temp'
        selected={blobName}
        updateName={(name: string) => callBack(updateBlobName(name))}
      />
    </Stack>
  );
};

export default DocumentView;

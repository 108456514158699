import { gql } from '@apollo/client';
import moment from 'moment';

import { IMeasuringUnit } from './MeasuringUnit';

export const GET_PURCHASE_ORDER_LINE_ITEMS = gql`
  query getPurchaseOrderLine($orderBy: PurchaseOrderLineItemOrderByInput) {
    findManyPurchaseOrderLine(orderBy: $orderBy) {
      id
      name
      description
      sku
      unit_price
      measuring_unit {
        id
        name
      }
      comment
      quantity
    }
  }
`;

export interface IPurchaseOrderLine {
  // [key: string]: any;
  id: number;
  name: string;
  description?: string;
  sku?: string;
  unit_price: string;
  quantity?: string;
  comment?: string;
  measuring_unit: IMeasuringUnit;
  weight?: number;
  isDirty?: boolean;
  isNew?: boolean;
  delete?: boolean;
}

export interface IPurchaseOrderLineCreate {
  name: string;
  sku?: string;
  quantity: number;
  unit_price: number;
  description?: string;
  comment?: string;
  measuring_unit?: {
    connect: {
      id: number;
    };
  };
  weight?: number;
}

export interface IPurchaseOrderLineUpdate {
  data: {
    name: string;
    sku?: string;
    quantity: number;
    comment?: string;
    unit_price: number;
    description?: string;
    measuring_unit?: {
      connect: {
        id: number;
      };
    };
    weight?: number;
  };
  where: {
    id: number;
  };
}

export interface IPurchaseOrderLineDelete {
  id: number;
}

export interface IPurchaseOrderLineConnect {
  id: number;
}

export const default_line_item: IPurchaseOrderLine = {
  id: 0,
  name: '',
  unit_price: '0',
  quantity: '0',
  comment: '',
  measuring_unit: {
    id: 7,
    name: 'ST',
  },
  weight: 0,
  isDirty: false,
  isNew: true,
};

export const getDefaultLineItem = ({ weight }: { weight?: number }) => {
  const id = moment().unix();
  return {
    ...default_line_item,
    id,
    weight: weight || 0,
  };
};

import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import {
  ADD_CUSTOMER_CONTRACT_UPDATE,
  GET_CUSTOMER_CONTRACT_UPDATE_BY_ID,
  UPDATE_CUSTOMER_CONTRACT_UPDATE,
  ICustomerContractUpdate,
} from '../../../utils/CustomerContractUpdate';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { GET_CONSTRUCTION_SITES } from '../../../utils';
import useDebounce from '../../../components/hooks/useDebounce';
import CustomerContractUpdateDetail from './CustomerContractUpdateDetail';

const CustomerContractUpdatesOverview = ({
  customerContractUpdates,
  setCustomerContractUpdates,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  refetchCustomerContractUpdates,
}: any) => {
  const [customerContractUpdate, setCustomerContractUpdate] = useState<
    ICustomerContractUpdate | undefined
  >(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [constructionSites, setConstructionSites] = useState([]);

  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');

  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const [addCustomerContractUpdate] = useMutation(ADD_CUSTOMER_CONTRACT_UPDATE);
  const [modifyCustomerContractUpdate] = useMutation(
    UPDATE_CUSTOMER_CONTRACT_UPDATE,
  );

  useQuery(GET_CONSTRUCTION_SITES, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        name: {
          contains: debouncedFilter,
        },
      },
    },
    onCompleted: (x: any) => {
      setConstructionSites(x.findManyConstructionSites);
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ICustomerContractUpdate | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openCustomerContractUpdateDetail = (
    newCustomerContractUpdate?: boolean,
  ) => {
    if (newCustomerContractUpdate) {
      setCustomerContractUpdate(undefined);
    }
    setIsPanelOpen(true);
  };

  const { refetch: refetchCustomerContractUpdate } = useQuery(
    GET_CUSTOMER_CONTRACT_UPDATE_BY_ID,
    {
      // notifyOnNetworkStatusChange: true,
      variables: selectionDetails
        ? { where: { id: selectionDetails.id } }
        : undefined,
      skip: !selectionDetails,
      onCompleted: data => {
        setCustomerContractUpdate(data.findOneCustomerContractUpdate);
      },
    },
  );

  const saveCustomerContractUpdate = () => {
    if (customerContractUpdate) {
      const allInput = {
        status: customerContractUpdate.status || 'NEW',
        summary: customerContractUpdate.summary,
        intro: customerContractUpdate.intro,
        outro: customerContractUpdate.outro,
        construction_site: {
          connect: { id: customerContractUpdate.construction_site && customerContractUpdate.construction_site.id },
        },
      };

      if (customerContractUpdate.id) {
        modifyCustomerContractUpdate({
          variables: {
            id: customerContractUpdate.id,
            data: allInput,
          },
        }).then(() => {
          setCustomerContractUpdates((prevState: any) => ({
            ...prevState,
            customerContractUpdates: [],
          }));
          refetchCustomerContractUpdates();
          setConstructionSiteFilter('');
          setIsPanelOpen(false);
        });
      } else {
        addCustomerContractUpdate({
          variables: {
            data: allInput,
          },
        }).then(x => {
          setCustomerContractUpdate(x.data.createCustomerContractUpdate);
          selection.setItems(x.data.createCustomerContractUpdate);
        });
      }
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Document no',
      fieldName: 'document_no',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      onRender: (customerContractUpdate: ICustomerContractUpdate) => (
        <span>{customerContractUpdate.document_no}</span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Omschrijving',
      fieldName: 'summary',
      minWidth: 400,
      maxWidth: 400,
      isRowHeader: true,
      onRender: (customerContractUpdate: ICustomerContractUpdate) => (
        <span>{customerContractUpdate.summary}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Werf',
      fieldName: 'construction_site',
      minWidth: 325,
      maxWidth: 325,
      isRowHeader: true,
      onRender: (customerContractUpdate: ICustomerContractUpdate) => (
        <span>{customerContractUpdate.construction_site?.name}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Datum',
      fieldName: 'document_date',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      onRender: (customerContractUpdate: ICustomerContractUpdate) => (
        <span>
          {moment(customerContractUpdate.document_date).format('YYYY-MM-DD')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Status',
      fieldName: 'status',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      onColumnClick,
      onRender: (customerContractUpdate: ICustomerContractUpdate) => {
        switch (customerContractUpdate.status) {
          case 'NEW':
            return <span>Nieuw</span>;
          case 'CANCELLED':
            return <span>Geannuleerd</span>;
          case 'INVOICED':
            return <span>Op factuur</span>;
          case 'COMPLETED':
            return <span>Afgesloten</span>;
          default:
            return <span>{customerContractUpdate.status}</span>;
        }
      },
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openCustomerContractUpdateDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openCustomerContractUpdateDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (customerContractUpdate && isPanelOpen) {
      document.title = `3bouw | Min-/meerwerk - ${customerContractUpdate.document_no}`;
    } else {
      document.title = '3bouw | Min- en meerwerken';
    }
  }, [customerContractUpdate, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />

      {isPanelOpen && (
      <CustomerContractUpdateDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        customerContractUpdateSource={customerContractUpdate || {}}
      />
      )}
      {customerContractUpdates && (
        <>
          <ShimmeredDetailsList
            items={customerContractUpdates}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selection={selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && customerContractUpdates.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default CustomerContractUpdatesOverview;

// Obsolote: in DetailsListDefault
import {
  IDragDropContext,
  Selection,
  getTheme,
  mergeStyles,
} from '@fluentui/react';
import { useState } from 'react';

const theme = getTheme();

const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
});

// Try and refactor the drag and drop functionality
// Strange: when this name starts with a lowercase letter, it generates errors that useState cannot be used in a function.
// Starting with an uppercase letter no problems at all...
const DragAndDrop = (
  items: any[],
  selection: Selection,
  onDropFn: (items: any[]) => Record<string, unknown>,
) => {
  const [draggedIndex, setDraggedIndex] = useState(-1);
  const [draggedItem, setDraggedItem] = useState();

  return {
    canDrop: (
      dropContext?: IDragDropContext,
      dragContext?: IDragDropContext,
    ) => true,
    canDrag: (item?: any) => true,
    onDragEnter: (item?: any, event?: DragEvent) => dragEnterClass,
    onDragLeave: (item?: any, event?: DragEvent) => {
      // return;
    },
    onDrop: (item?: any, event?: DragEvent) => {
      if (draggedItem) {
        const draggedItems = selection.isIndexSelected(draggedIndex)
          ? selection.getSelection()
          : [draggedItem!];

        const insertIndex = items.indexOf(item);
        const newItems = items.filter(
          (itm: any) => draggedItems.indexOf(itm) === -1,
        );

        newItems.splice(insertIndex, 0, ...draggedItems);

        onDropFn(newItems);
      }
    },
    onDragStart: (
      item?: any,
      itemIndex?: number,
      //   selectedItems?: any[],
      //   event?: MouseEvent,
    ) => {
      setDraggedItem(item);
      setDraggedIndex(itemIndex!);
    },
    onDragEnd: () => {
      setDraggedItem(undefined);
      setDraggedIndex(-1);
    },
  };
};

export default DragAndDrop;

import { useAuth0 } from '@auth0/auth0-react';
import {
  ICommandBarItemProps,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
} from '@fluentui/react';
import { useState, useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import useDebounce from '../../components/hooks/useDebounce';
import { CommandBarSticky } from '../../components/parts';
import { commandBarTheme } from '../../theme';
import {
  ADD_PURCHASE_ORDER_TEMPLATE,
  IPurchaseOrderTemplate,
  stackTokens15,
  UPDATE_PURCHASE_ORDER_TEMPLATE,
  GET_PURCHASE_ORDER_TEMPLATE_BY_ID,
  GET_MEASURING_UNITS,
  IPurchaseOrderLine,
} from '../../utils';
import {
  GET_SUPPLIER_BY_ID,
  ISupplier,
  GET_SUPPLIERS,
} from '../../utils/Supplier';
import { default_template } from '../../utils/PurchaseOrderTemplate';
import { getDefaultLineItem } from '../../utils/PurchaseOrderLine';
import PurchaseOrderTemplateBody from './components/PurchaseOrderTemplateBody';
import PurchaseOrderTemplateHeader from './components/PurchaseOrderTemplateHeader';

const sortLineItems = (data: IPurchaseOrderTemplate) => {
  const newPurchaseOrder = _.cloneDeep(data);
  if (newPurchaseOrder.line_items) {
    newPurchaseOrder.line_items.sort(
      (a, b) => (a.weight || 0) - (b.weight || 0),
    );
  }

  for (let i = 0; i < newPurchaseOrder.line_items.length; i++) {
    const currentWeight = newPurchaseOrder.line_items[i].weight;
    newPurchaseOrder.line_items[i].weight = i + 1;
    if (currentWeight !== newPurchaseOrder.line_items[i].weight) {
      newPurchaseOrder.line_items[i].isDirty = true;
    }
  }

  return newPurchaseOrder;
};

const addEmptyPurchaseOrderline = (data: IPurchaseOrderTemplate) => {
  const newPurchaseOrder = _.cloneDeep(data);

  if (newPurchaseOrder && newPurchaseOrder.line_items.length === 0) {
    newPurchaseOrder.line_items.push(getDefaultLineItem({}));
  } else {
    const arrayLength = newPurchaseOrder.line_items.length;
    const lastItem = newPurchaseOrder.line_items[arrayLength - 1];
    if ((lastItem && !lastItem.isNew) || (lastItem.isNew && lastItem.isDirty)) {
      const newWeight = (lastItem.weight || 0) + 1;
      newPurchaseOrder.line_items.push(
        getDefaultLineItem({ weight: newWeight }),
      );
    }
  }

  return newPurchaseOrder;
};

const PurchaseOrderTemplate = ({ ...props }: any) => {
  const { isAuthenticated } = useAuth0();

  const { id: purchaseOrderID } = useParams();

  const purchaseOrderTemplateId =
     purchaseOrderID && purchaseOrderID !== 'add'
      ? +purchaseOrderID
      : undefined;

  const [purchaseOrderTemplate, setPurchaseOrderTemplate] =
    useState<IPurchaseOrderTemplate>(default_template);
  const [status, setStatus] = useState<'new' | 'saving' | 'error' | 'saved'>(
    'new',
  );

  useEffect(() => {
    setPurchaseOrderTemplate(prevState => {
      const newState = sortLineItems(prevState);
      return addEmptyPurchaseOrderline(newState);
    });
  }, []);

  const [supplierSearch, setSupplierSearch] = useState<string | undefined>();
  const debouncedSearchTerm = useDebounce(supplierSearch, 500);
  const [suppliers, setSuppliers] = useState<ISupplier[] | undefined>();

  const supplierQuery = () => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTerm
        ? {
            OR: [
              { name: { contains: debouncedSearchTerm } },
              { city: { contains: debouncedSearchTerm } },
              { email: { contains: debouncedSearchTerm } },
              { phone_v2: { contains: debouncedSearchTerm } },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  };

  // Queries
  const { loading: loadingSuppliers } = useQuery(GET_SUPPLIERS, {
    variables: {
      ...supplierQuery(),
    },
    onCompleted: (x: any) => {
      if (x && x.findManySuppliers) {
        setSuppliers(x.findManySuppliers);
      }
    },
    // skip: order && order.id && order.id > 0,
  });

  const [getSupplier] = useLazyQuery(GET_SUPPLIER_BY_ID, {
    onCompleted: (x: any) => {
      setPurchaseOrderTemplate(prevState => {
        const newState = _.clone(prevState);

        if (newState) newState.supplier = x.findOneSupplier;
        return newState;
      });
    },
  });

  useEffect(() => {
    if (
      purchaseOrderTemplate &&
      purchaseOrderTemplate.supplier &&
      !purchaseOrderTemplate.supplier.contact_persons
    ) {
      // console.log('fetch supplier');
      getSupplier({
        variables: { where: { id: purchaseOrderTemplate.supplier.id } },
      });
    }
  }, [purchaseOrderTemplate.supplier]);

  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_PURCHASE_ORDER_TEMPLATE_BY_ID, {
    variables: {
      where: {
        id: purchaseOrderTemplateId,
      },
    },
    skip: purchaseOrderTemplateId === undefined,
    onCompleted: (x: any) => {
      if (x && x.findOnePurchaseOrderTemplate) {
        let purchaseOrderTemplateData: IPurchaseOrderTemplate =
          x.findOnePurchaseOrderTemplate;
        if (
          purchaseOrderTemplateData &&
          !purchaseOrderTemplateData.line_items
        ) {
          purchaseOrderTemplateData.line_items = [];
        }

        purchaseOrderTemplateData = sortLineItems(purchaseOrderTemplateData);
        purchaseOrderTemplateData = addEmptyPurchaseOrderline(
          purchaseOrderTemplateData,
        );
        setPurchaseOrderTemplate(purchaseOrderTemplateData);
      }
    },
  });

  const {
    loading: loadingMeasuringUnit,
    error: errorMeasuringUnit,
    data: dataMeasuringUnits,
  } = useQuery(GET_MEASURING_UNITS, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
  });

  const [addPurchaseOrderTemplate] = useMutation(ADD_PURCHASE_ORDER_TEMPLATE);
  const [updatePurchaseOrderTemplate] = useMutation(
    UPDATE_PURCHASE_ORDER_TEMPLATE,
    {
      onCompleted: (x: any) => {
        if (x.updatePurchaseOrderTemplate) {
          let purchaseOrderTemplateData = x.updatePurchaseOrderTemplate;
          purchaseOrderTemplateData = sortLineItems(purchaseOrderTemplateData);
          purchaseOrderTemplateData = addEmptyPurchaseOrderline(
            purchaseOrderTemplateData,
          );
          setPurchaseOrderTemplate(purchaseOrderTemplateData);
        }
      },
    },
  );

  const resetStatus = () => {
    setStatus('new');
  };

  const commandBarFaritems: ICommandBarItemProps[] = [
    {
      key: 'back',
      text: 'Terug naar overzicht',
      iconProps: { iconName: 'Back' },
      href: '/purchase-order-templates',
      theme: commandBarTheme,
    },
    {
      key: 'save',
      text: 'Opslaan',
      iconProps: { iconName: 'Save' },
      onClick: () => savePurchaseOrderTemplate(),
      theme: commandBarTheme,
      disabled: purchaseOrderTemplate === undefined,
    },
  ];

  const commandBarItems = (): ICommandBarItemProps[] => {
    const items: ICommandBarItemProps[] = [];

    if (status) {
      switch (status) {
        case 'saved':
          items.push({
            key: 'saved',
            text: 'Opgeslagen',
            iconProps: { iconName: 'CheckMark' },
            theme: commandBarTheme,
          });
          break;
        case 'saving':
          items.push({
            key: 'saving',
            text: 'Opslaan',
            theme: commandBarTheme,
            onRender: () => (
              <Stack horizontal tokens={stackTokens15} verticalAlign='center'>
                <StackItem>
                  <Spinner size={SpinnerSize.small} />
                </StackItem>
                <StackItem>Opslaan</StackItem>
              </Stack>
            ),
          });
          break;
        default:
          break;
      }
    }

    return items;
  };

  const updateTemplateLines = (items: IPurchaseOrderLine[]) => {
    setPurchaseOrderTemplate(prevState => {
      if (prevState) {
        let newTemplate = _.cloneDeep(prevState);
        newTemplate.line_items = items;

        newTemplate = addEmptyPurchaseOrderline(newTemplate);

        return newTemplate;
      }
      return prevState;
    });
  };

  const checkNoName = (purchaseOrderTemplate: IPurchaseOrderTemplate) =>
    purchaseOrderTemplate.name === '' ||
    purchaseOrderTemplate.name === undefined ||
    purchaseOrderTemplate.name === null ||
    purchaseOrderTemplate.name.startsWith('nieuw-sjabloon');

  const checkNoSupplier = (purchaseOrderTemplate: IPurchaseOrderTemplate) =>
    purchaseOrderTemplate.supplier === undefined ||
    purchaseOrderTemplate.supplier.id === undefined ||
    purchaseOrderTemplate.supplier.id === 0;

  const savePurchaseOrderTemplate = () => {
    let updatedValues = {};

    if (purchaseOrderTemplate) {
      const noName = checkNoName(purchaseOrderTemplate);
      const noSupplier = checkNoSupplier(purchaseOrderTemplate);

      if (noName || noSupplier) {
        return;
      }

      if (purchaseOrderTemplate.id > 0) {
        // update
        if (purchaseOrderTemplate.name) {
          updatedValues = {
            ...updatedValues,
            name: purchaseOrderTemplate.name,
          };
        }

        updatedValues = {
          ...updatedValues,
          remarks: purchaseOrderTemplate.remarks
            ? purchaseOrderTemplate.remarks
            : '',
        };

        if (purchaseOrderTemplate.contact_person_changed) {
          updatedValues = {
            ...updatedValues,
            is_default_contact_person:
              purchaseOrderTemplate.is_default_contact_person,
          };

          if (purchaseOrderTemplate.is_default_contact_person) {
            updatedValues = {
              ...updatedValues,
              contact_person: {
                disconnect: true,
              },
            };
          } else if (purchaseOrderTemplate.contact_person) {
            updatedValues = {
              ...updatedValues,
              contact_person: {
                connect: {
                  id: purchaseOrderTemplate.contact_person.id,
                },
              },
            };
          }
        }

        if (
          purchaseOrderTemplate.supplier &&
          purchaseOrderTemplate.supplier.id
        ) {
          updatedValues = {
            ...updatedValues,
            supplier: {
              connect: {
                id: purchaseOrderTemplate.supplier.id,
              },
            },
          };
        }

        if (
          purchaseOrderTemplate.line_items &&
          purchaseOrderTemplate.line_items.length > 0
        ) {
          // Update Purchase Order Line Items
          const updates = purchaseOrderTemplate.line_items
            .filter(
              (item: IPurchaseOrderLine) =>
                item.isDirty && !item.delete && !item.isNew,
            )
            .map((item: IPurchaseOrderLine) => ({
              where: {
                id: item.id,
              },
              data: {
                name: item.name,
                description: item.description,
                sku: item.sku,
                unit_price: +item.unit_price,
                quantity: item.quantity && +item.quantity,
                comment: item.comment,
                measuring_unit: item.measuring_unit
                  ? {
                      connect: {
                        id: item.measuring_unit.id,
                      },
                    }
                  : undefined,
                weight: item.weight,
              },
            }));

          // Remove link between template and deleted items

          const deletes = purchaseOrderTemplate.line_items
            .filter((item: IPurchaseOrderLine) => !item.isNew && item.delete)
            .map((item: IPurchaseOrderLine) => ({ id: item.id }));

          // Add new items
          const creates = purchaseOrderTemplate.line_items
            .filter(
              (item: IPurchaseOrderLine) =>
                item.isNew && item.isDirty && !item.delete,
            )
            .map((item: IPurchaseOrderLine) => ({
              name: item.name,
              description: item.description,
              sku: item.sku,
              unit_price: +item.unit_price,
              quantity: item.quantity && +item.quantity,
              comment: item.comment,
              measuring_unit: item.measuring_unit
                ? {
                    connect: {
                      id: item.measuring_unit.id,
                    },
                  }
                : undefined,
              active: true,
              weight: item.weight,
            }));

          updatedValues = {
            ...updatedValues,
            line_items: {
              create: creates.length > 0 ? creates : undefined,
              update: updates.length > 0 ? updates : undefined,
              delete: deletes.length > 0 ? deletes : undefined,
            },
          };
        }

        setStatus('saving');

        updatePurchaseOrderTemplate({
          variables: {
            id: purchaseOrderTemplate.id,
            data: updatedValues,
          },
        })
          .then(data => {
            // ();
            setStatus('saved');
            setTimeout(resetStatus, 5000);
          })
          .catch(error => {
            setStatus('error');
            setTimeout(resetStatus, 5000);
            console.log(error);
          });
      } else {
        // Add
        let createValues = {};
        createValues = { ...createValues, name: purchaseOrderTemplate.name };
        if (purchaseOrderTemplate.remarks) {
          createValues = {
            ...createValues,
            remarks: purchaseOrderTemplate.remarks,
          };
        }
        if (
          purchaseOrderTemplate.supplier &&
          purchaseOrderTemplate.supplier.id
        ) {
          createValues = {
            ...createValues,
            supplier: {
              connect: {
                id: purchaseOrderTemplate.supplier.id,
              },
            },
          };
        }
        if (purchaseOrderTemplate.is_default_contact_person) {
          createValues = {
            ...createValues,
            is_default_contact_person: true,
          };
        } else if (purchaseOrderTemplate.contact_person) {
          createValues = {
            ...createValues,
            is_default_contact_person: false,
            contact_person: {
              connect: {
                id: purchaseOrderTemplate.contact_person.id,
              },
            },
          };
        }
        if (
          purchaseOrderTemplate.line_items &&
          purchaseOrderTemplate.line_items.length > 0
        ) {
          const children = purchaseOrderTemplate.line_items
            .filter((item: IPurchaseOrderLine) => item.isNew && item.isDirty)
            .map((item: IPurchaseOrderLine) => ({
              name: item.name,
              description: item.description,
              sku: item.sku,
              unit_price: +item.unit_price,
              quantity: item.quantity && +item.quantity,
              comment: item.comment,
              measuring_unit: item.measuring_unit
                ? {
                    connect: {
                      id: item.measuring_unit.id,
                    },
                  }
                : undefined,
              active: true,
              weight: item.weight,
            }));

          createValues = {
            ...createValues,
            line_items: {
              create: children,
            },
          };
        }

        setStatus('saving');

        addPurchaseOrderTemplate({
          variables: {
            data: createValues,
          },
        })
          .then((x: any) => {
            navigate(
              `/purchase-order-template/${x.data.createPurchaseOrderTemplate.id}`,
            );
            setStatus('saved');
            setTimeout(resetStatus, 5000);
          })
          .catch(error => {
            setStatus('error');
            setTimeout(resetStatus, 5000);
            console.log(error);
          });
      }
    }
  };

  const modifyPurchaseOrderTemplate = (
    purchaseOrderTemplate: IPurchaseOrderTemplate,
  ) => {
    const noName = checkNoName(purchaseOrderTemplate);
    const noSupplier = checkNoSupplier(purchaseOrderTemplate);

    setPurchaseOrderTemplate(purchaseOrderTemplate);
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (loading) return <p>Laden...</p>;
  if (!loading && !purchaseOrderTemplate) return <p>Geen template.</p>;
  if (error) return <p>Fout :(</p>;

  document.title = `3bouw | Sjabloon - ${purchaseOrderTemplate.name}`;

  return (
    <Stack tokens={stackTokens15}>
      {/* <CommandBar
                items={commandBarItems()}
                farItems={commandBarFaritems}
                ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
                theme={commandBarTheme} /> */}

      <CommandBarSticky
        items={commandBarItems()}
        farItems={commandBarFaritems}
        ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
        theme={commandBarTheme}
        width='1239px'
        maxWidth='1239px'
      />

      <h3>Bestelbon sjabloon</h3>

      <PurchaseOrderTemplateHeader
        template={purchaseOrderTemplate}
        callBack={modifyPurchaseOrderTemplate}
        suppliers={suppliers}
        setSupplierSearch={setSupplierSearch}
        supplierSearch={supplierSearch}
        suppliersLoading={loadingSuppliers}
      />

      <PurchaseOrderTemplateBody
        template={purchaseOrderTemplate}
        callBack={updateTemplateLines}
        measuringUnits={
          dataMeasuringUnits && dataMeasuringUnits.findManyMeasuringUnits
            ? dataMeasuringUnits.findManyMeasuringUnits
            : []
        }
      />
    </Stack>
  );
};

export default PurchaseOrderTemplate;

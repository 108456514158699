import { gql } from '@apollo/client';
import { IPropertyListType } from './PropertyListType';

export const GET_CONSTRUCTION_SHEET_REMARKS_BY_CONSTRUCTION_SITE = gql`
  query GetConstructionSheetRemarksByConstructionSite(
    $filter: ConstructionSheetFilterInput
  ) {
    findManyConstructionSheetRemarks(filter: $filter) {
      id
      label
      remarks
      property_list_type {
        id
        name
      }
    }
  }
`;

export const UPDATE_CONSTRUCTION_SHEET_REMARKS = gql`
  mutation UpdateConstructionSHeetRemark(
    $id: Int!
    $data: ConstructionSheetRemarkUpdateInput!
  ) {
    updateConstructionSheetRemark(id: $id, data: $data) {
      id
      label
      remarks
    }
  }
`;

export interface IConstructionSheetRemark {
  id: number;
  label: string;
  remarks: string;
  property_list_type: IPropertyListType;
}

import React from 'react';
import { Stack } from '@fluentui/react';
import { Heading } from '../../components/typography';
import { stackStyles, stackTokens10, stackTokens15 } from '../../utils';
import TemplateAddExistingItem from './components/TemplateAddExistingItem';

const TemplatesManageItems = () => (
  <Stack tokens={stackTokens15}>
    <h3>Beheer items</h3>

    <Stack tokens={stackTokens10} styles={stackStyles}>
      <TemplateAddExistingItem forModal={false} />
    </Stack>
  </Stack>
);

export default TemplatesManageItems;

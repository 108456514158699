/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Stack, Link, Icon } from '@fluentui/react';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';

import { classes } from '../../utils';
import NavbarItemsList from './NavbarItemsList';
// import { NavbarItemslist } from '.';

interface INavigation {
  show: boolean;
  headerHeight: number;
  toggleNavigation: () => void;
}

const Navigation = ({ show, headerHeight, toggleNavigation }: INavigation) => (
  <div
    className={classes.navigation}
    style={{ display: show ? 'block' : 'none' }}
  >
    <div
      className={classes.dismiss}
      onClick={() => toggleNavigation()}
      style={{ pointerEvents: show ? 'auto' : 'none' }}
    >
      <Stack className={classes.menu}>
        <Stack>
          <Stack.Item
            className={`${classes.toggleButton} test`}
            align='stretch'
            styles={{ root: { minHeight: `${headerHeight}px` } }}
          >
            <Link
              className={classes.innerItem}
              onClick={toggleNavigation}
              style={{ display: 'block', fontSize: FontSizes.size18 }}
            >
              <Icon iconName='GlobalNavButton' className={classes.icon} />
            </Link>
          </Stack.Item>
          <NavbarItemsList />
        </Stack>
      </Stack>
    </div>
  </div>
);

export default Navigation;

import { gql } from '@apollo/client';

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($id: Int!, $data: DocumentUpdateInput) {
    updateDocument(id: $id, data: $data) {
      id
      name
      src
      extension
      blob_name
      is_external
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($where: DocumentWhereInput) {
    deleteDocument(where: $where) {
      id
      name
      src
      extension
      blob_name
      is_external
    }
  }
`;

export interface IDocument {
  blob_name?: string;
  extension?: string;
  id?: number;
  is_external?: boolean;
  name: string;
  src?: string;
  dirty?: boolean;
  draft?: boolean;
}

import { gql } from '@apollo/client';
import { IComboBoxOption, IDropdownOption } from '@fluentui/react';

export const GET_PROPERTY_LIST_TYPES = gql`
  query GetPropertyListTypes($orderBy: PropertyListTypeOrderBy) {
    findManyPropertyListTypes(orderBy: $orderBy) {
      id
      name
    }
  }
`;

export const convertPropertyListTypeToComboBoxOptions = (
  propertyListTypes: IPropertyListType[],
) => {
  const result: IComboBoxOption[] = propertyListTypes.map(
    (propertyListType: IPropertyListType): IComboBoxOption => ({
      key: propertyListType.id,
      text: propertyListType.name,
    }),
  );
  return result;
};

export const convertPropertyListTypeToDropdownOptions = (
  propertyListTypes: IPropertyListType[],
) => {
  const result: IDropdownOption[] = propertyListTypes.map(
    (propertyListType: IPropertyListType): IDropdownOption => ({
      key: propertyListType.id,
      text: propertyListType.name,
    }),
  );
  return result;
};

export const sortPropertyListTypesByName = (
  a: IPropertyListType,
  b: IPropertyListType,
) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export interface IPropertyListType {
  id: number;
  name: string;
}

import React from 'react';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';
import { FontWeights, mergeStyleSets } from '@fluentui/react';

export interface IComponentClassNames {
  root?: string;
}

const getClassNames = (): IComponentClassNames => mergeStyleSets({
  root: {
    selectors: {
      h1: {
        fontSize: FontSizes.size32,
        FontWeights: FontWeights.bold,
      },
      h2: {
        fontSize: FontSizes.size28,
        FontWeights: FontWeights.bold,
      },
      h3: {
        fontSize: FontSizes.size24,
        FontWeights: FontWeights.bold,
      },
      h4: {
        fontSize: FontSizes.size20,
        FontWeights: FontWeights.bold,
      },
      h5: {
        fontSize: FontSizes.size18,
        FontWeights: FontWeights.semibold,
      },
      h6: {
        fontSize: FontSizes.size16,
        FontWeights: FontWeights.semibold,
      },
      h7: {
        fontSize: FontSizes.size14,
        FontWeights: FontWeights.semibold,
      },
    },
  },
});

export interface Props {
  as?: any;
  style?: React.CSSProperties;
}

const Heading: React.FC<Props> = ({
  children, as, style, ...props
}) => {
  const { root } = getClassNames();

  return (
    <h1 className={root} style={style} {...props}>
      {children}
    </h1>
  );
};

export default Heading;

import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  Checkbox,
  IComboBoxOption,
  Dialog,
  DialogFooter,
} from '@fluentui/react';
import { useMutation } from '@apollo/client';
import {
  convertEmployeesToComboBoxOptions,
  modalContentStyles,
} from '../../../utils';
import DateView from '../../../components/parts/DateView';
import { ComboboxWithFilter } from '../../../components/parts';
import { DELETE_CAR_DRIVER } from '../../../utils/CarDriver';
import {
  dialogModelProps,
  dialogRemoveConfirmationPropsGeneral,
} from '../../../utils/Styles';

const CarDriverDetail = ({
  isOpen,
  dismissPanel,
  saveCarDriver,
  carDriver,
  setCarDriver,
  employees,
  refetchCar,
}: any) => {
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const [deleteDriver] = useMutation(DELETE_CAR_DRIVER);

  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
  };

  const deleteDriverFn = () => {
    deleteDriver({
      variables: {
        where: {
          id: carDriver.id,
        },
      },
    }).then(() => {
      toggleConfirmationDialog();
      refetchCar();
      dismissPanel();
    });
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        carDriver && carDriver.id
          ? 'Bestuurder wijzigen'
          : 'Bestuurder toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {carDriver && carDriver.id && <Label>ID: {carDriver.id}</Label>}

          <Stack style={{ marginBottom: 10 }}>
            <ComboboxWithFilter
              label='Bestuurder'
              options={convertEmployeesToComboBoxOptions(employees)}
              value={carDriver && carDriver.employee && carDriver.employee.id}
              multiline={false}
              disabled={
                carDriver && carDriver.employee && carDriver.employee.id
              }
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  const result = { ...carDriver };

                  result.employee = {
                    id: newValue[0].key as number,
                  };

                  setCarDriver(result);
                }
              }}
              required
              errorMessage={
                carDriver && !carDriver.employee ? 'Dit veld is verplicht' : ''
              }
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={carDriver}
              setItem={setCarDriver}
              date={carDriver && carDriver.start_date}
              label='Vanaf'
              field='start_date'
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={carDriver}
              setItem={setCarDriver}
              date={carDriver && carDriver.end_date}
              label='Tot'
              field='end_date'
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <Label>Huidige bestuurder</Label>
            <Checkbox
              name='current_driver'
              label='Huidige bestuurder?'
              checked={carDriver && carDriver.current_driver}
              onChange={() => {
                setCarDriver((prevState: any) => ({
                  ...prevState,
                  current_driver:
                    carDriver && carDriver.current_driver
                      ? !carDriver.current_driver
                      : true,
                }));
              }}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <StackItem>
                <PrimaryButton
                  onClick={saveCarDriver}
                  style={{ marginRight: 10 }}
                  disabled={!carDriver || (carDriver && !carDriver.employee)}
                >
                  Opslaan
                </PrimaryButton>
              </StackItem>
              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
            <Stack>
              <StackItem>
                <DefaultButton onClick={toggleConfirmationDialog}>
                  Verwijderen
                </DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationPropsGeneral}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteDriverFn} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default CarDriverDetail;

import { off } from 'process';
import { CommandBar, Stack } from '@fluentui/react';
import React, { useEffect, useState, useRef } from 'react';
import { commandBarTheme } from '../../theme';
import { useScrollPosition } from '../hooks/useScrollPosition';

const CommandBarSticky = ({
  items,
  farItems,
  arialabel = 'Use left and right arrow keys to navigate between commands',
  theme,
  // width,
  // maxWidth,
  top = 0,
}: any) => {
  const ref = useRef(null);

  const { viewport, rect, window } = useScrollPosition({
    wait: 500,
    element: ref,
  });

  const getItems = (position = 'default') => items;

  const [fixed, setFixed] = useState(false);

  const [wrapperHeight, setWrapperHeight] = useState(1);

  useEffect(() => {
    if (rect && rect.top <= 0) {
      setFixed(true);
    } else {
      if (rect) setWrapperHeight(rect.height);
      setFixed(false);
    }
  }, [rect]);

  return (
    <div ref={ref} style={{ minHeight: wrapperHeight }}>
      <Stack
        id="stickyCommandBarContainer"
        styles={
        fixed
          ? {
            root: {
              width: '100%',
              // maxWidth: addSuffix(stickyMaxWidth),
              position: 'fixed',
              minHeight: '5vh',
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              // transition: 'all .2s ease',
              top,
              zIndex: 1,
              left: 0,
              right: 0,
              'div:only-child': {
                // position: 'relative',
                width: '100%',
              },
              marginTop: '0px!important',
              // marginLeft: alignItems === 'left' ? '-15px' : '0px',
              backgroundColor: 'rgb(239, 239, 239)',
            },
          }
          : {}
      }
      >
        <CommandBar
          items={getItems()}
        // overflowItems={getItems('overflow')}
          farItems={farItems}
          ariaLabel={arialabel}
          theme={theme}
          overflowButtonProps={{
          theme: commandBarTheme,
        }}
          styles={{
          root: {
            maxWidth: '1280px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingLeft: '0px',
            paddingRight: '0px',
            'div:first-child': {
              marginLeft: '15px',
            },
            'div:last-child': {
              marginRight: '15px',
            },
            paddingTop: '5px',
            paddingBottom: '5px',
          },
        }}
        />
      </Stack>
    </div>

  );
};

export default CommandBarSticky;

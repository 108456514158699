import * as React from 'react';

type DivProps = React.HTMLProps<HTMLDivElement>;

export interface Props extends DivProps {
  style?: React.CSSProperties;
}

const Section: React.FC<Props> = ({ children, style, ...props }) => (
  <div
    style={{
      width: '100%',
      marginTop: '24px',
      marginBottom: '24px',
      ...style,
    }}
    {...props}
  >
    {children}
  </div>
);

export default Section;

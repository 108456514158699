import React, { useEffect, useState } from 'react';
import {
  DatePicker,
  IDatePickerStrings,
  mergeStyleSets,
  DayOfWeek,
} from '@fluentui/react';

export const DayPickerStrings: IDatePickerStrings = {
  months: [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ],

  shortMonths: [
    'Jan',
    'Feb',
    'Maa',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
  ],

  days: [
    'Zondag',
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
  ],

  shortDays: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],

  goToToday: 'Ga naar vandaag',
  prevMonthAriaLabel: 'Ga naar de vorige maand',
  nextMonthAriaLabel: 'Ga naar de volgende maand',
  prevYearAriaLabel: 'Ga naar het vorige jaar',
  nextYearAriaLabel: 'Ga naar het volgend jaar',
  closeButtonAriaLabel: 'Sluit deze control',
};

export const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 0 0',
  },
});

const DatePickerNL = ({ updateParent, value, isDisabled = false }: any) => {
  const [dateValue, setDateValue] = useState<Date>(); // value === null ? undefined : new Date(value));

  const onParseDateFromString = (val: string): Date | null => {
    const date = dateValue || new Date();
    const values = (val || '').trim().split('/');
    const day = val.length > 0
      ? Math.max(1, Math.min(31, parseInt(values[0], 10)))
      : date.getDate();
    const month = val.length > 1
      ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1
      : date.getMonth();

    let year = val.length > 2 ? parseInt(values[2], 10) : date.getFullYear();

    if (year < 100) {
      year += date.getFullYear() - (date.getFullYear() % 100);
    }

    return new Date(year, month, day);
  };

  const onFormatDate = (date?: Date): string => {
    if (date) {
      const month = date.getMonth() + 1;
      const day = date.getDate();

      return '{day}/{month}/{year}'
        .replace('{day}', (day < 10 ? `0${day}` : day).toString()) // Add leading zero's if necessary
        .replace('{month}', (month < 10 ? `0${month}` : month).toString()) // Add leading zero's if necessary
        .replace('{year}', (date.getFullYear() % 100).toString());
    }

    return 'Error';
  };

  const update = (date: Date) => {
    setDateValue(date);
    updateParent(date);
  };

  useEffect(() => {
    if (value) {
      setDateValue(new Date(value));
    } else {
      setDateValue(undefined);
    }
  }, [value]);

  return (
    <DatePicker
      className={controlClass.control}
      value={dateValue || undefined}
      firstDayOfWeek={DayOfWeek.Sunday}
      strings={DayPickerStrings}
      formatDate={onFormatDate}
      parseDateFromString={onParseDateFromString}
      placeholder='Kies een datum...'
      ariaLabel='Kies een datum'
      onSelectDate={(date: Date | null | undefined) => {
        update(date || new Date());
      }}
      disabled={isDisabled}
      style={{ minWidth: 100 }}
    />
  );
};

export default DatePickerNL;

import React from 'react';
import { IComboBoxOption } from '@fluentui/react';
import { ComboboxWithFilter } from '../../../components/parts';

const ConstructionSheetCombobox = ({
  selectedKey,
  props,
  callBack,
  property,
}: any) => {
  const comboBoxOptions: IComboBoxOption[] = props;

  return (
    <ComboboxWithFilter
      // eslint-disable-next-line react/destructuring-assignment
      label={props.label}
      value={selectedKey || 0}
      options={comboBoxOptions}
      multiline={false}
      callBack={(newValue: IComboBoxOption[]) => {
        if (newValue.length > 0) {
          const newValueKey = newValue[0].key;
          callBack(property, newValueKey);
        }
      }}
    />
  );
};

export default ConstructionSheetCombobox;

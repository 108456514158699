import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  TextField,
  Checkbox,
  IComboBoxOption,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from '@fluentui/react';
import {
  convertConstructionSitesToComboBoxOptions,
  convertEmployeesToComboBoxOptions,
  convertSuppliersToComboBoxOptions,
  IEmployee,
  modalContentStyles,
} from '../../../utils';
import { ComboboxWithFilter } from '../../../components/parts';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { IWorkOrder } from '../../../utils/WorkOrder';
import { convertCostCentersToComboBoxOptions } from '../../../utils/CostCenter';

const WorkOrderDetail = ({
  isOpen,
  dismissPanel,
  saveWorkOrder,
  workOrder,
  setWorkOrder,
  suppliers,
  employees,
  cost_centers,
  constructionSites,
  loadingConstructionSites = false,
  setSupplierFilter = (value: string) => {},
  setConstructionSiteFilter = (value: string) => {},
}: any) => {
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setWorkOrder((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        workOrder && workOrder.id ? 'Werkbon wijzigen' : 'Werkbon toevoegen'
      }
      type={PanelType.custom}
      customWidth='600px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {workOrder && <Label>ID: {workOrder.id}</Label>}

          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <Label>Document no: {workOrder.document_no}</Label>
              {/* <TextField
                name='document_no'
                label='Document no'
                value={
                  workOrder && workOrder.document_no
                    ? workOrder.document_no
                    : ''
                }
                onChange={onChangeTextFieldValue}
                required
              /> */}
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='description'
                label='Omschrijving'
                multiline
                value={
                  workOrder && workOrder.description
                    ? workOrder.description
                    : ''
                }
                onChange={onChangeTextFieldValue}
                required
              />
            </Stack>

            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <ComboboxWithFilter
                label='Uitvoerder (leverancier)'
                options={convertSuppliersToComboBoxOptions(suppliers)}
                value={
                  workOrder && workOrder.supplier ? workOrder.supplier.id : ''
                }
                allowFreeForm
                multiline={false}
                callBack={(newValue: IComboBoxOption[]) => {
                  if (newValue && newValue.length > 0) {
                    const result = { ...workOrder };

                    result.supplier = {
                      id: newValue[0].key as number,
                    };

                    setWorkOrder(result);
                  }
                }}
                setFilter={(value: string) => {
                  setSupplierFilter(value);
                }}
              />
            </Stack>

            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <ComboboxWithFilter
                label='Uitvoerder (medewerker)'
                options={convertEmployeesToComboBoxOptions(
                  employees.filter(
                    (e: IEmployee) =>
                      e.active === true ||
                      (workOrder.employee && e.id === workOrder.employee.id),
                  ),
                )}
                value={
                  workOrder && workOrder.employee ? workOrder.employee.id : ''
                }
                multiline={false}
                callBack={(newValue: IComboBoxOption[]) => {
                  if (newValue && newValue.length > 0) {
                    const result = { ...workOrder };

                    result.employee = {
                      id: newValue[0].key as number,
                    };

                    setWorkOrder(result);
                  }
                }}
              />
            </Stack>

            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <ComboboxWithFilter
                label='Werf'
                options={convertConstructionSitesToComboBoxOptions(
                  constructionSites,
                )}
                value={
                  workOrder && workOrder.construction_site
                    ? workOrder.construction_site.id
                    : ''
                }
                allowFreeForm
                multiline={false}
                callBack={(newValue: IComboBoxOption[]) => {
                  if (newValue && newValue.length > 0) {
                    const result = { ...workOrder };

                    result.construction_site = {
                      id: newValue[0].key as number,
                    };

                    setWorkOrder(result);
                  }
                }}
                setFilter={(value: string) => {
                  setConstructionSiteFilter(value);
                }}
                loading={loadingConstructionSites}
              />
            </Stack>

            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <ComboboxWithFilter
                label='Status'
                options={[
                  { key: 'NEW', text: 'Nieuw' },
                  { key: 'COMPLETED', text: 'Afgesloten' },
                ]}
                value={workOrder && workOrder.status ? workOrder.status : ''}
                multiline={false}
                callBack={(newValue: IComboBoxOption[]) => {
                  if (newValue && newValue.length > 0) {
                    const result = { ...workOrder };

                    result.status = newValue[0].key;

                    setWorkOrder(result);
                  }
                }}
              />
            </Stack>
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <ComboboxWithFilter
              label='Kostenplaats'
              options={convertCostCentersToComboBoxOptions(cost_centers || [])}
              value={
                workOrder && workOrder.cost_center
                  ? workOrder.cost_center.id
                  : ''
              }
              // multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  const result = { ...workOrder };

                  result.cost_center = { id: newValue[0].key };

                  setWorkOrder(result);
                }
              }}
            />
          </Stack>
          <Stack
            style={{
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            <Checkbox
              name='guarantee'
              label='Garantie'
              checked={workOrder && workOrder.guarantee}
              onChange={(e, checked) => {
                setWorkOrder((prevState: IWorkOrder) => ({
                  ...prevState,
                  guarantee: checked,
                }));
              }}
            />
          </Stack>

          {workOrder &&
            workOrder.id &&
            workOrder.defect_work_order_line_items &&
            workOrder.defect_work_order_line_items.length > 0 && (
              <Stack style={{ marginBottom: 10, marginTop: 30 }}>
                <Accordion
                  selectedKey={selectedValueItem}
                  defaultKey={undefined}
                  toggleItem={(key: string | number) => {
                    if (selectedValueItem === key) {
                      setSelectedValueItem(undefined);
                    } else setSelectedValueItem(key);
                  }}
                >
                  <AccordionItem
                    key='accordion-work-order-line-items'
                    id='accordion-work-order-line-items'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Detail
                        </h3>
                      </Stack>
                    }
                  >
                    <ShimmeredDetailsList
                      items={workOrder.defect_work_order_line_items || []}
                      columns={[
                        {
                          key: 'column1',
                          name: 'Beschrijving',
                          fieldName: 'description',
                          minWidth: 200,
                          maxWidth: 200,
                          isRowHeader: true,
                          onRender: (line_item: any) => (
                            <span>{line_item.description}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        // {
                        //   key: 'column2',
                        //   name: 'Extra informatie',
                        //   fieldName: 'comments',
                        //   minWidth: 100,
                        //   maxWidth: 100,
                        //   isRowHeader: true,
                        //   onRender: (line_item: any) => (
                        //     <span>{line_item.comments}</span>
                        //   ),
                        //   data: 'string',
                        //   isPadded: true,
                        // },
                      ]}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selectionMode={SelectionMode.none}
                    />
                  </AccordionItem>
                </Accordion>
              </Stack>
            )}
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                onClick={saveWorkOrder}
                style={{ marginRight: 10 }}
                disabled={
                  !workOrder ||
                  (workOrder &&
                    !workOrder.description &&
                    !workOrder.construction_site)
                }
              >
                Opslaan
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default WorkOrderDetail;

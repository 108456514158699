import React, { FormEvent, useCallback, useRef } from 'react';
import {
  Stack,
  TextField,
  Text,
  ComboBox,
  IComboBox,
  IComboBoxOption,
} from '@fluentui/react';
import _ from 'lodash';
import { getTheme } from '@fluentui/react/lib/Styling';
import {
  convertPropertyTypeToComboBoxOptions,
  property_types,
} from '../../../utils';

import { IProperty } from '../../../utils/Property';
import { IPropertyType } from '../../../utils/PropertyType';
import { ISuggestedValueCategory } from '../../../utils/SuggestedValueCategory';
import DocumentView from '../../implementation-list/components/DocumentView';
import SuggestedValueView from '../../implementation-list/components/SuggestedValueView';
import TextView from '../../implementation-list/components/TextView';
import DateView from '../../implementation-list/components/DateView';
import TemplateSuggestedValueItemMultiValue from './TemplateSuggestedValueItemMultiValue';

const theme = getTheme();

const fieldStyle: React.CSSProperties = {
  background: theme.palette.neutralLighterAlt,
  padding: '10px',
  marginBottom: '10px',
};

interface ITemplateMultiValueValue {
  updateItem: (item: IProperty) => void;
  type: number;
  item: IProperty;
  types: IPropertyType[];
  suggestedValuecategories?: ISuggestedValueCategory[];
}

const TemplateMultiValueValue: React.FC<ITemplateMultiValueValue> = (props) => {
  const {
    updateItem,
    item,
    types = [],
    suggestedValuecategories = [],
  } = props;

  const comboboxPropertyType = useRef<IComboBox>(null);

  const updateName = (newValue?: string) => {
    const currentItem = _.cloneDeep(item);

    if (currentItem) {
      currentItem.name = newValue || '';
    }

    currentItem.isDirty = true;

    updateItem(currentItem);
  };

  const updateCurrentItem = useCallback(
    (newItem: IProperty) => {
      const currentItem = _.cloneDeep(newItem);
      currentItem.isDirty = true;
      updateItem(currentItem);
    },
    [item],
  );

  const updateChildItem = useCallback(
    (index: number, newItem: IProperty) => {
      const currentItem = _.cloneDeep(item);
      const children = currentItem.children;
      if (children && children.length && children[index]) {
        children[index] = newItem;
        children[index].isDirty = true;
        currentItem.children = children;
        currentItem.isDirty = true;
        updateItem(currentItem);
      }
    },
    [item],
  );

  const renderInputelement = useCallback(
    (
      type: IPropertyType | undefined,
      index: number,
      subItem: IProperty,
      child?: boolean,
    ) => {
      if (!type) return;
      switch (type.id) {
        case 7: // document
          return (
            <Stack.Item style={child ? fieldStyle : undefined}>
              <Text
                style={{
                  marginBottom: '10px',
                  fontWeight: 'bold',
                  display: !child ? 'none' : 'block',
                }}
              >
                {subItem.name}
              </Text>
              <DocumentView
                property={subItem}
                callBack={(newItem) => {
                  if (child) {
                    updateChildItem(index, newItem);
                  } else {
                    updateCurrentItem(newItem);
                  }
                }}
                edit
                compact
              />
            </Stack.Item>
          );
        case 9: // string
          return (
            <Stack.Item style={child ? fieldStyle : undefined}>
              <Text
                style={{
                  marginBottom: '10px',
                  fontWeight: 'bold',
                  display: !child ? 'none' : 'block',
                }}
              >
                {subItem.name}
              </Text>
              <TextView
                property={subItem}
                callBack={(newItem) => {
                  if (child) {
                    updateChildItem(index, newItem);
                  } else {
                    updateCurrentItem(newItem);
                  }
                }}
                compact
                edit
              />
            </Stack.Item>
          );
        case 10: // suggestedValue
          return (
            <Stack.Item style={child ? fieldStyle : undefined}>
              <Text
                style={{
                  marginBottom: '10px',
                  fontWeight: 'bold',
                  display: !child ? 'none' : 'block',
                }}
              >
                {subItem.name}
              </Text>
              {subItem.remote_category ? (
                <SuggestedValueView
                  property={subItem}
                  callBack={(newItem) => {
                    if (child) {
                      updateChildItem(index, newItem);
                    } else {
                      updateCurrentItem(newItem);
                    }
                  }}
                />
              ) : (
                'Selecteer een categorie'
              )}

            </Stack.Item>
          );
        case 12: // date
          return (
            <Stack.Item style={child ? fieldStyle : undefined}>
              <Text
                style={{
                  marginBottom: '10px',
                  fontWeight: 'bold',
                  display: !child ? 'none' : 'block',
                }}
              >
                {subItem.name}
              </Text>
              <DateView
                property={subItem}
                callBack={(newItem) => {
                  if (child) {
                    updateChildItem(index, newItem);
                  } else {
                    updateCurrentItem(newItem);
                  }
                }}
                compact
              />
            </Stack.Item>
          );
        case 13: // note
          return (
            <Stack.Item style={child ? fieldStyle : undefined}>
              <Text
                style={{
                  marginBottom: '10px',
                  fontWeight: 'bold',
                  display: !child ? 'none' : 'block',
                }}
              >
                {subItem.name}
              </Text>

              <TextView
                property={subItem}
                callBack={(newItem) => {
                  if (child) {
                    updateChildItem(index, newItem);
                  } else {
                    updateCurrentItem(newItem);
                  }
                }}
                compact
                edit
              />
            </Stack.Item>
          );
        default:
          return 'type niet gedefinieërd.';
      }
    },
    [updateChildItem, updateCurrentItem],
  );

  const changeSubType = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption,
    //   index?: number,
    //   value?: string,
  ) => {
    if (option) {
      const newItem = _.cloneDeep(item);
      // setSelectedKeyPropertyType(+option.key);
      const selectedType = types.filter((t) => {
        if (t.id === +option.key) return true;
      })[0];

      newItem.type = selectedType;

      if (selectedType) {
        updateCurrentItem(newItem);
      }
    }
  };
  return (
    <Stack>
      {item.type && item.type.id !== 8 && (
        <ComboBox
          componentRef={comboboxPropertyType}
          selectedKey={item.type ? item.type.id : undefined}
          label='Subtype'
          allowFreeform
          autoComplete='on'
          onChange={changeSubType}
          options={convertPropertyTypeToComboBoxOptions(
            types.filter((item: IPropertyType) => {
              if (
                item.name === 'parent'
                || item.name === 'free-multivalue'
                || item.name === 'multi-value'
              ) {
                return false;
              }
              return true;
            }),
          )}
          required
        />
      )}
      {item.type && item.type.id === 8 && (
        <Stack.Item>
          <strong>Type: </strong>Container Item
        </Stack.Item>
      )}

      <TextField
        label='Naam'
        placeholder='Voer een naam in'
        value={item.name}
        onChange={(
          event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ) => {
          updateName(newValue);
          // setShowMaxLengthName(false);
        }}
        required
      />
      {item.type && item.type.name === property_types.SUGGESTED_VALUE && (
        <Stack.Item>
          <TemplateSuggestedValueItemMultiValue
            item={item}
            callBack={(item) => updateItem(item)}
            categories={suggestedValuecategories}
          />
        </Stack.Item>
      )}
      {item.type && item.type.id !== 8 && (
        <Stack style={{ marginTop: '15px' }}>
          {renderInputelement(item.type, -1, item, false)}
        </Stack>
      )}

      {item.type && item.type.id === 8 && (
        <Stack style={{ marginTop: '20px' }}>
          {item.children
            && item.children.map((child, index) => (
              <Stack.Item>
                {renderInputelement(child.type, index, child, true)}
              </Stack.Item>
            ))}
        </Stack>
      )}
    </Stack>
  );
};

export default TemplateMultiValueValue;

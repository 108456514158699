import { gql } from '@apollo/client';
import { ILead } from './Lead';
import { IConstructionSite } from './ConstructionSite';

export const ADD_CONSTRUCTION_SITE_INVOICE_CUSTOMER = gql`
  mutation CreateConstructionSiteInvoiceCustomer($data: InvoiceCustomerCreateInput!) {
    createConstructionSiteInvoiceCustomer(data: $data) {
        id
        percentage
        construction_site {
            id
            name
            address
        }
        lead {
            address1
            city1
            email1
            email2
            first_name1
            first_name2
            id
            last_name1
            last_name2
            mobile1
            mobile2
            phone1
            phone2
            zip_code1
        }
    }
  }
`;

export const UPDATE_CONSTRUCTION_SITE_INVOICE_CUSTOMER = gql`
  mutation UpdateConstructionSiteInvoiceCustomer(
    $id: Int!
    $data: InvoiceCustomerUpdateInput!
  ) {
    updateConstructionSiteInvoiceCustomer(id: $id, data: $data) {
        id
        percentage
        construction_site {
            id
            name
            address
        }
        lead {
            id
            address1
            city1
            email1
            email2
            first_name1
            first_name2
            id
            last_name1
            last_name2
            mobile1
            mobile2
            phone1
            phone2
            zip_code1
        }
    }
  }
`;

export const DELETE_CONSTRUCTION_SITE_INVOICE_CUSTOMER = gql`
  mutation DeleteConstructionSiteInvoiceCustomer($where: InvoiceCustomerWhereInput) {
    deleteConstructionSiteInvoiceCustomer(where: $where) {
      id
    }
  }
`;

export const GET_CONSTRUCTION_SITE_INVOICE_CUSTOMERS = gql`
  query getConstructionSiteInvoiceCustomers(
    $filter: InvoiceCustomerFilterInput
    $take: Int
    $skip: Int
    $orderBy: InvoiceCustomerOrderByInput
  ) {
    findManyConstructionSiteInvoiceCustomers(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      customer {
        first_name1
        last_name1
        title1
        title2
        first_name2
        last_name2
      }
      description
      payment_date
      customer_id
      percentage
      customer_invoice_legacy {
        id
        invoice_date
        invoice_no
        invoice_paid
      }
      construction_site {
        id
        name
      }
    }
  }
`;

export interface IInvoiceCustomer {
  id?: number;
  construction_site_id?: number;
  construction_site?: IConstructionSite;
  lead_id?: number;
  lead?: ILead;
  percentage?: number;
}

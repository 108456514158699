import { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { eachSeries } from 'async';
import { modalContentStyles } from '../../../utils';
import { ILead, displayNameLead } from '../../../utils/Lead';
import {
  GET_CUSTOMER_CONTRACT_UPDATES,
  ICustomerContractUpdate,
} from '../../../utils/CustomerContractUpdate';
import { ICustomerPaymentTransaction } from '../../../utils/CustomerPaymentTransaction';
import {
  ICustomerInvoiceLineItem,
  ICustomerInvoiceLineItemCreateWithoutCustomerInvoice,
} from '../../../utils/CustomerInvoiceLineItem';
import {
  ADD_CUSTOMER_INVOICE,
  ICustomerInvoice,
} from '../../../utils/CustomerInvoice';
import { IInvoiceCustomer } from '../../../utils/ConstructionSiteInvoiceCustomer';

const PaymentTransactionInvoiceDetail = ({
  isOpen,
  dismissPanel,
  paymentTransaction,
  setPaymentTransaction,
}: {
  isOpen?: boolean;
  dismissPanel?: any;
  paymentTransaction: ICustomerPaymentTransaction;
  setPaymentTransaction: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [customerPercentage, setCustomerPercentage] = useState(100);
  const [createdInvoices, setCreatedInvoices] = useState<
    ICustomerInvoice[] | undefined
  >();
  const [lineItems, setLineItems] = useState<ICustomerInvoiceLineItem[]>([
    {
      description: `[Betalingsschijf] ${paymentTransaction.description}`,
      percentage_vat: 0.21,
      units: 1,
      unit_price:
        paymentTransaction &&
        paymentTransaction.construction_site &&
        paymentTransaction.construction_site.contract_amount &&
        paymentTransaction.construction_site.contract_amount *
          ((paymentTransaction.percentage || 100) / 100),
    },
  ]);

  useEffect(() => {
    if (
      paymentTransaction &&
      paymentTransaction.construction_site &&
      paymentTransaction.percentage &&
      !total
    ) {
      setTotal(
        paymentTransaction.construction_site.contract_amount
          ? paymentTransaction.construction_site.contract_amount *
              (paymentTransaction.percentage / 100)
          : 0,
      );
    }

    if (
      paymentTransaction &&
      paymentTransaction.construction_site &&
      paymentTransaction.construction_site.invoice_customers &&
      paymentTransaction.construction_site.invoice_customers.length > 0
    ) {
      const sum = paymentTransaction.construction_site.invoice_customers.reduce(
        (acc: any, val: any) => acc + val.percentage,
        0,
      );

      setCustomerPercentage(100 - sum);
    }
  }, [paymentTransaction]);

  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  const { data: customerContractUpdatesData } = useQuery(
    GET_CUSTOMER_CONTRACT_UPDATES,
    {
      variables:
        paymentTransaction &&
        paymentTransaction.construction_site &&
        paymentTransaction.construction_site.id
          ? {
              filter: {
                construction_site: {
                  id: { equals: paymentTransaction.construction_site.id },
                },
              },
            }
          : undefined,
      skip:
        !paymentTransaction ||
        !paymentTransaction.id ||
        !paymentTransaction.construction_site ||
        !paymentTransaction.construction_site.id,
      onCompleted: data => {
        console.log(data);
        // setCustomerInvoice(data.findOneCustomerInvoice);
      },
    },
  );

  const addCustomerContractUpdate = (item: ICustomerContractUpdate) => {
    setPaymentTransaction((prevState: ICustomerPaymentTransaction) => {
      if (prevState.construction_site) {
        const currentPaymentTransaction = _.cloneDeep(prevState);
        const currentUpdates = _.cloneDeep(
          prevState.construction_site.customer_contract_updates,
        );
        if (currentUpdates && currentUpdates.length > 0) {
          let exists = false;
          for (let i = 0; i < currentUpdates.length; i++) {
            if (currentUpdates[i].id === item.id) {
              exists = true;
              currentUpdates[i].connect = true;
              currentUpdates[i].disconnect = false;

              currentPaymentTransaction.construction_site!.customer_contract_updates =
                currentUpdates;
            }
          }
          if (!exists) {
            currentPaymentTransaction.construction_site!.customer_contract_updates =
              [
                ...currentUpdates,
                {
                  ...item,
                  connect: true,
                  disconnect: false,
                },
              ];
          }
        } else {
          currentPaymentTransaction.construction_site!.customer_contract_updates =
            [
              {
                ...item,
                connect: true,
                disconnect: false,
              },
            ];
        }

        if (!lineItems || lineItems.length < 0) {
          setLineItems([]);
        }

        const price =
          item && item.customer_contract_update_line_items
            ? item.customer_contract_update_line_items.reduce(
                (a, b) => a + (b.unit_price || 0) * (b.units || 1),
                0,
              )
            : 0;

        const percentage_vat =
          item &&
          item.customer_contract_update_line_items &&
          item &&
          item.customer_contract_update_line_items[0] &&
          item &&
          item.customer_contract_update_line_items[0].percentage_vat;

        setLineItems(current => [
          ...current,
          {
            unit_price: price,
            percentage_vat,
            unit: 1,
            description: `[Meerwerk] ${item.document_no}`,
            id: moment().unix(),
          },
        ]);

        setTotal(prevState => prevState + price);

        return currentPaymentTransaction;
      }
    });
  };

  const removeCustomerContractUpdate = (item: ICustomerContractUpdate) => {
    setPaymentTransaction((prevState: ICustomerPaymentTransaction) => {
      if (prevState.construction_site) {
        const currentPaymentTransaction = _.cloneDeep(prevState);
        const currentUpdates = _.cloneDeep(
          prevState.construction_site.customer_contract_updates,
        );

        if (currentUpdates && currentUpdates.length > 0) {
          for (let i = 0; i < currentUpdates.length; i++) {
            if (currentUpdates[i].id === item.id) {
              currentUpdates[i].connect = false;
              currentUpdates[i].disconnect = true;

              currentPaymentTransaction.construction_site!.customer_contract_updates =
                currentUpdates;
            }
          }
        }

        const currentIndex = lineItems.findIndex(
          invoiceLineItem =>
            item.document_no &&
            invoiceLineItem.description?.includes(item.document_no),
        );

        if (currentIndex !== -1) {
          lineItems.splice(currentIndex, 1);
        }

        const price =
          item && item.customer_contract_update_line_items
            ? item.customer_contract_update_line_items.reduce(
                (a, b) => a + (b.unit_price || 0) * (b.units || 1),
                0,
              )
            : 0;

        setTotal(prevState => prevState - price);

        return currentPaymentTransaction;
      }
    });
  };

  const [addCustomerInvoice] = useMutation(ADD_CUSTOMER_INVOICE);

  const createPaymentTransactionInvoices = () =>
    new Promise((resolve, reject) => {
      (async () => {
        setLoading(true);
        if (paymentTransaction && paymentTransaction.construction_site) {
          const customers: IInvoiceCustomer[] = paymentTransaction
            .construction_site.invoice_customers
            ? _.cloneDeep(
                paymentTransaction.construction_site.invoice_customers,
              )
            : [];
          const percentage = customers.reduce(
            (previousValue, currentValue) =>
              previousValue + (currentValue.percentage || 0),
            0,
          );
          if (
            paymentTransaction &&
            paymentTransaction.construction_site.lead &&
            percentage < 100
          ) {
            customers.push({
              lead: paymentTransaction.construction_site.lead,
              percentage: 100 - percentage,
            });
          }

          await eachSeries(customers, (customer, cb) => {
            if (customer.lead && customer.percentage) {
              savePaymentTransactionInvoice(
                customer.lead,
                customer.percentage,
                customers && customers.length > 1,
              ).then(data => {
                if (data && data.createCustomerInvoice) {
                  setCreatedInvoices(prevState => [
                    ...(prevState || []),
                    data.createCustomerInvoice,
                  ]);
                }
                cb();
              });
            } else {
              cb();
            }
          }).catch(err => {
            reject(err);
            console.error(err);
          });
        }
        resolve(true);
        setLoading(false);
      })();
    });

  const savePaymentTransactionInvoice = (
    lead: ILead,
    percentage: number,
    multipleCustomers: boolean,
  ): Promise<{ createCustomerInvoice: ICustomerInvoice }> =>
    new Promise((resolve, reject) => {
      (async () => {
        if (paymentTransaction) {
          const connectCustomerInvoiceUpdates: { id?: number }[] = [];
          const disconnectCustomerInvoiceUpdates: { id?: number }[] = [];
          const createInvoiceLineItems: ICustomerInvoiceLineItemCreateWithoutCustomerInvoice[] =
            [];

          if (
            paymentTransaction &&
            paymentTransaction.construction_site &&
            paymentTransaction.construction_site.customer_contract_updates
          ) {
            for (
              let i = 0;
              i <
              paymentTransaction.construction_site.customer_contract_updates
                .length;
              i++
            ) {
              if (
                paymentTransaction.construction_site.customer_contract_updates[
                  i
                ].connect
              ) {
                connectCustomerInvoiceUpdates.push({
                  id: paymentTransaction.construction_site
                    .customer_contract_updates[i].id,
                });
              } else if (
                paymentTransaction.construction_site.customer_contract_updates[
                  i
                ].disconnect
              ) {
                disconnectCustomerInvoiceUpdates.push({
                  id: paymentTransaction.construction_site
                    .customer_contract_updates[i].id,
                });
              }
            }
          }

          if (lineItems) {
            for (let i = 0; i < lineItems.length; i++) {
              const item = lineItems[i];
              const description = multipleCustomers
                ? `${item.description} volgens eigendomspercentage (${percentage}%)`
                : item.description;
              if (item) {
                // add new line item
                createInvoiceLineItems.push({
                  comments: item.comments,
                  description: description || '',
                  percentage_vat: item.percentage_vat && +item.percentage_vat,
                  show_only_total: item.show_only_total,
                  units: 1,
                  unit_price:
                    item.unit_price && +item.unit_price * (percentage / 100),
                  // connect customer_contract_update_line_item for later reference & deletion
                  customer_contract_update_line_item:
                    item.customer_contract_update_line_item &&
                    item.customer_contract_update_line_item.id
                      ? {
                          connect: {
                            id: item.customer_contract_update_line_item.id!,
                          },
                        }
                      : undefined,
                });
              }
            }
          }

          const allInput: any = {
            customer_payment_transaction: {
              connect: { id: paymentTransaction.id },
            },
            construction_site:
              paymentTransaction.construction_site &&
              paymentTransaction.construction_site.id
                ? {
                    connect: {
                      id: paymentTransaction.construction_site.id,
                    },
                  }
                : undefined,
            lead:
              lead && lead.id
                ? {
                    connect: { id: lead.id },
                  }
                : undefined,
            customer_invoice_line_items: {
              create: createInvoiceLineItems,
            },
            customer_contract_updates: {
              connect: connectCustomerInvoiceUpdates,
            },
          };
          console.log(allInput);

          addCustomerInvoice({
            variables: {
              data: allInput,
            },
          })
            .then((x: any) => {
              resolve(x.data);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          reject(Error('no input data'));
        }
      })();
    });

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText='Factuur aanmaken'
      type={PanelType.custom}
      customWidth='800px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {paymentTransaction && (
            <>
              <Stack>
                <h3 style={{ marginTop: 0, marginBottom: 5 }}>Werf</h3>
                <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Label style={{ marginRight: 5 }}>Naam:</Label>
                  {paymentTransaction.construction_site &&
                    paymentTransaction.construction_site.name}
                </Stack>
                <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Label style={{ marginRight: 5 }}>Adres:</Label>
                  {paymentTransaction.construction_site &&
                    paymentTransaction.construction_site.address}
                </Stack>
              </Stack>
              <Stack>
                <h3 style={{ marginTop: 20, marginBottom: 5 }}>Klant</h3>
                <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Label style={{ marginRight: 5 }}>Naam:</Label>
                  {paymentTransaction.construction_site &&
                    paymentTransaction.construction_site.lead &&
                    displayNameLead(paymentTransaction.construction_site.lead)}
                </Stack>
                <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Label style={{ marginRight: 5 }}>Adres:</Label>
                  {paymentTransaction.construction_site &&
                    paymentTransaction.construction_site.lead &&
                    `${paymentTransaction.construction_site.lead.address1}, ${paymentTransaction.construction_site.lead.zip_code1} ${paymentTransaction.construction_site.lead.city1}`}
                </Stack>
              </Stack>

              {paymentTransaction.construction_site &&
                paymentTransaction.construction_site.invoice_customers &&
                paymentTransaction.construction_site.invoice_customers.length >
                  0 &&
                !createdInvoices && (
                  <Stack>
                    <h3 style={{ marginTop: 20, marginBottom: 5 }}>
                      Extra facturatieklant
                      {paymentTransaction.construction_site.invoice_customers
                        .length > 2 && 'en'}
                    </h3>
                    <ShimmeredDetailsList
                      items={
                        paymentTransaction.construction_site
                          .invoice_customers || []
                      }
                      columns={[
                        {
                          key: 'column1',
                          name: 'Naam',
                          fieldName: 'name',
                          minWidth: 350,
                          maxWidth: 350,
                          isRowHeader: true,
                          onRender: (customer: any) => (
                            <span>
                              {customer.lead && displayNameLead(customer.lead)}
                            </span>
                          ),
                          data: 'string',
                        },
                        {
                          key: 'column2',
                          name: 'Adres',
                          fieldName: 'address',
                          minWidth: 250,
                          maxWidth: 250,
                          isRowHeader: true,
                          onRender: (customer: any) => (
                            <span>
                              {customer.lead && (
                                <div>
                                  {customer.lead.address1},
                                  <br />
                                  {customer.lead.zip_code1}{' '}
                                  {customer.lead.city1}
                                </div>
                              )}
                            </span>
                          ),
                          data: 'string',
                        },
                        {
                          key: 'column3',
                          name: 'Percentage',
                          fieldName: 'percentage',
                          minWidth: 65,
                          maxWidth: 65,
                          isRowHeader: true,
                          onRender: (customer: any) => (
                            <span>{customer.percentage}%</span>
                          ),
                          data: 'string',
                        },
                      ]}
                      getKey={getKey}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selectionMode={SelectionMode.none}
                    />
                  </Stack>
                )}
              {!createdInvoices && (
                <Stack style={{ marginTop: 20 }}>
                  <h3 style={{ marginBottom: 5 }}>Beschikbare meerwerken</h3>
                  <ShimmeredDetailsList
                    items={
                      customerContractUpdatesData &&
                      customerContractUpdatesData.findManyCustomerContractUpdates
                        ? customerContractUpdatesData.findManyCustomerContractUpdates.filter(
                            (item: ICustomerContractUpdate) => {
                              if (
                                item.customer_invoice ||
                                item.customer_invoice_id
                              ) {
                                return false;
                              }
                              if (
                                paymentTransaction &&
                                paymentTransaction.customer_contract_updates
                              ) {
                                for (
                                  let i = 0;
                                  i <
                                  paymentTransaction.customer_contract_updates
                                    .length;
                                  i++
                                ) {
                                  if (
                                    paymentTransaction
                                      .customer_contract_updates[i].id ===
                                    item.id
                                  ) {
                                    if (
                                      paymentTransaction
                                        .customer_contract_updates[i].disconnect
                                    ) {
                                      return true;
                                    }
                                    return false;
                                  }
                                }
                              }

                              return true;
                            },
                          )
                        : []
                    }
                    columns={[
                      {
                        key: 'column1',
                        name: 'Bon nr',
                        fieldName: 'document_no',
                        minWidth: 75,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (item: ICustomerContractUpdate) => (
                          <span>{item.document_no}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Datum',
                        fieldName: 'document_date',
                        minWidth: 100,
                        maxWidth: 200,
                        isRowHeader: true,
                        onRender: (item: ICustomerContractUpdate) => (
                          <span>
                            {item.document_date
                              ? moment(item.document_date).format('DD/MM/YYYY')
                              : ''}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Omschrijving',
                        fieldName: 'summary',
                        minWidth: 100,
                        // maxWidth: 100,
                        isRowHeader: true,
                        onRender: (item: ICustomerContractUpdate) => (
                          <span>{item.summary}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: '',
                        fieldName: 'addToInvoice',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (item: ICustomerContractUpdate) => {
                          if (
                            lineItems.some(
                              lineItem =>
                                lineItem.description?.includes(
                                  `[Meerwerk] ${item.document_no}`,
                                ) && !lineItem.delete,
                              // eslint-disable-next-line function-paren-newline
                            )
                          ) {
                            return (
                              <DefaultButton
                                type='button'
                                onClick={e => {
                                  e.preventDefault();

                                  removeCustomerContractUpdate(item);
                                }}
                              >
                                Verwijder
                              </DefaultButton>
                            );
                          }
                          return (
                            <DefaultButton
                              type='button'
                              onClick={e => {
                                e.preventDefault();

                                addCustomerContractUpdate(item);
                              }}
                            >
                              Voeg toe
                            </DefaultButton>
                          );
                        },
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selectionMode={SelectionMode.none}
                  />
                </Stack>
              )}
              {!createdInvoices && (
                <Stack>
                  <h3 style={{ marginTop: 20, marginBottom: 0 }}>
                    Totaal: {`€ ${total.toFixed(2)}`}
                  </h3>
                </Stack>
              )}
              {!createdInvoices && (
                <Stack style={{ marginTop: 0 }}>
                  {paymentTransaction.construction_site &&
                    paymentTransaction.construction_site.invoice_customers && (
                      // eslint-disable-next-line react/jsx-indent
                      <Stack>
                        <h3 style={{ marginTop: 20, marginBottom: 10 }}>
                          Opsplitsing factuur
                        </h3>
                        <Stack
                          style={{
                            paddingBottom: 20,
                          }}
                        >
                          <Stack
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <h4 style={{ margin: 0 }}>
                              Factuur 1 (
                              {displayNameLead(
                                paymentTransaction.construction_site.lead,
                              )}
                              )
                            </h4>
                            <h4 style={{ margin: 0 }}>
                              Totaal: &euro;{' '}
                              {(total * (customerPercentage / 100)).toFixed(2)}
                            </h4>
                          </Stack>

                          <Stack
                            style={{
                              paddingLeft: 50,
                              paddingTop: 5,
                            }}
                          >
                            {lineItems.map(item => (
                              <Stack
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: 4,
                                }}
                              >
                                <StackItem style={{}}>
                                  {item.description}{' '}
                                  {paymentTransaction.construction_site &&
                                    paymentTransaction.construction_site
                                      .invoice_customers &&
                                    paymentTransaction.construction_site
                                      .invoice_customers.length > 0 &&
                                    ` volgens eigendomspercentage (${customerPercentage}%)`}
                                </StackItem>
                                <StackItem>
                                  &euro;{' '}
                                  {item.unit_price &&
                                    (
                                      item.unit_price *
                                      (item.units || 1) *
                                      ((customerPercentage || 100) / 100 || 1)
                                    ).toFixed(2)}
                                </StackItem>
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                        {paymentTransaction.construction_site.invoice_customers.map(
                          (customer, i) => {
                            if (
                              customer &&
                              customer.lead &&
                              customer.percentage
                            ) {
                              return (
                                // eslint-disable-next-line react/jsx-indent
                                <Stack
                                  style={{
                                    paddingBottom: 20,
                                  }}
                                >
                                  <Stack
                                    style={{
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <h4 style={{ margin: 0 }}>
                                      Factuur {i ? i + 2 : 2} (
                                      {customer.lead &&
                                        displayNameLead(customer.lead)}
                                      )
                                    </h4>
                                    <h4 style={{ margin: 0 }}>
                                      Totaal: &euro;{' '}
                                      {(
                                        total *
                                        (customer.percentage / 100)
                                      ).toFixed(2)}
                                    </h4>
                                  </Stack>

                                  <Stack
                                    style={{
                                      paddingLeft: 50,
                                      paddingTop: 5,
                                    }}
                                  >
                                    {lineItems.map(item => (
                                      <Stack
                                        style={{
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          paddingBottom: 4,
                                        }}
                                      >
                                        <StackItem style={{}}>
                                          {item.description}{' '}
                                          {` volgens eigendomspercentage (${customer.percentage}%)`}
                                        </StackItem>
                                        <StackItem>
                                          &euro;{' '}
                                          {item.unit_price &&
                                            (
                                              item.unit_price *
                                              (item.units || 1) *
                                              ((customer.percentage || 100) /
                                                100 || 1)
                                            ).toFixed(2)}
                                        </StackItem>
                                      </Stack>
                                    ))}
                                  </Stack>
                                </Stack>
                              );
                            }
                            return <Stack />;
                          },
                        )}
                      </Stack>
                    )}
                </Stack>
              )}
              {createdInvoices && (
                <Stack>
                  <h3 style={{ marginBottom: 5 }}>Aangemaakte facturen</h3>
                  <Stack>
                    {createdInvoices.map((item, i) => (
                      <Stack
                        style={{
                          paddingBottom: 20,
                        }}
                      >
                        <Stack
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <h4 style={{ margin: 0, width: '57%' }}>
                            Factuur {item.invoice_no} (
                            {item.lead && displayNameLead(item.lead)})
                          </h4>
                          <a
                            href={`/customer-invoices/${item.id}`}
                            target='_blank'
                            rel='noreferrer'
                            style={{ width: '20%' }}
                          >
                            <DefaultButton>Bekijk factuur</DefaultButton>
                          </a>
                          <h4
                            style={{
                              margin: 0,
                              width: '22%',
                              textAlign: 'right',
                            }}
                          >
                            Totaal: &euro;{' '}
                            {(
                              item &&
                              item.customer_invoice_line_items &&
                              item.customer_invoice_line_items.reduce(
                                (acc, item) =>
                                  acc +
                                  (item.unit_price || 0) * (item.units || 0),
                                0,
                              )
                            ).toFixed(2)}
                          </h4>
                        </Stack>

                        <Stack
                          style={{
                            paddingLeft: 50,
                            paddingTop: 5,
                          }}
                        >
                          {item.customer_invoice_line_items &&
                            item.customer_invoice_line_items.map(line => (
                              <Stack
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: 4,
                                }}
                              >
                                <StackItem style={{}}>
                                  {line.description}
                                </StackItem>
                                <StackItem>
                                  &euro;{' '}
                                  {line.unit_price &&
                                    (
                                      line.unit_price * (line.units || 1)
                                    ).toFixed(2)}
                                </StackItem>
                              </Stack>
                            ))}
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              )}
            </>
          )}
        </div>
        {!createdInvoices && (
          <div className={modalContentStyles.footer}>
            <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
              <StackItem>
                <PrimaryButton
                  onClick={createPaymentTransactionInvoices}
                  style={{ marginRight: 10 }}
                >
                  Factuur aanmaken{' '}
                  {loading && (
                    <Stack style={{ marginLeft: '5px' }}>
                      <Spinner size={SpinnerSize.small} />
                    </Stack>
                  )}
                </PrimaryButton>
              </StackItem>
              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
          </div>
        )}
      </div>
    </Panel>
  );
};

export default PaymentTransactionInvoiceDetail;

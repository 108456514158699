import React, { useState, useCallback, useEffect } from 'react';
import {
  IconButton, Label, PrimaryButton, Stack,
} from '@fluentui/react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import _ from 'lodash';
import { ConstructionSiteCompactView } from '../construction';
import { Heading } from '../../components/typography';
import {
  stackTokens15,
  IConstructionSite,
  IProperty,
  ISuggestedValueCategory,
  ISalesRep,
  IEmployee,
  ISupplier,
  stackTokens5,
  iconButtonStyles,
  cancelIcon,
} from '../../utils';
import { ConstructionSiteMenu } from '../../components/parts';
import ConstructionSheetOverview from './components/ConstructionSheetOverview';
import ConstructionSheetTemplate from './components/ConstructionSheetTemplate';

interface Props {
  constructionSite: IConstructionSite;
  employees: IEmployee[];
  suggestedValueCategories: ISuggestedValueCategory[];
  salesReps: ISalesRep[];
  setConstructionSite: React.Dispatch<
    React.SetStateAction<IConstructionSite | undefined>
  >;
  suppliers: ISupplier[];
  saveConstructionSite: () => void; // apollomutation
  setCurrentView: (value: string) => void;
  setFilter: React.Dispatch<string>;
  filter?: string;
  moveItem: (a: IProperty, b: IProperty) => void;
  documentEdited?: boolean;
}

const ConstructionSheet: React.FC<Props> = ({
  constructionSite,
  employees,
  suggestedValueCategories,
  salesReps,
  suppliers,
  setConstructionSite,
  saveConstructionSite,
  setCurrentView,
  setFilter,
  filter,
  moveItem,
  documentEdited,
}) => {
  // hooks
  const [showPDF, setShowPDF] = useState(false);

  useEffect(() => {
    if (constructionSite) {
      document.title = `3bouw | Werffiche - ${constructionSite.name}`;
    } else {
      document.title = '3bouw | Werffiche';
    }
  }, [constructionSite]);

  // Functions
  /* const addNewProperty = (property: IProperty) => {
        if (constructionSite) {
            property.id =
                Math.max(
                    0,
                    ...constructionSite.properties.map((prop: IProperty) => prop.id),
                ) + 1;
            property.weight_for_execution_list = 0;
            property.weight_for_construction_sheet = 0;

            if (property.show_in_execution_list) {
                property.weight_for_execution_list =
                    Math.max(
                        0,
                        ...constructionSite.properties.map(
                            (prop: IProperty) => prop.weight_for_execution_list,
                        ),
                    ) + 1;
            }

            if (property.show_in_construction_sheet) {
                property.weight_for_construction_sheet =
                    Math.max(
                        0,
                        ...constructionSite.properties.map(
                            (prop: IProperty) => prop.weight_for_construction_sheet,
                        ),
                    ) + 1;
            }

            const newConstructionSite = { ...constructionSite };
            newConstructionSite.properties = [
                ...constructionSite.properties,
                property,
            ];
            setConstructionSite(newConstructionSite);
        }
    };
*/

  const updateProperties = (properties: IProperty[]) => {
    if (constructionSite) {
      const newConstructionSite = { ...constructionSite };
      newConstructionSite.properties = properties;
      setConstructionSite(newConstructionSite);
    }
  };

  const assignConstructionManagerToOpenItems = useCallback(() => {
    const newConstructionSite = _.cloneDeep(constructionSite);
    const properties = newConstructionSite.properties;

    for (let i = 0; i < properties.length; i += 1) {
      if (!properties[i].employee) {
        properties[i].employee = constructionSite.site_manager;
        properties[i].isDirty = true;
      }
    }
    newConstructionSite.properties = properties;

    setConstructionSite(newConstructionSite);
  }, [constructionSite, setConstructionSite]);

  const getFileName = () => 'werffiche';

  const getPDFItems = () => {
    const newConstructionSite = { ...constructionSite };
    newConstructionSite.properties = constructionSite.properties
      .filter(
        (property: IProperty) => property.weight_for_construction_sheet > 0,
      )
      .sort((a, b) => (
        a.weight_for_construction_sheet - b.weight_for_construction_sheet
      ));

    return newConstructionSite;
  };

  if (!constructionSite) return <>Geen constructiesite</>;

  return (
    <Stack
      tokens={stackTokens15}
      style={{ width: '100%', marginBottom: '50px' }}
    >
      <Heading as='h3'>Werffiche</Heading>

      <ConstructionSiteMenu
        id={constructionSite.id}
        isConstructionSheet='true'
        // callBack={addNewProperty}
        saveConstructionSite={saveConstructionSite}
        download={() => {
          setShowPDF(true);
        }}
        setCurrentView={setCurrentView}
        // status={status}
        documentEdited={documentEdited}
      />

      <ConstructionSiteCompactView constructionsite={constructionSite} />
      <Label>Niet tegewezen items aan werfleider toewijzen:</Label>
      <PrimaryButton
        styles={{ root: { alignSelf: 'baseline', marginTop: '0px!important' } }}
        onClick={assignConstructionManagerToOpenItems}
      >
        toewijzen
      </PrimaryButton>
      <ConstructionSheetOverview
        // id={constructionSite.id}
        properties={
          constructionSite
            ? constructionSite?.properties.filter(
              (el: IProperty) => el.weight_for_construction_sheet > 0,
            )
            : []
        }
        suggestedvalues={suggestedValueCategories}
        salesreps={salesReps}
        employees={employees}
        suppliers={suppliers}
        // callBack={setConstructionSite}
        callBackProperties={updateProperties}
        setFilter={setFilter}
        filter={filter}
        moveItem={moveItem}
      />
      {showPDF && (
        <Stack
          styles={{
            root: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              marginTop: '0!important',
              display: 'flex',
              padding: '20px',
              zIndex: 10,
            },
          }}
        >
          <Stack
            horizontal
            horizontalAlign='space-between'
            styles={{ root: { marginBottom: 10 } }}
          >
            <Stack.Item>
              <h2 style={{ marginTop: 0 }}>PDF Bekijken</h2>
              <Stack tokens={stackTokens5} horizontal>
                <Stack.Item>
                  <Stack horizontalAlign='end'>
                    <PDFDownloadLink
                      document={(
                        <ConstructionSheetTemplate
                          constructionSite={getPDFItems()}
                          title={getFileName()}
                          suggestedValues={suggestedValueCategories}
                        />
                      )}
                      fileName={getFileName()}
                    >
                      {({
                        blob, url, loading, error,
                      }) => (loading ? '' : <PrimaryButton text='Pdf downloaden' />)
                      }
                    </PDFDownloadLink>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel='Sluit venster'
                onClick={() => {
                  setShowPDF(false);
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width="100%" height="100%">
              <ConstructionSheetTemplate
                constructionSite={getPDFItems()}
                title={getFileName()}
                suggestedValues={suggestedValueCategories}
              />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
};

export default ConstructionSheet;

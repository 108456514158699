/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
import React, { FormEvent, useCallback, useState } from 'react';
import {
  SelectionMode,
  DetailsListLayoutMode,
  Stack,
  IComboBoxOption,
  IconButton,
  Modal,
  TextField,
  Icon,
  Selection,
  IDragDropEvents,
  IDragDropContext,
  VirtualizedComboBox,
  Checkbox,
} from '@fluentui/react';
import { ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';
import _ from 'lodash';
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling';
import moment from 'moment';
import {
  convertSalesRepsToComboBoxOptions,
  convertSuppliersToDropdownOptions,
  ISalesRep,
  IPropertySupplier,
  convertEmployeesToComboBoxOptions,
  IEmployee,
  stackTokens15,
  modalContentStyles,
  iconButtonStyles,
  cancelIcon,
  overviewControlStyles,
  sortComboBoxOptionOnText,
  iconLastProps,
  ISupplier,
} from '../../../utils';
import { IProperty } from '../../../utils/Property';
import ConstructionSheetRemarks from './ConstructionSheetRemarks';
import ConstructionSheetDatePicker from './ConstructionSheetDatePicker';
import ConstructionSheetCombobox from './ConstructionSheetCombobox';

const theme = getTheme();

const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
});

interface Props {
  callBackProperties: any;
  employees: any;
  properties: IProperty[];
  salesreps: any;
  suggestedvalues: any;
  suppliers: any;
  setFilter: React.Dispatch<string>;
  filter?: string;
  moveItem: (a: IProperty, b: IProperty) => void;
}

const ConstructionSheetOverview: React.FC<Props> = ({
  //   callBack,
  callBackProperties,
  employees,
  //   id,
  properties,
  //   refetch,
  salesreps,
  suggestedvalues,
  suppliers,
  setFilter,
  filter,
  moveItem,
}) => {
  const [draggedItem, setDraggedItem] = useState<{
    property?: IProperty;
    index: number;
  }>({ property: undefined, index: -1 });

  const [_selection] = useState(
    new Selection({
      // onSelectionChanged: onSelectionChanged,
    }),
  );

  // Constants
  const optionsEmployees = convertEmployeesToComboBoxOptions(employees);
  const optionsSalesReps = convertSalesRepsToComboBoxOptions(salesreps);
  const optionsSuppliers = convertSuppliersToDropdownOptions(suppliers);

  const filteredOptionsSalesReps = optionsSalesReps.filter(
    (salesRep: IComboBoxOption) => (
      optionsEmployees
        .map((employee: IComboBoxOption) => employee.text)
        .indexOf(salesRep.text) === -1
    ),
  );

  /*
  const maxIdEmployees = Math.max(
    ...employees.map((employee: IEmployee) => employee.id),
  );
  */

  const responsibleOptions = [
    ...optionsEmployees,
  ].sort(sortComboBoxOptionOnText);

  // Hooks
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IProperty>();

  const getSelectedSuppliers = (property: IProperty) => (property.suppliers
    ? property.suppliers
      .filter((el) => !el.delete)
      .map((el: IPropertySupplier) => el.supplier.id)
    : []);
  /*
  const onDropFn = (items: any[]) => {
    const newListItems: IProperty[] = items;
    const sortedNewListItems = updateWeightsOnProperties(
      'weight_for_construction_sheet',
      newListItems,
    ).sort(sortPropertiesOnConstructionSheet);
    //setListItems(sortedNewListItems);
    callBackProperties(sortedNewListItems);
  };
*/
  const onItemInvoked = (
    item?: any,
    // index?: number | undefined,
    // ev?: Event | undefined,
  ) => {
    setSelectedItem(item);
    toggleModal();
  };

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue || '';

    setFilter(filterString);
  };

  const updateDate = (item: IProperty) => {
    const newProperties = properties.map((prop: IProperty) => (prop.id === item.id ? item : prop));
    // setListItems(newProperties);
    callBackProperties(newProperties);
  };

  const updateExecutionDate = useCallback((id: number, checked: boolean) => {
    const newProperties = _.cloneDeep(properties);
    for (let i = 0; i < newProperties.length; i++) {
      if (newProperties[i].id === id) {
        newProperties[i].execution_date = checked ? moment().toDate() : undefined;
        newProperties[i].isDirty = true;
      }
    }
    callBackProperties(newProperties);
  }, [properties, callBackProperties]);

  const updateResponsible = (id: number, value: number) => {
    let filteredProperties = _.cloneDeep(properties);
    filteredProperties = filteredProperties.filter(
      (property: IProperty) => property.id === id,
    );

    const updatedProperty = filteredProperties[0];

    updatedProperty.isDirty = true;

    updatedProperty.employee = employees.filter(
      (emp: IEmployee) => emp.id === value,
    )[0];

    const newProperties = properties.map((property: IProperty) => (property.id === id ? updatedProperty : property));
    // setListItems(newProperties);
    callBackProperties(newProperties);
  };

  // ids: the collection of selected supplierId's
  const updateSuppliers = (id: number, option: IComboBoxOption | undefined) => {
    if (!option) return;
    const currentProperties = _.cloneDeep(properties);
    let isCurrentSupplier = false;
    for (let i = 0; i < currentProperties.length; i += 1) {
      if (currentProperties[i].id === id) {
        // current property
        currentProperties[i].isDirty = true;
        const currentSuppliers = _.cloneDeep(currentProperties[i].suppliers) || [];
        for (let e = 0; e < currentSuppliers.length; e += 1) {
          if (option.key === currentSuppliers[e].supplier.id) {
            isCurrentSupplier = true;
            if (option.selected) {
              currentSuppliers[e].delete = false;
              currentSuppliers[e].dirty = true;
            } else {
              currentSuppliers[e].delete = true;
              currentSuppliers[e].dirty = true;
            }
          }
        }

        if (!isCurrentSupplier) {
          const supplierID = option.key as number; // type error to fix
          const supplier: ISupplier | undefined = suppliers.filter(
            (supplier: ISupplier) => supplier.id === supplierID,
          )[0];

          if (supplier) {
            currentSuppliers.push({
              id: -1,
              supplier,
              new: true,
              dirty: true,
            });
          }
        }

        currentProperties[i].suppliers = currentSuppliers;
      }
    }

    // setListItems(newProperties);
    callBackProperties(currentProperties);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  /*
  const getListItems = () => {
    if (filter) {
      const newProperties = properties.filter((property) => {
        if (filter && filter.length > 0) {
          return property.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
        } else return true;
      })
      return newProperties.sort((a, b) => {
        return a.weight_for_construction_sheet - b.weight_for_construction_sheet
      });
    }

    return properties.sort((a, b) => {
      return a.weight_for_construction_sheet - b.weight_for_construction_sheet
    });
  };
*/
  const columns = [
    {
      key: 'column1',
      name: 'Omschrijving',
      fieldName: 'name',
      minWidth: 250,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      onRender: (item: IProperty, index?: number) => (
        <span>
          <strong>
            {item.name}
            {item.isDirty ? '*' : null}
          </strong>
        </span>
      ),
      data: 'number',
      isPadded: false,
    },
    {
      key: 'column2',
      name: 'Besteld',
      fieldName: 'order_date',
      minWidth: 125,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: IProperty) => (
        <ConstructionSheetDatePicker
          canEmpty
          property={item}
          target='order_date'
          callBack={updateDate}
        />
      ),
      data: 'string',
      isPadded: false,
    },
    {
      key: 'column4',
      name: 'Verantwoordelijke',
      fieldName: '',
      minWidth: 125,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: IProperty) => (
        <ConstructionSheetCombobox
          property={item.id}
          props={responsibleOptions}
          selectedKey={
            item.employee && item.employee.id && item.employee.id > 0
              ? item.employee.id
              : 0
          }
          callBack={updateResponsible}
        />
      ),
      data: 'string',
      isPadded: false,
    },
    {
      key: 'column5',
      name: 'OA./leverancier',
      fieldName: '',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: IProperty) => (
        <VirtualizedComboBox
          /* setSelectedKeys={(keys) => {
            if (Array.isArray(keys)) {
              updateSuppliers(item.id, keys);
            } else updateSuppliers(item.id, [keys]);
          }} */
          options={optionsSuppliers}
          // multiline
          multiSelect
          placeholder='Kies leverancier(s)'
          autoComplete='on'
          onChange={(event, option, index, value) => {
            updateSuppliers(item.id, option);
          }}
          selectedKey={getSelectedSuppliers(item)}
          // onInputValueChange={(value) => { console.log(value)}}
          allowFreeform
          // value={filter}
        />
      ),
      data: 'string',
      isPadded: false,
    },
    {
      key: 'column6',
      name: 'Voorzien',
      fieldName: 'due_date',
      minWidth: 125,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: true,
      onRender: (item: IProperty) => (
        <ConstructionSheetDatePicker
          canEmpty
          property={item}
          target='due_date'
          callBack={updateDate}
        />
      ),
      data: 'string',
      isPadded: false,
    },
    {
      key: 'column7',
      name: 'Uitgevoerd',
      fieldName: 'execution_date',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: IProperty) => (
        <Checkbox
          // label='Uitvoeringslijst'
          checked={!!item.execution_date}
          onChange={(e) => {
            // @ts-ignore
            const { target } = e;
            const { checked } = target;
            updateExecutionDate(item.id, checked);
          }}
        />
      ),
      data: 'string',
      isPadded: false,
    },
    {
      key: 'column8',
      name: 'Opmerkingen',
      fieldName: 'value',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: IProperty) => (
        <ConstructionSheetRemarks
          item={item}
          suggestedvalues={suggestedvalues}
          horizontal
        />
      ),
      data: 'string',
      isPadded: false,
    },
    {
      key: 'column9',
      name: '',
      minWidth: 5,
      maxWidth: 5,
      isRowHeader: true,
      isResizable: true,
      onRender: (item: IProperty) => (item.children && item.children.length > 0 ? (
        <Icon
          iconName='RedEye'
          styles={iconLastProps}
          onClick={() => onItemInvoked(item)}
        />
      ) : null),
      isPadded: false,
    },
  ];

  const _insertBeforeItem = (item: IProperty) => {
    const draggedItems = _selection.isIndexSelected(draggedItem.index)
      ? (_selection.getSelection() as IProperty[])
      : [draggedItem.property];

    if (draggedItems && draggedItems[0]) {
      moveItem(draggedItems[0], item);
      _selection.setAllSelected(false);
    }
  };

  const _getDragDropEvents = (): IDragDropEvents => ({
    canDrop: (
      dropContext?: IDragDropContext,
      dragContext?: IDragDropContext,
    ) => true,
    canDrag: (item?: any) => true,
    onDragEnter: (item?: any, event?: DragEvent) => dragEnterClass,
    onDragLeave: (item?: any, event?: DragEvent) => {

    },
    onDrop: (item?: any, event?: DragEvent) => {
      if (draggedItem) {
        _insertBeforeItem(item);
      }
    },
    onDragStart: (
      item?: any,
      itemIndex?: number,
      selectedItems?: any[],
      event?: MouseEvent,
    ) => {
      setDraggedItem({
        property: item,
        index: itemIndex!,
      });
    },
    onDragEnd: (item?: any, event?: DragEvent) => {
      setDraggedItem({
        property: undefined,
        index: -1,
      });
    },
  });

  const _dragDropEvents = _getDragDropEvents();

  const sortProperties = (propertiesToSort: IProperty[]) => propertiesToSort.sort((a, b) => a.weight_for_construction_sheet - b.weight_for_construction_sheet);

  return (
    <div>
      <Stack tokens={stackTokens15}>
        <TextField
          label='Filter op naam:'
          onChange={onSearchValueChange}
          value={filter}
          styles={overviewControlStyles}
        />

        <ShimmeredDetailsList
          items={sortProperties(properties)}
          compact
          columns={columns}
          selection={_selection}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          onItemInvoked={onItemInvoked}
          isHeaderVisible
          // dragAndDropEnabled
          // dragAndDropCallBack={onDropFn}
          dragDropEvents={_dragDropEvents}
        />
      </Stack>

      <Modal
        titleAriaId='Toon opmerkingen'
        isOpen={isModalOpen}
        onDismiss={toggleModal}
        isModeless
        containerClassName={modalContentStyles.container}
      >
        <div className={modalContentStyles.header}>
          <span>Opmerkingen</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel='Sluit venster'
            onClick={toggleModal}
          />
        </div>

        <div className={modalContentStyles.body}>
          {selectedItem ? (
            <ConstructionSheetRemarks
              item={selectedItem}
              suggestedvalues={suggestedvalues}
            />
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default ConstructionSheetOverview;

import {
  DefaultButton,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  SelectionMode,
  Stack,
  StackItem,
} from '@fluentui/react';
import _ from 'lodash';
import React from 'react';
import { DetailsListDefault } from '../../../components/parts';
import {
  classNames,
  iconLastProps,
  iconProps,
  IPropertyListType,
  ITemplateItem,
  ITemplateTemplateItem,
  property_types,
  sortTemplateItemChildrenOnConstructionSheet,
  sortTemplateItemChildrenOnExecutionList,
  sortTemplateTemplateItemOnConstructionSheet,
  sortTemplateTemplateItemOnExecutionList,
  template_types,
} from '../../../utils';

const TemplateDetailsList = ({
  items,
  addChild,
  deleteChild,
  dragAndDropEnabled = false,
  isIncluded = true,
  modifyChild,
  moveDownChild,
  moveUpChild,
  listTypes,
  reAddItem,
  stageItemDelete,
  searchText,
  selectedListType,
  selection,
  templateType,
  updateProperties,
  editItem = () => { },
}: any) => {
  // Constants
  const columnsIncluded: IColumn[] = [
    {
      key: 'column1',
      name: 'Nr.',
      minWidth: 25,
      maxWidth: 25,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: ITemplateTemplateItem, index?: number) => (
        <span>
          {templateType === template_types.EXECUTION_LIST
            ? item.weight_for_execution_list
            : item.weight_for_construction_sheet}{' '}
        </span>
      ),
      data: 'number',
      isPadded: false,
    },
    {
      key: 'column2',
      name: 'Omschrijving',
      fieldName: 'name',
      minWidth: 273,
      maxWidth: 273,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: ITemplateTemplateItem) => renderName(item.template_item),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Materiaalkeuze - Opmerkingen',
      fieldName: 'value',
      minWidth: 290,
      maxWidth: 350,
      isMultiline: false,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: ITemplateTemplateItem) => renderValue(item.template_item),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Lijsten',
      minWidth: 105,
      maxWidth: 105,
      isMultiline: true,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: ITemplateTemplateItem) => renderList(item.template_item),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Rapportage',
      minWidth: 105,
      maxWidth: 105,
      isMultiline: true,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: ITemplateTemplateItem) => (item.template_item.is_reporting_param ? <span>{item.template_item.reporting_label}</span> : undefined),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Type',
      minWidth: 105,
      maxWidth: 105,
      isMultiline: true,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: ITemplateTemplateItem) => (item.template_item.type ? <span>{item.template_item.type.label}</span> : undefined),
      data: 'string',
      isPadded: true,
    },
  ];

  const columnsExcluded: IColumn[] = [
    {
      key: 'column1',
      name: 'Omschrijving',
      fieldName: 'name',
      minWidth: 335,
      maxWidth: 335,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: ITemplateTemplateItem) => renderName(item.template_item),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Materiaalkeuze - Opmerkingen',
      fieldName: 'value',
      minWidth: 350,
      maxWidth: 350,
      isMultiline: true,
      isRowHeader: true,
      isResizable: false,
      onRender: (item: ITemplateTemplateItem) => renderValue(item.template_item),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: '',
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      isIconOnly: true,
      fieldName: 'Add',
      minWidth: 110,
      maxWidth: 110,
      onRender: (item: ITemplateTemplateItem) => (
        <div>
          <DefaultButton text='Voeg toe' onClick={() => reAddItem(item)} />
          <DefaultButton text='Verwijderen' onClick={() => stageItemDelete(item)} />
        </div>
      ),
    },
  ];

  // Functions
  const filterItems = (
    items: ITemplateTemplateItem[],
    templateType: string,
    selectedListType: number,
    searchValue: string,
    isIncluded: boolean,
  ) => {
    let filteredItems;

    if (items) {
      if (isIncluded) {
        filteredItems = items.filter(
          (item: ITemplateTemplateItem) => (templateType === template_types.EXECUTION_LIST
            ? item.weight_for_execution_list! > 0
            : item.weight_for_construction_sheet! > 0),
        );
      } else {
        filteredItems = items.filter(
          (item: ITemplateTemplateItem) => (templateType === template_types.EXECUTION_LIST
            ? item.weight_for_construction_sheet! <= 0
            : item.weight_for_execution_list! <= 0),
        );
      }
    }

    if (filteredItems && filteredItems.length > 0) {
      filteredItems.sort(
        templateType === template_types.EXECUTION_LIST
          ? sortTemplateTemplateItemOnExecutionList
          : sortTemplateTemplateItemOnConstructionSheet,
      );
      filteredItems = filteredItems.map((inputItem: ITemplateTemplateItem) => {
        const item = _.cloneDeep(inputItem);
        if (
          item.template_item.children
          && item.template_item.children.length > 0
        ) {
          const newChildren = item.template_item.children;
          newChildren.slice().sort(
            templateType === template_types.EXECUTION_LIST
              ? sortTemplateItemChildrenOnExecutionList
              : sortTemplateItemChildrenOnConstructionSheet,
          );
          item.template_item.children = newChildren;
        }
        return item;
      });

      if (isIncluded) {
        if (searchValue !== '') {
          filteredItems = filteredItems.filter(
            (item: ITemplateTemplateItem) => item.template_item.name.toLowerCase().indexOf(searchValue) > -1,
          );
        }

        if (selectedListType > 0) {
          filteredItems = filteredItems.filter(
            (item: ITemplateTemplateItem) => {
              if (item.template_item.list_types) {
                const ids = item.template_item.list_types.split(',');
                return ids.indexOf(selectedListType.toString()) > -1;
              }
              return false;
            },
          );
        }
      }
    }

    if (filteredItems) {
      return filteredItems;
    }
    return [];
  };
  const getKey = (item: any, index?: number | undefined) => {
    if (
      item
      && item.template
      && item.template.id
      && item.template_item
      && item.template_item.id
    ) {
      return `${item.template.id}-${item.template_item.id}`;
    }
    return '';
  };

  const onDropFn = (items: any[]) => {
    const newIncludedItems = updateSequenceNumbers(items);
    updateProperties(newIncludedItems);
    // setTemplateItems(newIncludedItems); // probably should not do this...
  };

  const renderDate = (item: ITemplateItem) => {
    const children = item && item.children ? item.children : undefined;
    return (
      <div
        style={{
          minHeight: '18.29px',
          textDecoration: item.delete ? 'line-through' : undefined,
          opacity: item.delete ? 0.5 : undefined,
        }}
      >
        <div>Datum</div>
        {children || null}
      </div>
    );
  };

  const renderDocument = (item: ITemplateItem, children?: any) => (
    <div
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          minHeight: '18.29px', // workaround -> fix
          textDecoration: item.delete ? 'line-through' : undefined,
          opacity: item.delete ? 0.5 : undefined,
        }}
      >
        {item && item.document && item.document.name && item.document.name}
      </div>

      {children || null}
    </div>
  );

  const renderList = (item: ITemplateItem) => {
    if (item.list_types) {
      const listIds = item.list_types?.split(',');
      const children = listIds?.map((id: string) => {
        const type = listTypes.filter(
          (listType: IPropertyListType) => listType.id === +id,
        )[0];
        return <StackItem>{type.name}</StackItem>;
      });

      return <Stack>{children}</Stack>;
    }
  };

  const renderName = (item: ITemplateItem) => {
    const children = item.children
      ? item.children.map((child: ITemplateItem) => (
        <div
          style={{
            textDecoration: child.delete ? 'line-through' : undefined,
            opacity: child.delete ? 0.5 : undefined,
          }}
          key={child.id}
        >
          {child.name}
        </div>
      ))
      : null;

    return (
      <div>
        <div>{item.name}</div>
        {children}
      </div>
    );
  };

  const renderString = (item: ITemplateItem, children?: any) => (
    <>
      {item.value ? (
        <div
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            minHeight: '18.29px',
            textDecoration: item.delete ? 'line-through' : undefined,
            opacity: item.delete ? 0.5 : undefined,
          }}
        >
          {item.value}
        </div>
      ) : (
        <div style={{ minHeight: '18.29px' }}>&nbsp;</div>
      )}
      {children || null}
    </>
  );

  const renderMultiValue = (item: ITemplateItem, children?: any) => (
    <>
      {item.value ? (
        <div
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            minHeight: '18.29px',
            textDecoration: item.delete ? 'line-through' : undefined,
            opacity: item.delete ? 0.5 : undefined,
          }}
        >
          {item.value}
        </div>
      ) : (
        <div style={{ minHeight: '18.29px' }}>&nbsp;</div>
      )}
      {children || null}
    </>
  );
  const renderSuggestedValue = (item: ITemplateItem, children?: any) => (
    <div>
      <div
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          minHeight: '18.29px',
          textDecoration: item.delete ? 'line-through' : undefined,
          opacity: item.delete ? 0.5 : undefined,
        }}
      >
        Voorgestelde waarde:{' '}
        {item.suggested_value_category?.category_description}
      </div>
      {children || null}
    </div>
  );

  const renderValueItem = (item: ITemplateItem, children?: any) => {
    switch (item.type.name) {
      case property_types.DATE: {
        return renderDate(item);
      }
      case property_types.DOCUMENT: {
        return renderDocument(item, children);
      }
      case property_types.MULTI_VALUE: {
        return renderMultiValue(item, children);
      }
      case property_types.SUGGESTED_VALUE: {
        return renderSuggestedValue(item, children);
      }
      case property_types.STRING: {
        return renderString(item, children);
      }
      case property_types.NOTE: {
        return renderString(item, children);
      }
      case property_types.PARENT: {
        if (item.suggested_value_category_id) {
          return renderSuggestedValue(item, children);
        }
        if (item.document) {
          return renderDocument(item, children);
        }
        if (item.name.toLocaleLowerCase().indexOf('datum') > -1) {
          return renderDate(item);
        }
        return renderString(item, children);
      }
      default: {
        return <div>Fout!</div>;
      }
    }
  };

  const renderValue = (item: ITemplateItem) => {
    const children = item.children
      ? item.children
        .sort(
          templateType === template_types.EXECUTION_LIST
            ? sortTemplateItemChildrenOnExecutionList
            : sortTemplateItemChildrenOnConstructionSheet,
        )
        .map((child: ITemplateItem) => renderValueItem(child))
      : null;

    return renderValueItem(item, children);
  };

  const updateSequenceNumbers = (items: ITemplateTemplateItem[]) => {
    if (templateType === template_types.EXECUTION_LIST) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].weight_for_execution_list !== i + 1) {
          items[i].weight_for_execution_list = i + 1;
          if (!items[i].template_item.draft) {
            items[i].template_item.dirty = true;
          }
        }
      }
    } else {
      for (let i = 0; i < items.length; i++) {
        if (items[i].weight_for_construction_sheet !== i + 1) {
          items[i].weight_for_construction_sheet = i + 1;
          if (!items[i].template_item.draft) {
            items[i].template_item.dirty = true;
          }
        }
      }
    }

    return items;
  };

  return (
    <DetailsListDefault
      items={filterItems(
        items,
        templateType,
        selectedListType,
        searchText,
        isIncluded,
      )}
      columns={isIncluded ? columnsIncluded : columnsExcluded}
      selectionMode={isIncluded ? SelectionMode.single : SelectionMode.none}
      selection={selection}
      getKey={getKey}
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible
      isShimmered={false}
      dragAndDropEnabled={dragAndDropEnabled}
      dragAndDropCallBack={onDropFn}
      onItemInvoked={editItem}
    />
  );
};

export default TemplateDetailsList;

import React, { FormEvent, useEffect, useState } from 'react';
import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  ICommandBarItemProps,
  Icon,
  PrimaryButton,
  Selection,
  SelectionMode,
  Stack,
  TextField,
} from '@fluentui/react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import {
  DELETE_PURCHASE_ORDER,
  dialogModelProps,
  IPurchaseOrder,
  returnFormattedDate,
} from '../../../utils';
import {
  columnStyles,
  dialogRemovePurchaseOrderConfirmationProps,
  iconLastProps,
  iconProps,
  stackTokens15,
  textFieldStyles300,
} from '../../../utils/Styles';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import PurchaseOrderDownload from './PurchaseOrderDownload';

const PurchaseOrdersOverview = ({
  orders,
  refetch,
  setPurchaseOrders,
  showBack = false,
  constructionSite,
  redirectTarget,
}: any) => {
  const [items, setItems] = useState(orders);
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);
  const [selectedId, setSelectedId] = useState(0);

  const [selection, setSelection] = useState<any[]>([]);

  const onSelectionChanged = () => {
    const selection = selectionTemp.getSelection();
    setSelection(selection);
  };

  const selectionTemp = new Selection({
    onSelectionChanged,
  });

  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
    if (!isConfirmationHidden) setSelectedId(0);
  };

  const navigate = useNavigate();

  const [deletePurchaseOrder] = useMutation(DELETE_PURCHASE_ORDER);

  const deleteOrder = () => {
    deletePurchaseOrder({
      variables: {
        where: {
          id: selectedId,
        },
      },
    }).then(() => {
      refetch().then((x: any) => {
        setPurchaseOrders(x.data.findManyPurchaseOrders);
        toggleConfirmationDialog();
        selectionTemp.setAllSelected(false);
      });
    });
  };

  const onItemInvoked = () => {};

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';

    setItems(
      filterString === ''
        ? orders
        : orders.filter(
            (purchaseOrder: IPurchaseOrder) =>
              (purchaseOrder.purchase_order_no
                ? purchaseOrder.purchase_order_no.indexOf(filterString) > -1
                : false) ||
              (purchaseOrder.supplier
                ? purchaseOrder.supplier.name
                    .toLowerCase()
                    .indexOf(filterString) > -1
                : false),
          ),
    );
  };

  const editPurchaseOrder = (id?: number) => {
    if (id) {
      navigate(`/purchase-order/${id}`);
    } else {
      navigate(`/purchase-order/${selection[0].id}`);
    }
  };

  const removePurchaseOrder = (id?: number) => {
    if (id) {
      setSelectedId(id);
    } else {
      setSelectedId(selection[0].id);
    }

    toggleConfirmationDialog();
  };

  const columns = [
    {
      key: 'column1',
      name: 'Bestelbonnummer',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>
          {item && item.purchase_order_no ? item.purchase_order_no : ''}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Leverancier',
      minWidth: 225,
      maxWidth: 225,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>{item && item.supplier && item.supplier.name}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Werf',
      minWidth: 225,
      maxWidth: 225,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>{item.construction_site && item.construction_site.name}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Aanmaakdatum',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      isSorted: true,
      isSortedDescending: true,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>
          {item.creation_date &&
            moment(item.creation_date).format('DD/MM/YYYY')}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Leveringsdatum',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>
          {item.delivery_date
            ? moment(item.delivery_date).format('DD/MM/YYYY')
            : null}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: '',
      className: columnStyles.centerContents,
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>
          <Icon
            iconName='Edit'
            styles={iconProps}
            onClick={() => editPurchaseOrder(item.id)}
          />
          <Icon
            iconName='Download'
            styles={iconProps}
            onClick={() => PurchaseOrderDownload(item)}
          />
          <Icon
            iconName='Delete'
            styles={iconLastProps}
            onClick={() => removePurchaseOrder(item.id)}
          />
        </span>
      ),
      data: 'string',
      isPadded: false,
    },
  ];

  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      href: '/purchase-order/add',
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => editPurchaseOrder(),
      disabled: selection.length < 1,
      theme: commandBarTheme,
    },
    {
      key: 'download',
      text: 'PDF',
      iconProps: { iconName: 'Download' },
      onClick: () => PurchaseOrderDownload(selection[0] as IPurchaseOrder),
      disabled: selection.length < 1,
      theme: commandBarTheme,
    },
    {
      key: 'delete',
      text: 'Verwijder',
      iconProps: { iconName: 'delete' },
      onClick: () => removePurchaseOrder(),
      disabled: selection.length < 1,
      theme: commandBarTheme,
    },
  ];

  const commandBarFarItems: ICommandBarItemProps[] = [
    {
      key: 'back',
      text: 'Terug',
      iconProps: { iconName: 'back' },
      href: `/construction-sites/${constructionSite}/${
        redirectTarget === 'execution-list'
          ? 'implementation-list'
          : 'site-overview'
      }`,
      theme: commandBarTheme,
    },
  ];

  useEffect(() => {
    (() => {
      setItems(orders);
    })();
  }, [orders]);

  document.title = '3bouw | Bestelbonnen';

  return (
    <Stack tokens={stackTokens15}>
      <TextField
        label='Zoek op bestelbonnummer of leverancier'
        styles={textFieldStyles300}
        onChange={onSearchValueChange}
      />

      <CommandBarSticky
        items={commandBaritems}
        farItems={showBack ? commandBarFarItems : []}
        ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
        theme={commandBarTheme}
        width='1188px'
        maxWidth='1188px'
      />

      {/* <CommandBar
                items={commandBaritems}
                ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
                theme={commandBarTheme} /> */}

      <DetailsList
        items={items}
        compact
        columns={columns}
        selection={selectionTemp}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        getKey={undefined}
        setKey='none'
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible
        onItemInvoked={onItemInvoked}
      />

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemovePurchaseOrderConfirmationProps}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteOrder} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};

export default PurchaseOrdersOverview;

import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { IComboBoxOption } from '@fluentui/react';

import { ComboboxWithFilter } from '../../../components/parts';
import {
  convertSuggestedValuesToComboBoxOptions,
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
} from '../../../utils';

interface ISuggestedValueView {
  item: any;
  setItem: any;
  code: string;
  timesheetLine: number;
  required?: boolean;
  errorMessage?: string;
}

const SuggestedViewStatus = ({
  item,
  setItem,
  code,
  timesheetLine,
  required,
  errorMessage,
}: ISuggestedValueView) => {
  const { isAuthenticated } = useAuth0();

  const { loading, error, data } = useQuery(
    GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
    {
      variables: {
        filter: {
          suggested_value_category: {
            suggested_value_code_prefix: {
              contains: 'tse_stat_',
            },
          },
        },
      },
      skip: item === undefined,
    },
  );

  if (!isAuthenticated) return <p>Verboden</p>;
  if (loading && !data) return <p>Laden...</p>;
  if (error) return <p>Fout :(</p>;

  const comboboxSuggestedValueOptions: IComboBoxOption[] = convertSuggestedValuesToComboBoxOptions(
    data?.findManySuggestedValues,
  );

  const updateItem = (newValue: number) => {
    const result = [...item];
    result[timesheetLine].status = `tse_stat_${newValue}`;
    setItem(result);

    return result;
  };

  return (
    <ComboboxWithFilter
      options={comboboxSuggestedValueOptions}
      value={[+code]}
      multiline={false}
      required={required}
      errorMessage={errorMessage}
      callBack={(newValue: IComboBoxOption[]) => {
        if (newValue && newValue.length > 0) {
          updateItem(newValue[0].key as number);
        }
      }}
    />
  );
};

export default SuggestedViewStatus;

import React, { useCallback, useEffect, useState } from 'react';
import {
  IconButton,
  Modal,
  PrimaryButton,
  Stack,
  DefaultButton,
  TextField,
  PivotItem,
} from '@fluentui/react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import _ from 'lodash';
import moment from 'moment';
import { ConstructionSiteCompactView } from '../construction';
import { PropertyFilterView } from '../construction-list';
import {
  cancelIcon,
  convertPropertyTypeToComboBoxOptions,
  convertSuggestedValueCategoriesToComboBoxOptions,
  iconButtonStyles,
  IConstructionSheetRemark,
  IConstructionSite,
  IProperty,
  modalContentStyles,
  property_types,
  saving_subjects,
  stackTokens15,
  stackTokens5,
  IPropertyType,
  ISuggestedValueCategory,
} from '../../utils';
import { defaultProperty } from '../../utils/Property';
import { IPropertyListType } from '../../utils/PropertyListType';
import { ConstructionSiteMenu, DatePickerNL } from '../../components/parts';
import Combobox from '../../components/parts/ComboBox';
import {
  markItem,
  deleteItem,
  saveNewField,
  updateConstructionSheetRemarks,
} from './utils';
import ExecutionListTemplate from './components/ExecutionListTemplate';
import ExecutionListPanel from './components/ExecutionListPanel';
import WaterpointPanel from './components/WaterpointPanel';

interface ExecutionListProps {
  constructionSite: IConstructionSite;
  constructionSheetRemarks: IConstructionSheetRemark[] | undefined;
  dataTypes: IPropertyListType[];
  dataPropertyTypes: IPropertyType[];
  dataSuggestedValueCategories: ISuggestedValueCategory[];
  status: string;
  setConstructionSite: React.Dispatch<
    React.SetStateAction<IConstructionSite | undefined>
  >;
  setConstructionSheetRemarks: React.Dispatch<
    React.SetStateAction<IConstructionSheetRemark[] | undefined>
  >;
  setWaterPoints: React.Dispatch<React.SetStateAction<IProperty[] | undefined>>;
  setCurrentView: (value: string) => void;
  saveConstructionSite: () => void;
  waterPoints: IProperty[];
  setFilter: React.Dispatch<string>;
  filter?: string;
  moveItem: (a: IProperty, b: IProperty) => void;
  documentEdited?: boolean;
}

const ExecutionList: React.FC<ExecutionListProps> = ({
  constructionSite,
  constructionSheetRemarks,
  waterPoints,
  dataTypes,
  dataPropertyTypes,
  dataSuggestedValueCategories,
  status,
  setConstructionSite,
  setConstructionSheetRemarks,
  setWaterPoints,
  setCurrentView,
  saveConstructionSite,
  setFilter,
  filter,
  moveItem,
  documentEdited,
}) => {
  const TAP_POINTS = 'Tappunten';
  const SHOW_ALL = 'Toon alles';

  // Hooks
  const [selectedKey, setSelectedKey] = useState<string | undefined>();

  const [selectedPropertyID, setSelectedPropertyID] = useState<number>();

  const [downloadSubject, setDownloadSubject] = useState<string>(SHOW_ALL);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalNewItemOpen, setIsModalNewItemOpen] = useState(false);
  const [executionListDate, setExecutionListDate] = useState(new Date());
  const [showPanel, setShowPanel] = useState(false);
  const [showPDF, setShowPDF] = useState(false);

  const [savingSubject, setSavingSubject] = useState<string>(
    saving_subjects.CONSTRUCTION_SITE,
  );

  const [newProperty, setNewProperty] = useState<IProperty>(defaultProperty);
  // Constants

  const updateProperties = (properties: IProperty[]) => {
    if (constructionSite) {
      const newConstructionSite = { ...constructionSite };
      newConstructionSite.properties = properties;
      // newConstructionSite.isDirty = true; // for autosave!!
      setConstructionSite(newConstructionSite);
    }
  };

  const addNewProperty = useCallback(() => {
    const newConstructionSite = _.cloneDeep(constructionSite);
    const newProperty = _.cloneDeep(defaultProperty);
    newProperty.id = moment().unix();

    setSelectedPropertyID(newProperty.id);

    newConstructionSite.properties.push(newProperty);
    // newConstructionSite.isDirty = true; // for autosave!!
    setConstructionSite(newConstructionSite);

    setShowPanel(true);
  }, [constructionSite, setConstructionSite]);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleModalNewItem = () => {
    setIsModalNewItemOpen(!isModalNewItemOpen);
  };
  /*
  const showDateModal = () => {
    toggleModal();
  };
*/
  const onChangeDate = (newDate: Date) => {
    setExecutionListDate(newDate);
  };

  const getFileName = () => {
    const filename =
      downloadSubject === '' || downloadSubject === SHOW_ALL
        ? 'Uitvoeringslijst'
        : (downloadSubject === 'Tappunten' ? '' : 'Werflijst ') +
          downloadSubject;
    return `${
      constructionSite.name
    }_${filename}_${executionListDate.toLocaleDateString()}`;
  };

  const getPDFItems = () => {
    const filename =
      downloadSubject === '' || downloadSubject === SHOW_ALL
        ? 'Uitvoeringslijst'
        : (downloadSubject === 'Tappunten' ? '' : 'Werflijst ') +
          downloadSubject;
    const newConstructionSite = { ...constructionSite };
    newConstructionSite.properties = constructionSite.properties.filter(
      (property: IProperty) => property.weight_for_execution_list > 0,
    );
    let remarks;
    if (
      filename !== 'Uitvoeringslijst' &&
      newConstructionSite.properties &&
      newConstructionSite.properties.length > 0
    ) {
      newConstructionSite.properties = newConstructionSite.properties.filter(
        (el: IProperty) =>
          el.list_types.filter(
            (ele: any) => ele.list_type.name === downloadSubject,
          ).length > 0,
      );
      remarks = constructionSheetRemarks?.filter(
        (el: IConstructionSheetRemark) =>
          el.property_list_type.name === downloadSubject,
      );
    }

    return newConstructionSite;
  };

  const allTypes = dataTypes ? [{ id: 0, name: SHOW_ALL }, ...dataTypes] : [];

  const onLinkClick = (item: PivotItem) => {
    setSelectedKey(item.props.itemKey);
    setDownloadSubject(item.props.itemKey!);
    if (item?.props.itemKey === SHOW_ALL) {
      setSavingSubject(saving_subjects.CONSTRUCTION_SITE);
    } else if (item?.props.itemKey === TAP_POINTS) {
      setSavingSubject(saving_subjects.WATER_POINTS);
    } else {
      setSavingSubject(saving_subjects.CONSTRUCTION_SHEET_REMARKS);
    }
  };

  const selectionIsMarked = useCallback(() => {
    if (constructionSite && constructionSite.properties) {
      for (let i = 0; i < constructionSite.properties.length; i++) {
        if (
          constructionSite.properties[i] &&
          constructionSite.properties[i].id === selectedPropertyID
        ) {
          return constructionSite.properties[i].marked;
        }
      }
    }
    return false;
  }, [constructionSite, selectedPropertyID]);

  const selectionIsDeleted = useCallback(() => {
    if (constructionSite && constructionSite.properties) {
      for (let i = 0; i < constructionSite.properties.length; i++) {
        if (
          constructionSite.properties[i] &&
          constructionSite.properties[i].id === selectedPropertyID
        ) {
          return constructionSite.properties[i].delete;
        }
      }
    }
    return false;
  }, [constructionSite, selectedPropertyID]);

  useEffect(() => {
    if (constructionSite) {
      document.title = `3bouw | Uitvoeringlijst - ${constructionSite.name}`;
    } else {
      document.title = '3bouw | Uitvoeringslijst';
    }
  }, [constructionSite]);

  return (
    <Stack
      tokens={stackTokens15}
      style={{ width: '100%', marginBottom: '50px' }}
    >
      <h3>Uitvoeringslijst</h3>
      <ConstructionSiteMenu
        id={constructionSite.id}
        isExecutionList='true'
        saveConstructionSite={saveConstructionSite}
        download={() => {
          setShowPDF(true);
        }}
        status={status}
        editItem={() => {
          setShowPanel(true);
        }}
        toggleDeleteItem={() => {
          deleteItem(
            {
              selectedKey: selectedPropertyID, // update!
              constructionSite,
            },
            setConstructionSite,
          );
        }}
        markItem={(value: boolean) => {
          markItem(
            {
              selectedKey: selectedPropertyID, // update!
              value,
              constructionSite,
            },
            setConstructionSite,
          );
        }}
        properties={constructionSite ? constructionSite.properties : []}
        canEditItem={!!selectedPropertyID}
        addNewItem={() => addNewProperty()}
        isMarked={selectionIsMarked()}
        isDeleted={selectionIsDeleted()}
        setCurrentView={setCurrentView}
        documentEdited={documentEdited}
      />

      <ConstructionSiteCompactView constructionsite={constructionSite} />

      <PropertyFilterView
        constructionSite={constructionSite}
        remarks={constructionSheetRemarks}
        waterpoints={waterPoints}
        callBackProperties={updateProperties}
        callBackWaterPoints={setWaterPoints}
        callBackRemarks={(remarks: IConstructionSheetRemark[]) => {
          updateConstructionSheetRemarks(
            { remarks, constructionSheetRemarks },
            setConstructionSheetRemarks,
          );
        }}
        setSelectedPropertyID={setSelectedPropertyID}
        setSelection={() => {}} // {setCurrentSelection}
        editItem={() => {
          setShowPanel(true);
        }}
        selectedKey={selectedKey}
        // setSelectedKey={setSelectedKey}
        onLinkClick={onLinkClick}
        allTypes={allTypes}
        setFilter={setFilter}
        filter={filter}
        moveItem={moveItem}
      />

      <Modal
        titleAriaId='Wijzig leveradres'
        isOpen={isModalOpen}
        onDismiss={toggleModal}
        isModeless
        containerClassName={modalContentStyles.container}
      >
        <div className={modalContentStyles.header}>
          <span>Datum uitvoeringslijst</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel='Sluit venster'
            onClick={toggleModal}
          />
        </div>

        <div className={modalContentStyles.body}>
          <Stack tokens={stackTokens5}>
            <Stack.Item>
              <DatePickerNL
                updateParent={onChangeDate}
                value={executionListDate}
                // isDisabled={!isEdit}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack horizontalAlign='end' style={{ marginTop: 10 }}>
                <PrimaryButton
                  text='Pdf downloaden'
                  onClick={() => {
                    setShowPDF(true);
                  }}
                />
              </Stack>
            </Stack.Item>
          </Stack>
        </div>
      </Modal>
      <Modal
        isOpen={isModalNewItemOpen}
        onDismiss={toggleModalNewItem}
        containerClassName={modalContentStyles.container}
        isBlocking={false}
      >
        <div>
          <div className={modalContentStyles.header}>
            <span>Voeg nieuw veld toe</span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel='Sluit venster'
              onClick={toggleModalNewItem}
            />
          </div>
        </div>
        <div className={modalContentStyles.body}>
          {dataPropertyTypes && (
            <Stack>
              <Stack.Item style={{ marginBottom: 10 }}>
                <TextField
                  name='name'
                  label='Naam'
                  value={newProperty.name}
                  onChange={(
                    event: React.FormEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                    newValue?: string,
                  ) => {
                    const result = _.cloneDeep(newProperty);
                    result.name = newValue || '';
                    setNewProperty(result);
                  }}
                  required
                />
              </Stack.Item>
              <Stack.Item>
                <Combobox
                  label='Veld Type'
                  options={convertPropertyTypeToComboBoxOptions(
                    dataPropertyTypes.filter((item: IPropertyType) => {
                      if (
                        item.name === property_types.PARENT ||
                        item.name === 'free-multivalue'
                      ) {
                        return false;
                      }
                      return true;
                    }),
                  )}
                  selectedKey={
                    newProperty && newProperty.type
                      ? newProperty.type.id
                      : undefined
                  }
                  onSelect={key => {
                    if (key && !Array.isArray(key)) {
                      if (dataPropertyTypes) {
                        const filterValues = dataPropertyTypes.filter(
                          (item: IPropertyType) => {
                            if (item && item.id && item.id === key) return true;
                            return false;
                          },
                        );

                        if (filterValues && filterValues[0]) {
                          const typeValue = filterValues[0];
                          const result = _.cloneDeep(newProperty);
                          result.type = typeValue;

                          setNewProperty(result);
                        }
                      }
                    }
                  }}
                />
              </Stack.Item>
              {newProperty &&
                newProperty.type &&
                newProperty.type.name === property_types.MULTI_VALUE && (
                  <Stack.Item>
                    <Combobox
                      label='Multi Value Type'
                      options={convertPropertyTypeToComboBoxOptions(
                        dataPropertyTypes.filter(
                          (item: IPropertyType) =>
                            item.name !== property_types.PARENT &&
                            item.name !== property_types.MULTI_VALUE,
                        ),
                      )}
                      selectedKey={
                        newProperty && newProperty.nested_type
                          ? newProperty.nested_type.id
                          : undefined
                      }
                      onSelect={key => {
                        if (key && !Array.isArray(key)) {
                          if (dataPropertyTypes) {
                            const filterValues = dataPropertyTypes.filter(
                              (item: IPropertyType) => {
                                if (item && item.id && item.id === key) {
                                  return true;
                                }
                                return false;
                              },
                            );

                            if (filterValues && filterValues[0]) {
                              const typeValue = filterValues[0];
                              const result = _.cloneDeep(newProperty);
                              result.nested_type = typeValue;

                              setNewProperty(result);
                            }
                          }
                        }
                      }}
                    />
                  </Stack.Item>
                )}
              {newProperty &&
                ((newProperty.type &&
                  newProperty.type.name === property_types.SUGGESTED_VALUE) ||
                  (newProperty.nested_type &&
                    newProperty.nested_type.name ===
                      property_types.SUGGESTED_VALUE)) &&
                dataSuggestedValueCategories && (
                  <Stack.Item>
                    <Combobox
                      label='Category Voorgestelde Waarde'
                      options={convertSuggestedValueCategoriesToComboBoxOptions(
                        dataSuggestedValueCategories,
                      )}
                      selectedKey={
                        newProperty && newProperty.remote_category
                          ? dataSuggestedValueCategories.map(
                              (item: ISuggestedValueCategory) => {
                                if (
                                  item.category_code ===
                                  newProperty.remote_category
                                ) {
                                  return item.id;
                                }
                              },
                            )[0]
                          : undefined
                      }
                      onSelect={key => {
                        if (key && !Array.isArray(key)) {
                          if (dataSuggestedValueCategories) {
                            const filterValues =
                              dataSuggestedValueCategories.filter(
                                (item: ISuggestedValueCategory) => {
                                  if (item && item.id && item.id === key) {
                                    return true;
                                  }
                                  return false;
                                },
                              );

                            if (filterValues && filterValues[0]) {
                              const typeValue = filterValues[0];
                              const result = _.cloneDeep(newProperty);
                              result.remote_category = typeValue.category_code;

                              setNewProperty(result);
                            }
                          }
                        }
                      }}
                    />
                  </Stack.Item>
                )}
            </Stack>
          )}
        </div>
        <div className={modalContentStyles.footer}>
          <Stack horizontal reversed tokens={stackTokens5}>
            <DefaultButton
              onClick={() => {
                toggleModalNewItem();
              }}
              text='Annuleren'
            />
            <PrimaryButton
              onClick={() => {
                saveNewField(
                  {
                    currentSelection: selectedPropertyID,
                    newProperty,
                    constructionSite,
                  },
                  setConstructionSite,
                  setNewProperty,
                  toggleModalNewItem,
                );
              }}
              text='Toevoegen'
            />
          </Stack>
        </div>
      </Modal>
      {showPanel && selectedKey && selectedKey === TAP_POINTS && (
        <WaterpointPanel
          setWaterPoints={setWaterPoints}
          closePanel={() => setShowPanel(false)}
          properties={waterPoints}
          selectedKey={selectedPropertyID}
        />
      )}
      {showPanel && (!selectedKey || selectedKey !== TAP_POINTS) && (
        <ExecutionListPanel
          closePanel={() => setShowPanel(false)}
          dataTypes={dataTypes}
          // isConstructionSheet={false} //this is the execution list
          properties={constructionSite.properties}
          selectedKey={selectedPropertyID}
          setConstructionSite={setConstructionSite}
        />
      )}
      {showPDF && (
        <Stack
          styles={{
            root: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              marginTop: '0!important',
              display: 'flex',
              padding: '20px',
              zIndex: 10,
            },
          }}
        >
          <Stack
            horizontal
            horizontalAlign='space-between'
            styles={{ root: { marginBottom: 10 } }}
          >
            <Stack.Item>
              <h2 style={{ marginTop: 0 }}>PDF Bekijken</h2>
              <Stack tokens={stackTokens5} horizontal>
                <Stack.Item>
                  <DatePickerNL
                    updateParent={onChangeDate}
                    value={executionListDate}
                    // isDisabled={!isEdit}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Stack horizontalAlign='end'>
                    <PDFDownloadLink
                      document={
                        <ExecutionListTemplate
                          constructionSite={getPDFItems()}
                          title='test'
                          showSigningPage={downloadSubject === SHOW_ALL}
                          date={executionListDate}
                          isWaterPoints={false}
                          waterPoints={waterPoints}
                          remarks={constructionSheetRemarks}
                        />
                      }
                      fileName={getFileName()}
                    >
                      {({ loading }) => {
                        if (loading) return '';
                        return <PrimaryButton text='Pdf downloaden' />;
                      }}
                    </PDFDownloadLink>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel='Sluit venster'
                onClick={() => {
                  setShowPDF(false);
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <ExecutionListTemplate
                constructionSite={getPDFItems()}
                title='test'
                showSigningPage={downloadSubject === SHOW_ALL}
                date={executionListDate}
                isWaterPoints={false}
                waterPoints={waterPoints}
                remarks={constructionSheetRemarks}
              />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
};

export default ExecutionList;

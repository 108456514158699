import {
  ISpinButtonStyles, Label, Stack, SpinButton,
} from '@fluentui/react';
import React, { useCallback } from 'react';
import moment from 'moment';
import { stackTokens5 } from '../../utils';

const styles: Partial<ISpinButtonStyles> = {
  root: {
    width: '50px',
  },
};

interface Props {
  callback: (newDate: string) => void;
  currentDate?: string;
  divider: string;
  disabled?: boolean;
}

const TimePicker: React.FC<Props> = ({
  callback,
  divider = ':',
  disabled,
  currentDate = moment().startOf('d').toISOString(),
}) => {
  const setHour = useCallback((value?: string) => {
    if (currentDate && value) {
      const newDate = moment(currentDate);
      newDate.set('hour', parseInt(value, 10));
      callback(newDate.toISOString());
    } else if (currentDate) {
      const newDate = moment(currentDate);
      newDate.set('hour', 0);
      callback(newDate.toISOString());
    }
  }, [currentDate]);

  const setMinute = (value?: string) => {
    if (currentDate && value) {
      const newDate = moment(currentDate);
      newDate.set('minute', parseInt(value, 10));
      callback(newDate.toISOString());
    } else if (currentDate) {
      const newDate = moment(currentDate);
      newDate.set('minute', 0);
      callback(newDate.toISOString());
    }
  };
  return (
    <Stack horizontal tokens={stackTokens5}>
      {/* Hours */}
      <SpinButton
        onChange={(e, value) => { setHour(value); }}
        // id={1}
        value={moment(currentDate).get('hour').toString()}
        min={0}
        max={23}
        disabled={disabled}
        styles={styles}
      />

      <Label>{divider}</Label>

      {/* Minutes */}
      <SpinButton
        onChange={(e, value) => { setMinute(value); }}
        // id={2}
        value={moment(currentDate).get('minute').toString()}
        min={0}
        max={59}
        disabled={disabled}
        styles={styles}
      />
    </Stack>
  );
};

export default TimePicker;

import { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { DefaultButton, Stack, TextField } from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_LEADS, ILead } from '../../utils/Lead';
import CustomersOverview from './components/CustomersOverview';

interface IPagedCustomer {
  customers: ILead[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

type IStatus =
  | 'provisional_agreement'
  | 'only_invoice'
  | 'no_wings'
  | 'started'
  | 'ended'
  | 'canceled';

const Customers = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedCustomer>({
    customers: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'last_name1',
  });

  const [filter, setFilter] = useState('');

  const [status, setStatus] = useState<IStatus | undefined>(undefined);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, customers: [], skip: 0 }));
    setStatus(undefined);
  }, [filter]);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, customers: [], skip: 0 }));
  }, [status]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string, filterStatus?: string) => {
    const filterArray = filterString.split(' ');

    const filters: any = {
      AND: [],
    };

    if (filterStatus) {
      switch (filterStatus) {
        case 'provisional_agreement':
          filters.AND.push({
            OR: [{ status: { contains: 'PROVISIONAL_AGREEMENT' } }],
          });
          break;
        case 'only_invoice':
          filters.AND.push({
            OR: [{ status: { contains: 'ONLY_INVOICE' } }],
          });
          break;
        case 'no_wings':
          filters.AND.push({
            OR: [{ wings_code: null }],
          });
          break;
        case 'canceled':
          filters.AND.push({
            OR: [
              {
                construction_sites: {
                  some: {
                    status: {
                      id: 4,
                    },
                  },
                },
              },
            ],
          });
          break;
        case 'started':
          filters.AND.push({
            OR: [
              {
                construction_sites: {
                  some: {
                    status: {
                      id: 2,
                    },
                  },
                },
              },
            ],
          });
          break;
        case 'ended':
          filters.AND.push({
            OR: [
              {
                construction_sites: {
                  some: {
                    status: {
                      id: 1,
                    },
                  },
                },
              },
            ],
          });
          break;
        default:
          break;
      }
    } else {
      filters.AND.push({
        OR: [
          { status: { contains: 'SOLD' } },
          {
            construction_sites: {
              some: {
                id: {
                  gt: 0,
                },
              },
            },
          },
        ],
      });
    }

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { first_name1: { contains: filterArray[i] } },
          { first_name2: { contains: filterArray[i] } },
          { last_name1: { contains: filterArray[i] } },
          { last_name2: { contains: filterArray[i] } },
          { address1: { contains: filterArray[i] } },
          { city1: { contains: filterArray[i] } },
          {
            sales_rep: {
              OR: [
                { first_name: { contains: filterArray[i] } },
                { last_name: { contains: filterArray[i] } },
              ],
            },
          },
        ],
      };

      const numericValue = parseInt(filterArray[i], 10);
      if (numericValue) filterValue.OR.push({ zip_code1: +numericValue });

      filters.AND.push(filterValue);
    }

    return filters;
  };

  const {
    loading,
    error,
    refetch: refetchCustomers,
  } = useQuery(GET_LEADS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, status),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      if (x.findManyLeads && x.findManyLeads.length > 0) {
        setPagedState(prevState => ({
          ...prevState,
          customers: pagedState.customers.concat(x.findManyLeads),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(x.findManyLeads.length < default_page_size),
        }));
      } else if (x.findManyLeads) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearCustomers();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearCustomers = () => {
    setPagedState(prevState => ({
      ...prevState,
      customers: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.customers.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Klanten</h3>

      <div>
        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: '15px',
          }}
        >
          <div>
            <span
              style={{
                fontSize: '14px',
                fontWeight: '600',
                color: 'rgb(50, 49, 48)',
                display: 'block',
                padding: '5px 0px',
              }}
            >
              Status:
            </span>
            <div style={{ paddingTop: '2px' }}>
              <DefaultButton
                text='Alle'
                onClick={() => {
                  setStatus(undefined);
                }}
                primary={status === undefined}
              />
            </div>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <span
              style={{
                fontSize: '14px',
                fontWeight: '600',
                color: 'rgb(50, 49, 48)',
                display: 'block',
                padding: '5px 0px',
              }}
            >
              Klant
            </span>
            <div style={{ paddingTop: '2px' }}>
              <DefaultButton
                text='Voorlopige overeenkomst'
                onClick={() => {
                  setStatus('provisional_agreement');
                }}
                primary={status === 'provisional_agreement'}
              />
              <DefaultButton
                text='Enkel factuur'
                styles={{ root: { marginLeft: '10px' } }}
                onClick={() => {
                  setStatus('only_invoice');
                }}
                primary={status === 'only_invoice'}
              />
              <DefaultButton
                text='Geen wings ID'
                styles={{ root: { marginLeft: '10px' } }}
                onClick={() => {
                  setStatus('no_wings');
                }}
                primary={status === 'no_wings'}
              />
            </div>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <span
              style={{
                fontSize: '14px',
                fontWeight: '600',
                color: 'rgb(50, 49, 48)',
                display: 'block',
                padding: '5px 0px',
              }}
            >
              Werf
            </span>
            <div style={{ paddingTop: '2px' }}>
              <DefaultButton
                text='Gestart'
                onClick={() => {
                  setStatus('started');
                }}
                primary={status === 'started'}
              />
              <DefaultButton
                text='Beëindigd'
                styles={{ root: { marginLeft: '10px' } }}
                onClick={() => {
                  setStatus('ended');
                }}
                primary={status === 'ended'}
              />
              <DefaultButton
                text='Geannuleerd'
                styles={{ root: { marginLeft: '10px' } }}
                onClick={() => {
                  setStatus('canceled');
                }}
                primary={status === 'canceled'}
              />
            </div>
          </div>
        </div>
      </div>

      <CustomersOverview
        customers={pagedState.customers}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchCustomers={refetchCustomers}
        clearCustomers={clearCustomers}
      />
    </Stack>
  );
};

export default Customers;

import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ApolloError } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { useParams } from 'react-router';
import { forEach } from 'async';
import {
  GET_CONSTRUCTION_SHEET_REMARKS_BY_CONSTRUCTION_SITE,
  GET_CONSTRUCTION_SITE_BY_ID,
  GET_WATER_POINTS_BY_CONSTRUCTION_SITE,
  GET_PROPERTY_TYPES,
  GET_SUGGESTED_VALUE_CATEGORIES,
  GET_SALES_REPS,
  GET_EMPLOYEES,
  GET_SUPPLIERS,
  IConstructionSheetRemark,
  IConstructionSite,
  IProperty,
  UPDATE_CONSTRUCTION_SITE_WITH_PROPERTIES,
} from '../../utils';
import { UPDATE_PROPERTY, ADD_PROPERTY } from '../../utils/Property';
import { GET_PROPERTY_LIST_TYPES } from '../../utils/PropertyListType';
import { useAppDispatch } from '../../redux/hooks';
import { throwError, SeverityLevel } from '../../redux/error/errorSlice';
import {
  sendNotification,
  dismissNotification,
} from '../../redux/notification/notificationSlice';
import ExecutionListOverview from '../implementation-list/ExecutionList';
import ConstructionSheetOverview from '../site-overview/ConstructionSheet';
import { getPropertyCreateData, getPropertyUpdateData } from './utils';
/*
const sortConstructionSiteProperties = (constructionSite: IConstructionSite, view?: string) => {
    let newConstructionsite = _.cloneDeep(constructionSite);
    let properties = newConstructionsite.properties;

    if(view === "implementation-list") {
        newConstructionsite.properties = properties.sort((a, b) => {
            return a.weight_for_construction_sheet - b.weight_for_construction_sheet
        })
    } else if(view === 'site-overview') {
        newConstructionsite.properties = properties.sort((a, b) => {
            return a.weight_for_execution_list - b.weight_for_execution_list

        })
    } else {
        console.log("no view!!!");
    }
    return newConstructionsite;
}
*/
const filteredConstructionSite = (
  constructionSite: IConstructionSite,
  filter?: string,
) => {
  if (filter && filter.length > 0) {
    const newConstructionSite = _.cloneDeep(constructionSite);
    newConstructionSite.properties = newConstructionSite.properties.filter(
      (item) => item.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
    );

    return newConstructionSite;
  }
  return constructionSite;
};

type DocumentState = {
  constructionSite: {
    init: boolean; // flag if data is set for the first time
    isDirty?: boolean;
  };
  remarks: {
    init: boolean; // flag if data is set for the first time
    isDirty?: boolean;
  };
  waterPoints: {
    init: boolean; // flag if data is set for the first time
    isDirty?: boolean;
  };
};

const ConstructionSiteDetail = () => {
  // Hooks
  const [documentState, setDocumentState] = useState<DocumentState>({
    constructionSite: {
      init: false,
      isDirty: false,
    },
    remarks: {
      init: false,
      isDirty: false,
    },
    waterPoints: {
      init: false,
      isDirty: false,
    },
  });

  const [autosave, setAutosave] = useState(true);

  // @ts-ignore
  const { id: constructionSiteId, view } = useParams();

  const [currentView, setCurrentView] = useState<string>(
    view || 'implementation-list',
  );

  const [constructionSite, setConstructionSite] = useState<IConstructionSite>();
  const [constructionSheetRemarks, setConstructionSheetRemarks] = useState<IConstructionSheetRemark[]>();
  const [waterPoints, setWaterPoints] = useState<IProperty[]>();

  const [filter, setFilter] = useState('');

  // console.log(view);

  const dispatch = useAppDispatch();

  const { isAuthenticated } = useAuth0();

  // Mutations
  const [updateConstructionSite] = useMutation(
    UPDATE_CONSTRUCTION_SITE_WITH_PROPERTIES,
    {
      onCompleted: (d) => {
        if (d && d.updateConstructionSite) {
          setConstructionSite(d.updateConstructionSite);
          refetchWaterPoints();
          refetchRemarks();
        }
      },
      onError: (error) => {
        dispatch(
          throwError({
            module: 'executionlist.updateConstructionSite',
            message: error.message,
            level: SeverityLevel.Critical,
          }),
        );
        console.log(error);
      },
    },
  );
  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onError: (error) => {
      dispatch(
        throwError({
          module: 'executionlist.updateProperty',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
      console.log(error);
    },
  });
  // Queries
  const [addProperty] = useMutation(ADD_PROPERTY, {
    onError: (error) => {
      dispatch(
        throwError({
          module: 'constructionsheet.addProperty',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
      console.log(error);
    },
  });
  const { loading, error } = useQuery(GET_CONSTRUCTION_SITE_BY_ID, {
    variables: {
      where: {
        id: parseInt(constructionSiteId || '-1', 10),
      },
      whereProps: {
        parent_id: null,
      },
    },
    skip: constructionSiteId === undefined,
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => setConstructionSite(x.findOneConstructionSite),
    onError: (error) => {
      dispatch(
        throwError({
          module: 'constructionsheet.getConstructionSite',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
      console.log(error);
    },
  });
  const {
    loading: loadingTypes,
    error: errorTypes,
    data: dataTypes,
  } = useQuery(GET_PROPERTY_LIST_TYPES, {
    onError: (error) => {
      dispatch(
        throwError({
          module: 'constructionsheet.getPropertyListTypes',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
      console.log(error);
    },
  });
  const {
    data: dataSuggestedValueCategories,
    loading: loadingSuggestedValueCategories,
  } = useQuery(GET_SUGGESTED_VALUE_CATEGORIES, {
    onError: (error) => {
      dispatch(
        throwError({
          module: 'constructionsheet.getSuggestedValue',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
      console.log(error);
    },
  });
  const { data: dataPropertyTypes, loading: loadingPropertyTypes } = useQuery(
    GET_PROPERTY_TYPES,
    {
      onError: (error) => {
        dispatch(
          throwError({
            module: 'constructionsheet.getPropertyTypes',
            message: error.message,
            level: SeverityLevel.Critical,
          }),
        );
        console.log(error);
      },
    },
  );
  const {
    loading: loadingWaterPoints,
    error: errorWaterPoints,
    refetch: refetchWaterPoints,
  } = useQuery(GET_WATER_POINTS_BY_CONSTRUCTION_SITE, {
    variables: {
      filter: {
        OR: [
          {
            construction_site_id: parseInt(constructionSiteId || '-1', 10),
            needs_water_points: true,
          },
          {
            needs_water_points: true,
            parent: {
              construction_site_id: parseInt(constructionSiteId || '-1', 10),
            },
          },
        ],
        NOT: [
          {
            type: {
              id: 14,
            },
          },
        ],
      },
    },
    skip: constructionSiteId === undefined,
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setWaterPoints(x.findManyProperties);
    },
    onError: (error) => {
      dispatch(
        throwError({
          module: 'constructionsheet.getWaterPoints',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
      console.log(error);
    },
  });
  const {
    loading: loadingRemarks,
    error: errorRemarks,
    refetch: refetchRemarks,
  } = useQuery(GET_CONSTRUCTION_SHEET_REMARKS_BY_CONSTRUCTION_SITE, {
    variables: {
      filter: {
        construction_site_id: parseInt(constructionSiteId || '-1', 10),
      },
    },
    fetchPolicy: 'no-cache',
    skip: constructionSiteId === undefined,
    onCompleted: (x: any) => {
      console.log(x);
      if (
        _.isEqual(constructionSheetRemarks, x.findManyConstructionSheetRemarks)
      ) {
        setDocumentState((prevState) => ({
          ...prevState,
          remarks: { init: true, isDirty: false },
        }));
      }
      setConstructionSheetRemarks(x.findManyConstructionSheetRemarks);
    },
    onError: (error) => {
      dispatch(
        throwError({
          module: 'constructionsheet.getConstructionSheetRemarks',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
      console.log(error);
    },
  });
  const {
    loading: loadingSalesRep,
    error: errorSalesRep,
    data: dataSalesRep,
  } = useQuery(GET_SALES_REPS, {
    variables: {
      filter: {
        active: true,
      },
      orderBy: {
        last_name: 'asc',
      },
    },
    onError: (error) => {
      dispatch(
        throwError({
          module: 'constructionsheet.updateConstructionSite',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });
  const {
    loading: loadingEmployees,
    error: errorEmployees,
    data: dataEmployees,
  } = useQuery(GET_EMPLOYEES, {
    variables: {
      filter: {
        AND: [
          { active: true },
          {
            OR: [
              { construction_site_manager: true },
              { sales_rep: true },
            ],
          },
        ],
      },
      orderBy: {
        last_name: 'asc',
      },
    },
  });

  const {
    loading: loadingSuppliers,
    error: errorSuppliers,
    data: dataSuppliers,
  } = useQuery(GET_SUPPLIERS, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    onError: (error) => {
      dispatch(
        throwError({
          module: 'constructionsheet.updateConstructionSite',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });
  /*
    const saveConstructionSheetRemarks = async () => {
        return new Promise((resolve, reject) => {
            if (constructionSheetRemarks && constructionSite) {
                const updatedRemarks = constructionSheetRemarks.map(
                    (remark: IConstructionSheetRemark) => {
                        return {
                            data: {
                                remarks: remark.remarks,
                            },
                            where: {
                                id: remark.id,
                            },
                        };
                    },
                );

                updateConstructionSite({
                    variables: {
                        id: constructionSite.id,
                        data: {
                            construction_sheet_remarks: {
                                update: updatedRemarks,
                            },
                        },
                    },
                })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error: any) => {
                        reject(error);
                    });
            }
        });
    };
*/

  const saveConstructionSite = async () => {
    try {
      if (constructionSite) {
        const newProperties = constructionSite.properties
          .filter((property: IProperty) => property.isNew && !property.delete)
          .map((property: IProperty) => {
            let children;

            if (property.children && property.children.length > 0) {
              children = property.children.map((child: IProperty) => ({
                ...getPropertyCreateData(child),
                construction_site: {
                  connect: {
                    id: constructionSite.id,
                  },
                },
              }));
            }

            let parent = {};

            parent = {
              ...parent,
              ...getPropertyCreateData(property),
            };

            if (children && children.length > 0) {
              parent = {
                ...parent,
                children: {
                  create: children,
                },
              };
            }
            return parent;
          });

        const updatedProperties = constructionSite.properties
          .filter(
            (property: IProperty) => property.isDirty && !property.isNew && !property.delete,
          )
          .map((property: IProperty) => {
            let updateData = {};

            updateData = {
              ...updateData,
              ...getPropertyUpdateData(property),
            };

            return {
              data: updateData,
              where: {
                id: property.id,
              },
            };
          });

        const deleteProperties = constructionSite.properties
          .filter((property: IProperty) => !property.isNew && property.delete)
          .map((property: IProperty) => ({ id: property.id }));

        dispatch(
          sendNotification({
            message: 'bezig met opslaan',
            level: 0,
            module: 'template.updateTemplate',
            spinner: true,
          }),
        );

        const updatedRemarks = constructionSheetRemarks
          ? constructionSheetRemarks.map(
            (remark: IConstructionSheetRemark) => ({
              data: {
                remarks: remark.remarks,
              },
              where: {
                id: remark.id,
              },
            }),
          )
          : undefined;

        updateConstructionSite({
          variables: {
            id: constructionSite.id,
            data: {
              properties: {
                create: newProperties,
                update: updatedProperties,
                delete: deleteProperties,
              },
              construction_sheet_remarks: {
                update: updatedRemarks,
              },
            },
            whereProps: {
              parent_id: null,
            },
            orderByProps: {
              weight_for_execution_list: 'asc',
            },
          },
        });
      }

      // await saveConstructionSheetRemarks();
      await saveWaterPoints();

      dispatch(
        sendNotification({
          module: 'template.updateTemplate',
          message: 'De wijzigingen zijn opgeslagen',
          level: 1,
          timeout: 2500,
        }),
      );
    } catch (error) {
      dispatch(dismissNotification());

      if (typeof error === 'string') {
        dispatch(
          throwError({
            module: 'executionList.saveConstructionSite',
            message: error,
            level: SeverityLevel.Critical,
          }),
        );
      } else if (error instanceof Error || error instanceof ApolloError) {
        dispatch(
          throwError({
            module: 'executionList.saveConstructionSite',
            message: error.message,
            level: SeverityLevel.Critical,
          }),
        );
      }
    }
  };

  const saveWaterPoints = () => new Promise((resolve, reject) => {
    (async () => {
      try {
        if (waterPoints) {
          const newWaterPoints = waterPoints
            .filter((waterPoint: IProperty) => waterPoint.isNew)
            .map((waterPoint: IProperty) => {
              if (constructionSite) {
                return {
                  data: {
                    name: waterPoint.name,
                    needs_water_points: waterPoint.needs_water_points,
                    tap_water_points: waterPoint.tap_water_points,
                    rain_water_points: waterPoint.rain_water_points,
                    construction_site: {
                      connect: {
                        id: constructionSite.id,
                      },
                    },
                    type: {
                      connect: {
                        id: 9, // text
                      },
                    },
                  },
                };
              }
            });

          const updatedWaterPoints = waterPoints
            .filter(
              (waterPoint: IProperty) => waterPoint.isDirty && !waterPoint.isNew,
            )
            .map((waterPoint: IProperty) => ({
              data: {
                tap_water_points: waterPoint.tap_water_points,
                rain_water_points: waterPoint.rain_water_points,
                name: waterPoint.name,
              },
              id: waterPoint.id,
            }));

          await forEach(updatedWaterPoints, (waterPoint, cb) => {
            updateProperty({ variables: waterPoint }).then(
              (data) => {
                cb();
              },
            );
          });

          await forEach(newWaterPoints, (waterPoint, cb) => {
            addProperty({ variables: waterPoint }).then(
              (data) => {
                cb();
              },
            );
          });
        }
        resolve(true);
      } catch (error) {
        reject(error);
      }
    })();
  });

  const moveExecutionListItem = (a: IProperty, b: IProperty) => {
    const newConstructionSite = _.cloneDeep(constructionSite);
    if (newConstructionSite) {
      const properties = newConstructionSite.properties;
      properties.sort(
        (a, b) => a.weight_for_execution_list - b.weight_for_execution_list,
      );
      console.log(properties);
      const newProperties: IProperty[] = [];
      let propertyInserted = false;
      let previousWeight = -1;
      for (let i = 0; i < properties.length; i++) {
        const currentProperty = properties[i];

        if (currentProperty.id === a.id) {
          // skip if current property is the one that needs to be moved
        } else if (currentProperty.id !== b.id && !propertyInserted) {
          newProperties.push(currentProperty);
          previousWeight = currentProperty.weight_for_execution_list;
        } else if (currentProperty.id !== b.id && propertyInserted) {
          currentProperty.weight_for_execution_list = previousWeight + 1;
          currentProperty.isDirty = true;
          previousWeight = currentProperty.weight_for_execution_list;
          newProperties.push(currentProperty);
        } else if (currentProperty.id === b.id) {
          // current property should shift down
          const newProperty = a;
          newProperty.weight_for_execution_list = currentProperty.weight_for_execution_list;
          currentProperty.weight_for_execution_list = newProperty.weight_for_execution_list + 1;
          previousWeight = currentProperty.weight_for_execution_list;
          propertyInserted = true;
          newProperties.push(currentProperty, newProperty);
        }
      }

      newConstructionSite.properties = newProperties;
      setConstructionSite(newConstructionSite);
    }
  };

  const moveConstructionSheetItem = (a: IProperty, b: IProperty) => {
    const newConstructionSite = _.cloneDeep(constructionSite);
    if (newConstructionSite) {
      const properties = newConstructionSite.properties;
      properties.sort(
        (a, b) => a.weight_for_construction_sheet - b.weight_for_construction_sheet,
      );
      console.log(properties);
      const newProperties: IProperty[] = [];
      let propertyInserted = false;
      let previousWeight = -1;
      for (let i = 0; i < properties.length; i++) {
        const currentProperty = properties[i];

        if (currentProperty.id === a.id) {
          // skip if current property is the one that needs to be moved
        } else if (currentProperty.id !== b.id && !propertyInserted) {
          newProperties.push(currentProperty);
          previousWeight = currentProperty.weight_for_construction_sheet;
        } else if (currentProperty.id !== b.id && propertyInserted) {
          currentProperty.weight_for_construction_sheet = previousWeight + 1;
          currentProperty.isDirty = true;
          previousWeight = currentProperty.weight_for_construction_sheet;
          newProperties.push(currentProperty);
        } else if (currentProperty.id === b.id) {
          // current property should shift down
          const newProperty = a;
          newProperty.weight_for_construction_sheet = currentProperty.weight_for_construction_sheet;
          currentProperty.weight_for_construction_sheet = newProperty.weight_for_construction_sheet + 1;
          previousWeight = currentProperty.weight_for_construction_sheet;
          propertyInserted = true;
          newProperties.push(currentProperty, newProperty);
        }
      }

      newConstructionSite.properties = newProperties;
      setConstructionSite(newConstructionSite);
    }
  };

  useEffect(() => {
    if (view) setCurrentView(view);
  }, [view]);

  useEffect(() => {
    if (constructionSite) {
      if (documentState && documentState.constructionSite.init) {
        setDocumentState((prevState) => ({
          ...prevState,
          constructionSite: { init: true, isDirty: true },
        }));
      } else if (documentState) {
        setDocumentState((prevState) => ({
          ...prevState,
          constructionSite: { init: true, isDirty: false },
        }));
      }
    }
  }, [constructionSite]);

  useEffect(() => {
    if (constructionSheetRemarks) {
      if (documentState && documentState.remarks.init) {
        setDocumentState((prevState) => ({
          ...prevState,
          remarks: { init: true, isDirty: true },
        }));
      } else if (documentState) {
        setDocumentState((prevState) => ({
          ...prevState,
          remarks: { init: true, isDirty: false },
        }));
      }
    }
  }, [constructionSheetRemarks]);

  useEffect(() => {
    if (waterPoints) {
      if (documentState && documentState.waterPoints.init) {
        setDocumentState((prevState) => ({
          ...prevState,
          waterPoints: { init: true, isDirty: true },
        }));
      } else if (documentState) {
        setDocumentState((prevState) => ({
          ...prevState,
          waterPoints: { init: true, isDirty: false },
        }));
      }
    }
  }, [waterPoints]);
  /*
  useEffect(() => {
    if (autosave) {
      if (
        documentState.constructionSite.isDirty ||
        documentState.remarks.isDirty ||
        documentState.waterPoints.isDirty
      ) {
        console.log('autosaved');
        setDocumentState({
          constructionSite: {
            init: false,
            isDirty: false,
          },
          remarks: {
            init: false,
            isDirty: false,
          },
          waterPoints: {
            init: false,
            isDirty: false,
          },
        });
        saveConstructionSite();
      }
    }
  }, [documentState]);
*/
  if (!isAuthenticated) return <p>Verboden</p>;

  if (loading) return <p>Laden...</p>;
  if (error) return <p>Fout :(</p>;

  if (!constructionSite) return <p>no construction site found</p>;

  if (currentView === 'implementation-list') {
    return (
      <ExecutionListOverview
        constructionSite={filteredConstructionSite(constructionSite, filter)}
        constructionSheetRemarks={constructionSheetRemarks}
        dataTypes={dataTypes ? dataTypes.findManyPropertyListTypes : []}
        dataPropertyTypes={
          dataPropertyTypes ? dataPropertyTypes.findManyPropertyTypes : []
        }
        dataSuggestedValueCategories={
          dataSuggestedValueCategories
            ? dataSuggestedValueCategories.findManySuggestedValueCategories
            : []
        }
        status=''
        setConstructionSheetRemarks={setConstructionSheetRemarks}
        setConstructionSite={setConstructionSite}
        setWaterPoints={setWaterPoints}
        saveConstructionSite={saveConstructionSite}
        waterPoints={waterPoints || []}
        setCurrentView={(value) => {
          setCurrentView(value);
        }}
        setFilter={setFilter}
        filter={filter}
        moveItem={moveExecutionListItem}
        documentEdited={
          documentState.constructionSite.isDirty
          || documentState.remarks.isDirty
          || documentState.waterPoints.isDirty
        }
      />
    );
  }
  if (currentView === 'site-overview') {
    return (
      <ConstructionSheetOverview
        constructionSite={filteredConstructionSite(constructionSite, filter)}
        employees={dataEmployees ? dataEmployees.findManyEmployees : []}
        suggestedValueCategories={
          dataSuggestedValueCategories
            ? dataSuggestedValueCategories.findManySuggestedValueCategories
            : []
        }
        salesReps={dataSalesRep ? dataSalesRep.findManySalesReps : []}
        suppliers={dataSuppliers ? dataSuppliers.findManySuppliers : []}
        setConstructionSite={setConstructionSite}
        saveConstructionSite={saveConstructionSite}
        setCurrentView={(value) => {
          setCurrentView(value);
        }}
        setFilter={setFilter}
        filter={filter}
        moveItem={moveConstructionSheetItem}
        documentEdited={
          documentState.constructionSite.isDirty
          || documentState.remarks.isDirty
          || documentState.waterPoints.isDirty
        }
      />
    );
  }
  return <p>view: {view}</p>;
};

export default ConstructionSiteDetail;

import React, { useState } from 'react';
import {
  Icon, Stack, Link, StackItem,
} from '@fluentui/react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { getTheme } from '@fluentui/react/lib/Styling';
import {
  GET_STORAGE_TOKEN,
  stackTokens5,
} from '../../../utils';
import { BlobStorage } from '../../blob-storage';
import { IProperty } from '../../../utils/Property';

const theme = getTheme();

interface IDocumentView {
  property: IProperty;
  inline?: boolean;
}

const DocumentLink: React.FC<IDocumentView> = ({
  property,
  inline,
}) => {
  // Constants
  const blobName = property && property.document ? property.document.blob_name : '';

  // Queries
  const { loading, error, data } = useQuery(GET_STORAGE_TOKEN);

  // Functions
  const downloadFile = (url?: string) => {
    if (url) {
      fileStore.downloadBlob('temp', url);
    }
  };

  if (loading) return <div>Loading</div>;

  const fileStore = new BlobStorage(
    data.createStorageToken.accountName,
    data.createStorageToken.sasToken,
  );

  return (
    <Stack style={inline ? { display: 'inline' } : undefined}>
      {blobName && (
        <Link
          href='#'
          onClick={(e) => { e.preventDefault(); downloadFile(blobName); }}
          id={`deleteDocument-${property.id}-${(property.document ? property.document.id : '')}`}
        >
          {blobName}
        </Link>
      )}
    </Stack>
  );
};

export default DocumentLink;

import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  Checkbox,
  TextField,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/client';
import { modalContentStyles } from '../../../utils';
import {
  ADD_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM,
  GET_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM_BY_ID,
  ICustomerContractUpdateLineItem,
  UPDATE_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM,
} from '../../../utils/CustomerContractUpdateLineItem';
import { GET_CUSTOMER_CONTRACT_UPDATE_BY_ID, ICustomerContractUpdate } from '../../../utils/CustomerContractUpdate';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  contractUpdateDetailSource: ICustomerContractUpdateLineItem;
  customerContractUpdate: ICustomerContractUpdate;
}

const ContractUpdateDetail = ({
  isOpen,
  dismissPanel,
  contractUpdateDetailSource,
  customerContractUpdate,
}: Props) => {
  const [contractUpdateDetail, setContractUpdateDetail] = useState(contractUpdateDetailSource);

  useQuery(GET_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: !contractUpdateDetailSource || !contractUpdateDetailSource.id ? undefined : { where: { id: contractUpdateDetailSource.id } },
    skip: !contractUpdateDetailSource || !contractUpdateDetailSource.id,
    onCompleted: (data: any) => {
      setContractUpdateDetail(data.findOneCustomerContractUpdateLineItem);
    },
  });

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setContractUpdateDetail((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [addContractUpdateDetail] = useMutation(ADD_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM, {
      refetchQueries: [
        { query: GET_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM_BY_ID },
        { query: GET_CUSTOMER_CONTRACT_UPDATE_BY_ID },
      ],
    });
  const [modifyContractUpdateDetail] = useMutation(UPDATE_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM, {
      refetchQueries: [
        { query: GET_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM_BY_ID },
        { query: GET_CUSTOMER_CONTRACT_UPDATE_BY_ID },
      ],
    });

  const saveContractUpdateDetail = () => {
    if (contractUpdateDetail) {
      const allInput = {
        comments: contractUpdateDetail.comments,
        description: contractUpdateDetail.description,
        percentage_vat: +contractUpdateDetail.percentage_vat!,
        show_only_total: contractUpdateDetail.show_only_total,
        units: +contractUpdateDetail.units!,
        unit_price: +contractUpdateDetail.unit_price!,
        customer_contract_update: {
          connect: { id: customerContractUpdate.id },
        },
      };

      if (contractUpdateDetail.id) {
        modifyContractUpdateDetail({
          variables: {
            id: contractUpdateDetail.id,
            data: allInput,
          },
        }).then(() => {
          dismissPanel();
        });
      } else {
        addContractUpdateDetail({
          variables: {
            data: allInput,
          },
        }).then(() => {
          // dismissPanel();
        });
      }
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        contractUpdateDetail && contractUpdateDetail.id
          ? 'Min-/meerwerk detail wijzigen'
          : 'Min-/meerwerk detail toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {contractUpdateDetail && contractUpdateDetail.id && (
            <Label>ID: {contractUpdateDetail.id}</Label>
          )}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='description'
              label='Omschrijving'
              value={
                contractUpdateDetail && contractUpdateDetail.description
                  ? contractUpdateDetail.description
                  : ''
              }
              onChange={onChangeTextFieldValue}
              multiline
              required
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='units'
              label='Aantal'
              type='number'
              value={
                contractUpdateDetail && contractUpdateDetail.units
                  ? contractUpdateDetail.units.toString()
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='unit_price'
              label='Eenheidsprijs'
              type='number'
              step='0.01'
              min='0'
              value={
                contractUpdateDetail && contractUpdateDetail.unit_price
                  ? contractUpdateDetail.unit_price.toString()
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          {contractUpdateDetail &&
            contractUpdateDetail.unit_price &&
            contractUpdateDetail.units && (
              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <Label>Totaal excl BTW</Label>&euro;{' '}
                {(
                  contractUpdateDetail.unit_price * contractUpdateDetail.units
                ).toFixed(2)}
              </Stack>
            )}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='percentage_vat'
              label='BTW %'
              type='number'
              step='1'
              max='100'
              min='0'
              value={
                contractUpdateDetail && contractUpdateDetail.percentage_vat
                  ? contractUpdateDetail.percentage_vat.toString()
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          {contractUpdateDetail &&
            contractUpdateDetail.unit_price &&
            contractUpdateDetail.units && (
              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <Label>Totaal incl BTW</Label>&euro;{' '}
                {!contractUpdateDetail.percentage_vat ||
                +contractUpdateDetail.percentage_vat === 0
                  ? (
                      contractUpdateDetail.unit_price *
                      contractUpdateDetail.units
                    ).toFixed(2)
                  : +contractUpdateDetail.percentage_vat < 1
                  ? (
                      contractUpdateDetail.unit_price *
                      contractUpdateDetail.units *
                      (1 + contractUpdateDetail.percentage_vat)
                    ).toFixed(2)
                  : (
                      contractUpdateDetail.unit_price *
                      contractUpdateDetail.units *
                      (1 + contractUpdateDetail.percentage_vat / 100)
                    ).toFixed(2)}
              </Stack>
            )}

          <Stack style={{ marginBottom: 10 }}>
            <Label>Toon enkel totaal</Label>
            <Checkbox
              name='show_only_total'
              label='Toon enkel totaal?'
              checked={
                !!(contractUpdateDetail && contractUpdateDetail.show_only_total)
              }
              onChange={() => {
                setContractUpdateDetail((prevState: any) => ({
                  ...prevState,
                  show_only_total:
                    contractUpdateDetail && contractUpdateDetail.show_only_total
                      ? !contractUpdateDetail.show_only_total
                      : true,
                }));
              }}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <StackItem>
                <PrimaryButton
                  onClick={saveContractUpdateDetail}
                  style={{ marginRight: 10 }}
                  disabled={
                    !contractUpdateDetail ||
                    (contractUpdateDetail &&
                      (!contractUpdateDetail.units ||
                        !contractUpdateDetail.unit_price ||
                        !contractUpdateDetail.percentage_vat ||
                        !contractUpdateDetail.description))
                  }
                >
                  Opslaan
                </PrimaryButton>
              </StackItem>
              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default ContractUpdateDetail;

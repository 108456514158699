import { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
  Stack,
  StackItem,
  TextField,
  Toggle,
  TextStyles,
} from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_WORK_ORDERS, IWorkOrder } from '../../utils/WorkOrder';
import WorkOrdersOverview from './components/WorkOrdersOverview';

interface IPagedWorkOrder {
  workOrders: IWorkOrder[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

function WorkOrders() {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedWorkOrder>({
    workOrders: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'document_date',
  });

  const [filter, setFilter] = useState('');
  const [completed, setCompleted] = useState(false);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, workOrders: [], skip: 0 }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string, completed: boolean) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [{ status: { contains: completed ? 'COMPLETED' : 'NEW' } }],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { description: { contains: filterArray[i] } },
          { document_no: { contains: filterArray[i] } },
          {
            construction_site: {
              OR: [
                { name: { contains: filterArray[i] } },
                { address: { contains: filterArray[i] } },
              ],
            },
          },
        ],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    loading,
    error,
    refetch: refetchWorkOrders,
  } = useQuery(GET_WORK_ORDERS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, completed),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      if (
        x &&
        x.findManyDefectWorkOrders &&
        x.findManyDefectWorkOrders.length > 0
      ) {
        setPagedState(prevState => ({
          ...prevState,
          workOrders: pagedState.workOrders.concat(x.findManyDefectWorkOrders),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(
            x.findManyDefectWorkOrders.length < default_page_size
          ),
        }));
      } else if (x && x.findManyDefectWorkOrders) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearWorkOrders();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearWorkOrders = () => {
    setPagedState(prevState => ({
      ...prevState,
      workOrders: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.workOrders.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  const onChangeFilterCompleted = () => {
    setCompleted(!completed);
    clearWorkOrders();
    refetchWorkOrders();
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Overzicht werkbonnen</h3>

      <Stack style={{ flexDirection: 'row' }}>
        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />

        <StackItem style={{ marginLeft: 70 }}>
          <Toggle
            label='Afgesloten?'
            defaultChecked={completed}
            onText='Afgesloten'
            offText='Nieuw'
            onChange={onChangeFilterCompleted}
          />
        </StackItem>
      </Stack>

      <WorkOrdersOverview
        workOrders={pagedState.workOrders}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchWorkOrders={refetchWorkOrders}
        clearWorkOrders={clearWorkOrders}
      />
    </Stack>
  );
}

export default WorkOrders;

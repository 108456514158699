import {
  Stack,
  Label,
  IStackStyles,
  IStackTokens,
  TextStyles,
} from '@fluentui/react';
import { stackTokens5 } from '../../../utils';

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    width: 500,
  },
};

// Tokens definition
const numericalSpacingStackTokens: IStackTokens = {
  childrenGap: 0,
  padding: 0,
};

const ConstructionSiteCompactView = ({ constructionsite }: any) => (
  <Stack horizontal tokens={stackTokens5}>
    <Stack styles={stackStyles} tokens={numericalSpacingStackTokens}>
      <Label>{constructionsite?.name}</Label>
      {constructionsite?.address && <Label>{constructionsite?.address}</Label>}
      {constructionsite?.zip_code && constructionsite?.city && (
        <Label>
          {constructionsite?.zip_code} {constructionsite?.city}
        </Label>
      )}
      {constructionsite?.comments && (
        <Label>{constructionsite?.comments}</Label>
      )}
      {constructionsite?.lead.mobile1_v2 ||
        (constructionsite?.lead.mobile1 && (
          <Label>
            {constructionsite?.lead.mobile1_v2 ||
              constructionsite?.lead.mobile1}
          </Label>
        ))}
      {constructionsite?.lead.email1 && (
        <Label>{constructionsite?.lead.email1}</Label>
      )}
    </Stack>
    <Stack styles={stackStyles} tokens={numericalSpacingStackTokens}>
      {constructionsite?.sales_rep &&
        constructionsite?.sales_rep.first_name && (
          <Label>Verkoper: {constructionsite?.sales_rep.first_name}</Label>
        )}
      {constructionsite?.project_manager &&
        constructionsite?.project_manager.first_name && (
          <Label>
            Projectbeheerder: {constructionsite?.project_manager.first_name}
          </Label>
        )}
      {constructionsite?.site_manager &&
        constructionsite?.site_manager.first_name && (
          <Label>
            Werfopvolger: {constructionsite?.site_manager.first_name}
          </Label>
        )}
      {constructionsite?.epb_reporter &&
        constructionsite?.epb_reporter.first_name &&
        constructionsite?.epb_reporter.last_name && (
          <Label>
            Verslaggever EPB: {constructionsite?.epb_reporter.first_name}{' '}
            {constructionsite?.epb_reporter.last_name}
          </Label>
        )}
      {constructionsite?.safety_coordinator &&
        constructionsite?.safety_coordinator.first_name &&
        constructionsite?.safety_coordinator.last_name && (
          <Label>
            Veiligheidscoordinator:{' '}
            {constructionsite?.safety_coordinator.first_name}{' '}
            {constructionsite?.safety_coordinator.last_name}
          </Label>
        )}
      {constructionsite?.architect &&
        constructionsite?.architect.first_name && (
          <Label>Architect: {constructionsite?.architect.first_name}</Label>
        )}
    </Stack>
  </Stack>
);

export default ConstructionSiteCompactView;

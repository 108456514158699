import React from 'react';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import Logo from '../../../3Bouw_logo.png';
import fontSegoe from '../../../fonts/Segoe_UI.ttf';
import fontSegoeBold from '../../../fonts/Segoe_UI_Bold.ttf';
import {
  IProperty,
  IPropertySupplier,
  ISuggestedValue,
  ISuggestedValueCategory,
  property_types,
} from '../../../utils';
import { displayNameLead } from '../../../utils/Lead';

Font.register({
  family: 'Segoe UI',
  fonts: [{ src: fontSegoe }, { src: fontSegoeBold, fontStyle: 'bold' }],
});

const ConstructionSheetTemplate = ({
  constructionSite,
  title = 'Werffiche',
  suggestedValues,
}: any) => (
  <Document>
    <Page size='A4' style={styles.page}>
      {/* Header repeated on every page */}
      <PageHeader constructionSite={constructionSite} title={title} />
      <View style={styles.pageHeader}>
        <View>
          <ConstructionSiteInfo constructionSite={constructionSite} />
        </View>
      </View>

      {/* ListContent */}
      <ListContent
        constructionSite={constructionSite}
        suggestedValues={suggestedValues}
      />

      {/* Footer repeated on every page */}
      <Text style={styles.now} fixed>
        {moment(new Date()).format('DD/MM/YYYY')}
      </Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Pagina ${pageNumber} van ${totalPages}`
        }
        fixed
      />
    </Page>
  </Document>
);

const PageHeader = ({ constructionSite }: any) => (
  <View style={styles.pageHeaderWrapper} fixed>
    <View style={styles.pageHeader}>
      <Image src={Logo} style={styles.image} />
      <View>
        <Text style={styles.pageHeaderTitle}>Werffiche</Text>
        <Text style={styles.pageHeaderName}>Werf: {constructionSite.name}</Text>
        {/* <Text style={styles.pageHeaderName}>
          Locatie: {constructionSite.city}
        </Text> */}
      </View>
    </View>
  </View>
);

const ConstructionSiteInfo = ({ constructionSite }: any) => (
  <View style={styles.constructionSiteAddress}>
    <View style={styles.constructionSiteInfoRow}>
      <View style={{ width: '45%' }}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>Werfadres:</Text>
          <Text>
            {constructionSite.address}
            {'\n'}
            {constructionSite.zip_code} {constructionSite.city}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>Naam:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {displayNameLead(constructionSite.lead)}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>Tel.:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {constructionSite.lead.mobile1_v2 || constructionSite.lead.mobile1}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>E-mail:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {constructionSite.lead.email1}
          </Text>
        </View>
      </View>
      <View
        style={{
          width: '55%',
          borderLeft: '1px solid black',
          paddingLeft: 10,
        }}
      >
        {constructionSite.sales_rep && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>Verkoper:</Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.sales_rep.first_name}{' '}
              {constructionSite.sales_rep.last_name}
            </Text>
          </View>
        )}

        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '43%', fontStyle: 'bold' }}>Architect:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {constructionSite.architect &&
              `${constructionSite.architect.first_name} ${constructionSite.architect.last_name}`}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '43%', fontStyle: 'bold' }}>Werfleider:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {constructionSite.site_manager &&
              `${constructionSite.site_manager.first_name} ${constructionSite.site_manager.last_name}`}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '43%', fontStyle: 'bold' }}>
            EPB verslaggever:
          </Text>
          <Text style={styles.constructionSiteInfoValue}>
            {constructionSite.epb_reporter &&
              `${constructionSite.epb_reporter.first_name} ${constructionSite.epb_reporter.last_name}`}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '43%', fontStyle: 'bold' }}>
            Veiligheidscoördinator:
          </Text>
          <Text style={styles.constructionSiteInfoValue}>
            {constructionSite.safety_coordinator &&
              `${constructionSite.safety_coordinator.first_name} ${constructionSite.safety_coordinator.last_name}`}
          </Text>
        </View>
      </View>
    </View>
  </View>
);

const ListContent = ({ constructionSite, suggestedValues }: any) => (
  <View style={styles.table}>
    {/* Table header repeated on every page */}
    <View style={styles.tableRowHeader} fixed>
      <Text style={styles.tableColDescription}>Omschrijving</Text>
      <Text style={styles.tableColOrderDate}>Besteld</Text>
      <Text style={styles.tableColResponsible}>Verant.</Text>
      <Text style={styles.tableColSupplier}>OA</Text>
      <Text style={styles.tableColDueDate}>Voorz.</Text>
      <Text style={styles.tableColExecutionDate}>Uitg.</Text>
      <Text style={styles.tableColRemarks}>Opmerkingen</Text>
    </View>

    {/* Table Rows */}
    {constructionSite.properties.map((property: IProperty, index: number) => (
      <ListContentItem
        property={property}
        index={index}
        suggestedValues={suggestedValues}
      />
    ))}
  </View>
);

const ListContentItem = ({ property, index, suggestedValues }: any) => (
  <View style={styles.tableRow} wrap={false}>
    <Text style={styles.tableColDescription}>{property.name}</Text>
    <ListContentItemDate
      style={styles.tableColOrderDate}
      value={property.order_date}
      format='DD/MM/YY'
    />
    <ListContentItemResponsible
      style={styles.tableColResponsible}
      property={property}
    />
    <ListContentItemSupplier
      style={styles.tableColSupplier}
      property={property}
    />
    <ListContentItemDate
      style={styles.tableColDueDate}
      value={property.due_date}
      format='DD/MM/YY'
    />
    <ListContentItemDate
      style={styles.tableColExecutionDate}
      value={property.execution_date}
      format='DD/MM/YY'
    />
    <Text style={[styles.tableColRemarks, styles.description]}>
      {ListContentItemRemarks(property, suggestedValues)}
    </Text>
  </View>
);

const ListContentItemRemarks = (
  property: IProperty,
  suggestedValues: ISuggestedValueCategory[],
) => {
  const result = property.value;

  switch (property.type.name) {
    case property_types.STRING:
      return property.value;
    case property_types.DATE: {
      if (result) {
        return moment(result).format('DD/MM/YYYY');
      }
      break;
    }
    case property_types.SUGGESTED_VALUE: {
      const category = suggestedValues.filter(
        (el: ISuggestedValueCategory) =>
          el.category_code === property.remote_category,
      );

      if (category && category.length === 1) {
        return `${
          category[0].category_description
        }, (${category[0].suggested_values
          ?.map((el: ISuggestedValue) => el.suggested_value)
          .join(', ')})`;
      }
      break;
    }
    case property_types.DOCUMENT: {
      return 'document{name} ({type})'
        .replace(
          '{name}',
          property.document && property.document.name
            ? ` ${property.document.name}`
            : '',
        )
        .replace(
          '{type}',
          property.document && property.document.is_external ? 'ext.' : 'int.',
        );
    }
    case property_types.PARENT: {
      if (property.children && property.children.length > 0) {
        return property.children
          .map((child: IProperty) => {
            switch (child.type.name) {
              case property_types.STRING: {
                return '{name}{value}'
                  .replace('{name}', child.name)
                  .replace('{value}', child.value ? ` ${child.value}` : '');
              }
              case property_types.DATE: {
                return '{name}{value}'
                  .replace('{name}', child.name)
                  .replace(
                    '{value}',
                    child.value
                      ? moment(child.value).format(' DD/MM/YYYY')
                      : '',
                  ); // keep the space!!!!
              }
              case property_types.SUGGESTED_VALUE: {
                const category = suggestedValues.filter(
                  (el: ISuggestedValueCategory) =>
                    el.category_code === child.remote_category,
                );

                if (category && category.length === 1) {
                  return `${
                    category[0].category_description
                  }, (${category[0].suggested_values
                    ?.map((el: ISuggestedValue) => {
                      if (
                        child.suggested_value &&
                        child.suggested_value.id &&
                        child.suggested_value.id === el.id
                      ) {
                        return `"${el.suggested_value}"`;
                      }
                      return el.suggested_value;
                    })
                    .join(', ')})`;
                }
                break;
              }
              case property_types.DOCUMENT: {
                return 'document{name} ({type})'
                  .replace(
                    '{name}',
                    child.document && child.document.name
                      ? ` ${child.document.name}`
                      : '',
                  )
                  .replace(
                    '{type}',
                    child.document && child.document.is_external
                      ? 'ext.'
                      : 'int.',
                  );
              }
              default:
                break;
            }

            return undefined;
          })
          .join(', ');
      }
      break;
    }
    default:
      break;
  }

  // if (property.children && property.children.length > 0) {
  //     property.children.forEach((child: IProperty) => {
  //         switch(child.type.name) {
  //             case property_types.STRING:
  //             case property_types.DATE:
  //                 result += ", {name}{value}".replace("{name}", child.name).replace("{value}", child.value ? " " + child.value : "");
  //                 break;
  //             case property_types.SUGGESTED_VALUE: {
  //                 const category = suggestedValues.filter((el: ISuggestedValueCategory) => el.category_code === child.remote_category);
  //                 if (category) {
  //                     result += ", " + child.name + ", " + category[0].category_description + ", (" + category[0].suggested_values!.map((el: ISuggestedValue) => {
  //                         return el.suggested_value;
  //                     }).join(", ") + ")";
  //                 }
  //                 break;
  //             };
  //             default:
  //                 break;
  //         }
  //     });
  // }

  return result;
};

const ListContentItemDate = ({ value, style, format = 'DD/MMM.' }: any) => (
  <Text style={style}>{value ? moment(value).format(format) : null}</Text>
);

const ListContentItemResponsible = ({ property, style }: any) => {
  if (property && property.employee && !property.sales_rep) {
    return (
      <Text style={style}>
        {property.employee.first_name} {property.employee.last_name ? `${property.employee.last_name[0]}.` : ''}
      </Text>
    );
  }
  if (property && !property.employee && property.sales_rep) {
    return (
      <Text style={style}>
        {property.sales_rep.first_name} {property.sales_rep.last_name}
      </Text>
    );
  }
  return <Text style={style}>&nbsp;</Text>;
};

const ListContentItemSupplier = ({ property, style }: any) => (
  <View style={style}>
    {property && property.suppliers && property.suppliers.length > 0
      ? property.suppliers.map((el: IPropertySupplier) => (
          // eslint-disable-next-line react/jsx-indent
          <Text>+ {el.supplier.name}</Text>
        ))
      : null}
  </View>
);

const styles = StyleSheet.create({
  backgroundAlt: {
    backgroundColor: '#ffffff',
  },
  backgroundDefault: {
    backgroundColor: '#D2D4D1',
  },
  constructionSiteInfoRow: {
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingTop: '3px',
    lineHeight: '120%',
  },
  constructionSiteAddress: {},
  constructionSiteInfoValue: {},
  image: {
    width: '100px',
    // height: '35px',
    objectFit: 'scale-down',
    marginRight: 15,
  },
  now: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    bottom: 30,
    left: 50,
    right: 0,
  },
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 75,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 95,
  },
  pageHeaderWrapper: {
    position: 'absolute',
    top: 45,
    left: 45,
    right: 45,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  pageHeaderName: {
    fontFamily: 'Segoe UI',
    fontSize: 13,
  },
  pageHeaderTitle: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
  },
  pageNumber: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 50,
    textAlign: 'right',
  },

  table: {
    display: 'flex',
    width: 'auto',
    paddingTop: '15px',
  },
  tableColDescription: {
    width: '20%',
    borderRight: '0.5px solid #d1d4d1',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '8px',
  },
  tableColDueDate: {
    width: '10%',
    borderRight: '0.5px solid #d1d4d1',
    borderLeft: '0.5px solid #d1d4d1',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '8px',
  },
  tableColOrderDate: {
    width: '10%',
    borderRight: '0.5px solid #d1d4d1',
    borderLeft: '0.5px solid #d1d4d1',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '8px',
  },
  tableColExecutionDate: {
    width: '10%',
    borderRight: '1px solid #d1d4d1',
    borderLeft: '0.5px solid #d1d4d1',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '8px',
  },
  tableColRemarks: {
    width: '20%',
    textOverflow: 'ellipsis',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '8px',
  },
  tableColResponsible: {
    width: '15%',
    borderRight: '0.5px solid #d1d4d1',
    borderLeft: '0.5px solid #d1d4d1',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '8px',
  },
  tableColSupplier: {
    width: '15%',
    borderRight: '0.5px solid #d1d4d1',
    borderLeft: '0.5px solid #d1d4d1',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '8px',
  },
  tableRow: {
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    borderColor: '#D2D4D1',
    borderBottomWidth: 1,
  },
  tableRowHeader: {
    backgroundColor: '#D2D4D1',
    borderColor: 'black',
    borderBottomWidth: 1,
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 9,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    height: '20px',
  },
});

export default ConstructionSheetTemplate;

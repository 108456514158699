/* eslint-disable no-param-reassign */
import { Icon } from '@fluentui/react';
import React, { useEffect } from 'react';

import { DatePickerNL } from '../../../components/parts';

const ConstructionSheetDatePicker = ({
  target,
  property,
  callBack,
  canEmpty,
}: any) => {
  const getValue = () => {
    switch (target) {
      case 'order_date':
        return property.order_date;
      case 'due_date':
        return property.due_date;
      case 'execution_date':
        return property.execution_date;
      default:
        return new Date();
    }
  };

  const updateValue = (newValue: Date | null) => {
    property.isDirty = true;
    switch (target) {
      case 'order_date':
        property.order_date = newValue;
        break;
      case 'due_date':
        property.due_date = newValue;
        break;
      case 'execution_date':
        property.execution_date = newValue;
        break;
      default:
        break;
    }
    callBack(property);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      <DatePickerNL value={getValue()} updateParent={updateValue} />
      {canEmpty && getValue() && (
        <Icon
          iconName='Cancel'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            updateValue(null);
          }}
        />
      )}
    </div>
  );
};

export default ConstructionSheetDatePicker;

import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import { IConstructionSite, IConstructionSiteCreateUpdate } from './ConstructionSite';
import { ICustomer } from './Customer';
import { ICustomerContractUpdateLineItem } from './CustomerContractUpdateLineItem';
import { ICustomerInvoice } from './CustomerInvoice';
import { ISalesRep } from './SalesRep';

export const GET_CUSTOMER_CONTRACT_UPDATES = gql`
  query getCustomerContractUpdates(
    $filter: CustomerContractUpdateFilterInput
    $take: Int
    $skip: Int
    $orderBy: CustomerContractUpdateOrderByInput
  ) {
    findManyCustomerContractUpdates(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      customer_invoice_id
      document_date
      document_no
      intro
      outro
      status
      summary
      customer_contract_update_line_items {
        id
        description
        units
        unit_price
        percentage_vat
        show_only_total
      }
      construction_site {
        id
        name
      }
    }
  }
`;

export const GET_CUSTOMER_CONTRACT_UPDATE_BY_ID = gql`
  query getCustomerContractUpdateById(
    $where: CustomerContractUpdateWhereInput
  ) {
    findOneCustomerContractUpdate(where: $where) {
      id
      customer_invoice_id
      document_date
      document_no
      intro
      outro
      status
      summary

      construction_site {
        id
        name
      }

      customer_contract_update_line_items {
        id
        description
        units
        unit_price
        percentage_vat
        show_only_total
      }
    }
  }
`;

export const ADD_CUSTOMER_CONTRACT_UPDATE = gql`
  mutation addCustomerContractUpdate(
    $data: CustomerContractUpdateCreateInput!
  ) {
    createCustomerContractUpdate(data: $data) {
      id
      customer_invoice_id
      document_date
      document_no
      intro
      outro
      status
      summary

      construction_site {
        id
        name
      }
    }
  }
`;

export const UPDATE_CUSTOMER_CONTRACT_UPDATE = gql`
  mutation updateCustomerContractUpdate(
    $id: Int!
    $data: CustomerContractUpdateUpdateInput
  ) {
    updateCustomerContractUpdate(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_CUSTOMER_CONTRACT_UPDATE = gql`
  mutation deleteCustomerContractUpdate(
    $where: CustomerContractUpdateWhereInput
  ) {
    deleteCustomerContractUpdate(where: $where) {
      id
    }
  }
`;

export interface ICustomerContractUpdate {
  customer_id?: number;
  customer_invoice_id?: number;
  document_date?: Date;
  document_no?: string;
  id?: number;
  intro?: string;
  outro?: string;
  status?: string;
  summary?: string;
  version?: number;

  connect?: boolean; // only frontend app
  disconnect?: boolean; // only frontend app

  construction_site?: IConstructionSite | IConstructionSiteCreateUpdate;
  // customer: ICustomer;
  customer_invoice?: ICustomerInvoice;
  customer_contract_update_line_items?: ICustomerContractUpdateLineItem[];
}

import { FormEvent, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
  Stack,
  StackItem,
  TextField,
  Toggle,
  TextStyles,
} from '@fluentui/react';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_EMPLOYEES, IEmployee } from '../../utils/Employee';
import useDebounce from '../../components/hooks/useDebounce';
import SalesRepsOverview from './components/SalesRepsOverview';

interface IPagedSalesRep {
  salesReps: IEmployee[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const SalesReps = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedSalesRep>({
    salesReps: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'first_name',
  });

  const [filter, setFilter] = useState('');
  const [active, setActive] = useState(true);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, salesReps: [], skip: 0 }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string, active: boolean) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [{ active }, { sales_rep: true }],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { first_name: { contains: filterArray[i] } },
          { last_name: { contains: filterArray[i] } },
          { function: { contains: filterArray[i] } },
          { city: { contains: filterArray[i] } },
          { address: { contains: filterArray[i] } },
        ],
      };

      const numericValue = parseInt(filterArray[i], 10);
      if (numericValue) filterValue.OR.push({ zip_code1: +numericValue });

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    loading,
    error,
    refetch: refetchSalesReps,
  } = useQuery(GET_EMPLOYEES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, active),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      if (x.findManyEmployees && x.findManyEmployees.length > 0) {
        setPagedState(prevState => ({
          ...prevState,
          salesReps: pagedState.salesReps.concat(x.findManyEmployees),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(x.findManyEmployees.length < default_page_size),
        }));
      } else if (x.findManyEmployees) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const onChangeFilterActive = () => {
    setActive(!active);
    clearSalesReps();
    refetchSalesReps();
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearSalesReps();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearSalesReps = () => {
    setPagedState(prevState => ({
      ...prevState,
      salesReps: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.salesReps.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Verkopers</h3>

      <Stack style={{ flexDirection: 'row' }}>
        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />

        <StackItem style={{ marginLeft: 70 }}>
          <Toggle
            label='In dienst?'
            defaultChecked={active}
            onText='In dienst'
            offText='Niet in dienst'
            onChange={onChangeFilterActive}
          />
        </StackItem>
      </Stack>

      <SalesRepsOverview
        salesReps={pagedState.salesReps}
        setSalesReps={setPagedState}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchSalesReps={refetchSalesReps}
        clearSalesReps={clearSalesReps}
      />
    </Stack>
  );
};

export default SalesReps;

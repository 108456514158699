import { gql } from '@apollo/client';

export const GET_STORAGE_TOKEN = gql`
  query getStorageToken {
    createStorageToken {
      accountName
      sasToken
      sasURL
    }
  }
`;

export interface IStorageToken {
  accountName: string;
  sasToken: string;
  sasURL: string;
}

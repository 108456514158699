import React, { FormEvent, useRef, useState } from 'react';
import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  Stack,
  TextField,
} from '@fluentui/react';
import {
  convertPropertyTypeToComboBoxOptions,
  stackTokens5,
  property_types,
} from '../../../utils';
import { ITemplateItem } from '../../../utils/TemplateItem';
import { ISuggestedValueCategory } from '../../../utils/SuggestedValueCategory';
import { IPropertyType } from '../../../utils/PropertyType';
import TemplateTextItem from './TemplateTextItem';
import TemplateDocumentItem from './TemplateDocumentItem';
import TemplateDateItem from './TemplateDateItem';
import TemplateSuggestedValueItem from './TemplateSuggestedValueItem';

type ITemplateItemChild = {
  updateItem: (newItem: ITemplateItem) => void;
  item: ITemplateItem;
  // hasNewItemName: boolean;
  suggestedValueCategories?: ISuggestedValueCategory[];
  propertyTypes?: IPropertyType[];
  templateID?: any;
};

const TemplateItemChild = ({
  templateID,
  updateItem,
  item,
  // hasNewItemName,
  suggestedValueCategories,
  propertyTypes,
}: ITemplateItemChild) => {
  const getPropertyType = (id: number | string): IPropertyType | undefined => {
    if (id && propertyTypes) {
      return propertyTypes.filter((el: IPropertyType) => el.id === id)[0];
    }
    return undefined;
  };

  const updateType = (type: IPropertyType) => {
    const currentItem = { ...item };
    if (currentItem && type) {
      currentItem.type = type;

      updateItem(currentItem);
    }
  };

  return (
    <Stack>
      <Stack.Item>
        {item && !item.id ? (
          <ComboBox
            selectedKey={item.type && item.type.id ? item.type.id : undefined}
            label='Type'
            allowFreeform
            autoComplete='on'
            onChange={(
              event: FormEvent<IComboBox>,
              option?: IComboBoxOption,
              //   index?: number,
              //   value?: string,
            ) => {
              if (option) {
                // setSelectedKey(option.key);
                const type = getPropertyType(option.key);
                if (type) updateType(type);
              }
            }}
            options={convertPropertyTypeToComboBoxOptions(
              propertyTypes
                ? propertyTypes.filter((item: IPropertyType) => {
                  if (item.id === 14 || item.id === 8 || item.id === 15) {
                    return false;
                  }
                  return true;
                })
                : [],
            )}
            required
          />
        ) : (
          <Stack>
            <span
              style={{
                fontWeight: 600,
                paddingTop: '10px',
                paddingBottom: '5px',
              }}
            >
              Type
            </span>
            {item.type.label}
          </Stack>
        )}
      </Stack.Item>
      <Stack.Item>
        <TextField
          label='Naam'
          placeholder='Voer een naam in'
          value={item.name}
          onChange={(
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            const newItem = { ...item };
            newItem.name = newValue || '';

            updateItem(newItem);
          }}
        />
      </Stack.Item>
      <Stack.Item style={{ marginTop: '10px' }}>
        {item && item.type.name === property_types.STRING && (

          <TemplateTextItem
            key={item.type.id}
            item={item}
            callBack={updateItem}
          />
        )}
        {item && item.type.name === property_types.NOTE && (
          <TemplateTextItem
            key={item.type.id}
            item={item}
            callBack={updateItem}
          />
        )}
        {item && item.type.name === property_types.DOCUMENT && (
          <TemplateDocumentItem
            key={item.type.id}
            item={item}
            callBack={updateItem}
            type={item.type}
            // hasNewItemName={hasNewItemName}
            template={templateID}
          />
        )}
        {item && item.type.name === property_types.DATE && (
          <TemplateDateItem
            key={item.type.id}
            item={item}
            callBack={updateItem}
            type={item.type}
            // hasNewItemName={hasNewItemName}
          />
        )}
        {item && item.type.name === property_types.SUGGESTED_VALUE && (
          <TemplateSuggestedValueItem
            key={item.type.id}
            item={item}
            callBack={updateItem}
            type={item.type}
            categories={suggestedValueCategories}
            // hasNewItemName={hasNewItemName}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default TemplateItemChild;

import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  DefaultButton,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useBoolean } from '@fluentui/react-hooks';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import _ from 'lodash';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_EMPLOYEE_BY_ID,
  IEmployee,
  displayNameEmployee,
  GET_EMPLOYEES,
} from '../../../utils/Employee';
import { CREATE_USER } from '../../../utils';
import { assignRoles, IAppUserRole } from '../../../utils/AppUserRole';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { throwError } from '../../../redux/error/errorSlice';
import AppUserModal from './AppUserModal';
import EmployeeDetail from './EmployeeDetail';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  employees: IEmployee[],
  roles: IAppUserRole[],
  // filter,
  // filterChange?: (filter: string) => void,
  setSorting: (isSortedAsc: boolean, sortedField: string) => void,
  isSortedAsc?: boolean,
  sortedField?: string,
  showLoadMore?: boolean,
  listFilters: any,
}

const EmployeesOverview = ({
  employees,
  roles,
  loading,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  listFilters,
}: Props) => {
  // COMPONENT STATE
  const dispatch = useAppDispatch();
  const [announcedMessage] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(!loading);
  const [showWarning, setShowWarning] = useState(false);

  const [employee, setEmployee] = useState<IEmployee | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  // QUERIES AND MUTATIONS
  const [addEmployee] = useMutation(ADD_EMPLOYEE, {
    onError: error => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveEmployee',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // ADD ITEM TO LIST IN CACHE, BUG -> MERGE DOES NOT REPLACE THE INCOMMING 'UPDATED' LIST WITH EXISTING LIST. (apollo cache in app.tsx);
    /* fetchPolicy: 'no-cache',
    update(cache, { data }) {
      const createdEmployee = data.createEmployee;
      const existingEmployees = cache.readQuery<{
        findManyEmployees: IEmployee[];
      }>({
        query: GET_EMPLOYEES,
        variables: listFilters,
      });

      let findManyEmployees = existingEmployees
        ? _.cloneDeep(existingEmployees?.findManyEmployees)
        : null;
      if (findManyEmployees && createdEmployee) {
        findManyEmployees = [createdEmployee, ...findManyEmployees];
        cache.writeQuery({
          query: GET_EMPLOYEES,
          variables: listFilters,
          data: {
            findManyEmployees,
          },
        });
      }
    }, */
  });

  const [modifyEmployee] = useMutation(UPDATE_EMPLOYEE, {
    onError: error => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveEmployee',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // no need for writeQuery since apollo updates cache automatically when executing a update mutation
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    IEmployee | undefined
  >();

  const getEmployee = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getEmployee,
  });

  const { refetch: refetchEmployee } = useQuery(GET_EMPLOYEE_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      const employee: IEmployee = data.findOneEmployee;

      if (employee.app_user) {
        const appUserRoles: IAppUserRole[] = [];

        roles.forEach(role => {
          const appUserRole = role;

          if (employee.app_user?.app_user_roles) {
            employee.app_user.app_user_roles.forEach(app_user_role => {
              if (role.id === app_user_role.id) {
                appUserRole.checked = true;
              }
            });
          }

          appUserRoles.push(role);
        });

        const expandedEmployee: IEmployee = {
          ...employee,
          app_user: {
            id: employee.app_user.id,
            email: employee.app_user.email,
            auth0_id: employee.app_user.auth0_id,
            app_user_roles: appUserRoles,
            account_locked: employee.app_user.account_locked,
          },
        };
        setEmployee(expandedEmployee);
      } else {
        setEmployee(employee);
      }
    },
  });

  const openEmployeeDetail = (newEmployee?: boolean) => {
    if (newEmployee) {
      setEmployee(undefined);
    }
    setIsPanelOpen(true);
  };

  // App user modal
  const [modalUser, setModalUser] = useState<IEmployee | undefined>(undefined);
  const [hideModal, { toggle: toggleHideModal }] = useBoolean(true);

  const [createUser, { data: newUserData, loading: newUserLoading }] =
    useMutation(CREATE_USER, {
      onError: error => {
        dispatch(dismissNotification());

        dispatch(
          throwError({
            module: 'executionList.saveEmployee',
            message: error.message,
            level: SeverityLevel.Critical,
          }),
        );
      },
      update(cache, { data }, { variables }) {
        // We use an update function here to write the
        // new value of the GET_ALL_TODOS query.
        const employee_id = variables?.data.employee_id;
        const updatedAppUser = data.createAppUser;

        const existingEmployees = cache.readQuery<{
          findManyEmployees: IEmployee[];
        }>({
          query: GET_EMPLOYEES,
          variables: listFilters,
        });

        const findManyEmployees = existingEmployees
          ? _.cloneDeep(existingEmployees?.findManyEmployees)
          : null;
        if (findManyEmployees && employee_id && updatedAppUser) {
          for (let i = 0; i < findManyEmployees.length; i++) {
            if (findManyEmployees[i].id === employee_id) {
              findManyEmployees[i] = {
                ...findManyEmployees[i], // add app_user to employee.
                app_user: {
                  ...updatedAppUser,
                },
              };
            }
          }
          cache.writeQuery({
            query: GET_EMPLOYEES,
            // variables: listFilters,
            data: {
              findManyEmployees,
            },
          });
        }
      },
    });

  const openNewUserModal = (employee: IEmployee) => {
    setModalUser(employee);
    toggleHideModal();
  };

  const getConnectDisconnect = (app_user_roles: IAppUserRole[]) => {
    const connect: any[] = [];
    const disconnect: any[] = [];

    app_user_roles.forEach(app_user_role => {
      if (app_user_role.checked) {
        connect.push({
          id: app_user_role.id,
        });
      } else {
        disconnect.push({
          id: app_user_role.id,
        });
      }
    });

    return { connect, disconnect };
  };

  // Save employee
  const saveEmployee = () => {
    if (employee) {
      const app_user_roles: IAppUserRole[] = employee.app_user ? employee.app_user.app_user_roles : [];

      const { connect, disconnect } = getConnectDisconnect(app_user_roles);

      const allInput = {
        first_name: employee.first_name,
        last_name: employee.last_name,
        address: employee.address,
        app_user: employee.app_user ? {
          id: employee.app_user.id,
          account_locked: employee.app_user.account_locked,
          auth0_id: employee.app_user.auth0_id,
          dirty: employee.app_user.dirty,
          app_user_roles: {
            connect,
            disconnect,
          },
          email: employee.app_user.email,
        } : undefined,
        city: employee.city,
        zip_code: employee.zip_code,
        email: employee.email,
        active: employee.id ? employee.active : true,
        function: employee.function,
        mobile_v2: employee.mobile_v2,
        private_mobile_v2: employee.private_mobile_v2,
        phone_v2: employee.phone_v2,
        birthday: employee.birthday,
        iban: employee.iban,
        comments: employee.comments,
        diploma: employee.diploma,
        start_date: employee.start_date,
        end_date: employee.end_date,
        hourly_rate: employee.hourly_rate,
        hourly_rate_for_calculation: employee.hourly_rate_for_calculation
          ? +employee.hourly_rate_for_calculation
          : 0,
        monthly_rate: employee.monthly_rate || 0,
        driver: employee.driver,
        customer_manager: employee.customer_manager,
        construction_site_manager: employee.construction_site_manager,
        passport_no: employee.passport_no,
        sis_no: employee.sis_no,
        employment_method: employee.employment_method,
        category: employee.category,
        name_partner: employee.name_partner,
        mobile_partner_v2: employee.mobile_partner_v2,
        birthday_partner: employee.birthday_partner,
        children: employee.children ? +employee.children : null,
        jacket_size: employee.jacket_size,
        pants_size: employee.pants_size,
        shirt_size: employee.shirt_size,
        shoe_size: employee.shoe_size,
        sweater_size: employee.sweater_size,
        sales_rep: employee.sales_rep,
        epb_reporter: employee.epb_reporter,
        safety_coordinator: employee.safety_coordinator,
      };

      if (employee.id) {
        modifyEmployee({
          variables: {
            id: employee.id,
            data: allInput,
          },
        }).then(() => {
          // clearEmployees();
          // refetchEmployees();
          setIsPanelOpen(false);
        });
      } else {
        addEmployee({
          variables: {
            data: allInput,
          },
        }).then(x => {
          setEmployee(x.data.createEmployee);
          selection.setItems(x.data.createEmployee);
        });
      }
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Achternaam',
      fieldName: 'last_name',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (employee: IEmployee) => <span>{employee.last_name}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Voornaam',
      fieldName: 'first_name',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (employee: IEmployee) => <span>{employee.first_name}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Functie',
      fieldName: 'function',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (employee: IEmployee) => <span>{employee.function}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'GSM Werk',
      fieldName: 'mobile',
      minWidth: 130,
      maxWidth: 130,
      isRowHeader: true,
      onRender: (employee: IEmployee) => <span>{employee.mobile_v2}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'GSM Privé',
      fieldName: 'private_mobile',
      minWidth: 130,
      maxWidth: 130,
      isRowHeader: true,
      onRender: (employee: IEmployee) => (
        <span>{employee.private_mobile_v2}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Actief',
      fieldName: 'active',
      minWidth: 30,
      maxWidth: 30,
      isRowHeader: true,
      onRender: (employee: IEmployee) => (
        <span>
          <Checkbox disabled checked={!!employee.active} />
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'App User',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (employee: IEmployee) => {
        if (employee && employee.app_user && employee.app_user.auth0_id) {
          return <div />;
        }
        return (
          <DefaultButton
            text='Gebruiker toevoegen'
            onClick={e => {
              e.preventDefault();
              openNewUserModal(employee);
            }}
          />
        );
      },
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openEmployeeDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openEmployeeDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        // loadMoreCallback(); triggers overload
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (employee && isPanelOpen) {
      document.title = `3bouw | Medewerker - ${displayNameEmployee(employee)}`;
    } else {
      document.title = '3bouw | Medewerkers';
    }
  }, [employee, isPanelOpen]);

  return (
    employees && (
      <>
        <AppUserModal
          hideModal={hideModal}
          toggleHideModal={toggleHideModal}
          modalUser={modalUser}
          newUserLoading={newUserLoading}
          newUserData={newUserData}
          createUser={createUser}
        />

        <CommandBarSticky
          items={commandBaritems}
          theme={commandBarTheme}
          width='1200px'
          maxWidth='1200px'
        />

        <EmployeeDetail
          isOpen={isPanelOpen}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          employee={employee || {}}
          saveEmployee={saveEmployee}
          setEmployee={setEmployee}
          refetchEmployee={refetchEmployee}
          openNewUserModal={openNewUserModal}
        />

        <ShimmeredDetailsList
          items={employees}
          columns={columns}
          getKey={getKey}
          enableShimmer={loading} // && initial
          ariaLabelForShimmer='Content is being fetched'
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible
          selection={selection}
          selectionMode={SelectionMode.single}
          selectionPreservedOnEmptyClick
        />

        {!loading && !showLoadMore && employees.length === 0 && (
          <div
            style={{
              textAlign: 'center',
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Geen resultaten
          </div>
        )}
        {showLoadMore && (
          <Stack
            style={{
              marginTop: '15px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            horizontal
            horizontalAlign='center'
          >
            <PrimaryButton text='Toon meer' onClick={() => loadMoreCallback()} />
          </Stack>
        )}
        <Stack style={{ minHeight: '50px' }} />
      </>
    )
  );
};

export default EmployeesOverview;

import { IComboBoxOption } from '@fluentui/react';
import { gql } from '@apollo/client';

export const GET_MEASURING_UNITS = gql`
  query getMeasuringUnits($orderBy: MeasuringUnitOrderByInput) {
    findManyMeasuringUnits(orderBy: $orderBy) {
      id
      name
    }
  }
`;

export const convertMeasuringUnitsToComboBoxOptions = (
  measuringUnits: IMeasuringUnit[],
) => {
  const result: IComboBoxOption[] = measuringUnits.map(
    (measuringUnit: IMeasuringUnit): IComboBoxOption => ({
      key: measuringUnit.id,
      text: measuringUnit.name,
    }),
  );
  return result;
};

export interface IMeasuringUnit {
  id: number;
  name: string;
}

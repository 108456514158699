import React, { useRef, useState } from 'react';
import {
  Icon,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
} from '@fluentui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import {
  GET_STORAGE_TOKEN,
  stackTokens10,
  stackTokens15,
  stackTokens5,
} from '../../utils';
import BlobStorage from './BlobStorage';

interface IMetaData {
  [key: string]: string;
}

const UploadFile = ({
  container = process.env.NODE_ENV === 'production' ? 'prod' : 'temp',
  updateName,
  templateID = undefined,
  constructionSiteID = undefined,
  compact = false,
}: any) => {
  const { isAuthenticated, user } = useAuth0(); // Do we need this check since it is already builtin the routing mechanism?
  const [acquire_token, { called, loading, data }] = useLazyQuery(
    GET_STORAGE_TOKEN,
    {
      fetchPolicy: 'no-cache', // Force not to cache this query
      onCompleted: () => handleFileUpload(),
    },
  );

  const handleFileUpload = () => {
    const fileStore = new BlobStorage(
      data.createStorageToken.accountName,
      data.createStorageToken.sasToken,
    );

    const metaData: IMetaData = {};

    if (templateID) metaData.template = String(templateID);
    if (constructionSiteID) metaData.constructionSite = String(constructionSiteID);
    if (user) metaData.user = JSON.stringify(user);

    if (selectedFile) {
      const fileName = `${moment().format('YYYMMDD_HHmm')}_${selectedFile.name}`;
      selectedFile.arrayBuffer().then((x: any) => {
        fileStore.createContainer(container).then(() => {
          fileStore
            .uploadBlob(container, fileName, x, x.byteLength, {
              metadata: metaData,
            })
            .then(() => {
              updateName(fileName, selectedFile.name);
              setStatus('uploaded');
              setTimeout(resetStatus, 5000);
            });
        });
      });
    }
  };

  const fileInput = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [status, setStatus] = useState<'init' | 'uploading' | 'uploaded'>(
    'init',
  );
  const resetStatus = () => {
    setStatus('init');
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (called && loading) return <p>Laden...</p>;

  return (
    <Stack tokens={stackTokens5}>
      {compact && (
        <StackItem>
          <Label>Upload bestand</Label>
        </StackItem>
      )}
      <Stack tokens={stackTokens10}>
        <StackItem>
          <input
            ref={fileInput}
            type='file'
            accept='*'
            name='KiesBestand'
            onChange={() => {
              setSelectedFile(fileInput.current?.files?.[0]);
              setStatus('uploading');
              acquire_token();
            }}
            disabled={loading}
          />
        </StackItem>
      </Stack>
    </Stack>
  );
};

export default UploadFile;

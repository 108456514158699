import React from 'react';
import {
  TextField,
  DatePicker,
  mergeStyles,
  defaultDatePickerStrings,
  Stack,
  DayOfWeek,
} from '@fluentui/react';
import moment from 'moment';
import { ITemplateItem } from '../../../utils/TemplateItem';
import { IPropertyType } from '../../../utils/PropertyType';

const onFormatDate = (date?: Date): string => (!date ? '' : moment(date).format('DD/MM/YYYY'));

function TemplateDateItem({
  item,
  callBack,
  type,
  // hasNewItemName = false,
}: {
  item: ITemplateItem;
  callBack: any;
  type: IPropertyType;
  // hasNewItemName: boolean;
}) {
  const name = item ? item.name : '';

  const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Monday);

  const setDate = (date: Date | null | undefined) => {
    const newItem = { ...item };

    newItem.dirty = true;
    newItem.value = moment(date).toISOString();

    callBack(newItem);
  };

  return (
    <Stack>
      <DatePicker
        firstDayOfWeek={firstDayOfWeek}
        placeholder='Selecteer een datum...'
        ariaLabel='Selecteer een datum'
        strings={defaultDatePickerStrings}
        formatDate={onFormatDate}
        onSelectDate={setDate}
        value={item.value ? moment(item.value).toDate() : undefined}
      />
    </Stack>
  );
}

export default TemplateDateItem;

import { gql } from '@apollo/client';
import { ICar } from './Car';
import { IEmployee } from './Employee';

export const GET_CAR_DRIVERS = gql`
  query getCarDrivers(
    $filter: CarDriverFilterInput
    $take: Int
    $skip: Int
    $orderBy: CarDriverOrderByInput
  ) {
    findManyCarDrivers(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
    }
  }
`;

export const GET_CAR_DRIVER_BY_ID = gql`
  query getCarDriverById($where: CarDriverWhereInput) {
    findOneCarDriver(where: $where) {
      id
      start_date
      end_date
      current_driver
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const ADD_CAR_DRIVER = gql`
  mutation addCarDriver($data: CarDriverCreateInput!) {
    createCarDriver(data: $data) {
      id
      start_date
      end_date
      current_driver
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const UPDATE_CAR_DRIVER = gql`
  mutation updateCarDriver($id: Int!, $data: CarDriverUpdateInput) {
    updateCarDriver(id: $id, data: $data) {
      id
      start_date
      end_date
      current_driver
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const DELETE_CAR_DRIVER = gql`
  mutation deleteCarDriver($where: CarDriverWhereInput) {
    deleteCarDriver(where: $where) {
      id
    }
  }
`;

export interface ICarDriver {
  id: number;
  current_driver: boolean;
  end_date: Date;
  start_date: Date;
  car: ICar;
  employee: IEmployee;
}

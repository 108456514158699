import React, { useState } from 'react';
import {
  DefaultButton,
  Icon,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import SuggestedView from '../../../components/parts/SuggestedView';
import { iconProps, modalContentStyles } from '../../../utils';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { getDefaultContactPerson } from '../../../utils/ContactPerson';

const SupplierDetail = ({
  isOpen,
  dismissPanel,
  saveSupplier,
  supplier,
  setSupplier,
}: any) => {
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const addContactPerson = () => {
    const defaultContactPerson = getDefaultContactPerson();
    const currentSupplier = _.cloneDeep(supplier);
    if (currentSupplier.contact_persons) {
      currentSupplier.contact_persons.push(defaultContactPerson);
    } else {
      currentSupplier.contact_persons = [defaultContactPerson];
    }
    setSupplier(currentSupplier);
  };

  const toggleDeleteContactPerson = (index: number) => {
    const contactPersons = supplier.contact_persons;
    contactPersons[index] = {
      ...contactPersons[index],
      delete: !contactPersons[index].delete,
    };
    setSupplier((prevState: any) => ({
      ...prevState,
      contact_persons: contactPersons,
    }));
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setSupplier((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const onChangeContactTextFieldValue = (
    index: number,
    event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
    fieldName?: string,
  ) => {
    if (supplier) {
      const contactPersons = supplier.contact_persons;
      if (fieldName) {
        contactPersons[index] = {
          ...contactPersons[index],
          [fieldName]: newValue || '',
          dirty: true,
        };
      } else if (event) {
        contactPersons[index] = {
          ...contactPersons[index],
          [(event.target as HTMLTextAreaElement).name]: newValue || '',
          dirty: true,
        };
      }

      setSupplier((prevState: any) => ({
        ...prevState,
        contact_persons: contactPersons,
      }));
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        supplier && supplier.id
          ? 'Leverancier wijzigen'
          : 'Leverancier toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='name'
                label='Naam'
                value={supplier && supplier.name ? supplier.name : ''}
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  supplier && !supplier.name ? 'Dit veld is verplicht' : ''
                }
              />
            </Stack>

            <Stack style={{ marginBottom: 5 }}>
              <TextField
                name='address'
                label='Adres'
                value={supplier && supplier.address ? supplier.address : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>

            <Stack
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <StackItem style={{ width: '25%' }}>
                <TextField
                  name='zip_code'
                  placeholder='Postcode'
                  value={supplier && supplier.zip_code ? supplier.zip_code : ''}
                  onChange={onChangeTextFieldValue}
                />
              </StackItem>
              <StackItem style={{ width: '74%' }}>
                <TextField
                  name='city'
                  placeholder='Gemeente'
                  value={supplier && supplier.city ? supplier.city : ''}
                  onChange={onChangeTextFieldValue}
                />
              </StackItem>
            </Stack>

            <Stack
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <StackItem style={{ width: '49.5%' }}>
                <Label>Telefoon</Label>
                <PhoneInput
                  country='be'
                  preferredCountries={['be']}
                  value={supplier && supplier.phone_v2}
                  onChange={phone =>
                    setSupplier((prevState: any) => ({
                      ...prevState,
                      phone_v2: phone,
                    }))
                  }
                  inputStyle={{
                    width: '100%',
                    border: '1px solid rgb(96, 94, 92)',
                    borderRadius: '2px',
                  }}
                  buttonStyle={{
                    border: '1px solid rgb(96, 94, 92)',
                    borderRadius: '2px',
                  }}
                />
                {supplier && !supplier.phone_v2 && supplier.phone && (
                  <div>{supplier.phone}</div>
                )}
              </StackItem>
              <StackItem style={{ width: '49.5%' }}>
                <Label>GSM</Label>
                <PhoneInput
                  country='be'
                  preferredCountries={['be']}
                  value={supplier && supplier.mobile_v2}
                  onChange={phone =>
                    setSupplier((prevState: any) => ({
                      ...prevState,
                      mobile_v2: phone,
                    }))
                  }
                  inputStyle={{
                    width: '100%',
                    border: '1px solid rgb(96, 94, 92)',
                    borderRadius: '2px',
                  }}
                  buttonStyle={{
                    border: '1px solid rgb(96, 94, 92)',
                    borderRadius: '2px',
                  }}
                />
                {supplier && !supplier.mobile_v2 && supplier.mobile && (
                  <div>{supplier.mobile}</div>
                )}
              </StackItem>
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='vat_code'
                label='BTW nummer'
                value={supplier && supplier.vat_code ? supplier.vat_code : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='iban'
                label='Bankrekeningnummer (IBAN)'
                value={supplier && supplier.iban ? supplier.iban : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='bic'
                label='BIC-code'
                value={supplier && supplier.bic ? supplier.bic : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <SuggestedView
                item={supplier}
                setItem={setSupplier}
                prefix='sector'
                code={
                  supplier &&
                  supplier.sector &&
                  supplier.sector.substr(supplier.sector.length - 4)
                }
                label='Sector'
                field='sector'
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <Label style={{ marginBottom: -8 }}>Contactpersonen</Label>
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                {supplier &&
                  supplier.contact_persons &&
                  supplier.contact_persons.map(
                    (contact_person: any, i: number) => (
                      <AccordionItem
                        key={contact_person.id || `temp${i.toString()}`}
                        id={contact_person.id || `temp${i.toString()}`}
                        title={
                          <Stack horizontal>
                            <Stack.Item
                              style={{
                                textDecoration: contact_person.delete
                                  ? 'line-through'
                                  : undefined,
                                marginRight: '10px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '200px',
                              }}
                            >
                              {contact_person.draft && (
                                <Icon iconName='LocationDot' />
                              )}
                              {`${contact_person.first_name} ${contact_person.last_name}`}
                            </Stack.Item>
                            <Stack.Item>
                              <Icon
                                iconName={
                                  contact_person.delete ? 'Refresh' : 'Delete'
                                }
                                styles={iconProps}
                                onClick={() => {
                                  toggleDeleteContactPerson(i);
                                }}
                              />
                            </Stack.Item>
                          </Stack>
                        }
                      >
                        <Stack style={{ marginBottom: 10 }}>
                          <TextField
                            name='first_name'
                            label='Voornaam'
                            value={
                              contact_person && contact_person.first_name
                                ? contact_person.first_name
                                : ''
                            }
                            onChange={(
                              event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string,
                            ) =>
                              onChangeContactTextFieldValue(i, event, newValue)
                            }
                          />
                          <TextField
                            name='last_name'
                            label='Achternaam'
                            value={
                              contact_person && contact_person.last_name
                                ? contact_person.last_name
                                : ''
                            }
                            onChange={(
                              event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string,
                            ) =>
                              onChangeContactTextFieldValue(i, event, newValue)
                            }
                          />
                          <TextField
                            name='email'
                            label='E-mailadres'
                            value={
                              contact_person && contact_person.email
                                ? contact_person.email
                                : ''
                            }
                            onChange={(
                              event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string,
                            ) =>
                              onChangeContactTextFieldValue(i, event, newValue)
                            }
                          />
                          <Label>Telefoon</Label>
                          <PhoneInput
                            country='be'
                            preferredCountries={['be']}
                            value={contact_person && contact_person.phone_v2}
                            onChange={phone =>
                              onChangeContactTextFieldValue(
                                i,
                                undefined,
                                phone,
                                'phone_v2',
                              )
                            }
                            inputStyle={{
                              width: '100%',
                              border: '1px solid rgb(96, 94, 92)',
                              borderRadius: '2px',
                            }}
                            buttonStyle={{
                              border: '1px solid rgb(96, 94, 92)',
                              borderRadius: '2px',
                            }}
                          />
                          {contact_person &&
                            !contact_person.phone_v2 &&
                            contact_person.phone && (
                              <div>{contact_person.phone}</div>
                            )}
                          <Label>GSM</Label>
                          <PhoneInput
                            country='be'
                            preferredCountries={['be']}
                            value={contact_person && contact_person.mobile_v2}
                            onChange={phone =>
                              onChangeContactTextFieldValue(
                                i,
                                undefined,
                                phone,
                                'mobile_v2',
                              )
                            }
                            inputStyle={{
                              width: '100%',
                              border: '1px solid rgb(96, 94, 92)',
                              borderRadius: '2px',
                            }}
                            buttonStyle={{
                              border: '1px solid rgb(96, 94, 92)',
                              borderRadius: '2px',
                            }}
                          />
                          {contact_person &&
                            !contact_person.mobile_v2 &&
                            contact_person.mobile && (
                              <div>{contact_person.mobile}</div>
                            )}
                          <TextField
                            name='notes'
                            multiline
                            label='Extra informatie'
                            value={
                              contact_person && contact_person.notes
                                ? contact_person.notes
                                : ''
                            }
                            onChange={(
                              event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string,
                            ) =>
                              onChangeContactTextFieldValue(i, event, newValue)
                            }
                          />
                        </Stack>
                        {/* Contact (contact, email, email opt out, phone, fax,
                        mobile, website) */}
                      </AccordionItem>
                    ),
                  )}
              </Accordion>
              <Stack.Item style={{ marginBottom: '10px', marginTop: '10px' }}>
                <PrimaryButton
                  text='Voeg een contactpersoon toe'
                  disabled={!supplier || (supplier && !supplier.id)}
                  onClick={addContactPerson}
                />
                {((supplier && !supplier.id) || !supplier) && (
                  <div>Na opslaan kan je contactpersonen toevoegen.</div>
                )}
              </Stack.Item>
            </Stack>

            <Stack
              style={{
                marginBottom: 10,
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}
            >
              <StackItem style={{ width: '50%' }}>
                <TextField
                  name='payment_terms'
                  label='Betalingstermijn'
                  type='number'
                  value={
                    supplier && supplier.payment_terms
                      ? supplier.payment_terms
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </StackItem>
              <StackItem
                style={{ marginBottom: 7, marginLeft: 8, fontWeight: 600 }}
              >
                dagen
              </StackItem>
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='wings_code'
                label='Wings code'
                type='number'
                value={
                  supplier && supplier.wings_code ? supplier.wings_code : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='comments'
                multiline
                label='Extra informatie'
                value={supplier && supplier.comments ? supplier.comments : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                onClick={saveSupplier}
                style={{ marginRight: 10 }}
                disabled={!supplier || (supplier && !supplier.name)}
              >
                Opslaan
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default SupplierDetail;

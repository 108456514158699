import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TextField } from '@fluentui/react';
import moment from 'moment';

interface ISuggestedValueView {
  item: any;
  setItem: any;
  label: string;
  field: string;
  date?: string | number | Date;
  onChangeDate?: any;
  required?: boolean;
}

const DateView = ({
  item,
  setItem,
  label,
  field,
  date,
  onChangeDate,
  required,
}: ISuggestedValueView) => {
  const { isAuthenticated } = useAuth0();
  const [formattedDate, setFormattedDate] = useState<string>();

  useEffect(() => {
    if (item && date) {
      setFormattedDate(moment(new Date(date)).format('YYYY-MM-DD'));
    }
  }, [item]);

  if (!isAuthenticated) return <p>Verboden</p>;

  const updateDate = (newValue: any) => {
    let result = { ...item };
    result.isDirty = true;

    setFormattedDate(newValue.target.value);

    result = {
      ...result,
      [field]: moment(newValue.target.value).toISOString(),
    };

    if (onChangeDate) {
      onChangeDate();
    }

    setItem(result);
    return result;
  };

  return (
    <TextField
      name={field}
      label={label}
      type='date'
      value={date ? formattedDate && formattedDate : undefined}
      onChange={updateDate}
      required={required}
    />
  );
};

export default DateView;

import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Selection, Stack, PrimaryButton } from '@fluentui/react';
import {
  overviewClassNames as classNames,
  DELETE_CONSTRUCTION_SITE,
  IConstructionSite,
} from '../../../utils';

import { IConstructionSiteCreateUpdate } from '../../../utils/ConstructionSite';
import ConstructionSitesMessageBar from './ConstructionSitesMessageBar';
import ConstructionSitesDetailsList from './ConstructionSitesDetailsList';
import ConstructionSitesCommandBar from './ConstructionSitesCommandBar';
import ConstructionSiteDetail from './ConstructionSiteDetail';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  constructionSites: IConstructionSite[];
  // filter,
  // filterChange?: (filter: string) => void,
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
  listFilters: any;
}

const ConstructionSitesOverview = ({
  constructionSites,
  // filter,
  loading,
  // filterChange = (filter: string) => {},
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  listFilters,
}: Props) => {
  // States
  const [announcedMessage] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(!loading);
  const [showWarning, setShowWarning] = useState(false);

  const [openPanel, setOpenPanel] = useState(false);
  const [constructionSite, setConstructionSite] = useState<
    IConstructionSiteCreateUpdate | undefined
  >(undefined);

  const { id: constructionSiteId } = useParams();

  // Queries
  const [deleteConstructionSite] = useMutation(DELETE_CONSTRUCTION_SITE);

  // Selection
  /* const [constructionSite, setconstructionSite] = useState<
    IConstructionSite | undefined
  >(); */

  const getconstructionSite = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setConstructionSite(currentSelection[0]);
    } else {
      setConstructionSite(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getconstructionSite,
  });

  const openDetail = (newSite?: boolean) => {
    if (newSite) {
      setConstructionSite({});
    }
    setOpenPanel(true);
  };

  // Const
  const navigate = useNavigate();

  const gotoExecutionList = (id: number) => {
    navigate(`/construction-sites/${id}/implementation-list`);
  };
  const gotoConstructionSheet = (id: number) => {
    navigate(`/construction-sites/${id}/site-overview`);
  };
  const gotoAddPurchaseOrder = (id: number) => {
    navigate(`/purchase-order/add?construction-site=${id}`);
  };

  const removeConstructionSite = () => {
    setIsDataLoaded(false);

    const sitesToBeDeleted = constructionSite;
    if (sitesToBeDeleted) {
      deleteConstructionSite({
        variables: {
          where: {
            id: sitesToBeDeleted.id,
          },
        },
      }).then(() => {
        // clearConstructionSites();
        // refetchConstructionSites();
        setIsDataLoaded(true);
        setShowWarning(false);
      });
    }
  };

  const loadExecutionList = () => {
    if (constructionSite && constructionSite.id) {
      gotoExecutionList(constructionSite.id);
    }
  };

  const loadConstructionSheet = () => {
    if (constructionSite && constructionSite.id) {
      gotoConstructionSheet(constructionSite.id);
    }
  };

  const loadNewPurchaseOrder = () => {
    if (constructionSite && constructionSite.id) {
      gotoAddPurchaseOrder(constructionSite.id);
    }
  };

  const dismissPanel = () => {
    setOpenPanel(false);
  };

  useEffect(() => {
    if (constructionSite && openPanel) {
      document.title = `3bouw | Werf - ${constructionSite.name}`;
    } else {
      document.title = '3bouw | Werven';
    }
  }, [constructionSite, openPanel]);

  useEffect(() => {
    if (constructionSiteId) {
      setOpenPanel(true);
    }
  }, []);

  return (
    <>
      <ConstructionSitesCommandBar
        modifyFn={openDetail}
        modifyDisabled={!constructionSite}
        loadExecutionListFn={loadExecutionList}
        loadExecutionListDisabled={!constructionSite}
        loadConstructionSheetFn={loadConstructionSheet}
        loadConstructionSheetDisabled={!constructionSite}
        loadNewPurchaseOrderFn={loadNewPurchaseOrder}
        loadNewPurchaseOrderDisabled={!constructionSite}
        deleteFn={() => setShowWarning(true)}
        deleteDisabled={!constructionSite}
      />

      <ConstructionSitesMessageBar
        showWarning={showWarning}
        deleteConfirm={removeConstructionSite}
        deleteCancel={() => setShowWarning(false)}
        isLoaded={!isDataLoaded}
      />

      {announcedMessage && (
        <div className={classNames.selectionDetails}>{announcedMessage}</div>
      )}

      <ConstructionSitesDetailsList
        items={constructionSites}
        selection={selection}
        // onItemInvoked={onItemInvoked}
        enableShimmer={!isDataLoaded}
        isSortedAsc={isSortedAsc}
        sortedField={sortedField}
        setSorting={setSorting}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />

      {!loading && !showLoadMore && constructionSites.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}

      {showLoadMore && (
        <Stack
          styles={{ root: { marginTop: '15px' } }}
          horizontal
          horizontalAlign='center'
        >
          <PrimaryButton text='Toon meer' onClick={() => loadMoreCallback()} />
        </Stack>
      )}

      {openPanel && (constructionSite || constructionSiteId) && (
        <ConstructionSiteDetail
          isOpen={openPanel}
          dismissPanel={() => dismissPanel()}
          constructionSiteSource={constructionSite || {}}
          listFilters={listFilters}
          constructionSiteId={
            constructionSiteId ? +constructionSiteId : undefined
          }
        />
      )}
    </>
  );
};

export default ConstructionSitesOverview;

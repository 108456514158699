import React, { useState } from 'react';
import { Icon, Stack } from '@fluentui/react';
import _ from 'lodash';
import {
  convertMeasuringUnitsToComboBoxOptions,
  iconLastProps,
  IPurchaseOrderLine,
  IPurchaseOrder,
} from '../../../utils';
import { InlineComboBox, InlineEditableLabel } from '../../../components/parts';
import DraggableList from '../../../components/parts/DraggableList';
import { IMeasuringUnit } from '../../../utils/MeasuringUnit';

type PurchaseOrderBodyProps = {
  purchaseOrder: IPurchaseOrder;
  measuringUnits?: IMeasuringUnit[];
  setPurchaseOrderState: (value: IPurchaseOrder) => void;
};

const PurchaseOrderBody = ({
  purchaseOrder,
  measuringUnits,
  setPurchaseOrderState,
}: PurchaseOrderBodyProps) => {
  // Item needs to be any, otherwise it cannot be indexed by a string.
  const updateItemValue = (item: any, attribute: string, newValue: string) => {
    const currentOrder = _.cloneDeep(purchaseOrder);
    const newItem = { ...item };
    // if (attribute === 'unit_price' || attribute === 'quantity') {
    //   newItem[attribute] = parseFloat(newValue);
    // } else {
    //   newItem[attribute] = newValue;
    // }

    newItem[attribute] = newValue;

    newItem.isDirty = true;
    newItem.isEdit = true;

    updateItems(newItem);
  };

  const updateItemMeasuringUnit = (
    item: IPurchaseOrderLine,
    newUnit: number,
  ) => {
    if (measuringUnits) {
      const newMeasuringUnit = measuringUnits.filter(
        (item: IMeasuringUnit) => item.id === newUnit,
      )[0];
      const newItem = { ...item };

      newItem.measuring_unit = newMeasuringUnit;
      newItem.isDirty = true;

      updateItems(newItem);
    }
  };

  const updateItems = (item: IPurchaseOrderLine) => {
    const currentOrder = _.cloneDeep(purchaseOrder);
    const newOrderLines = currentOrder ? currentOrder.line_items : [];

    if (newOrderLines) {
      for (let i = 0; i < newOrderLines.length; i++) {
        if (newOrderLines[i].id === item.id) {
          newOrderLines[i] = item;
        }
      }
    }

    if (newOrderLines && currentOrder) {
      currentOrder.line_items = newOrderLines;
      console.log('updateitem');
      setPurchaseOrderState(currentOrder);
    }
  };

  const toggleDeleteItem = (id: number) => {
    const currentOrder = _.cloneDeep(purchaseOrder);
    const currentOrderLines: IPurchaseOrderLine[] =
      currentOrder && currentOrder.line_items ? currentOrder.line_items : [];

    for (let i = 0; i < currentOrderLines.length; i++) {
      if (currentOrderLines[i].id === id) {
        currentOrderLines[i].delete = !currentOrderLines[i].delete;
        currentOrderLines[i].isDirty = true;
      }
    }

    currentOrder.line_items = currentOrderLines;
    console.log('toggleDel');
    setPurchaseOrderState(currentOrder);
  };

  const orderLineItems = (source: number, target: number) => {
    const currentOrder = _.cloneDeep(purchaseOrder);
    const currentOrderLines: IPurchaseOrderLine[] =
      currentOrder && currentOrder.line_items ? currentOrder.line_items : [];

    const fromIndex = source;
    const toIndex = target;

    const element = currentOrderLines.splice(fromIndex, 1)[0];
    console.log(element); // ['css']

    currentOrderLines.splice(toIndex, 0, element);

    for (let i = 0; i < currentOrderLines.length; i++) {
      currentOrderLines[i].weight = i + 1;
      if (!currentOrderLines[i].isNew) {
        currentOrderLines[i].isDirty = true;
      }
    }

    currentOrder.line_items = currentOrderLines;

    setPurchaseOrderState(currentOrder);
  };

  return (
    <div>
      {purchaseOrder.line_items && purchaseOrder.line_items.length > 0 && (
        <Stack>
          <Stack
            horizontal
            style={{
              width: '100%',
              borderBottom: '1px solid rgb(229, 229, 229);',

              textAlign: 'left',
            }}
          >
            <Stack.Item
              style={{
                textAlign: 'center',
                width: '75px',
                fontWeight: 'bold',
              }}
            >
              Regel
            </Stack.Item>
            <Stack.Item
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                width: '350px',
                fontWeight: 'bold',
              }}
            >
              Omschrijving
            </Stack.Item>
            <Stack.Item
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                width: '100px',
                fontWeight: 'bold',
              }}
            >
              Aantal
            </Stack.Item>
            <Stack.Item
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                width: '100px',
                fontWeight: 'bold',
              }}
            >
              Eenheid
            </Stack.Item>
            <Stack.Item
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                width: '100px',
                fontWeight: 'bold',
              }}
            >
              Prijs
            </Stack.Item>
            <Stack.Item
              grow={1}
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                fontWeight: 'bold',
              }}
            >
              Opmerking
            </Stack.Item>
            <Stack.Item style={{ textAlign: 'center', width: '75px' }} />
          </Stack>
          <DraggableList
            onDrop={(source, target) => {
              orderLineItems(source, target);
            }}
          >
            {purchaseOrder.line_items.map(
              (item: IPurchaseOrderLine, index: number) => {
                const fieldsPerRow = 5;
                const currentFieldIndex = index * fieldsPerRow + 1;
                return (
                  <Stack
                    horizontal
                    style={{
                      width: '100%',
                      marginTop: '20px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      // '&:hover': { background: 'rgb(239, 239, 239)' },
                    }}
                    styles={{
                      root: {
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        '&:hover': {
                          background: 'rgb(239, 239, 239)',
                        },
                      },
                    }}
                  >
                    <Stack.Item
                      style={{
                        textAlign: 'center',
                        width: '75px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <span>{item.weight}</span>
                    </Stack.Item>
                    <Stack.Item
                      style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        width: '350px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <InlineEditableLabel
                        isMultiline
                        isEdit
                        value={item.name || undefined}
                        callBack={(value: string) =>
                          updateItemValue(item, 'name', value)
                        }
                        tabIndex={currentFieldIndex + 1}
                      />
                    </Stack.Item>
                    <Stack.Item
                      style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        width: '100px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <InlineEditableLabel
                        isEdit
                        value={item.quantity || undefined}
                        type='number'
                        step='0.01'
                        callBack={(value: string) =>
                          updateItemValue(item, 'quantity', value)
                        }
                        tabIndex={currentFieldIndex + 2}
                        lang='nl'
                        noScroll
                      />
                    </Stack.Item>
                    <Stack.Item
                      style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        width: '100px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <InlineComboBox
                        isEdit
                        value={item.measuring_unit.id || undefined}
                        options={
                          measuringUnits
                            ? convertMeasuringUnitsToComboBoxOptions(
                                measuringUnits,
                              )
                            : []
                        }
                        callBack={(newUnit: number) =>
                          updateItemMeasuringUnit(item, newUnit)
                        }
                        tabIndex={currentFieldIndex + 3}
                      />
                    </Stack.Item>
                    <Stack.Item
                      style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        width: '100px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <InlineEditableLabel
                        isEdit
                        type='number'
                        step='0.01'
                        value={item.unit_price || undefined}
                        callBack={(value: string) =>
                          updateItemValue(item, 'unit_price', value)
                        }
                        tabIndex={currentFieldIndex + 4}
                        noScroll
                      />
                    </Stack.Item>
                    <Stack.Item
                      grow={1}
                      style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <InlineEditableLabel
                        isEdit
                        value={item.comment || undefined}
                        callBack={(value: string) =>
                          updateItemValue(item, 'comment', value)
                        }
                        tabIndex={currentFieldIndex + 5}
                      />
                    </Stack.Item>
                    <Stack.Item
                      style={{ textAlign: 'center', width: '75px', opacity: 1 }}
                    >
                      <Icon
                        iconName='Delete'
                        styles={iconLastProps}
                        onClick={() => {
                          toggleDeleteItem(item.id);
                        }}
                      />
                    </Stack.Item>
                  </Stack>
                );
              },
            )}
          </DraggableList>
        </Stack>
      )}
    </div>
  );
};
export default PurchaseOrderBody;

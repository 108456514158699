import { gql } from '@apollo/client';
import { IPropertyListType } from './PropertyListType';

export const ADD_PROPERTY_PROPERTY_LIST_TYPE = gql`
  mutation AddPropertyPropertyListType(
    $data: PropertyPropertyListTypeCreateInput!
  ) {
    createPropertyPropertyListType(data: $data) {
      id
      property_id
      property_list_type_id
    }
  }
`;

export const DELETE_PROPERTY_PROPERTY_LIST_TYPE = gql`
  mutation DeletePropertyPropertyListType(
    $where: PropertyPropertyListTypeWhereInput
  ) {
    deletePropertyPropertyListType(where: $where) {
      id
      property_id
      property_list_type_id
    }
  }
`;

export interface IPropertyPropertyListType {
  id?: number;
  list_type: IPropertyListType;
  draft?: boolean;
  delete?: boolean;
}

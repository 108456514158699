import { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { displayNameLead } from '../../../utils/Lead';
import { displayNameEmployee } from '../../../utils';
import { GET_SUGGESTED_VALUE } from '../../../utils/SuggestedValue';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';

const TimesheetStatus = (suggestedValue: any) => {
  const id = +suggestedValue.substr(suggestedValue.length - 4);
  const { data } = useQuery(GET_SUGGESTED_VALUE, {
    variables: {
      where: {
        id,
      },
    },
  });

  if (data) {
    return <span>{data.findOneSuggestedValue.suggested_value}</span>;
  }
  return <span />;
};

const TimesheetsOverview = ({
  timesheets,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  refetchTimesheets,
  clearTimesheets,
}: any) => {
  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Datum',
      fieldName: 'entry_date',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) => (
        <span>
          {timesheet.entry_date &&
            moment(timesheet.entry_date).format('DD-MM-YYYY')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
      isSorted: true,
      isSortedDescending: true,
      sortAscendingAriaLabel: 'Sorteer van laag tot hoog',
      sortDescendingAriaLabel: 'Sorteer van hoog tot laag',
    },
    {
      key: 'column2',
      name: 'Uren',
      fieldName: 'hours',
      minWidth: 20,
      maxWidth: 20,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) => timesheet.hours,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Medewerker',
      fieldName: 'employee',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) =>
        displayNameEmployee(timesheet.employee),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Status',
      fieldName: 'status',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) =>
        timesheet.status && TimesheetStatus(timesheet.status),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Klant',
      fieldName: 'customer',
      minWidth: 300,
      maxWidth: 300,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) =>
        displayNameLead(timesheet.customer),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Post',
      fieldName: 'cost_center',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) => (
        <span>
          {timesheet && timesheet.cost_center && timesheet.cost_center.name}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  // useEffect(() => {
  //   if (timesheet && isPanelOpen) {
  //     document.title = '3bouw | Timesheet';
  //   } else {
  //     document.title = '3bouw | Timesheets';
  //   }
  // }, [timesheet, isPanelOpen]);

  document.title = '3bouw | Timesheets';

  return (
    <>
      {timesheets && (
        <>
          <ShimmeredDetailsList
            items={timesheets}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && timesheets.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default TimesheetsOverview;

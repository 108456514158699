import React from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  Checkbox,
  TextField,
} from '@fluentui/react';
import { modalContentStyles } from '../../../utils';
import { ICustomerInvoiceLineItem } from '../../../utils/CustomerInvoiceLineItem';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  saveInvoiceLineItem: () => void;
  invoiceLine: ICustomerInvoiceLineItem;
  setInvoiceLine: React.Dispatch<React.SetStateAction<ICustomerInvoiceLineItem | undefined>>
}

const InvoiceLineDetail = ({
  isOpen,
  dismissPanel,
  saveInvoiceLine,
  invoiceLine,
  setInvoiceLine,
}: any) => {
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    let parsedValue: number | string | undefined = newValue;

    switch ((event.target as HTMLTextAreaElement).name) {
      case 'unit_price':
      case 'units':
      case 'percentage_vat':
        parsedValue = newValue && parseFloat(newValue);
        break;
      default:
        break;
    }
    setInvoiceLine((prevState: any) => ({
      ...prevState,
      isDirty: true,
      [(event.target as HTMLTextAreaElement).name]: parsedValue,
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        invoiceLine && invoiceLine.id
          ? 'Factuurlijn wijzigen'
          : 'Factuurlijn toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {invoiceLine && invoiceLine.id && <Label>ID: {invoiceLine.id}</Label>}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='description'
              label='Omschrijving'
              value={
                invoiceLine && invoiceLine.description
                  ? invoiceLine.description
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='units'
              label='Aantal'
              type='number'
              value={invoiceLine && invoiceLine.units ? invoiceLine.units : ''}
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='unit_price'
              label='Eenheidsprijs'
              type='number'
              step='0.01'
              min='0'
              value={
                invoiceLine && invoiceLine.unit_price
                  ? invoiceLine.unit_price
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          {invoiceLine && invoiceLine.unit_price && invoiceLine.units && (
            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <Label>Totaal excl BTW</Label>&euro;{' '}
              {(invoiceLine.unit_price * invoiceLine.units).toFixed(2)}
            </Stack>
          )}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='percentage_vat'
              label='BTW %'
              type='number'
              step='1'
              max='100'
              min='0'
              value={
                invoiceLine && invoiceLine.percentage_vat
                  ? invoiceLine.percentage_vat
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          {invoiceLine && invoiceLine.unit_price && invoiceLine.units && (
            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <Label>Totaal incl BTW</Label>&euro;{' '}
              {!invoiceLine.percentage_vat || +invoiceLine.percentage_vat === 0
                ? (invoiceLine.unit_price * invoiceLine.units).toFixed(2)
                : +invoiceLine.percentage_vat < 1
                ? (
                    invoiceLine.unit_price *
                    invoiceLine.units *
                    (1 + invoiceLine.percentage_vat)
                  ).toFixed(2)
                : (
                    invoiceLine.unit_price *
                    invoiceLine.units *
                    (1 + invoiceLine.percentage_vat / 100)
                  ).toFixed(2)}
            </Stack>
          )}

          <Stack style={{ marginBottom: 10 }}>
            <Label>Toon enkel totaal</Label>
            <Checkbox
              name='show_only_total'
              label='Toon enkel totaal?'
              checked={!!(invoiceLine && invoiceLine.show_only_total)}
              onChange={() => {
                setInvoiceLine((prevState: any) => ({
                  ...prevState,
                  isDirty: true,
                  show_only_total:
                    invoiceLine && invoiceLine.show_only_total
                      ? !invoiceLine.show_only_total
                      : true,
                }));
              }}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <StackItem>
                <PrimaryButton
                  onClick={saveInvoiceLine}
                  style={{ marginRight: 10 }}
                  disabled={
                    !invoiceLine ||
                    (invoiceLine &&
                      (!invoiceLine.units ||
                        !invoiceLine.unit_price ||
                        !invoiceLine.percentage_vat ||
                        !invoiceLine.description))
                  }
                >
                  Opslaan
                </PrimaryButton>
              </StackItem>
              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default InvoiceLineDetail;

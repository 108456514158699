import React, { useEffect } from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { Stack, IStackStyles } from '@fluentui/react/lib/Stack';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  getNotification,
  dismissNotification,
} from '../../redux/notification/notificationSlice';

const stackStyles: IStackStyles = {
  root: {
    position: 'fixed',
    bottom: '20px;',
    left: 0,
    right: 0,
  },
};

const Notification = () => {
  const notification = useAppSelector(getNotification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (notification && notification.active && notification.timeout) {
      setTimeout(() => {
        dispatch(dismissNotification());
      }, notification.timeout);
    }
  }, [notification]);

  const getMessageBarType = (type?: number) => {
    switch (type) {
      case 0:
        return MessageBarType.info;
      case 1:
        return MessageBarType.success;
      case 2:
        return MessageBarType.warning;
      default:
        return MessageBarType.info;
    }
  };
  if (notification.active) {
    return (
      <Stack styles={stackStyles} style={{ zIndex: 2000000 }}>
        <MessageBar
          messageBarType={getMessageBarType(notification.level)}
          isMultiline
          onDismiss={() => dispatch(dismissNotification())}
          dismissButtonAriaLabel='Close'
          styles={{
            root: {
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '750px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingTop: '10px',
              paddingBottom: '10px',
              zIndex: 9000,
            },
          }}
        >
          {notification.message}
          {notification.spinner && (
            <Stack
              styles={{
                root: { display: 'inline-block', marginLeft: '15px' },
              }}
            >
              <Spinner ariaLive='assertive' />
            </Stack>
          )}
        </MessageBar>
      </Stack>
    );
  }

  return null;
};

export default Notification;

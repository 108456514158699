import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { IPurchaseOrder } from '../../../utils';
import PurchaseOrderDownloadTemplate from './PurchaseOrderDownloadTemplate';

const PurchaseOrderDownload = async (purchaseOrder?: IPurchaseOrder) => {
  if (purchaseOrder) {
    const blob = await pdf(
      <PurchaseOrderDownloadTemplate purchaseOrder={purchaseOrder} />,
    ).toBlob();
    saveAs(
      blob,
      'bestelbon{orderNo}.pdf'.replace(
        '{orderNo}',
        purchaseOrder?.purchase_order_no
          ? `-${purchaseOrder?.purchase_order_no}`
          : '',
      ),
    );
  }
};

export default PurchaseOrderDownload;

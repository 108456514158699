import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';
import { AUTH_CONFIG as config } from './utils';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const redirectUri = process.env.REACT_APP_REDIRECT_URI || '';
const domain = process.env.REACT_APP_DOMAIN || '';
const clientId = process.env.REACT_APP_CLIENT_ID || '';
const audience = process.env.REACT_APP_AUDIENCE || '';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
  window.location.href = appState && appState.targetUrl
    ? appState.targetUrl
    : window.location.pathname;
};

// Main App
ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={redirectUri}
        audience={audience}
        onRedirectCallback={onRedirectCallback}
        scope={config.scope}
        useRefreshTokens
        cacheLocation='localstorage'
      >
        <App />
      </Auth0Provider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

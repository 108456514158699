import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { IComboBoxOption } from '@fluentui/react';

import {
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
  convertSuggestedValuesToComboBoxOptions,
} from '../../utils';
import { ComboboxWithFilter } from '.';

interface ISuggestedValueView {
  item: any;
  setItem: any;
  prefix: string;
  label?: string;
  code?: string;
  field: string;
  disabled?: boolean;
}

const SuggestedView = ({
  item,
  setItem,
  prefix,
  label,
  code,
  field,
  disabled = false,
}: ISuggestedValueView) => {
  const { isAuthenticated } = useAuth0();

  const { loading, error, data } = useQuery(
    GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
    {
      variables: {
        filter: {
          suggested_value_category: {
            suggested_value_code_prefix: {
              contains: prefix,
            },
          },
        },
      },
      skip: prefix === undefined || prefix.length === 0,
    },
  );

  if (!isAuthenticated) return <p>Verboden</p>;
  if (loading && !data) return <p>Laden...</p>;
  if (error) return <p>Fout :(</p>;

  const comboboxSuggestedValueOptions: IComboBoxOption[] = convertSuggestedValuesToComboBoxOptions(data?.findManySuggestedValues);

  const updateItem = (newValue: number) => {
    let result = { ...item };
    result.isDirty = true;

    result = { ...result, [field]: `${prefix}${newValue}` };

    setItem(result);
    return result;
  };

  return (
    <ComboboxWithFilter
      disabled={disabled}
      label={label || undefined}
      options={comboboxSuggestedValueOptions}
      value={code ? [+code] : undefined}
      multiline={false}
      callBack={(newValue: IComboBoxOption[]) => {
        if (newValue && newValue.length > 0) {
          updateItem(newValue[0].key as number);
        }
      }}
    />
  );
};

export default SuggestedView;

import React, { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { Stack, TextField } from '@fluentui/react';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_LEADS, ILead } from '../../utils/Lead';
import useDebounce from '../../components/hooks/useDebounce';
import LeadsOverview from './components/LeadsOverview';

interface IPagedLead {
  leads: ILead[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const Leads = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedLead>({
    leads: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'last_name1',
  });

  const [filter, setFilter] = useState('');

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, leads: [], skip: 0 }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filters: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { first_name1: { contains: filterArray[i] } },
          { first_name2: { contains: filterArray[i] } },
          { last_name1: { contains: filterArray[i] } },
          { last_name2: { contains: filterArray[i] } },
          { address1: { contains: filterArray[i] } },
          { city1: { contains: filterArray[i] } },
          {
            sales_rep: {
              OR: [
                { first_name: { contains: filterArray[i] } },
                { last_name: { contains: filterArray[i] } },
              ],
            },
          },
        ],
      };

      const numericValue = parseInt(filterArray[i], 10);
      if (numericValue) filterValue.OR.push({ zip_code1: +numericValue });

      filters.AND.push(filterValue);
    }

    return filters;
  };

  const {
    loading,
    error,
    refetch: refetchLeads,
  } = useQuery(GET_LEADS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      if (x.findManyLeads && x.findManyLeads.length > 0) {
        setPagedState(prevState => ({
          ...prevState,
          leads: pagedState.leads.concat(x.findManyLeads),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(x.findManyLeads.length < default_page_size),
        }));
      } else if (x.findManyLeads) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearLeads();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearLeads = () => {
    setPagedState(prevState => ({
      ...prevState,
      leads: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.leads.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Prospecten</h3>

      <TextField
        label='Zoeken...'
        value={filter}
        styles={textFieldStyles300}
        onChange={onSearchValueChange}
      />

      <LeadsOverview
        leads={pagedState.leads}
        setLeads={setPagedState}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchLeads={refetchLeads}
      />
    </Stack>
  );
};

export default Leads;

import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  TextField,
  Dialog,
  DialogFooter,
} from '@fluentui/react';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { dialogModelProps, modalContentStyles } from '../../../utils';
import DateView from '../../../components/parts/DateView';
import { displayNameLead } from '../../../utils/Lead';
import { dialogRemoveQuoteConfirmationProps } from '../../../utils/Styles';
import { DELETE_QUOTE_REFERENCE } from '../../../utils/QuoteReference';

const QuoteDetail = ({
  isOpen,
  dismissPanel,
  saveQuote,
  quote,
  setQuote,
  refetchLead,
}: any) => {
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const [deleteQuoteReference] = useMutation(DELETE_QUOTE_REFERENCE);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setQuote((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  // It is toggle and clear selected value actually :-)
  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
    // if (!isConfirmationHidden) setSelectedId(0);
  };

  const deleteQuote = () => {
    deleteQuoteReference({
      variables: {
        where: {
          id: quote.id,
        },
      },
    }).then(() => {
      toggleConfirmationDialog();
      refetchLead();
      dismissPanel();
    });
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={quote && quote.id ? 'Offerte wijzigen' : 'Offerte toevoegen'}
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {quote && (
          <>
            <Label>ID: {quote.id}</Label>
            <Label>
              Prospect: {quote.lead && displayNameLead(quote.lead)}
            </Label>
          </>
          )}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='quote_no'
              label='Offerte nr'
              value={quote && quote.quote_no ? quote.quote_no : ''}
              onChange={onChangeTextFieldValue}
              required
              errorMessage={
                  quote && !quote.quote_no ? 'Dit veld is verplicht' : ''
                }
            />
          </Stack>
          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={quote}
              setItem={setQuote}
              date={quote && quote.quote_date}
              label='Datum'
              field='quote_date'
            />
          </Stack>
          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='description'
              label='Omschrijving'
              value={quote && quote.description ? quote.description : ''}
              onChange={onChangeTextFieldValue}
            />
          </Stack>
          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='comments'
              label='Extra info'
              multiline
              value={quote && quote.comments ? quote.comments : ''}
              onChange={onChangeTextFieldValue}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <StackItem>
                <PrimaryButton
                  onClick={saveQuote}
                  style={{ marginRight: 10 }}
                  disabled={!quote || (quote && !quote.quote_no)}
                >
                  Opslaan
                </PrimaryButton>
              </StackItem>
              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>

            <Stack>
              <StackItem>
                <DefaultButton onClick={toggleConfirmationDialog}>
                  Verwijderen
                </DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveQuoteConfirmationProps}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteQuote} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default QuoteDetail;

import React, { useState } from 'react';
import {
  DefaultButton,
  Icon,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
// import SuggestedSectorView from './SuggestedSectorView';
import { iconProps, modalContentStyles } from '../../../utils';
import { ISuggestedValueCategory } from '../../../utils/SuggestedValueCategory';
import {
  ISuggestedValue,
  getDefaultSuggestedValue,
} from '../../../utils/SuggestedValue';
import Accordion, { AccordionItem } from '../../../components/parts/Accordion';

interface ISuggestedValueCategoryDetail {
  isOpen: boolean;
  dismissPanel: () => void;
  saveSuggestedValueCategory: () => void;
  suggestedValueCategory: ISuggestedValueCategory | undefined;
  setSuggestedValueCategory: React.Dispatch<
    React.SetStateAction<ISuggestedValueCategory | undefined>
  >;
}

const SuggestedValueCategoryDetail: React.FC<ISuggestedValueCategoryDetail> = ({
  isOpen,
  dismissPanel,
  saveSuggestedValueCategory,
  suggestedValueCategory,
  setSuggestedValueCategory,
}) => {
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const [category, setCategory] = useState<string | undefined>();

  const addSuggestedValue = () => {
    const defaultSuggestedValue = getDefaultSuggestedValue();
    const currentSuggestedValueCategory = _.cloneDeep(suggestedValueCategory);

    if (currentSuggestedValueCategory) {
      if (currentSuggestedValueCategory.suggested_values) {
        currentSuggestedValueCategory.suggested_values.push(
          defaultSuggestedValue,
        );
      } else {
        currentSuggestedValueCategory.suggested_values = [
          defaultSuggestedValue,
        ];
      }
      setSuggestedValueCategory(currentSuggestedValueCategory);
    }

    setSelectedValueItem(defaultSuggestedValue.id);
  };

  const toggleDeleteSuggestedValue = (index: number) => {
    const suggested_values = suggestedValueCategory
      ? suggestedValueCategory.suggested_values
      : undefined;
    if (suggested_values) {
      suggested_values[index] = {
        ...suggested_values[index],
        delete: !suggested_values[index].delete,
      };

      setSuggestedValueCategory((prevState: any) => ({
        ...prevState,
        suggested_values,
      }));
    }
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (
      category
      && newValue
      && ((suggestedValueCategory && !suggestedValueCategory.id)
        || !suggestedValueCategory)
    ) {
      const formatted_description = newValue.replace(/\s+/g, '-').toLowerCase();
      const formatted_category = category.replace(/\s+/g, '-').toLowerCase();
      const code = `${formatted_category}.${formatted_description}`;
      const prefix = `${formatted_category}_${formatted_description}_`;

      setSuggestedValueCategory((prevState: any) => ({
        ...prevState,
        category_code: code,
        suggested_value_code_prefix: prefix,
        [(event.target as HTMLTextAreaElement).name]: newValue || '',
      }));
    } else {
      setSuggestedValueCategory((prevState: any) => ({
        ...prevState,
        [(event.target as HTMLTextAreaElement).name]: newValue || '',
      }));
    }
  };

  const onChangeCategoryTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCategory(newValue);
    if (
      suggestedValueCategory
      && suggestedValueCategory.category_description
      && newValue
    ) {
      const formatted_description = suggestedValueCategory.category_description
        .replace(/\s+/g, '-')
        .toLowerCase();
      const formatted_category = newValue.replace(/\s+/g, '-').toLowerCase();
      const code = `${formatted_category}.${formatted_description}`;
      const prefix = `${formatted_category}_${formatted_description}_`;

      setSuggestedValueCategory((prevState: any) => ({
        ...prevState,
        category_code: code,
        suggested_value_code_prefix: prefix,
      }));
    }
  };

  const onChangeSuggestedValueTextFieldValue = (
    index: number,
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (suggestedValueCategory) {
      const suggested_values = suggestedValueCategory.suggested_values;

      if (suggested_values) {
        if (suggested_values[index].draft) {
          const code = suggestedValueCategory.suggested_value_code_prefix
            || `${suggested_values[index].id}`;

          suggested_values[index] = {
            ...suggested_values[index],
            [(event.target as HTMLTextAreaElement).name]: newValue || '',
            dirty: true,
            suggested_value_code: code,
          };
          setSuggestedValueCategory((prevState: any) => ({
            ...prevState,
            suggested_values,
          }));
        } else {
          suggested_values[index] = {
            ...suggested_values[index],
            [(event.target as HTMLTextAreaElement).name]: newValue || '',
            dirty: true,
          };
          setSuggestedValueCategory((prevState: any) => ({
            ...prevState,
            suggested_values,
          }));
        }
      }
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        suggestedValueCategory && suggestedValueCategory.id
          ? 'Voorgestelde waarde wijzigen'
          : 'Voorgestelde waarde toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='category_description'
                label='Omschrijving'
                value={
                  suggestedValueCategory
                  && suggestedValueCategory.category_description
                    ? suggestedValueCategory.category_description
                    : ''
                }
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  suggestedValueCategory
                  && !suggestedValueCategory.category_description
                    ? 'Dit veld is verplicht'
                    : ''
                }
              />
            </Stack>
            {(!suggestedValueCategory
              || (suggestedValueCategory && !suggestedValueCategory.id)) && (
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='category'
                  label='Categorie'
                  value={category || ''}
                  onChange={onChangeCategoryTextFieldValue}
                  required={
                    !!(!suggestedValueCategory
                    || (suggestedValueCategory && !suggestedValueCategory.id))
                  }
                  // errorMessage={!category ? 'Dit veld is verplicht' : ''}
                />
              </Stack>
            )}

            <Stack style={{ marginBottom: 10 }} />

            <Stack style={{ marginBottom: 10 }}>
              <Label style={{ marginBottom: -8 }}>Waarden</Label>
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) setSelectedValueItem(undefined);
                  else setSelectedValueItem(key);
                }}
              >
                {suggestedValueCategory
                  && suggestedValueCategory
                  && suggestedValueCategory.suggested_values
                  && suggestedValueCategory.suggested_values.map(
                    (suggested_value: ISuggestedValue, i: number) => {
                      if (suggested_value.active || suggested_value.draft) {
                        return (
                          <AccordionItem
                            key={suggested_value.id || `temp${i.toString()}`}
                            id={suggested_value.id || `temp${i.toString()}`}
                            title={(
                              <Stack horizontal>
                                <Stack.Item
                                  style={{
                                    textDecoration: suggested_value.delete
                                      ? 'line-through'
                                      : undefined,
                                    marginRight: '10px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '200px',
                                  }}
                                >
                                  {suggested_value.draft && (
                                  <Icon iconName='LocationDot' />
                                  )}
                                  {`${suggested_value.suggested_value}`}
                                </Stack.Item>
                                <Stack.Item>
                                  <Icon
                                    iconName={
                                      suggested_value.delete
                                        ? 'Refresh'
                                        : 'Delete'
                                    }
                                    styles={iconProps}
                                    onClick={() => {
                                      toggleDeleteSuggestedValue(i);
                                    }}
                                  />
                                </Stack.Item>
                              </Stack>
                            )}
                          >
                            <Stack style={{ marginBottom: 10 }}>
                              <TextField
                                name='suggested_value'
                                label='Omschrijving'
                                value={
                                  suggested_value
                                  && suggested_value.suggested_value
                                    ? suggested_value.suggested_value
                                    : ''
                                }
                                onChange={(
                                  event: React.FormEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >,
                                  newValue?: string,
                                ) => onChangeSuggestedValueTextFieldValue(
                                  i,
                                  event,
                                  newValue,
                                )
                                }
                              />
                            </Stack>
                            {/* Contact (contact, email, email opt out, phone, fax,
                        mobile, website) */}
                          </AccordionItem>
                        );
                      }
                    },
                  )}
              </Accordion>
              {suggestedValueCategory && (
                <Stack.Item style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <PrimaryButton
                    text='Voeg een waarde toe'
                    onClick={addSuggestedValue}
                  />
                </Stack.Item>
              )}
            </Stack>
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                onClick={saveSuggestedValueCategory}
                style={{ marginRight: 10 }}
                disabled={
                  !!(!suggestedValueCategory
                  || !suggestedValueCategory.category_description
                  || (!suggestedValueCategory.id && !category))
                }
              >
                Opslaan
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default SuggestedValueCategoryDetail;

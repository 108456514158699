import React, { FormEvent, useState } from 'react';
import moment from 'moment';
import {
  TextField,
  DatePicker,
  mergeStyles,
  defaultDatePickerStrings,
  Stack,
  StackItem,
  DayOfWeek,
} from '@fluentui/react';
import { DatePickerNL } from '../../../components/parts';
import { property_types, stackTokens5 } from '../../../utils';
import { IProperty } from '../../../utils/Property';

const onFormatDate = (date?: Date): string => (!date ? '' : moment(date).format('DD/MM/YYYY'));
interface IDateView {
  property: IProperty;
  callBack: (item: IProperty) => void;
  compact?: boolean;
}

const DateView = ({ property, callBack, compact = false }: IDateView) => {
  const [hasErrors, setHasErrors] = useState(false);

  const updateProperty = (newValue: string) => {
    const result = { ...property };
    result.value = newValue;
    result.isDirty = true;
    return result;
  };

  const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Monday);

  const setDate = (date: Date | null | undefined) => {
    const newItem = { ...property };

    newItem.isDirty = true;
    newItem.value = moment(date).toISOString();

    callBack(newItem);
  };

  const updateParent = (date: string) => {
    callBack(updateProperty(date));
  };

  return property.type.id === 8 ? (
    <Stack>
      {property.name}
      <DatePicker
        firstDayOfWeek={firstDayOfWeek}
        placeholder='Selecteer een datum...'
        ariaLabel='Selecteer een datum'
        // DatePicker uses English strings by default. For localized apps, you must override this prop.
        strings={defaultDatePickerStrings}
        formatDate={onFormatDate}
        onSelectDate={setDate}
        value={property.value ? moment(property.value).toDate() : undefined}
      />
    </Stack>
  ) : (
    <DatePicker
      firstDayOfWeek={firstDayOfWeek}
      placeholder='Selecteer een datum...'
      ariaLabel='Selecteer een datum'
      // DatePicker uses English strings by default. For localized apps, you must override this prop.
      strings={defaultDatePickerStrings}
      formatDate={onFormatDate}
      onSelectDate={setDate}
      value={property.value ? moment(property.value).toDate() : undefined}
    />
  );
};

export default DateView;

import { IComboBoxOption } from '@fluentui/react';
import { gql } from '@apollo/client';
import { ITimesheetEntry } from './Timesheet';
import { IIncomingInvoiceLineItem } from './IncomingInvoiceLineItem';

export interface ICostCenter {
  active: boolean;
  customer_specific: boolean;
  id: number;
  name: string;
  number: number;
  version: number;

  // cost_center_wings: [Cost_center_wings]
  // defect_work_order_line_items: [Defect_work_order_line_item]
  incoming_invoice_line_items: IIncomingInvoiceLineItem;
  time_sheet_entries: ITimesheetEntry;
}

export const GET_COST_CENTERS = gql`
  query getCostCenters(
    $filter: CostCenterFilterInput
    $orderBy: CostCenterOrderByInput
  ) {
    findManyCostCenters(filter: $filter, orderBy: $orderBy) {
      id
      name
      active
      customer_specific
    }
  }
`;

export const GET_COST_CENTER_BY_ID = gql`
  query getCostCenterById($where: CostCenterWhereInput) {
    findOneCostCenter(where: $where) {
      id
      name
      active
      customer_specific
    }
  }
`;

export const ADD_COST_CENTER = gql`
  mutation addCostCenter($data: CostCenterCreateInput!) {
    createCostCenter(data: $data) {
      id
    }
  }
`;

export const UPDATE_COST_CENTER = gql`
  mutation updateCostCenter($id: Int!, $data: CostCenterUpdateInput) {
    updateCostCenter(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_COST_CENTER = gql`
  mutation deleteCostCenter($where: CostCenterWhereInput) {
    deleteCostCenter(where: $where) {
      id
    }
  }
`;

export const convertCostCentersToComboBoxOptions = (
  cost_centers: ICostCenter[],
) => {
  if (cost_centers) {
    const result: IComboBoxOption[] = cost_centers.map(
      (cost_center: ICostCenter): IComboBoxOption => ({
        key: cost_center.id,
        text: cost_center.name,
      }),
    );
    return result;
  }

  return [];
};

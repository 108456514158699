import React, { useState, useEffect } from 'react';
/* eslint-disable react/function-component-definition */
/* eslint-disable no-trailing-spaces */
import jwt_decode from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';

interface IPrivateRoute {
  // component: any;
  // path: any;
  permission?: string;
}

const checkPermissions = (permissions: string[], permission?: string) => {
  if (!permission) return true;
  if (permissions.includes(permission)) return true;
  return false;
};

const PrivateRoute: React.FC<IPrivateRoute> = ({ permission, children }) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        if (token) {
          const decoded: any = jwt_decode(token);
          if (decoded && typeof decoded === 'object' && decoded.permissions) {
            setPermissions(decoded.permissions);
          }
        }
      });

      return;
    }

    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };
    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) return <div>...Laden</div>;
  if (!isAuthenticated || !checkPermissions(permissions, permission)) {
    return <div>Je hebt geen rechten om deze pagina te bekijken.</div>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default PrivateRoute;

import { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { Stack, TextField } from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_ARCHITECTS, IArchitect } from '../../utils/Architect';
import ArchitectsOverview from './components/ArchitectsOverview';

interface IPagedArchitect {
  architects: IArchitect[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const Architects = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedArchitect>({
    architects: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'last_name',
  });

  const [filter, setFilter] = useState('');

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, architects: [], skip: 0 }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = { AND: [] };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { first_name: { contains: filterArray[i] } },
          { last_name: { contains: filterArray[i] } },
          { company: { contains: filterArray[i] } },
          { city: { contains: filterArray[i] } },
        ],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    loading,
    error,
    refetch: refetchArchitects,
  } = useQuery(GET_ARCHITECTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      console.log(x);
      if (x.findManyArchitects && x.findManyArchitects.length > 0) {
        setPagedState(prevState => ({
          ...prevState,
          architects: pagedState.architects.concat(x.findManyArchitects),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(x.findManyArchitects.length < default_page_size),
        }));
      } else if (x.findManyArchitects) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearArchitects();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearArchitects = () => {
    setPagedState(prevState => ({
      ...prevState,
      architects: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.architects.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Architecten</h3>

      <TextField
        label='Zoeken...'
        value={filter}
        styles={textFieldStyles300}
        onChange={onSearchValueChange}
      />

      <ArchitectsOverview
        architects={pagedState.architects}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchArchitects={refetchArchitects}
        clearArchitects={clearArchitects}
      />
    </Stack>
  );
};

export default Architects;

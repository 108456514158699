import React from 'react';
import {
  Callout, DefaultButton, PrimaryButton, Stack,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { callOutClasses, stackTokens15, stackTokens5 } from '../../utils';

const CalloutDefault = ({
  actionConfirm,
  actionCancel,
  actionText = 'actie',
  cancelText = 'Annuleer',
  message = 'boodschap',
  target,
  title = 'Titel',
  visible = false,
}: any) => {
  const labelId = useId('callout-label');
  const descriptionId = useId('callout-description');

  return (
    visible && (
      <Callout
        className={callOutClasses.callout}
        ariaLabelledBy={labelId}
        ariaDescribedBy={descriptionId}
        role='alertdialog'
        gapSpace={0}
        target={target}
        onDismiss={actionCancel}
        setInitialFocus
      >
        <div className={callOutClasses.header}>
          <span className={callOutClasses.title} id={labelId}>
            {title}
          </span>
        </div>
        <div className={callOutClasses.inner}>
          <Stack tokens={stackTokens15}>
            {message}
            <Stack
              horizontal
              styles={{ root: { marginTop: '15px' } }}
              tokens={stackTokens5}
            >
              <PrimaryButton
                text={actionText}
                onClick={() => actionConfirm()}
              />
              <DefaultButton text={cancelText} onClick={() => actionCancel()} />
            </Stack>
          </Stack>
        </div>
      </Callout>
    )
  );
};

export default CalloutDefault;

import React from 'react';
import { Stack } from '@fluentui/react';
import { useQuery } from '@apollo/react-hooks';
import { IProperty } from '../../../utils/Property';
import { GET_PROPERTY_TYPES } from '../../../utils/PropertyType';
import TextView from './TextView';
import SuggestedValueView from './SuggestedValueView';
import ParentView from './ParentView';
import MultiValueView from './MultiValueView';
import DocumentView from './DocumentView';
import DateView from './DateView';

interface IModifyProperty {
  item: IProperty;
  setCurrentSelection: React.Dispatch<
    React.SetStateAction<IProperty | undefined>>;
}
const ModifyProperty: React.FC<IModifyProperty> = ({
  item,
  setCurrentSelection,
}) => {
  const {
    loading: loadingTypes,
    error: errorTypes,
    data: dataTypes,
  } = useQuery(GET_PROPERTY_TYPES, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
  });

  if (item && item.type) {
    switch (item.type.name) {
      case 'string': // text
        return (
          <TextView
            property={item}
            callBack={(item: IProperty) => {
              setCurrentSelection(item);
            }}
            edit
            compact
          />
        );

      case 'document':
        return (
          <DocumentView
            property={item}
            callBack={(item: IProperty) => {
              setCurrentSelection(item);
            }}
            edit
          />
        );
      case 'parent':
        return (
          <ParentView property={item} callBack={() => {}} edit />
        );
      case 'suggested-value':
        return (
          <SuggestedValueView
            key={item.suggested_value ? item.suggested_value.id : undefined}
            property={item}
            callBack={(item: IProperty) => {
              setCurrentSelection(item);
            }}
          />
        );
      case 'date':
        return (
          <DateView
            property={item}
            callBack={(item: IProperty) => {
              // console.log(item);
              setCurrentSelection(item);
            }}
          />
        );
      case 'note':
        return (
          <TextView
            property={item}
            callBack={(item: IProperty) => {
              setCurrentSelection(item);
            }}
            edit
            compact
          />
        );
      case 'multi-value':
        return (
          <MultiValueView
            property={item}
            callBack={(item: IProperty) => {
              setCurrentSelection(item);
            }}
            types={dataTypes ? dataTypes.findManyPropertyTypes : []}
          />
        );
      default:
        break;
    }
  }

  return null;
};

export default ModifyProperty;

import {
  IComboBoxOption,
  Icon,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  TextField,
  DayOfWeek,
  defaultDatePickerStrings,
  DatePicker,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment, { Moment } from 'moment';
import 'moment/locale/nl-be';
import { ComboboxWithFilter } from '../../../components/parts';
import {
  convertEmployeesToComboBoxOptions,
  GET_CONSTRUCTION_SITES,
  GET_EMPLOYEES,
  iconLastProps,
  IEmployee,
} from '../../../utils';
import { GET_LEADS } from '../../../utils/Lead';
import {
  convertCostCentersToComboBoxOptions,
  GET_COST_CENTERS,
  ICostCenter,
} from '../../../utils/CostCenter';
import { SAVE_TIMESHEETS } from '../../../utils/Timesheet';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';
import ComboboxProject from './ComboboxProject';

moment.locale('nl-be');

function NewTimesheetProject({ timesheet, setTimesheet, getTimesheet }: any) {
  const { data: employeesData } = useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
  });

  const { loading: customersLoading, data: customersData } = useQuery(
    GET_LEADS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        filter: { AND: [{ status: { contains: 'SOLD' } }] },
        take: 10,
      },
    },
  );

  const { loading: projectsLoading, data: projectsData } = useQuery(
    GET_CONSTRUCTION_SITES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        take: 10,
      },
    },
  );

  const { data: costCentersData } = useQuery(GET_COST_CENTERS, {
    fetchPolicy: 'no-cache',
  });

  const [currentProject, setCurrentProject] = useState<number | string>();
  const [loader, setLoader] = useState(false);

  const [currentDate, setCurrentDate] = useState(moment().startOf('week'));
  // const now = moment().startOf('d');
  // const [monday, setMonday] = useState(now.weekday(1));
  const [days, setDays] = useState<Moment[]>([]);

  useEffect(() => {
    const startMoment = moment(currentDate);
    for (let i = 0; i < 7; i++) {
      setDays(prevArray => [...prevArray, moment(startMoment).add(i, 'd')]);
    }
  }, [currentDate]);

  const [saveTimesheetEntries] = useMutation(SAVE_TIMESHEETS);

  const getValues = (values: any) => {
    const valuesWithoutType: any[] = [];
    values.map((value: any) => {
      valuesWithoutType.push({
        id: value.id || undefined,
        hours: value.hours,
        entry_date: value.entry_date,
      });
    });

    return valuesWithoutType;
  };

  const saveTimesheet = () => {
    setLoader(true);
    if (timesheet && currentProject) {
      const allInput: any[] = [];
      const split = currentProject.toString().split('_');

      timesheet.map((timesheetEntry: any) => {
        allInput.push({
          construction_site:
            split[0] === 'constructionsite' ? { id: +split[1] } : null,
          customer: split[0] === 'customer' ? { id: +split[1] } : null,
          lead: split[0] === 'lead' ? { id: +split[1] } : null,
          employee: timesheetEntry.employee
            ? { id: timesheetEntry.employee.id }
            : { id: 70 },
          cost_center: timesheetEntry.cost_center
            ? { id: timesheetEntry.cost_center.id }
            : undefined,
          status: timesheetEntry.status,
          values: getValues(timesheetEntry.values),
        });
      });

      saveTimesheetEntries({
        variables: { data: allInput },
        update: (cache, data: any) => {
          setTimesheet(data.data.saveTimesheetObject);
          setLoader(false);
        },
      });
    }
  };

  const [isTimesheetSorted, setisTimesheetSorted] = useState(false);

  useEffect(() => {
    if (!isTimesheetSorted && timesheet) {
      const tempTimesheet = [...timesheet];
      tempTimesheet.sort((obj1: ITimesheetEntry, obj2: ITimesheetEntry) => {
        let compareFirstName = 0;
        if (obj1.employee && obj1.employee.first_name && obj2.employee && obj2.employee.first_name) {
          compareFirstName = obj1.employee.first_name.localeCompare(
            obj2.employee.first_name,
          );
        }

        let compareLastName = 0;
        if (obj1.employee && obj1.employee.last_name && obj2.employee && obj2.employee.last_name) {
          compareLastName = obj1.employee.last_name.localeCompare(
            obj2.employee.last_name,
          );
        }

        return compareFirstName || compareLastName;
      });
      setisTimesheetSorted(true);
      setTimesheet(tempTimesheet);
    }
  }, [timesheet, isTimesheetSorted]);

  return (
    <Stack style={{ width: 1200 }}>
      <Stack
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
          padding: '0px 10px',
        }}
      >
        <Stack>
          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginRight: 8, minWidth: '100px' }}>Datum:</Label>
            <DatePicker
              firstDayOfWeek={DayOfWeek.Monday}
              placeholder='Select a date...'
              ariaLabel='Select a date'
              // DatePicker uses English strings by default. For localized apps, you must override this prop.
              strings={defaultDatePickerStrings}
              onSelectDate={date => {
                setDays([]);
                setCurrentDate(moment(date).startOf('week'));
                const split = currentProject?.toString().split('_');
                if (split) {
                  getTimesheet({
                    variables: {
                      start: moment(date).startOf('week').toISOString(),
                      construction_site:
                        split[0] === 'constructionsite' ? +split[1] : null,
                      customer: split[0] === 'customer' ? +split[1] : null,
                      lead: split[0] === 'lead' ? +split[1] : null,
                    },
                  });
                }
              }}
              value={currentDate.toDate()}
              formatDate={date => moment(date).format('dd DD/MM/YYYY')}
            />
          </Stack>
          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginRight: 8 }}>Project:</Label>
            <StackItem style={{ width: 220 }}>
              {customersLoading || projectsLoading ? (
                <Stack>
                  <Spinner size={SpinnerSize.medium} />
                </Stack>
              ) : (customersData &&
                  customersData.findManyLeads &&
                  customersData.findManyLeads.length > 0) ||
                (projectsData &&
                  projectsData.findManyLeads &&
                  projectsData.findManyLeads.length > 0) ? (
                // eslint-disable-next-line react/jsx-indent
                <ComboboxProject
                  initCustomers={customersData.findManyLeads}
                  initProjects={projectsData.findManyConstructionSites}
                  value={currentProject}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      setCurrentProject(newValue[0].key);
                      const split = newValue[0].key.toString().split('_');

                      getTimesheet({
                        variables: {
                          duration: 6,
                          construction_site:
                            split[0] === 'constructionsite' ? +split[1] : null,
                          customer: split[0] === 'customer' ? +split[1] : null,
                          lead: split[0] === 'lead' ? +split[1] : null,
                          start: moment(currentDate).toISOString(),
                        },
                      });
                    }
                  }}
                />
              ) : (
                <Stack>&nbsp;</Stack>
              )}
            </StackItem>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
          alignSelf: 'flex-end',
          alignItems: 'center',
        }}
      >
        <StackItem style={{ fontWeight: 700 }}>
          <PrimaryButton
            disabled={!currentProject}
            onClick={() => {
              setDays([]);
              const split = currentProject?.toString().split('_');
              if (split) {
                getTimesheet({
                  variables: {
                    start: moment(currentDate).add(-1, 'w').toISOString(),
                    construction_site:
                      split[0] === 'constructionsite' ? +split[1] : null,
                    customer: split[0] === 'customer' ? +split[1] : null,
                    lead: split[0] === 'lead' ? +split[1] : null,
                  },
                });
              }
              setCurrentDate(moment(currentDate).add(-1, 'w'));
            }}
          >
            &lt;
          </PrimaryButton>
        </StackItem>
        <StackItem style={{ fontWeight: 700, fontSize: 15 }}>
          Week {moment(currentDate).isoWeek()}:{' '}
          {currentDate.locale('nl-be').format('dd DD/MM/YYYY')} -{' '}
          {moment(currentDate).add(6, 'd').format('dd DD/MM/YYYY')}
        </StackItem>
        <StackItem style={{ fontWeight: 700 }}>
          <PrimaryButton
            disabled={!currentProject}
            onClick={() => {
              setDays([]);
              const split = currentProject?.toString().split('_');
              if (split) {
                getTimesheet({
                  variables: {
                    start: moment(currentDate).add(1, 'w').toISOString(),
                    construction_site:
                      split[0] === 'constructionsite' ? +split[1] : null,
                    customer: split[0] === 'customer' ? +split[1] : null,
                    lead: split[0] === 'lead' ? +split[1] : null,
                  },
                });
              }

              setCurrentDate(moment(currentDate).add(1, 'w'));
            }}
          >
            &gt;
          </PrimaryButton>
        </StackItem>
      </Stack>

      <Stack>
        <Stack
          style={{
            flexDirection: 'row',
            borderBottom: '1px solid rgb(229, 229, 229)',
            fontWeight: 600,
          }}
        >
          <StackItem
            style={{
              width: '25%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Project / Taak
          </StackItem>
          <StackItem
            style={{
              width: '18%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Post
          </StackItem>
          {days.map((day, i: number) => (
            <StackItem
              style={{
                width: '8%',
                padding: 10,
                fontWeight: 600,
              }}
              key={`timesheet-days-${i}`}
            >
              {moment(day).format('ddd DD/MM')}
            </StackItem>
          ))}
          <StackItem style={{ width: '2%' }}>&nbsp;</StackItem>
        </Stack>
        {timesheet &&
          timesheet.length > 0 &&
          timesheet.map((timesheetEntry: any, i: number) => (
            <Stack
              style={{
                flexDirection: 'row',
                borderBottom: '1px solid rgb(239, 239, 239)',
                alignItems: 'top',
              }}
              key={`timesheet-lines-${i}`}
            >
              <StackItem
                style={{
                  width: '25%',
                  padding: 10,
                }}
              >
                <ComboboxWithFilter
                  options={convertEmployeesToComboBoxOptions(
                    employeesData &&
                      employeesData.findManyEmployees.sort(
                        (obj1: IEmployee, obj2: IEmployee) => {
                          let compareName = 0;
                            if (obj1.first_name && obj2.first_name) {
                              compareName = obj1.first_name.localeCompare(
                                obj2.first_name,
                              );
                            }

                            let compareTitle = 0;
                            if (obj1.last_name && obj2.last_name) {
                              compareTitle = obj1.last_name.localeCompare(
                                obj2.last_name,
                              );
                            }

                          return compareName || compareTitle;
                        },
                      ),
                  )}
                  required
                  errorMessage={
                    (timesheetEntry && !timesheetEntry.employee) ||
                    (timesheetEntry.employee && !timesheetEntry.employee.id)
                      ? 'Dit veld is verplicht'
                      : ''
                  }
                  value={
                    timesheetEntry &&
                    timesheetEntry.employee &&
                    timesheetEntry.employee.id
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = [...timesheet];
                      if (result[i].employee) {
                        result[i].employee.id = +newValue[0].key;
                      } else {
                        result[i].employee = { id: +newValue[0].key };
                      }
                      setTimesheet(result);
                    }
                  }}
                />
              </StackItem>
              <StackItem
                style={{
                  width: '18%',
                  padding: 10,
                }}
              >
                <ComboboxWithFilter
                  options={convertCostCentersToComboBoxOptions(
                    costCentersData &&
                      costCentersData.findManyCostCenters.sort(
                        (obj1: ICostCenter, obj2: ICostCenter) =>
                          obj1.name.localeCompare(obj2.name),
                      ),
                  )}
                  required
                  errorMessage={
                    (timesheetEntry && !timesheetEntry.cost_center) ||
                    (timesheetEntry.cost_center &&
                      !timesheetEntry.cost_center.id)
                      ? 'Dit veld is verplicht'
                      : ''
                  }
                  value={
                    timesheetEntry &&
                    timesheetEntry.cost_center &&
                    timesheetEntry.cost_center.id
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = [...timesheet];
                      if (result[i].cost_center) {
                        result[i].cost_center.id = +newValue[0].key;
                      } else {
                        result[i].cost_center = { id: +newValue[0].key };
                      }
                      setTimesheet(result);
                    }
                  }}
                />
              </StackItem>
              {days.map((day, key: number) => {
                const entry: any = timesheetEntry.values.find(
                  (x: any) => moment(x.entry_date).isSame(day, 'day'),
                  // eslint-disable-next-line function-paren-newline
                );
                const entryIndex: number = timesheetEntry.values.findIndex(
                  (x: any) => moment(x.entry_date).isSame(day, 'day'),
                );
                return (
                  <StackItem
                    style={{
                      width: '8%',
                      padding: 10,
                      backgroundColor:
                        key === 5 || key === 6
                          ? 'rgb(239, 239, 239)'
                          : 'transparent',
                    }}
                    key={`day-${i}-${key}`}
                  >
                    <TextField
                      name={entry ? entry.id : `day-${i}`}
                      type='number'
                      value={entry ? entry.hours : '0'}
                      min={0}
                      onChange={(
                        event: React.FormEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                        newValue?: string,
                      ) => {
                        if (entry) {
                          const result = [...timesheet];
                          result[i].values[entryIndex].hours = newValue
                            ? +newValue
                            : 0;
                          setTimesheet(result);
                        } else {
                          const result = [...timesheet];
                          result[i].values.push({
                            hours: newValue ? +newValue : 0,
                            entry_date: moment(day).toISOString(),
                          });
                          setTimesheet(result);
                        }
                      }}
                    />
                  </StackItem>
                );
              })}
              <StackItem
                style={{
                  alignSelf: 'center',
                  width: '2%',
                  textAlign: 'right',
                }}
                onClick={() => {
                  const result = [...timesheet];
                  result[i].values.map((value: any, x: number) => {
                    result[i].values[x].hours = 0;
                  });
                  setTimesheet(result);
                }}
              >
                <Icon iconName='Delete' styles={iconLastProps} />
              </StackItem>
            </Stack>
          ))}
      </Stack>

      <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Stack style={{ padding: 10 }}>
          <PrimaryButton
            disabled={!currentProject}
            style={{ marginRight: 5 }}
            onClick={() => {
              const split = currentProject?.toString().split('_');
              if (split) {
                setTimesheet((prevState: any) => [
                  ...prevState,
                  {
                    construction_site:
                      split[0] === 'constructionsite' ? +split[1] : undefined,
                    customer: split[0] === 'customer' ? +split[1] : undefined,
                    lead: split[0] === 'lead' ? +split[1] : undefined,
                    cost_center: undefined,
                    employee: undefined,
                    status: 'tse_stat_3136',
                    values: [],
                  },
                ]);
              }
            }}
          >
            Medewerker toevoegen
          </PrimaryButton>
        </Stack>

        <Stack style={{ padding: 10 }}>
          <PrimaryButton
            disabled={!currentProject}
            onClick={() => saveTimesheet()}
          >
            Werkuren opslaan{' '}
            {loader && (
              <Spinner style={{ marginLeft: 10 }} size={SpinnerSize.small} />
            )}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default NewTimesheetProject;

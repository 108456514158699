import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import { ICarDriver } from './CarDriver';
import { IEmployee } from './Employee';

export const GET_CARS = gql`
  query getCars(
    $filter: CarFilterInput
    $take: Int
    $skip: Int
    $orderBy: CarOrderByInput
  ) {
    findManyCars(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      name
      car_type
      car_phone
      license_plate
      employee {
        first_name
        last_name
      }
      active
      car_driver {
        current_driver
        employee {
          first_name
          last_name
        }
      }
    }
  }
`;

export const GET_CAR_BY_ID = gql`
  query getCarById(
    $where: CarWhereInput
    $orderInvoiceLineItemBy: IncomingInvoiceLineItemOrderByInput
  ) {
    findOneCar(where: $where) {
      id
      name
      car_type
      car_phone
      car_phone_comments
      car_phone_pin_code
      car_phone_puk_code
      car_phone_simcard_number
      car_comments
      chassis_number
      fuel_type
      license_plate
      first_use_date
      lease_start_date
      lease_stop_date
      purchase_date
      tank_card_code
      tank_card_comments
      tank_card_number
      tank_card_number2
      sold_date
      employee {
        id
        first_name
        last_name
      }
      car_driver {
        current_driver
        id
        start_date
        end_date
        employee {
          id
          first_name
          last_name
        }
      }
      active

      incoming_invoice_line_items(orderBy: $orderInvoiceLineItemBy) {
        amount
        amount_vat
        description
        incoming_invoice {
          invoice_date
          invoice_no
          total_excl_vat
          comments
          supplier {
            name
          }
        }
      }
    }
  }
`;

export const ADD_CAR = gql`
  mutation addCar($data: CarCreateInput!) {
    createCar(data: $data) {
      id
      name
      car_type
      car_phone
      car_phone_comments
      car_phone_pin_code
      car_phone_puk_code
      car_phone_simcard_number
      car_comments
      chassis_number
      fuel_type
      license_plate
      first_use_date
      lease_start_date
      lease_stop_date
      purchase_date
      tank_card_code
      tank_card_comments
      tank_card_number
      tank_card_number2
      sold_date
      employee {
        id
        first_name
        last_name
      }
      car_driver {
        current_driver
        id
        start_date
        end_date
        employee {
          id
          first_name
          last_name
        }
      }
      active
    }
  }
`;

export const UPDATE_CAR = gql`
  mutation updateCar($id: Int!, $data: CarUpdateInput) {
    updateCar(id: $id, data: $data) {
      id
      name
      car_type
      car_phone
      car_phone_comments
      car_phone_pin_code
      car_phone_puk_code
      car_phone_simcard_number
      car_comments
      chassis_number
      fuel_type
      license_plate
      first_use_date
      lease_start_date
      lease_stop_date
      purchase_date
      tank_card_code
      tank_card_comments
      tank_card_number
      tank_card_number2
      sold_date
      employee {
        id
        first_name
        last_name
      }
      car_driver {
        current_driver
        id
        start_date
        end_date
        employee {
          id
          first_name
          last_name
        }
      }
      active
    }
  }
`;

export const DELETE_CAR = gql`
  mutation deleteCar($where: CarWhereInput) {
    deleteCar(where: $where) {
      id
    }
  }
`;

export const DELETE_MANY_CARS = gql`
  mutation deleteManyCars($where: CarWhereManyInput) {
    deleteManyCars(where: $where) {
      count
    }
  }
`;

export interface ICar {
  id: number;
  active: boolean;
  car_comments: string;
  car_phone: string;
  car_phone_comments: string;
  car_phone_pin_code: string;
  car_phone_puk_code: string;
  car_phone_simcard_number: string;
  car_type: string;
  chassis_number: string;
  driver_id: number;
  first_use_date: Date;
  fuel_type: string;
  lease_start_date: Date;
  lease_stop_date: Date;
  license_plate: string;
  name: string;
  purchase_date: Date;
  sold_date: Date;
  tank_card_code: string;
  tank_card_comments: string;
  tank_card_number: string;
  tank_card_number2: string;
  traffic_tax_date: Date;
  version: number;
  // incoming_invoice_line_items: IInvoice

  employee: IEmployee;
  car_driver: ICarDriver[];
}

export const convertCarsToComboBoxOptions = (cars: ICar[]) => {
  const result: IComboBoxOption[] = cars.map((car: ICar): any => {
    if (car.id && car.id) {
      return {
        key: car.id,
        text: `${car.license_plate} - ${car.name}`,
      };
    }
  });
  return result.length > 0 ? result : [];
};

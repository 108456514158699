import { gql } from '@apollo/client';
import {
  IConstructionSite,
  IConstructionSiteCreateUpdate,
} from './ConstructionSite';
import { ICustomer } from './Customer';
import { ICustomerContractUpdate } from './CustomerContractUpdate';
import { ICustomerInvoice } from './CustomerInvoice';

export const GET_CUSTOMER_PAYMENT_TRANSACTIONS = gql`
  query getCustomerPaymentTransactions(
    $filter: CustomerPaymentTransactionFilterInput
    $take: Int
    $skip: Int
    $orderBy: CustomerPaymentTransactionOrderByInput
  ) {
    findManyCustomerPaymentTransactions(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      customer {
        first_name1
        last_name1
        title1
        title2
        first_name2
        last_name2
      }
      description
      payment_date
      customer_id
      percentage
      customer_invoices {
        id
        invoice_no
      }
      construction_site {
        id
        name
        address
        city
        zip_code
        contract_amount

        customer_contract_updates {
          id
          document_no
          document_date
          status
          summary

          customer_invoice {
            id
          }
        }

        invoice_customers {
          id
          percentage
          lead {
            id
            first_name1
            first_name2
            last_name1
            last_name2
            address1
            city1
            zip_code1
          }
        }

        lead {
          id
          first_name1
          first_name2
          last_name1
          last_name2
          address1
          city1
          zip_code1
        }
      }
    }
  }
`;

export const GET_CUSTOMER_PAYMENT_TRANSACTION_BY_ID = gql`
  query getCustomerPaymentTransactionById(
    $where: CustomerPaymentTransactionWhereInput
  ) {
    findOneCustomerPaymentTransaction(where: $where) {
      id
      customer {
        first_name1
        last_name1
        title1
        title2
        first_name2
        last_name2
      }
      description
      payment_date
      percentage
      customer_id
      advance_payment_amount
      construction_site {
        id
        name
        address
        city
        zip_code
        contract_amount

        invoice_customers {
          id
          percentage
          lead {
            id
            first_name1
            first_name2
            last_name1
            last_name2
            address1
            city1
            zip_code1
          }
        }

        lead {
          id
          first_name1
          first_name2
          last_name1
          last_name2
          address1
          city1
          zip_code1
        }

        customer_contract_updates {
          id
          document_no
          summary
        }
      }

      customer_invoices {
        id
        invoice_no
        invoice_date
      }
    }
  }
`;

export const ADD_CUSTOMER_PAYMENT_TRANSACTION = gql`
  mutation AddCustomerPaymentTransaction(
    $data: CustomerPaymentTransactionCreateInput!
  ) {
    createCustomerPaymentTransaction(data: $data) {
      id
      construction_site {
        id
        name
        address
        city
        zip_code
        contract_amount
      }
    }
  }
`;

export const UPDATE_CUSTOMER_PAYMENT_TRANSACTION = gql`
  mutation UpdateCustomerPaymentTransaction(
    $id: Int!
    $data: CustomerPaymentTransactionUpdateInput!
  ) {
    updateCustomerPaymentTransaction(id: $id, data: $data) {
      id
      construction_site {
        id
        name
        address
        city
        zip_code
        contract_amount
      }
    }
  }
`;

export interface ICustomerPaymentTransaction {
  advance_payment?: boolean;
  advance_payment_amount?: number;
  customer_id?: number;
  customer_invoice_id?: number;
  description?: string;
  id?: number;
  payment_date?: Date;
  percentage?: number;
  version?: number;
  customer?: ICustomer;
  customer_invoice_legacy?: ICustomerInvoice;
  customer_invoices?: ICustomerInvoice[];
  construction_site?: IConstructionSite | IConstructionSiteCreateUpdate;
  customer_contract_updates?: ICustomerContractUpdate[];
}

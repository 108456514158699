import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  Label,
  Link,
  Stack,
  StackItem,
  Text,
  Modal,
  PrimaryButton,
  IconButton,
  DefaultButton,
  Toggle,
  Icon,
  IStackItemStyles,
} from '@fluentui/react';
import { getTheme } from '@fluentui/react/lib/Styling';
import React, { FormEvent, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { ComboboxWithFilter } from '../../../components/parts';
import TextFieldDropDown from '../../../components/parts/TextFieldDropdown';
import { Heading } from '../../../components/typography';
import {
  cancelIcon,
  convertConstructionSitesToComboBoxOptions,
  convertPurchaseOrderTemplatesToComboBoxOptions,
  convertSuppliersToComboBoxOptions,
  iconButtonStyles,
  IPurchaseOrderLine,
  modalContentStyles,
  stackTokens5,
} from '../../../utils';
import {
  convertContactPersonsToComboBoxOptions,
  IContactPerson,
} from '../../../utils/ContactPerson';
import { IPurchaseOrderStatus } from '../../../utils/PurchaseOrder';
import { IPurchaseOrderTemplate } from '../../../utils/PurchaseOrderTemplate';
import { IConstructionSite } from '../../../utils/ConstructionSite';
import { ISupplier } from '../../../utils/Supplier';
import { IPurchaseOrderState } from '../PurchaseOrder';

const theme = getTheme();

type PurchaseOrderHeaderProps = {
  order: IPurchaseOrderState;
  templates: IPurchaseOrderTemplate[];
  setPurchaseOrderState: React.Dispatch<
    React.SetStateAction<IPurchaseOrderState>
  >;
  constructionSites?: IConstructionSite[];
  constructionSiteLoading?: boolean;
  constructionSiteSearch?: string;
  statuses?: IPurchaseOrderStatus[];
  isNew?: boolean;
  suppliers?: ISupplier[];
  supplierSearch?: string;
  suppliersLoading?: boolean;
  setSupplierSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  setConstructionSiteSearch: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};

const styleTitle: IStackItemStyles = {
  root: {
    background: theme.palette.neutralLight,
    padding: '10px',
    marginTop: '15px',
    marginBottom: '15px',
    paddingRight: '20px',
    alignItems: 'center',
    color: 'black',
    fontSize: '16px',
    fontWeight: 'bold',
    h3: {
      margin: 0,
    },
  },
};

const PurchaseOrderHeader = ({
  order,
  templates,
  setPurchaseOrderState,
  suppliers,
  suppliersLoading,
  supplierSearch,
  setSupplierSearch,
  setConstructionSiteSearch,
  constructionSites,
  constructionSiteLoading,
  constructionSiteSearch,
  // contacts,
  statuses,
  isNew,
}: PurchaseOrderHeaderProps) => {
  // Hooks

  const [isModalConstructionSiteOpen, setIsModalConstructionSiteOpen] =
    useState(false);

  const [tempConstructionSite, setTempConstructionSite] = useState<
    IConstructionSite | undefined
  >();

  const [editSupplier, setEditSupplier] = useState(false);
  const [editCustomer, setEditCustomer] = useState(false);
  const [showGeneralInfo, setShowGeneralInfo] = useState(true);
  const [showSupplierInfo, setShowSupplierInfo] = useState(isNew);
  const [showCustomerInfo, setShowCustomerInfo] = useState(isNew);
  // const [isPurchaseOrder, setIsPurchaseOrder] = useState(false);

  // Functions
  const changeTemplate = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    // index?: number | undefined,
    // value?: string | undefined,
  ) => {
    const selectedKey = +option!.key;

    if (selectedKey && order) {
      const selectedTemplate = templates.filter(
        (item: IPurchaseOrderTemplate) => item.id === +option!.key,
      )[0];

      const newItem: IPurchaseOrderState = {
        ...order,
        purchaseOrder: {
          remarks:
            selectedTemplate && selectedTemplate.remarks
              ? selectedTemplate.remarks
              : '',
          supplier: selectedTemplate.supplier,
          creation_date: moment().toISOString(),
          delivery_to_other_supplier: false,
          is_on_call: false,
          is_purchase_order: true,
          is_default_contact_person: true,
          line_items: [],
          contact_person: selectedTemplate.is_default_contact_person
            ? selectedTemplate.contact_person
            : undefined,
          status: {
            id: 1,
            label: 'Nieuw', // hard coded -> to change @TP
          },
        },
        template: selectedTemplate,
        templateKey: selectedKey,
      };
      if (selectedTemplate && selectedTemplate.line_items) {
        const templateLineItems = _.cloneDeep(selectedTemplate.line_items);
        const purchaseOrderLines: IPurchaseOrderLine[] = templateLineItems
          .sort((a, b) => (a.weight || 0) - (b.weight || 0))
          .map((item: IPurchaseOrderLine, index) => ({
            isNew: true,
            isDirty: true,
            id: -1 - index,
            weight: index + 1,
            quantity: item.quantity,
            unit_price: item.unit_price,
            comment: item.comment,
            name: item.name + (item.description ? ` ${item.description}` : ''),
            measuring_unit:
              item.measuring_unit && item.measuring_unit.id
                ? {
                    // probably make this optional? Or store in template?
                    id: item.measuring_unit.id,
                    name: item.measuring_unit.name,
                  }
                : {
                    // probably make this optional? Or store in template?
                    id: 9,
                    name: 'GEEN',
                  },
          }));

        if (
          newItem &&
          newItem.purchaseOrder &&
          newItem.purchaseOrder.line_items
        ) {
          newItem.purchaseOrder.line_items = purchaseOrderLines;
        }
      }
      setPurchaseOrderState(prevState => ({
        ...prevState,
        ...newItem,
      }));
    }
  };

  const onChangeContactPerson = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    // index?: number | undefined,
    // value?: string | undefined,
  ) => {
    // setSelectedContactPersonId(+option!.key);
    console.log(option);
    const contacts = order.purchaseOrder.supplier
      ? order.purchaseOrder.supplier.contact_persons
      : undefined;

    if (
      option &&
      +option!.key > 0 &&
      contacts &&
      order &&
      order.purchaseOrder
    ) {
      const foundContactPerson = contacts.filter(
        (el: IContactPerson) => el.id === +option!.key,
      )[0];
      const newOrder = { ...order.purchaseOrder };
      newOrder.is_default_contact_person = false;
      newOrder.contact_person = foundContactPerson;
      setPurchaseOrderState(prevState => ({
        ...prevState,
        purchaseOrder: newOrder,
      }));
    } else if (order && order.purchaseOrder) {
      const newOrder = { ...order.purchaseOrder };
      newOrder.is_default_contact_person = true;
      newOrder.contact_person = undefined;
      setPurchaseOrderState(prevState => ({
        ...prevState,
        purchaseOrder: newOrder,
      }));
    }
  };

  const loadContactPersons = (supplier: ISupplier) => {
    if (supplier) {
      let contactArray: IComboBoxOption[] = [];

      const contactArrayTemp = supplier.contact_persons || [];

      const comboBoxOptions =
        convertContactPersonsToComboBoxOptions(contactArrayTemp);

      if (comboBoxOptions && comboBoxOptions.length > 0) {
        contactArray = contactArray.concat(comboBoxOptions);
      }

      return contactArray;
    }
    return [];
  };

  const toggleModalConstructionSite = () => {
    setIsModalConstructionSiteOpen(!isModalConstructionSiteOpen);
  };

  const openModal = () => {
    toggleModalConstructionSite();
  };

  const closeModal = () => {
    setPurchaseOrderState(prevState => ({
      ...prevState,
      purchaseOrder: {
        ...prevState.purchaseOrder,
        construction_site: tempConstructionSite,
      },
    }));
    if (tempConstructionSite) setTempConstructionSite(undefined);
    toggleModalConstructionSite();
  };

  const onStatusChange = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    const currentOrder = { ...order.purchaseOrder };
    const statusID = checked ? 2 : 1;
    if (currentOrder && statuses) {
      const selectedStatus = statuses.filter(status => {
        if (status.id === statusID) return true;
        return false;
      })[0];
      currentOrder.status = selectedStatus;
      setPurchaseOrderState(prevState => ({
        ...prevState,
        purchaseOrder: currentOrder,
      }));
    }
  };

  const onChangeIsPurchaseOrder = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    const currentOrder = { ...order.purchaseOrder };
    if (currentOrder) {
      currentOrder.is_purchase_order = checked ?? false;

      setPurchaseOrderState(prevState => ({
        ...prevState,
        purchaseOrder: currentOrder,
      }));
    }
  };

  const getContactLabel = () => {
    if (order && order.purchaseOrder && order.purchaseOrder.contact_person) {
      if (
        order.purchaseOrder.supplier &&
        order.purchaseOrder.supplier.contact_persons &&
        order.purchaseOrder.is_default_contact_person
      ) {
        return (
          <Label>
            {order.purchaseOrder.supplier.contact_persons[0].first_name}{' '}
            {order.purchaseOrder.supplier.contact_persons[0].last_name}
          </Label>
        );
      }
      if (
        order.purchaseOrder.supplier &&
        order.purchaseOrder.contact_person &&
        !order.purchaseOrder.is_default_contact_person
      ) {
        return (
          <Label>
            {order.purchaseOrder.contact_person.first_name}{' '}
            {order.purchaseOrder.contact_person.last_name}
          </Label>
        );
      }
    } else {
      return (
        <div>
          <ComboBox
            selectedKey={
              order && order.purchaseOrder && order.purchaseOrder.contact_person
                ? order.purchaseOrder.contact_person.id
                : order &&
                  order.purchaseOrder &&
                  order.purchaseOrder.supplier &&
                  order.purchaseOrder.supplier.contact_persons &&
                  order.purchaseOrder.supplier.contact_persons[0] &&
                  order.purchaseOrder.supplier.contact_persons[0].id
                ? order.purchaseOrder.supplier.contact_persons[0].id
                : undefined
            }
            allowFreeform
            autoComplete='on'
            options={
              order.purchaseOrder && order.purchaseOrder.supplier
                ? loadContactPersons(order.purchaseOrder.supplier)
                : []
            }
            onChange={onChangeContactPerson}
            disabled={
              !(order && order.purchaseOrder && order.purchaseOrder.supplier)
            }
          />
        </div>
      );
    }

    return <Label />;
  };

  const getPhoneLabel = () => {
    const selectedSupplier =
      order && order.purchaseOrder && order.purchaseOrder.supplier
        ? order.purchaseOrder.supplier
        : undefined;
    const supplierContactPhone =
      selectedSupplier &&
      selectedSupplier.contact_persons &&
      selectedSupplier.contact_persons[0] &&
      (selectedSupplier.contact_persons[0].phone_v2 ||
        selectedSupplier.contact_persons[0].mobile_v2 ||
        selectedSupplier.contact_persons[0].phone ||
        selectedSupplier.contact_persons[0].mobile)
        ? selectedSupplier.contact_persons[0].phone_v2 ||
          selectedSupplier.contact_persons[0].mobile_v2 ||
          selectedSupplier.contact_persons[0].phone ||
          selectedSupplier.contact_persons[0].mobile
        : undefined;
    return order &&
      order.purchaseOrder &&
      order.purchaseOrder.contact_person ? (
      // eslint-disable-next-line react/jsx-indent
      <Label>
        {order.purchaseOrder.contact_person.phone_v2 ||
          order.purchaseOrder.contact_person.mobile_v2 ||
          order.purchaseOrder.contact_person.phone ||
          order.purchaseOrder.contact_person.mobile}
      </Label>
    ) : supplierContactPhone ? (
      <Label>{supplierContactPhone}</Label>
    ) : null;
  };

  const getFaxLabel = () => {
    const selectedSupplier =
      order && order.purchaseOrder && order.purchaseOrder.supplier
        ? order.purchaseOrder.supplier
        : undefined;
    const supplierContactFax =
      selectedSupplier &&
      selectedSupplier.contact_persons &&
      selectedSupplier.contact_persons[0] &&
      selectedSupplier.contact_persons[0].fax
        ? selectedSupplier.contact_persons[0].fax
        : undefined;
    return order &&
      order.purchaseOrder &&
      order.purchaseOrder.contact_person ? (
      // eslint-disable-next-line react/jsx-indent
      <Label>{order.purchaseOrder.contact_person.fax}</Label>
    ) : supplierContactFax ? (
      <Label>{supplierContactFax}</Label>
    ) : null;
  };

  const getCreationDateLabel = () => {
    order && order.purchaseOrder && order.purchaseOrder.creation_date ? (
      <Text style={{ display: 'block' }}>
        {moment(order.purchaseOrder.creation_date).format('DD-MM-YYYY')}
      </Text>
    ) : null;
  };

  const getTemplatesIncludingNewOption = () => {
    const newOption = {
      key: 0,
      text: 'Nieuw',
    };

    return [
      newOption,
      ...convertPurchaseOrderTemplatesToComboBoxOptions(templates),
    ];
  };

  const setSupplier = (value: ISupplier) => {
    setPurchaseOrderState(prevState => {
      const newState = _.cloneDeep(prevState);
      newState.purchaseOrder.supplier = value;
      return newState;
    });
  };

  const setConstructionSite = (value: IConstructionSite) => {
    setPurchaseOrderState(prevState => {
      const newState = _.cloneDeep(prevState);
      newState.purchaseOrder.construction_site = value;
      return newState;
    });
  };

  return (
    <div>
      <h3 style={{ marginBottom: 15 }}>
        {order && order.purchaseOrder && order.purchaseOrder.is_purchase_order
          ? 'Bestelbon'
          : 'Prijsaanvraag'}{' '}
        {order && order.purchaseOrder && order.purchaseOrder.purchase_order_no
          ? `(${order.purchaseOrder.purchase_order_no})`
          : ''}
      </h3>

      <Stack
        // onClick={() => toggleItem(id)}
        styles={styleTitle}
        horizontal
        horizontalAlign='space-between'
      >
        <Stack horizontal gap='15' style={{ alignItems: 'center' }}>
          <Stack.Item
            onClick={() => {
              setShowGeneralInfo(!showGeneralInfo);
            }}
            styles={{ root: { '&:hover': { cursor: 'pointer' } } }}
          >
            <h3>Algemeen</h3>
          </Stack.Item>
        </Stack>
        <Stack.Item
          onClick={() => {
            setShowGeneralInfo(!showGeneralInfo);
          }}
          styles={{
            root: {
              '&:hover': {
                cursor: 'pointer',
              },
            },
          }}
        >
          {showGeneralInfo && <Icon iconName='chevronUp' />}
          {!showGeneralInfo && <Icon iconName='chevronDown' />}
        </Stack.Item>
      </Stack>

      <Stack
        horizontal
        horizontalAlign='start'
        gap={30}
        styles={{
          root: {
            display: !showGeneralInfo ? 'none' : undefined,
            paddingLeft: '15px',
          },
        }}
      >
        <Stack.Item tokens={stackTokens5}>
          {order &&
          order.purchaseOrder &&
          order.purchaseOrder.id &&
          order.purchaseOrder.id > 0 ? null : (
            <Stack horizontal tokens={stackTokens5}>
              <Label>Sjabloon:</Label>
              <ComboBox
                selectedKey={order.template?.id || 0}
                allowFreeform
                autoComplete='on'
                options={getTemplatesIncludingNewOption()}
                onChange={changeTemplate}
              />
            </Stack>
          )}
        </Stack.Item>
        <Stack.Item tokens={stackTokens5}>
          <Stack horizontal tokens={stackTokens5}>
            <Toggle
              label='Bestelbon | Prijsvraag'
              onText='Bestelbon'
              offText='Prijsaanvraag'
              checked={
                !!(
                  order &&
                  order.purchaseOrder &&
                  order.purchaseOrder.is_purchase_order
                )
              }
              onChange={onChangeIsPurchaseOrder}
            />
          </Stack>
        </Stack.Item>
        <Stack.Item tokens={stackTokens5}>
          <Stack horizontal tokens={stackTokens5}>
            <Toggle
              label='Nieuw | Verstuurd'
              onText='Verstuurd'
              offText='Nieuw'
              checked={
                !(
                  order &&
                  order.purchaseOrder &&
                  order.purchaseOrder.status &&
                  order.purchaseOrder.status.id === 1
                )
              }
              onChange={onStatusChange}
            />
          </Stack>
        </Stack.Item>

        <Stack.Item>
          <Label>Aangemaakt op:</Label>
          {getCreationDateLabel()}
        </Stack.Item>
      </Stack>

      <Stack
        // onClick={() => toggleItem(id)}
        styles={styleTitle}
        horizontal
        horizontalAlign='space-between'
        gap='30'
      >
        <Stack horizontal gap='15' style={{ alignItems: 'center' }}>
          <Stack.Item
            onClick={() => {
              setShowSupplierInfo(!showSupplierInfo);
            }}
            styles={{ root: { '&:hover': { cursor: 'pointer' } } }}
          >
            <h3>Leverancier</h3>
          </Stack.Item>

          {order.purchaseOrder.supplier && (
            <Stack.Item
              onClick={() => {
                setEditSupplier(!editSupplier || !showSupplierInfo);
                if (!editSupplier) setShowSupplierInfo(true);
              }}
              styles={{
                root: {
                  '&:hover': {
                    cursor: 'pointer',
                  },
                },
              }}
            >
              <Icon iconName='Edit' />
            </Stack.Item>
          )}
        </Stack>
        <Stack.Item
          onClick={() => {
            setShowSupplierInfo(!showSupplierInfo);
          }}
          styles={{
            root: {
              '&:hover': {
                cursor: 'pointer',
              },
            },
          }}
        >
          {showSupplierInfo && <Icon iconName='chevronUp' />}
          {!showSupplierInfo && <Icon iconName='chevronDown' />}
        </Stack.Item>
      </Stack>

      <Stack
        horizontal
        horizontalAlign='start'
        gap='30'
        styles={{
          root: {
            display: !showSupplierInfo ? 'none' : undefined,
            paddingLeft: '15px',
          },
        }}
      >
        {(!order.purchaseOrder.supplier || editSupplier) && (
          <Stack.Item>
            <Text
              style={{
                display: 'block',
                fontWeight: 'bold',
                marginBottom: '10px',
              }}
            >
              Leverancier
            </Text>
            <TextFieldDropDown
              emptyResult={
                !suppliersLoading && suppliers && suppliers.length === 0
              }
              placeholder='zoek een leverancier'
              value={supplierSearch}
              loading={suppliersLoading}
              onChange={(e, value) => {
                setSupplierSearch(value);
              }}
              options={
                suppliers ? convertSuppliersToComboBoxOptions(suppliers) : []
              }
              select={key => {
                if (suppliers) {
                  for (let i = 0; i < suppliers.length; i++) {
                    if (suppliers[i].id === key) setSupplier(suppliers[i]);
                  }
                }
              }}
            />
          </Stack.Item>
        )}

        <Stack.Item>
          {order &&
            order.purchaseOrder &&
            order.purchaseOrder.supplier &&
            order.purchaseOrder.supplier.id && (
              <>
                <Text
                  style={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                  }}
                >
                  {' '}
                  {order.purchaseOrder.supplier.name}{' '}
                </Text>

                <Text style={{ display: 'block' }}>
                  {order.purchaseOrder.supplier.address}
                </Text>
                <Text style={{ display: 'block' }}>
                  {order.purchaseOrder.supplier.zip_code}{' '}
                  {order.purchaseOrder.supplier.city}
                </Text>
                <Link href={`mailto:${order.purchaseOrder.supplier.email}`}>
                  {order.purchaseOrder.supplier.email}
                </Link>
              </>
            )}
        </Stack.Item>
        <Stack.Item>
          <Text
            style={{
              display: 'block',
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
          >
            {' '}
            Contactpersoon
          </Text>
          <Text style={{ display: 'block' }}>{getContactLabel()}</Text>
          <Text style={{ display: 'block' }}>{getPhoneLabel()}</Text>
          <Text style={{ display: 'block' }}>{getFaxLabel()}</Text>
        </Stack.Item>
      </Stack>

      <Stack
        // onClick={() => toggleItem(id)}
        styles={styleTitle}
        horizontal
        horizontalAlign='space-between'
      >
        <Stack horizontal gap='15' style={{ alignItems: 'center' }}>
          <Stack.Item
            onClick={() => {
              setShowCustomerInfo(!showCustomerInfo);
            }}
            styles={{ root: { '&:hover': { cursor: 'pointer' } } }}
          >
            <h3>Klant / Werf</h3>
          </Stack.Item>
          {order.purchaseOrder.construction_site && (
            <Stack.Item
              onClick={() => {
                setEditCustomer(!editCustomer || !showCustomerInfo);
                if (!editCustomer) setShowCustomerInfo(true);
              }}
              styles={{
                root: {
                  '&:hover': {
                    cursor: 'pointer',
                  },
                },
              }}
            >
              <Icon iconName='Edit' />
            </Stack.Item>
          )}
        </Stack>
        <Stack.Item
          onClick={() => {
            setShowCustomerInfo(!showCustomerInfo);
          }}
          styles={{
            root: {
              '&:hover': {
                cursor: 'pointer',
              },
            },
          }}
        >
          {showCustomerInfo && <Icon iconName='chevronUp' />}
          {!showCustomerInfo && <Icon iconName='chevronDown' />}
        </Stack.Item>
      </Stack>

      <Stack
        horizontal
        horizontalAlign='start'
        gap='30'
        styles={{
          root: {
            display: !showCustomerInfo ? 'none' : undefined,
            paddingLeft: '15px',
          },
        }}
      >
        {(!order.purchaseOrder.construction_site || editCustomer) && (
          <Stack.Item>
            <Text
              style={{
                display: 'block',
                fontWeight: 'bold',
                marginBottom: '10px',
              }}
            >
              Werf
            </Text>
            <TextFieldDropDown
              emptyResult={
                !constructionSiteLoading &&
                constructionSites &&
                constructionSites.length === 0
              }
              placeholder='zoek een werf'
              value={constructionSiteSearch}
              onChange={(e, value) => {
                setConstructionSiteSearch(value);
              }}
              options={
                constructionSites
                  ? convertConstructionSitesToComboBoxOptions(constructionSites)
                  : []
              }
              select={key => {
                if (constructionSites) {
                  for (let i = 0; i < constructionSites.length; i++) {
                    if (constructionSites[i].id === key) {
                      setConstructionSite(constructionSites[i]);
                    }
                  }
                }
              }}
            />
          </Stack.Item>
        )}
        <StackItem>
          {order &&
            order.purchaseOrder.construction_site &&
            (order.purchaseOrder.construction_site.name ||
              order.purchaseOrder.construction_site.address) && (
              <>
                <Text style={{ display: 'block', fontWeight: 'bold' }}>
                  {order.purchaseOrder.construction_site.name}
                </Text>
                <Text style={{ display: 'block' }}>
                  {order.purchaseOrder.construction_site.address}
                </Text>
                <Text style={{ display: 'block' }}>
                  {order.purchaseOrder.construction_site.zip_code}{' '}
                  {order.purchaseOrder.construction_site.city}
                </Text>

                <Text
                  style={{
                    display: 'block',
                    maxWidth: '350px',
                    marginTop: '10px',
                  }}
                >
                  {order.purchaseOrder.construction_site.comments}
                </Text>
              </>
            )}
        </StackItem>
        {order &&
          order.purchaseOrder.construction_site &&
          order.purchaseOrder.construction_site.project_manager && (
            <Stack.Item style={{ marginTop: 20 }}>
              <Label>Projectbeheerder:</Label>
              <Text>
                {
                  order.purchaseOrder.construction_site.project_manager
                    .first_name
                }{' '}
                {
                  order.purchaseOrder.construction_site.project_manager
                    .last_name
                }
                <br />
                {order.purchaseOrder.construction_site.project_manager.mobile}
              </Text>
            </Stack.Item>
          )}
      </Stack>

      <Stack
        // onClick={() => toggleItem(id)}
        styles={styleTitle}
        horizontal
        horizontalAlign='space-between'
      >
        <Stack.Item>
          <h3>Details</h3>
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default PurchaseOrderHeader;

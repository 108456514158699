import React from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { Stack } from '@fluentui/react';
import { Link } from '@fluentui/react/lib/Link';
import { mergeStyleSets, getTheme } from '@fluentui/react/lib/Styling';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from '../graphic/logo';
import { Container } from '../layout';

const redirectUri = process.env.REACT_APP_REDIRECT_URI || '';
const clientId = process.env.REACT_APP_CLIENT_ID || '';

const theme = getTheme();

// const filterIcon = { iconName: 'Filter' };
const classes = mergeStyleSets({
  header: {
    background: theme.palette.themePrimary,
    color: 'white',
  },
  icon: {},
  navItem: {
    selectors: {
      ':hover': {
        background: theme.palette.themeTertiary,
      },
    },
    display: 'flex',
    alignItems: 'center',
  },
  innerItem: {
    display: 'flex',
    paddingTop: theme.spacing.s2,
    paddingBottom: theme.spacing.s2,
    paddingLeft: theme.spacing.m,
    paddingRight: theme.spacing.m,
    fontSize: FontSizes.size14,
    lineHeight: FontSizes.size14,
    color: 'white',
    selectors: {
      ':hover': {
        textDecoration: 'none',
      },
    },
    alignItems: 'center',
  },
  searchBox: {
    marginTop: theme.spacing.s2,
    marginBottom: theme.spacing.s2,
  },
  searchContainer: {
    maxWidth: '750px',
  },
});

const Header: React.FC = ({ children }) => (
  <Container
    horizontal
    horizontalAlign='space-between'
    fullWidth
    className={classes.header}
  >
    {children}
  </Container>
);

const InnerHeader = ({ toggleNavigation = () => {} }) => {
  const { isAuthenticated, logout, user } = useAuth0();

  const getUserInfo = () => {
    if (isAuthenticated && user) {
      return {
        imageUrl: user.picture,
        text:
          user.name !== undefined && user.family_name !== undefined
            ? `${user.name} ${user.family_name}`
            : user.name !== undefined && user.family_name === undefined
              ? user.name
              : user.name === undefined && user.family_name !== undefined
                ? user.family_name
                : user.nickname !== undefined
                  ? user.nickname
                  : 'Error',
      };
    }

    return {};
  };

  return (
    <>
      <Stack.Item align='stretch'>
        <Stack horizontal disableShrink verticalFill>
          <Stack.Item className={classes.navItem} align='stretch'>
            <Link className={classes.innerItem} onClick={toggleNavigation}>
              <Icon iconName='GlobalNavButton' className={classes.icon} />
            </Link>
          </Stack.Item>
          <Stack.Item className={classes.navItem} align='stretch'>
            <Link className={classes.innerItem}>
              <Logo size={32} />
              <h4 style={{ marginLeft: 10 }}>
                Bouw Manager
              </h4>
            </Link>
          </Stack.Item>
        </Stack>
      </Stack.Item>

      {/* <Stack.Item align="baseline" grow className={classes.searchContainer}>
        <SearchBox
          placeholder="Zoek naar content in de volledige applicatie"
          className={classes.searchBox}
          iconProps={filterIcon}
        />
      </Stack.Item>       */}
      <Stack.Item align='stretch'>
        <Stack horizontal disableShrink verticalFill>
          <Stack.Item className={classes.navItem} align='stretch'>
            <Persona
              {...getUserInfo()}
              size={PersonaSize.size32}
              // presence={PersonaPresence.online}
              imageAlt='unknown'
              styles={{
                primaryText: {
                  color: 'white',
                  cursor: 'pointer',
                  selectors: {
                    ':hover': {
                      color: 'white',
                    },
                  },
                  fontSize: FontSizes.size14,
                  lineHeight: FontSizes.size14,
                },
                secondaryText: {
                  color: 'white',
                },
              }}
            />
          </Stack.Item>
          <Stack.Item className={classes.navItem} align='stretch'>
            <Link
              className={classes.innerItem}
              onClick={() => logout({ returnTo: redirectUri, client_id: clientId })
              }
            >
              Afmelden
            </Link>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </>
  );
};

export default Header;

export { InnerHeader };

import React from 'react';
import {
  DefaultButton,
  Modal,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { useState } from 'react';

const AppUserModal = ({
  hideModal,
  toggleHideModal,
  modalUser,
  newUserLoading,
  newUserData,
  createUser,
}: any) => {
  const [appUserEmail, setAppUserEmail] = useState('');

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setAppUserEmail((prevState: any) => (
      newValue || ''
    ));
  };

  return (
    <Modal
      isOpen={!hideModal}
      onDismiss={toggleHideModal}
      styles={{
        main: {
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 35,
          paddingRight: 35,
        },
        scrollableContent: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '350px',
        },
      }}
    >
      <Stack grow={1}>
        <Stack.Item>
          <h2>Gebruiker toevoegen</h2>
        </Stack.Item>
        <Stack.Item grow={1}>
          <Stack
            grow={1}
            align-items='center'
            styles={{ root: { paddingTop: 15 } }}
          >
            {modalUser && (
              <div>
                <h4>Geselecteerde gebruiker:</h4>
                <div>
                  <TextField
                    name='naam'
                    label='Naam'
                    disabled
                    value={`${modalUser.first_name} ${modalUser.last_name}`}
                  />
                </div>
                <div>
                  <StackItem style={{ paddingTop: '10px' }}>
                    <TextField
                      name='email'
                      label='Email'
                      value={appUserEmail}
                      onChange={(e: any) => onChangeTextFieldValue(e, e.target.value)}
                    />
                  </StackItem>
                </div>
              </div>
            )}
            <Stack styles={{ root: { paddingTop: 15 } }}>
              {newUserLoading && <Spinner size={SpinnerSize.medium} />}
              {newUserData && newUserData.createAppUser && (
                <div>
                  <h5>De gebruiker is aangemaakt.</h5>
                  <div>
                    <strong>Bewaar deze gegevens:</strong>
                  </div>
                  {newUserData.createAppUser.password ? (
                    <div>
                      <strong>Wachtwoord: </strong>
                      {newUserData.createAppUser.password}
                    </div>
                  ) : (
                    <div>
                      <strong>Wachtwoord: </strong>geen wachtwoord. Dit betekend
                      dat de user al actief is in het systeem. We hebben deze
                      opnieuw gekoppeld.
                    </div>
                  )}
                  <div>
                    <strong>Login: </strong>
                    {newUserData.createAppUser.email}
                  </div>
                </div>
              )}
            </Stack>
          </Stack>
        </Stack.Item>
        <Stack horizontal>
          {!newUserData && (
            <Stack.Item align='end'>
              <PrimaryButton
                onClick={() => {
                  if (modalUser) {
                    createUser({
                      variables: {
                        data: {
                          employee_id: modalUser.id,
                          email: appUserEmail,
                        },
                      },
                    });
                  }
                }}
                text='Gebruiker toevoegen'
                styles={{ root: { marginRight: '15px' } }}
              />
            </Stack.Item>
          )}
          <Stack.Item align='end' style={{ paddingTop: '10px' }}>
            <DefaultButton
              onClick={toggleHideModal}
              text={
                newUserData && newUserData.createAppUser
                  ? 'Sluiten'
                  : 'Annuleren'
              }
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AppUserModal;

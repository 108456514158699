import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  DetailsListLayoutMode,
  IColumn,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import moment from 'moment';
import { IIncomingInvoice } from '../../../utils/IncomingInvoice';

const IncomingInvoicesOverview = ({
  incomingInvoices,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
}: any) => {
  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Intern nr',
      fieldName: 'internal_invoice_no',
      minWidth: 65,
      maxWidth: 65,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>{incomingInvoice.internal_invoice_no}</span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Leverancier',
      fieldName: 'supplier',
      minWidth: 160,
      maxWidth: 160,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>{incomingInvoice.supplier.name}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Factuurnr',
      fieldName: 'invoice_no',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>{incomingInvoice.invoice_no}</span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Datum ingave',
      fieldName: 'date_created',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          {incomingInvoice.date_created &&
            moment(new Date(incomingInvoice.date_created)).format('YYYY-MM-DD')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Vervaldatum',
      fieldName: 'due_date',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          {incomingInvoice.due_date &&
            moment(new Date(incomingInvoice.due_date)).format('YYYY-MM-DD')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Factuurdatum',
      fieldName: 'invoice_date',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          {incomingInvoice.invoice_date &&
            moment(new Date(incomingInvoice.invoice_date)).format('YYYY-MM-DD')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'Betaald',
      fieldName: 'paid',
      minWidth: 30,
      maxWidth: 30,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          <Checkbox disabled defaultChecked={incomingInvoice.paid} />
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column8',
      name: 'Wings betaald',
      fieldName: 'paid_in_wings',
      minWidth: 30,
      maxWidth: 30,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          <Checkbox disabled defaultChecked={incomingInvoice.paid_in_wings} />
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column9',
      name: 'Excl. BTW',
      fieldName: 'total_excl_vat',
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          {/* &euro;{' '}
          {incomingInvoice.incoming_invoice_line_items
            .reduce((previousValue, currentValue) => {
              return previousValue + currentValue.unit_price;
            }, 0)
            .toFixed(2)} */}
          &euro; {incomingInvoice.total_excl_vat.toFixed(2)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column10',
      name: 'Incl. BTW',
      fieldName: 'total_incl_vat',
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          {/* &euro;{' '}
          {incomingInvoice.incoming_invoice_line_items
            .reduce((previousValue, currentValue) => {
              return previousValue + currentValue.unit_price;
            }, 0)
            .toFixed(2)} */}
          &euro; {incomingInvoice.total_incl_vat.toFixed(2)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  document.title = '3bouw | Inkomende facturen';

  return (
    <>
      {incomingInvoices && (
        <>
          <ShimmeredDetailsList
            items={incomingInvoices}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            // selectionMode={SelectionMode.single}
            // selection={selection}
            selectionMode={SelectionMode.none}
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && incomingInvoices.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default IncomingInvoicesOverview;

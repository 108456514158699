import React from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
  Label,
} from '@fluentui/react';
import PhoneInput from 'react-phone-input-2';
import { modalContentStyles } from '../../../utils';

const ArchitectDetail = ({
  isOpen,
  dismissPanel,
  saveArchitect,
  architect,
  setArchitect,
}: any) => {
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setArchitect((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        architect && architect.id ? 'Architect wijzigen' : 'Architect toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {architect && architect.id && <Label>ID: {architect.id}</Label>}

          <Stack
            style={{
              marginBottom: 10,
              flexDirection: 'row',
            }}
          >
            <StackItem style={{ width: '100%' }}>
              <TextField
                name='company'
                label='Bedrijf'
                value={architect && architect.company ? architect.company : ''}
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  architect && !architect.company ? 'Dit veld is verplicht' : ''
                }
              />
            </StackItem>
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StackItem style={{ width: '49.5%' }}>
              <TextField
                name='first_name'
                label='Voornaam'
                value={
                  architect && architect.first_name ? architect.first_name : ''
                }
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  architect && !architect.first_name
                    ? 'Dit veld is verplicht'
                    : ''
                }
              />
            </StackItem>
            <StackItem style={{ width: '49.5%' }}>
              <TextField
                name='last_name'
                label='Achternaam'
                value={
                  architect && architect.last_name ? architect.last_name : ''
                }
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  architect && !architect.first_name
                    ? 'Dit veld is verplicht'
                    : ''
                }
              />
            </StackItem>
          </Stack>

          <Stack style={{ marginBottom: 5 }}>
            <TextField
              name='address'
              label='Adres'
              value={architect && architect.address ? architect.address : ''}
              onChange={onChangeTextFieldValue}
            />
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <StackItem style={{ width: '25%' }}>
              <TextField
                name='zip_code'
                placeholder='Postcode'
                type='number'
                value={
                  architect && architect.zip_code ? architect.zip_code : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
            <StackItem style={{ width: '74%' }}>
              <TextField
                name='city'
                placeholder='Gemeente'
                value={architect && architect.city ? architect.city : ''}
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <StackItem style={{ width: '49.5%' }}>
              <Label>GSM</Label>
              <PhoneInput
                country='be'
                preferredCountries={['be']}
                value={architect && architect.mobile_v2}
                onChange={phone =>
                  setArchitect((prevState: any) => ({
                    ...prevState,
                    mobile_v2: phone,
                  }))
                }
                inputStyle={{
                  width: '100%',
                  border: '1px solid rgb(96, 94, 92)',
                  borderRadius: '2px',
                }}
                buttonStyle={{
                  border: '1px solid rgb(96, 94, 92)',
                  borderRadius: '2px',
                }}
              />
              {architect && !architect.mobile_v2 && architect.mobile && (
                <div>{architect.mobile}</div>
              )}
            </StackItem>
            <StackItem style={{ width: '49.5%' }}>
              <Label>Telefoon</Label>
              <PhoneInput
                country='be'
                preferredCountries={['be']}
                value={architect && architect.phone_v2}
                onChange={phone => {
                  setArchitect((prevState: any) => ({
                    ...prevState,
                    phone_v2: phone,
                  }));
                }}
                inputStyle={{
                  width: '100%',
                  border: '1px solid rgb(96, 94, 92)',
                  borderRadius: '2px',
                }}
                buttonStyle={{
                  border: '1px solid rgb(96, 94, 92)',
                  borderRadius: '2px',
                }}
              />
              {architect && !architect.phone_v2 && architect.phone && (
                <div>{architect.phone}</div>
              )}
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <StackItem style={{ width: '49.5%' }}>
              <TextField
                name='email'
                label='E-mail'
                value={architect && architect.email ? architect.email : ''}
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
            <StackItem style={{ width: '49.5%' }}>
              <TextField
                name='website'
                label='Website'
                value={architect && architect.website ? architect.website : ''}
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
              flexDirection: 'row',
            }}
          >
            <StackItem style={{ width: '100%' }}>
              <TextField
                name='comments'
                label='Opmerkingen'
                multiline
                value={
                  architect && architect.comments ? architect.comments : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                onClick={saveArchitect}
                style={{ marginRight: 10 }}
                disabled={
                  !architect ||
                  (architect &&
                    !architect.first_name &&
                    !architect.last_name &&
                    !architect.company)
                }
              >
                Opslaan
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default ArchitectDetail;

import React, { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import moment from 'moment';
import { Stack, TextField, Toggle } from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import {
  GET_INCOMING_INVOICES,
  IIncomingInvoice,
} from '../../utils/IncomingInvoice';
import DateView from '../../components/parts/DateView';
import IncomingInvoicesOverview from './components/IncomingInvoicesOverview';

interface IPagedIncomingInvoice {
  incomingInvoices: IIncomingInvoice[];
  skip: number;
  take: number;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const IncomingInvoices = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedIncomingInvoice>({
    incomingInvoices: [],
    skip: 0,
    take: default_page_size,
    // filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'internal_invoice_no',
  });

  const [filter, setFilter] = useState('');
  const [isPaid, setIsPaid] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    start_date: '',
    end_date: '',
  });

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({
      ...prevState,
      incomingInvoices: [],
      skip: 0,
    }));
  }, [filter, dateFilter, isPaid]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (
    filterString: string,
    dates: {
      start_date: string;
      end_date: string;
    },
  ) => {
    const filterArray = filterString.split(' ');

    const filters: any = {
      AND: [
        {
          paid: isPaid,
        },
      ],
    };

    if (dates.start_date || dates.end_date) {
      filters.AND.push({
        invoice_date: {
          gte: dates.start_date
            ? moment(new Date(dates.start_date))
            : undefined,
          lte: dates.end_date ? moment(new Date(dates.end_date)) : undefined,
        },
      });
    }

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { invoice_no: { contains: filterArray[i] } },
          { internal_invoice_no: { contains: filterArray[i] } },
          { supplier: { name: { contains: filterArray[i] } } },
        ],
      };

      filters.AND.push(filterValue);
    }

    return filters;
  };

  const {
    loading,
    error,
    refetch: refetchIncomingInvoices,
  } = useQuery(GET_INCOMING_INVOICES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, dateFilter),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      if (
        x &&
        x.findManyIncomingInvoices &&
        x.findManyIncomingInvoices.length > 0
      ) {
        setPagedState(prevState => ({
          ...prevState,
          incomingInvoices: pagedState.incomingInvoices.concat(
            x.findManyIncomingInvoices,
          ),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(
            x.findManyIncomingInvoices.length < default_page_size
          ),
        }));
      } else if (x && x.findManyIncomingInvoices) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearIncomingInvoices();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const onChangeFilterInUse = () => {
    setIsPaid(!isPaid);
    clearIncomingInvoices();
    refetchIncomingInvoices();
  };

  const clearIncomingInvoices = () => {
    setPagedState(prevState => ({
      ...prevState,
      incomingInvoices: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.incomingInvoices.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Inkomende facturen</h3>

      <TextField
        label='Zoeken...'
        value={filter}
        styles={textFieldStyles300}
        onChange={onSearchValueChange}
      />

      <Stack style={{ flexDirection: 'row' }}>
        <Stack style={{ flexDirection: 'row', marginRight: 70 }}>
          <Stack style={{ marginBottom: 5, marginRight: 15 }}>
            <DateView
              item={dateFilter}
              setItem={setDateFilter}
              date={dateFilter && dateFilter.start_date}
              label='Startdatum'
              field='start_date'
              onChangeDate={(date: string) =>
                setDateFilter(prevState => ({ ...prevState, start_date: date }))
              }
            />
          </Stack>

          <Stack style={{ marginBottom: 5 }}>
            <DateView
              item={dateFilter}
              setItem={setDateFilter}
              date={dateFilter && dateFilter.end_date}
              label='Einddatum'
              field='end_date'
              onChangeDate={(date: string) =>
                setDateFilter(prevState => ({ ...prevState, end_date: date }))
              }
            />
          </Stack>
        </Stack>

        <Toggle
          label='Betaald?'
          defaultChecked={isPaid}
          onText='Betaald'
          offText='Niet betaald'
          onChange={onChangeFilterInUse}
        />
      </Stack>
      <IncomingInvoicesOverview
        incomingInvoices={pagedState.incomingInvoices}
        setIncomingInvoices={setPagedState}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchIncomingInvoices={refetchIncomingInvoices}
        clearIncomingInvoices={clearIncomingInvoices}
      />
    </Stack>
  );
};

export default IncomingInvoices;

import { gql } from '@apollo/client';
import { ILead } from './Lead';

export interface IQuoteReference {
  comments?: string;
  description: string;
  id: number;
  lead_id: number;
  quote_date: Date;
  quote_no: string;
  lead: ILead;
}

export const GET_QUOTE_REFERENCES = gql`
  query getQuoteReferences(
    $filter: QuoteReferenceFilterInput
    $orderBy: QuoteReferenceOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyQuoteReferences(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      quote_no
      quote_date
      description
      lead {
        id
        first_name1
        first_name2
        last_name1
        last_name2
        status
        sales_rep {
          first_name
          last_name
        }
      }
    }
  }
`;

export const GET_QUOTE_REFERENCE = gql`
  query getQuoteReference($where: QuoteReferenceWhereInput) {
    findOneQuoteReference(where: $where) {
      id
      quote_no
      quote_date
      description
      comments
      lead {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
    }
  }
`;

export const ADD_QUOTE_REFERENCE = gql`
  mutation AddQuoteReference($data: QuoteReferenceCreateInput!) {
    createQuoteReference(data: $data) {
      id
    }
  }
`;

export const UPDATE_QUOTE_REFERENCE = gql`
  mutation UpdateQuoteReference($id: Int!, $data: QuoteReferenceUpdateInput!) {
    updateQuoteReference(id: $id, data: $data) {
      id
      quote_no
      quote_date
      description
      comments
    }
  }
`;

export const DELETE_QUOTE_REFERENCE = gql`
  mutation deleteQuoteReference($where: QuoteReferenceWhereInput) {
    deleteQuoteReference(where: $where) {
      id
    }
  }
`;

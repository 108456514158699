import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  ADD_COST_CENTER,
  GET_COST_CENTER_BY_ID,
  ICostCenter,
  UPDATE_COST_CENTER,
} from '../../../utils/CostCenter';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { IContactPerson } from '../../../utils';
import CostCenterDetail from './CostCenterDetail';

const CostCentersOverview = ({
  costCenters,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  clearCostCenters,
  refetchCostCenters,
}: any) => {
  const [costCenter, setCostCenter] = useState<ICostCenter | undefined>(
    undefined,
  );
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [addCostCenter] = useMutation(ADD_COST_CENTER);
  const [modifyCostCenter] = useMutation(UPDATE_COST_CENTER);

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ICostCenter | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  useQuery(GET_COST_CENTER_BY_ID, {
    notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: (data) => {
      setCostCenter(data.findOneCostCenter);
    },
  });

  const openCostCenterDetail = (newCostCenter?: boolean) => {
    if (selectionDetails && !newCostCenter) {
      // getCostCenter({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setCostCenter(undefined);
    }
    setIsPanelOpen(true);
  };

  const saveCostCenter = () => {
    if (costCenter) {
      const allInput = {
        name: costCenter.name,
        active: costCenter.active || false,
        customer_specific: costCenter.customer_specific || false,
      };

      if (costCenter.id) {
        modifyCostCenter({
          variables: {
            id: costCenter.id,
            data: allInput,
          },
        }).then(() => {
          clearCostCenters();
          refetchCostCenters();
          setIsPanelOpen(false);
        });
      } else {
        addCostCenter({
          variables: {
            data: allInput,
          },
        }).then(() => {
          clearCostCenters();
          refetchCostCenters();
          setIsPanelOpen(false);
        });
      }
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending = column.fieldName !== sortedColumn[0].fieldName
            ? false
            : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      minWidth: 500,
      maxWidth: 500,
      isRowHeader: true,
      isSorted: true,
      onColumnClick,
      onRender: (costCenter: ICostCenter) => <span>{costCenter.name}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Actief',
      fieldName: 'active',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onColumnClick,
      onRender: (costCenter: ICostCenter) => (
        <span>
          <Checkbox disabled defaultChecked={costCenter.active} />
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Werfpost',
      fieldName: 'customer_specific',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onColumnClick,
      onRender: (costCenter: ICostCenter) => (
        <span>
          <Checkbox disabled defaultChecked={costCenter.customer_specific} />
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
    if (column.fieldName === sortedField) {
      column.isSorted = true;
      column.isSortedDescending = !isSortedAsc;
      return column;
    }
    return column;
  });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openCostCenterDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openCostCenterDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (costCenter && isPanelOpen) {
      document.title = `3bouw | Boekhoudkundige post - ${costCenter.name}`;
    } else {
      document.title = '3bouw | Boekhoudkundige posten';
    }
  }, [costCenter, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1034px'
        maxWidth='1034px'
      />

      <CostCenterDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        costCenter={costCenter}
        saveCostCenter={saveCostCenter}
        setCostCenter={setCostCenter}
      />

      {costCenters && (
        <>
          <ShimmeredDetailsList
            items={costCenters}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selection={selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                // display: items.length === costCenters.length ? 'block' : 'none',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && costCenters.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default CostCentersOverview;

import React from 'react';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import moment from 'moment';

import Logo from '../../../3Bouw_logo.png';

import fontSegoe from '../../../fonts/Segoe_UI.ttf';
import fontSegoeBold from '../../../fonts/Segoe_UI_Bold.ttf';
import { IPurchaseOrderLine, IPurchaseOrder } from '../../../utils';

Font.register({
  family: 'Segoe UI',
  fonts: [{ src: fontSegoe }, { src: fontSegoeBold, fontStyle: 'bold' }],
});

const PurchaseOrderDownloadTemplate = ({
  purchaseOrder,
}: {
  purchaseOrder: IPurchaseOrder;
}) => {
  console.log(purchaseOrder);
  return (
    <Document>
      <Page size='A4' style={styles.page} wrap>
        {/* Header repeated on every page */}
        <PageHeader purchaseOrder={purchaseOrder} />
        <View style={styles.pageHeader}>
          <View>
            <PageSupplierInfo purchaseOrder={purchaseOrder} />
          </View>
        </View>

        {/* PurchaseOrder */}
        <PagePurchaseOrderLines purchaseOrder={purchaseOrder} />
        {purchaseOrder.remarks ? (
          <View
            style={[styles.borderTop, styles.purchaseOrderDetailsFull]}
            // wrap={false}
          >
            <Text style={[styles.purchaseOrderDetailsCellBold]}>
              Opmerkingen:
            </Text>
            <Text style={[styles.purchaseOrderDetailsCell, styles.paddingTop5]}>
              {purchaseOrder.remarks}
            </Text>
          </View>
        ) : null}
        <PagePurchaseOrderDeliveryDetails purchaseOrder={purchaseOrder} />

        {/* Footer repeated on every page */}
        {/* Legal information */}
        <PageLegalInfo />
        <Text style={styles.now} fixed>
          {moment(new Date()).format('DD/MM/YYYY')}
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Pagina ${pageNumber} van ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const PageHeader = ({ purchaseOrder }: { purchaseOrder: IPurchaseOrder }) => (
  <View style={styles.pageHeaderWrapper} fixed>
    <View style={styles.pageHeader}>
      <Image src={Logo} style={styles.image} />
      <View>
        <Text style={styles.pageHeaderTitle}>
          {purchaseOrder.is_purchase_order ? 'Bestelbon' : 'Prijsaanvraag'}{' '}
          {purchaseOrder.purchase_order_no}
        </Text>
        <Text style={styles.pageHeaderName}>
          {purchaseOrder && purchaseOrder.construction_site && (
            <>Werf: {purchaseOrder.construction_site.name}</>
          )}
        </Text>
      </View>
    </View>
  </View>
);
const PageLegalInfo = () => (
  <View style={[styles.legalInfo]} fixed wrap={false}>
    {/* <Text>Maatschappelijke zetel:</Text> */}
    <View style={styles.legalInfoRow}>
      <Text>
        <Text style={{ fontStyle: 'bold' }}>3Bouw BV</Text> Zone Reme 1, 2260
        Westerlo <Text style={{ fontStyle: 'bold' }}>T</Text> 014/70 47 47{' '}
        <Text style={{ fontStyle: 'bold' }}>E</Text> info@3bouw.be{' '}
        <Text style={{ fontStyle: 'bold' }}>BTW</Text> BE 0884.733.931
      </Text>
    </View>
    <View style={styles.legalInfoRow}>
      <Text>
        RPR Antwerpen, afd. Turnhout &bull; Erkend aannemer klasse 5 onder
        erkenningsnr.: 32.454 <Text style={{ fontStyle: 'bold' }}>KBC</Text>{' '}
        iban BE16 7310 2542 3974
      </Text>
    </View>
  </View>
);

const PagePurchaseOrderDeliveryDetails = ({
  purchaseOrder,
}: {
  purchaseOrder: IPurchaseOrder;
}) => (
  <View style={styles.purchaseOrderDetails} fixed wrap={false}>
    <View>
      <Text style={styles.exBTW}>* alle prijzen zijn exclusief BTW</Text>
    </View>
    {(purchaseOrder.deliver_to_supplier ||
      purchaseOrder.construction_site ||
      purchaseOrder.is_on_call ||
      purchaseOrder.delivery_date) && (
      <View style={[styles.purchaseOrderDetailsRow, styles.borderTop]}>
        <View style={styles.purchaseOrderDetailsLeft}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.purchaseOrderDetailsCellBold]}>
              Leveringsadres:
            </Text>
            {purchaseOrder.delivery_to_other_supplier &&
            purchaseOrder.deliver_to_supplier ? (
              <View>
                <Text
                  style={[styles.purchaseOrderDetailsCell, styles.paddingTop5]}
                >
                  {purchaseOrder.deliver_to_supplier.name}
                </Text>
                <Text style={styles.purchaseOrderDetailsCell}>
                  {purchaseOrder.deliver_to_supplier.address}
                  {',\n '}
                  {purchaseOrder.deliver_to_supplier.zip_code}{' '}
                  {purchaseOrder.deliver_to_supplier.city}
                </Text>
              </View>
            ) : null}

            {!purchaseOrder.delivery_to_other_supplier &&
            purchaseOrder.construction_site ? (
              <View>
                <Text
                  style={[styles.purchaseOrderDetailsCell, styles.paddingTop5]}
                >
                  {purchaseOrder.construction_site.address}
                  {', \n'}
                  {purchaseOrder.construction_site.zip_code}{' '}
                  {purchaseOrder.construction_site.city}
                </Text>
                <Text style={styles.purchaseOrderDetailsCell}>
                  {purchaseOrder.construction_site &&
                    purchaseOrder.construction_site.comments &&
                    purchaseOrder.construction_site.comments}
                </Text>
              </View>
            ) : null}
          </View>
          {/* {purchaseOrder.construction_site && (
              <View style={{ flexDirection: 'row' }}>
                <Text style={[styles.purchaseOrderDetailsCellBold]}>
                  Referentie:
                </Text>
                <Text
                  style={[styles.purchaseOrderDetailsCell, styles.paddingTop5]}
                >
                  {purchaseOrder.construction_site.name}
                </Text>
              </View>
            )} */}
        </View>

        {purchaseOrder.is_on_call ? (
          <View style={styles.purchaseOrderDetailsRight}>
            <Text style={styles.purchaseOrderDetailsCellBold}>Op afroep:</Text>
            <Text style={[styles.purchaseOrderDetailsCell, styles.paddingTop5]}>
              {moment(purchaseOrder.delivery_date).format('DD/MM/YYYY')}
            </Text>
          </View>
        ) : purchaseOrder.delivery_date ? (
          <View style={styles.purchaseOrderDetailsRight}>
            <Text style={styles.purchaseOrderDetailsCellBold}>
              {!purchaseOrder.no_delivery_hour && 'Leveringsdatum:'}
              {purchaseOrder.no_delivery_hour && 'Loop van de dag'}
            </Text>
            {purchaseOrder.no_delivery_hour && (
              <Text
                style={[styles.purchaseOrderDetailsCell, styles.paddingTop5]}
              >
                {moment(purchaseOrder.delivery_date).format('DD/MM/YYYY')}
              </Text>
            )}
            {!purchaseOrder.no_delivery_hour && (
              <Text
                style={[styles.purchaseOrderDetailsCell, styles.paddingTop5]}
              >
                {+moment(purchaseOrder.delivery_date).format('HH') > 0 ||
                +moment(purchaseOrder.delivery_date).format('mm') > 0
                  ? moment(purchaseOrder.delivery_date).format(
                      'DD/MM/YYYY - HH:mm',
                    )
                  : moment(purchaseOrder.delivery_date).format('DD/MM/YYYY')}
              </Text>
            )}
          </View>
        ) : null}
      </View>
    )}
  </View>
);

const PagePurchaseOrderLines = ({
  purchaseOrder,
}: {
  purchaseOrder: IPurchaseOrder;
}) => (
  <View style={styles.table}>
    {/* Table header repeated on every page */}
    <View style={styles.tableRowHeader} fixed>
      <Text style={styles.tableColDescription}>Omschrijving</Text>
      <Text style={styles.tableColQuantity}>Aantal</Text>
      <Text style={styles.tableColUnit}>Eenheid</Text>
      <Text style={styles.tableColPrice}>Prijs*</Text>
    </View>

    {/* Lines */}
    {purchaseOrder.line_items && purchaseOrder.line_items.length > 0
      ? purchaseOrder.line_items
          .slice()
          .sort((a, b) => (a.weight || 1) - (b.weight || 1))
          .map((line: IPurchaseOrderLine) => (
            <View style={styles.tableRow}>
              <Text style={styles.tableColDescription}>{line.name}</Text>
              <Text style={styles.tableColQuantity}>
                {line.quantity ? line.quantity : null}
              </Text>
              <Text style={styles.tableColUnit}>
                {line.measuring_unit && line.measuring_unit.id !== 9
                  ? line.measuring_unit.name
                  : null}
              </Text>
              <Text style={styles.tableColPrice}>
                {line.unit_price ? `€ ${line.unit_price}` : null}
              </Text>
              {line.comment && line.comment.length > 0 ? (
                <Text style={styles.tableColRemarks}>
                  Opmerking: {line.comment}
                </Text>
              ) : null}
            </View>
          ))
      : null}
  </View>
);

const PageSupplierInfo = ({
  purchaseOrder,
}: {
  purchaseOrder: IPurchaseOrder;
}) => (
  <View>
    <View style={styles.supplierDetailsRow}>
      <View style={{ flexDirection: 'row', width: '45%' }}>
        <Text style={styles.supplierDetailsLabel}>Aan:</Text>
        {purchaseOrder.supplier && (
          <Text>
            {purchaseOrder.supplier.name}
            {'\n'}
            {purchaseOrder.supplier.address}
            {'\n'}
            {purchaseOrder.supplier.zip_code} {purchaseOrder.supplier.city}
          </Text>
        )}
      </View>

      {purchaseOrder.supplier && (
        <View style={styles.supplierDetailsRight}>
          {(purchaseOrder.supplier && purchaseOrder.supplier.contact_persons) ||
          (purchaseOrder.contact_person &&
            purchaseOrder.contact_person.last_name) ? (
            // eslint-disable-next-line react/jsx-indent
            <PageSupplierInfoRow
              label='T.a.v.'
              value={
                purchaseOrder.is_default_contact_person &&
                purchaseOrder.supplier &&
                purchaseOrder.supplier.contact_persons &&
                purchaseOrder.supplier.contact_persons.length > 0
                  ? `${purchaseOrder.supplier.contact_persons[0].first_name} ${purchaseOrder.supplier.contact_persons[0].last_name}`
                  : purchaseOrder.contact_person
                  ? `${purchaseOrder.contact_person.first_name} ${purchaseOrder.contact_person.last_name}`
                  : ''
              }
            />
          ) : null}

          {purchaseOrder && purchaseOrder.supplier && (
            <View>
              <PageSupplierInfoRow
                label='Tel.'
                value={
                  purchaseOrder.supplier.phone_v2 ||
                  purchaseOrder.supplier.phone
                }
              />
              <PageSupplierInfoRow
                label='E-mail'
                value={purchaseOrder.supplier.email}
              />
            </View>
          )}

          {purchaseOrder.construction_site &&
            purchaseOrder.construction_site.site_manager && (
              <PageSupplierInfoRow
                label='Werfleider'
                value={`${purchaseOrder.construction_site.site_manager.first_name} ${purchaseOrder.construction_site.site_manager.last_name} - ${purchaseOrder.construction_site.site_manager.mobile}`}
              />
            )}
        </View>
      )}
    </View>
  </View>
);

const PageSupplierInfoRow = ({
  label,
  value,
}: {
  label?: string;
  value?: string;
}) => (
  <View style={styles.supplierDetailsRow}>
    <Text style={styles.supplierDetailsLabelAlt}>{label}:</Text>
    <Text style={styles.supplierDetailsValueAlt}>{value}</Text>
  </View>
);

const styles = StyleSheet.create({
  image: {
    width: '100px',
    objectFit: 'scale-down',
    marginRight: 15,
  },
  bold: {
    fontStyle: 'bold',
  },
  borderTop: {
    borderTopWidth: 2,
    borderColor: '#76B729',
  },
  exBTW: {
    fontSize: 11,
    marginBottom: 5,
  },
  legalInfo: {
    fontFamily: 'Segoe UI',
    fontSize: 8,
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 140,
    paddingTop: 2,
  },
  legalInfoBold: {
    fontStyle: 'bold',
  },
  legalInfoLeft: {
    textAlign: 'left',
    width: '33%',
  },
  legalInfoMiddle: {
    alignItems: 'baseline',
    textAlign: 'center',
    width: '34%',
  },
  legalInfoRight: {
    textAlign: 'right',
    width: '33%',
  },
  legalInfoRow: {
    flexDirection: 'row',
  },

  now: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 45,
    right: 40,
    // color: 'grey',
  },
  paddingTop10: {
    paddingTop: 10,
  },
  paddingTop5: {
    paddingTop: 3,
  },
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 140,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 95,
  },
  pageHeaderWrapper: {
    position: 'absolute',
    top: 45,
    left: 45,
    right: 45,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  pageHeaderName: {
    fontFamily: 'Segoe UI',
    fontSize: 12,
  },
  pageHeaderTitle: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
  },
  pageNumber: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 40,
    textAlign: 'right',
    // color: 'grey',
  },

  purchaseOrderDetails: {
    paddingTop: '20px',
    position: 'absolute',
    bottom: 70,
    left: 40,
    right: 40,
    paddingBottom: 4,
  },
  purchaseOrderDetailsCell: {
    fontFamily: 'Segoe UI',
    fontSize: 11,
  },
  purchaseOrderDetailsCellBold: {
    fontFamily: 'Segoe UI',
    fontSize: 11,
    fontStyle: 'bold',
    paddingBottom: 0,
    paddingTop: '3px',
    paddingRight: '5px',
  },
  purchaseOrderDetailsFull: {
    width: '98.5%',
    // flexDirection: 'row',
    marginLeft: 4,
    marginTop: 15,
  },
  purchaseOrderDetailsLeft: {
    width: '60%',
    // flexDirection: 'row',
  },
  purchaseOrderDetailsRight: {
    width: '40%',
    flexDirection: 'row',
  },
  purchaseOrderDetailsRow: {
    flexDirection: 'row',
  },

  supplierDetailsFont: {
    fontFamily: 'Segoe UI',
    fontSize: 11,
  },
  supplierDetailsLabel: {
    fontStyle: 'bold',
    width: '15%',
  },
  supplierDetailsLabelAlt: {
    fontStyle: 'bold',
    width: '25%',
  },
  supplierDetailsRight: {
    width: '50%',
    borderLeft: '1px solid black',
    paddingLeft: 10,
  },
  supplierDetailsRow: {
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
    flexDirection: 'row',
    display: 'flex',
  },
  supplierDetailsValue: {
    width: '32%',
  },
  supplierDetailsValueAlt: {
    width: '75%',
  },

  table: {
    display: 'flex',
    width: 'auto',
    paddingTop: '15px',
  },
  tableColDescription: {
    width: '65%',
  },
  tableColQuantity: {
    width: '10%',
  },
  tableColUnit: {
    width: '10%',
  },
  tableColPrice: {
    width: '10%',
  },
  tableColRemarks: {
    marginTop: '10px',
    width: '65%',
  },
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#D2D4D1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
  tableRowHeader: {
    backgroundColor: '#D2D4D1',
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    justifyContent: 'space-between',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  manager: {
    textAlign: 'center',
    fontSize: 11,
    fontStyle: 'bold',
    fontFamily: 'Segoe UI',
  },
});

export default PurchaseOrderDownloadTemplate;

import React, {
  useState, FormEvent, useRef, useEffect,
} from 'react';
import {
  Stack,
  Checkbox,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  Dropdown,
  IDropdownOption,
  TextField,
  PrimaryButton,
  Icon,
  MessageBar,
  MessageBarType,
  // SuggestionsHeaderFooterItem,
} from '@fluentui/react';
import _, { property } from 'lodash';
import { getTheme } from '@fluentui/react/lib/Styling';
import {
  property_types,
  stackTokens10,
  convertPropertyListTypeToDropdownOptions,
  IPropertyType,
  convertPropertyTypeToComboBoxOptions,
  IPropertyListType,
  iconProps,
} from '../../../utils';
import { ITemplateState } from '../Template';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { Heading } from '../../../components/typography';
import {
  defaultTemplateItem,
  ITemplateItem,
} from '../../../utils/TemplateItem';
import TemplateTextItem from './TemplateTextItem';
import TemplateDocumentItem from './TemplateDocumentItem';
import TemplateDateItem from './TemplateDateItem';
import TemplateSuggestedValueItem from './TemplateSuggestedValueItem';
import TemplateMultiValueItem from './TemplateMultiValueItem';
import TemplateItemChild from './TemplateItemChild';

const theme = getTheme();

type ITemplateAddModifyItemProps = {
  selectedItem?: ITemplateItem;
  // selectedItemParent?: ITemplateItem;
  originalType: any;
  templateID?: any;
  tappoints: any;
  // callBackItem: any;
  listTypes: IPropertyListType[];
  types: IPropertyType[];
  suggestedValuecategories: any;
  parentId: number;
  // hasNewItemName: boolean;
  setCurrentItem: (item: ITemplateItem) => void;
  validateTemplateItem: (item: ITemplateItem) => boolean;
};

const TemplateAddModifyItem = ({
  templateID,
  selectedItem,
  // selectedItemParent,
  originalType,
  tappoints,
  // callBackItem,
  listTypes,
  types,
  suggestedValuecategories,
  parentId,
  // hasNewItemName = false,
  setCurrentItem,
  validateTemplateItem,
}: ITemplateAddModifyItemProps) => {
  const [needWaterPoints, setNeedWaterPoints] = useState(
    selectedItem ? selectedItem.needs_water_points : false,
  );

  const [showDeleteMessageBar, setShowDeleteMessageBar] = useState(false);

  const [selectedAccordionItem, setSelectedAccordionItem] = useState<
    number | string | undefined
  >();

  const defaultType = undefined;

  // When the TemplateItem is a parent, it loses its original type and therefor the ability to be edited.
  // So when it is a parent, the original type is passed along and all the controls are diverted to use this one.
  // 9 is id of string, should fetch id from inputP
  const comboboxPropertyType = useRef<IComboBox>(null);

  const [values, setValues] = useState<number[]>(
    selectedItem && selectedItem.list_types
      ? selectedItem.list_types.split(',').map((el: string) => +el)
      : [],
  );

  const callBackItem = (currentItem: ITemplateItem) => {
    const item = _.cloneDeep(currentItem);

    // const parentId = selectedItemParent ? selectedItemParent.id : undefined;
    if (item) {
      if (item.id > 0) {
        // Update
      } else {
        // Add
        // item.parent_id = parentId;
      }

      setCurrentItem(item);
    }
  };

  const updateMultiValueType = (newType: IPropertyType) => {
    const currentItem = selectedItem ? _.cloneDeep(selectedItem) : undefined;
    if (currentItem) {
      currentItem.nested_type = newType;
      setCurrentItem(currentItem);
    }
  };

  const updateType = (type: IPropertyType) => {
    const currentItem = selectedItem
      ? _.cloneDeep(selectedItem)
      : _.cloneDeep(defaultTemplateItem);
    if (currentItem) {
      currentItem.type = type;
      setCurrentItem(currentItem);
    }
  };

  const updateName = (name?: string) => {
    const currentItem = selectedItem
      ? _.cloneDeep(selectedItem)
      : _.cloneDeep(defaultTemplateItem);
    if (currentItem) {
      currentItem.name = name || '';
      setCurrentItem(currentItem);
    }
  };

  const callBackWaterPoints = (checked: boolean) => {
    const currentItem = selectedItem
      ? _.cloneDeep(selectedItem)
      : _.cloneDeep(defaultTemplateItem);
    if (currentItem) {
      const newItem = _.cloneDeep(currentItem);
      if (newItem) {
        newItem.needs_water_points = checked;
      }

      if (newItem.list_types) {
        const selectedIndex = newItem.list_types.indexOf(tappoints.id);
        if (checked && selectedIndex === -1) {
          newItem.list_types += `,${tappoints.id}`;
          setValues([...values, tappoints.id]);
        } else if (!checked && selectedIndex >= 0) {
          newItem.list_types = newItem.list_types
            .replace(tappoints.id, '')
            .replace(',,', ',')
            .replace(/(^,)|(,$)/g, ''); // regex to trim trailing comma
          setValues(newItem.list_types.split(',').map((el: string) => +el));
        }
      } else if (checked) {
        newItem.list_types = tappoints.id.toString();
        setValues([tappoints.id]);
      }
      callBackItem(newItem);
    }
  };

  const updateValues = (option: IDropdownOption) => {
    const currentItem = selectedItem
      ? _.cloneDeep(selectedItem)
      : _.cloneDeep(defaultTemplateItem);
    if (currentItem) {
      let result: number[] = [];
      const data = {
        needs_water_points: !!(currentItem && currentItem.needs_water_points),
      };

      if (option.selected) {
        // Add
        result = [...values, +option.key];

        if (+option.key === tappoints.id) {
          setNeedWaterPoints(true);
          data.needs_water_points = true;
        }
      } else {
        // Remove
        result = [...values];
        result.splice(values.indexOf(+option.key), 1);

        if (+option.key === tappoints.id) {
          setNeedWaterPoints(false);
          data.needs_water_points = false;
        }
      }
      setValues(result);

      const newItem: ITemplateItem = { ...currentItem, ...data };

      newItem.list_types = listTypes
        .filter((el: IPropertyListType) => result.indexOf(el.id) >= 0)
        .map((el: IPropertyListType) => el.id)
        .join(',');

      callBackItem(newItem);
    }
  };

  const getPropertyType = (id: number | string): IPropertyType | undefined => {
    if (id) {
      return types.filter((el: IPropertyType) => el.id === id)[0];
    }
    return undefined;
  };

  const updateReportingValue = (isReporting: boolean) => {
    const currentItem = selectedItem
      ? _.cloneDeep(selectedItem)
      : _.cloneDeep(defaultTemplateItem);
    if (currentItem) {
      let newItem = currentItem;
      newItem = {
        ...newItem,
        is_reporting_param: isReporting,
      };
      setCurrentItem(newItem);
    }
  };
  const updateReportingLabel = (label: string) => {
    const currentItem = selectedItem
      ? _.cloneDeep(selectedItem)
      : _.cloneDeep(defaultTemplateItem);
    if (currentItem) {
      let newItem = currentItem;
      newItem = {
        ...newItem,
        reporting_label: label,
      };
      setCurrentItem(newItem);
    }
  };

  const addChild = () => {
    const currentItem = selectedItem ? _.cloneDeep(selectedItem) : undefined;
    const currentChildren = currentItem ? currentItem.children : undefined;
    let newSortKey = 0;

    if (currentChildren && currentChildren.length > 0) {
      for (let i = 0; i < currentChildren.length; i++) {
        const child = currentChildren[i];
        if (newSortKey < child.child_pos_execution_list) newSortKey = child.child_pos_execution_list;
      }
    }

    newSortKey++; // add 1 to the maximum number

    const defaultItem = _.cloneDeep(defaultTemplateItem);
    defaultItem.name = 'nieuw item';
    defaultItem.dirty = false;
    defaultItem.draft = true;
    defaultItem.child_pos_execution_list = newSortKey;

    if (currentItem) {
      let currentChildren = currentItem.children;
      if (currentChildren) {
        currentChildren.push({ ...defaultItem });
      } else {
        currentChildren = [{ ...defaultItem }];
      }

      currentItem.children = currentChildren;

      setCurrentItem(currentItem);
    }
  };

  const updateChild = (index: number, child: ITemplateItem) => {
    const currentItem = selectedItem ? _.cloneDeep(selectedItem) : undefined;

    if (currentItem) {
      const currentChildren = currentItem.children;
      if (
        currentChildren
        && currentChildren.length > 0
        && currentChildren[index]
      ) {
        currentChildren[index] = child;
        currentChildren[index].dirty = true;
        currentItem.children = currentChildren;
        setCurrentItem(currentItem);
      }
    }
  };

  const toggleDeleteChild = (index: number) => {
    const currentItem = selectedItem ? _.cloneDeep(selectedItem) : undefined;
    const child = currentItem && currentItem.children && currentItem.children[index]
      ? _.cloneDeep(currentItem.children[index])
      : undefined;

    if (child) {
      child.delete = !child.delete;
      child.dirty = true;
      updateChild(index, child);
    }
  };

  const moveChildDown = (index: number) => {
    const currentItem = _.cloneDeep(selectedItem);
    if (currentItem && currentItem.children) {
      const children = currentItem.children;
      const currentChildSortKey = currentItem.children[index]
        ? currentItem.children[index].child_pos_execution_list
        : undefined;
      const nextChildSortKey = currentItem.children[index + 1]
        ? currentItem.children[index + 1].child_pos_execution_list
        : undefined;

      if (
        typeof currentChildSortKey !== 'undefined'
        && typeof nextChildSortKey !== 'undefined'
      ) {
        children[index].child_pos_execution_list = nextChildSortKey;
        children[index].dirty = true;
        children[index + 1].child_pos_execution_list = currentChildSortKey;
        children[index + 1].dirty = true;
      }

      const newChildren = children.sort((a, b) => a.child_pos_execution_list - b.child_pos_execution_list);

      currentItem.children = newChildren;

      setCurrentItem(currentItem);
    }
  };

  const moveChildUp = (index: number) => {
    const currentItem = _.cloneDeep(selectedItem);
    if (currentItem && currentItem.children) {
      const children = currentItem.children;
      const currentChildSortKey = currentItem.children[index]
        ? currentItem.children[index].child_pos_execution_list
        : undefined;
      const prevChildSortKey = currentItem.children[index - 1]
        ? currentItem.children[index - 1].child_pos_execution_list
        : undefined;

      if (
        typeof currentChildSortKey !== 'undefined'
        && typeof prevChildSortKey !== 'undefined'
      ) {
        children[index].child_pos_execution_list = prevChildSortKey;
        children[index].dirty = true;
        children[index - 1].child_pos_execution_list = currentChildSortKey;
        children[index - 1].dirty = true;
      }

      const newChildren = children.sort((a, b) => a.child_pos_execution_list - b.child_pos_execution_list);

      currentItem.children = newChildren;

      setCurrentItem(currentItem);
    }
  };

  const makeEditable = (e: any) => {
    const currentItem = _.cloneDeep(selectedItem);
    if (currentItem) {
      currentItem.editType = true;

      setCurrentItem(currentItem);
    }
  };

  useEffect(() => {
    let show = false;
    if (selectedItem && selectedItem.children) {
      for (let i = 0; i < selectedItem.children.length; i++) {
        if (selectedItem.children[i].delete) show = true;
      }
    }

    setShowDeleteMessageBar(show);
  }, [selectedItem]);
  return (
    <Stack tokens={stackTokens10}>
      {(!selectedItem || !selectedItem.id || selectedItem.draft) && (
        <ComboBox
          componentRef={comboboxPropertyType}
          selectedKey={
            selectedItem && selectedItem.type
              ? selectedItem.type.id
              : defaultType
          }
          label='Type'
          allowFreeform
          autoComplete='on'
          onChange={(
            event: FormEvent<IComboBox>,
            option?: IComboBoxOption,
            //   index?: number,
            //   value?: string,
          ) => {
            if (option) {
              const type = getPropertyType(option.key);
              if (type) updateType(type);
            }
          }}
          options={convertPropertyTypeToComboBoxOptions(
            types.filter((type) => type.name !== property_types.PARENT),
          )}
          required
        />
      )}

      <TextField
        label='Naam'
        placeholder='Voer een naam in'
        value={selectedItem ? selectedItem.name : ''}
        onChange={(
          event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ) => {
          updateName(newValue);
        }}
        // errorMessage={hasNewItemName ? '' : 'Kies een naam!'}
        required
      />

      {selectedItem
        && selectedItem.id
        && !selectedItem.draft
        && !selectedItem.editType && (
          <Stack horizontal style={{ marginTop: '15px' }}>
            <Stack.Item align="center">
              <span
                style={{
                  fontWeight: 600,
                  paddingTop: '10px',
                  paddingBottom: '5px',
                  marginRight: '5px',
                }}
              >
                Type
              </span>
              {selectedItem.type.label}
            </Stack.Item>
            <Stack.Item style={{ marginLeft: '5px' }} align="center">
              <PrimaryButton onClick={makeEditable}>Edit</PrimaryButton>
            </Stack.Item>
          </Stack>
      )}

      {selectedItem
        && selectedItem.id
        && !selectedItem.draft
        && selectedItem.editType && (
          <Stack>
            <ComboBox
              componentRef={comboboxPropertyType}
              selectedKey={
              selectedItem && selectedItem.type
                ? selectedItem.type.id
                : defaultType
            }
              label='Type'
              allowFreeform
              autoComplete='on'
              onChange={(
                event: FormEvent<IComboBox>,
                option?: IComboBoxOption,
              //   index?: number,
              //   value?: string,
              ) => {
                if (option) {
                  const type = getPropertyType(option.key);
                  if (type) updateType(type);
                }
              }}
              options={convertPropertyTypeToComboBoxOptions(
                types.filter((type) => type.name !== property_types.PARENT),
              )}
              required
            />
            <span style={{ display: 'block', marginTop: '5px' }}>Let op! Dit kan de huidige configuratie verstoren en tot ongewenste instellingen leiden.</span>
          </Stack>
      )}
      <Stack
        style={{
          backgroundColor:
            selectedItem && selectedItem.type
              ? theme.palette.neutralLighterAlt
              : 'transparent',
          marginTop: '20px',
          marginBottom: '10px',
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor:
            selectedItem && selectedItem.type
              ? theme.palette.neutralLighter
              : 'transparent',
          padding: '15px',
        }}
      >
        {selectedItem
          && selectedItem.type
          && (selectedItem.type.name === property_types.STRING
            || selectedItem.type.name === property_types.PARENT) && (
            <TemplateTextItem
              key={selectedItem.type.id}
              item={selectedItem}
              callBack={callBackItem}
            />
        )}
        {selectedItem
          && selectedItem.type
          && selectedItem.type.name === property_types.NOTE && (
            <TemplateTextItem
              key={selectedItem.type.id}
              item={selectedItem}
              callBack={callBackItem}
            />
        )}
        {selectedItem
          && selectedItem.type
          && selectedItem.type.name === property_types.MULTI_VALUE && (
            <TemplateMultiValueItem
              key={selectedItem.type.id}
              item={selectedItem}
              updateName={(newName) => {
                const currentItem = { ...selectedItem };
                currentItem.name = newName;
                callBackItem(currentItem);
              }}
              updateType={(newType) => {
                updateMultiValueType(newType);
              }}
              updateValue={(value) => {
                const currentItem = { ...selectedItem };
                currentItem.value = value;
                callBackItem(currentItem);
              }}
              updateItem={(item) => {
                callBackItem(item);
              }}
              types={types}
              // hasNewItemName={hasNewItemName}
              suggestedValuecategories={suggestedValuecategories}
            />
        )}
        {selectedItem
          && selectedItem.type
          && selectedItem.type.name === property_types.DOCUMENT && (
            <TemplateDocumentItem
              key={selectedItem.type.id}
              item={selectedItem}
              callBack={callBackItem}
              type={selectedItem.type}
              template={templateID}
              // hasNewItemName={hasNewItemName}
            />
        )}
        {selectedItem
          && selectedItem.type
          && selectedItem.type.name === property_types.DATE && (
            <TemplateDateItem
              key={selectedItem.type.id}
              item={selectedItem}
              callBack={callBackItem}
              type={selectedItem.type}
              // hasNewItemName={hasNewItemName}
            />
        )}
        {selectedItem
          && selectedItem.type
          && selectedItem.type.name === property_types.SUGGESTED_VALUE && (
            <TemplateSuggestedValueItem
              key={selectedItem.type.id}
              item={selectedItem}
              callBack={callBackItem}
              type={selectedItem.type}
              categories={suggestedValuecategories}
              // hasNewItemName={hasNewItemName}
            />
        )}
        {selectedItem
          && selectedItem.type
          && selectedItem.type.name === property_types.MULTI_VALUE
          && selectedItem.nested_type
          && selectedItem.nested_type.name === property_types.PARENT && (
            <Stack>
              <Stack.Item style={{ marginBottom: '10px', marginTop: '10px' }}>
                <h3>Onderliggende velden</h3>
              </Stack.Item>
              <Stack.Item style={{ marginBottom: '10px' }}>
                {showDeleteMessageBar && (
                  <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline
                    // dismissButtonAriaLabel='Close'
                  >
                    Opgepast! Bij het verwijderen van onderliggende velden zal
                    dit effect hebben op alle templates waar dit veld toegepast
                    is.
                  </MessageBar>
                )}
              </Stack.Item>
              <Stack.Item>
                <Accordion
                  selectedKey={selectedAccordionItem}
                  defaultKey={undefined}
                  toggleItem={(key: string | number) => {
                    if (selectedAccordionItem === key) setSelectedAccordionItem(undefined);
                    else setSelectedAccordionItem(key);
                  }}
                >
                  {selectedItem.children
                    && selectedItem.children.length > 0
                    && selectedItem.children
                      .sort(
                        (a, b) => a.child_pos_execution_list
                          - b.child_pos_execution_list,
                      )
                      .map((child, index) => (
                        <AccordionItem
                          key={child.id || `temp${index.toString()}`}
                          id={child.id || `temp${index.toString()}`}
                          title={(
                            <Stack horizontal>
                              <Stack.Item
                                onClick={() => {
                                  if (selectedAccordionItem === child.id) setSelectedAccordionItem(undefined);
                                  else setSelectedAccordionItem(child.id);
                                }}
                                style={{
                                  textDecoration: child.delete
                                    ? 'line-through'
                                    : undefined,
                                  marginRight: '10px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '200px',
                                }}
                              >
                                {child.draft && (
                                <Icon iconName="LocationDot" />
                                )}
                                {!validateTemplateItem(child) && (
                                <Icon
                                  iconName="LocationDot"
                                  style={{ color: 'red' }}
                                />
                                )}
                                {child.name}
                              </Stack.Item>
                              <Stack.Item>
                                <Icon
                                  iconName={
                                      child.delete ? 'Refresh' : 'Delete'
                                    }
                                  styles={iconProps}
                                  onClick={() => {
                                    toggleDeleteChild(index);
                                  }}
                                />
                                <Icon
                                  iconName='Up'
                                  styles={iconProps}
                                  onClick={() => {
                                    moveChildUp(index);
                                  }}
                                />
                                <Icon
                                  iconName='Down'
                                  styles={iconProps}
                                  onClick={() => {
                                    moveChildDown(index);
                                  }}
                                />
                              </Stack.Item>
                            </Stack>
                            )}
                        >
                          <TemplateItemChild
                            updateItem={(item) => {
                              updateChild(index, item);
                            }}
                            item={child}
                            // hasNewItemName
                            suggestedValueCategories={
                                suggestedValuecategories
                              }
                            propertyTypes={types}
                            templateID={templateID}
                          />
                        </AccordionItem>
                      ))}
                </Accordion>
              </Stack.Item>

              <Stack.Item style={{ marginBottom: '10px', marginTop: '10px' }}>
                <PrimaryButton
                  text='Voeg een onderliggend veld toe'
                  onClick={addChild}
                />
              </Stack.Item>
            </Stack>
        )}
      </Stack>
      {selectedItem
        && parentId === 0
        && selectedItem.type.name !== property_types.DATE && (
          <Stack tokens={stackTokens10}>
            <Checkbox
              label='Heeft waterpunten nodig?'
              checked={needWaterPoints}
              onChange={(
                event?: FormEvent<HTMLElement | HTMLInputElement>,
                checked?: boolean,
              ) => {
                setNeedWaterPoints(!needWaterPoints);
                callBackWaterPoints(!!checked);
              }}
            />
          </Stack>
      )}
      {selectedItem && parentId === 0 ? (
        // eslint-disable-next-line react/jsx-indent
        <Stack tokens={stackTokens10}>
          <Dropdown
            placeholder='Kies lijsttype(s)'
            multiSelect
            selectedKeys={values}
            options={convertPropertyListTypeToDropdownOptions(listTypes).map(
              (el: IDropdownOption) => (el.key === tappoints.id
                ? {
                  key: el.key,
                  text: el.text,
                  disabled: true,
                }
                : el),
            )}
            onChange={(
              event: FormEvent<HTMLDivElement>,
              option?: IDropdownOption | undefined,
              // index?: number | undefined,
            ) => {
              if (option) updateValues(option);
            }}
          />
        </Stack>
      ) : null}

      {selectedItem && selectedItem.type ? (
        <Stack tokens={stackTokens10}>
          <Checkbox
            label='Voor rapportage?'
            checked={selectedItem.is_reporting_param}
            onChange={(
              event?: FormEvent<HTMLElement | HTMLInputElement>,
              checked?: boolean,
            ) => {
              // setIsReportingParam(!isReportingParam);
              updateReportingValue(checked!);
            }}
          />
          {selectedItem.is_reporting_param ? (
            <TextField
              label='Rapportage label'
              placeholder='Voer een standaard waarde in'
              value={selectedItem.reporting_label}
              onChange={(
                event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string,
              ) => {
                if (newValue || newValue === '') {
                  updateReportingLabel(newValue);
                }
              }}
            />
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default TemplateAddModifyItem;

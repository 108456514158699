import { gql } from '@apollo/client';
import { ISupplier } from './Supplier';

export const ADD_PROPERTY_SUPPLIER = gql`
  mutation AddPropertySupplier($data: PropertySupplierCreateInput!) {
    createPropertySupplier(data: $data) {
      id
      property {
        id
      }
      supplier {
        id
      }
    }
  }
`;

export const DELETE_PROPERTY_SUPPLIER = gql`
  mutation DeletePropertySupplier($where: PropertySupplierWhereInput) {
    deletePropertySupplier(where: $where) {
      id
    }
  }
`;

export interface IPropertySupplier {
  id: number;
  supplier: ISupplier;
  dirty?: boolean;
  new?: boolean;
  delete?: boolean;
}

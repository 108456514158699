import { Stack, StackItem } from '@fluentui/react';
import moment from 'moment';
import React from 'react';
import {
  ISuggestedValue,
  ISuggestedValueCategory,
  IProperty,
  property_types,
  stackTokens5,
} from '../../../utils';

const ConstructionSheetRemarks = ({
  item,
  suggestedvalues,
  horizontal = false,
}: any) => {
  const renderChild = (item: IProperty) => {
    switch (item.type.name) {
      case property_types.STRING:
        return <StackItem>{`${item.name}: ${item.value || ''}`}</StackItem>;
      case property_types.DATE:
        return (
          <StackItem>
            {`${item.name}: ${
              item.value ? moment(item.value).format('DD/MM/YYYY') : ''
            }`}
          </StackItem>
        );
      case property_types.SUGGESTED_VALUE: {
        const category = suggestedvalues.filter(
          (el: ISuggestedValueCategory) => el.category_code === item.remote_category,
        );

        if (category && category.length === 1) {
          return (
            <StackItem>
              {`${item.name}: ${
                category[0].category_description
              } (${category[0].suggested_values
                .map((el: ISuggestedValue) => {
                  if (
                    item.suggested_value
                    && item.suggested_value.id
                    && item.suggested_value.id === el.id
                  ) {
                    return `"${el.suggested_value}"`;
                  }
                  return el.suggested_value;
                })
                .join(', ')}) `}
            </StackItem>
          );
        }
        break;
      }
      case property_types.DOCUMENT:
        return (
          <StackItem>
            {`Document: ${item.document ? item.document.name : ''} (${
              item.document && item.document.is_external ? 'Ext.)' : 'Int.)'
            }`}
          </StackItem>
        );
      default:
        break;
    }
  };

  const renderRemarks = (item: IProperty) => {
    switch (item.type.name) {
      case property_types.STRING: {
        return item.value;
      }
      case property_types.DATE: {
        if (item.value) {
          return moment(item.value).format('DD/MM/YYYY');
        }
        return moment().format('DD/MM/YYYY');
      }
      case property_types.DOCUMENT: {
        return 'document{name} ({type})'
          .replace(
            '{name}',
            item.document && item.document.name ? ` ${item.document.name}` : '',
          )
          .replace(
            '{type}',
            item.document && item.document.is_external ? 'ext.' : 'int.',
          );
      }
      case property_types.SUGGESTED_VALUE: {
        const category = suggestedvalues.filter(
          (el: ISuggestedValueCategory) => el.category_code === item.remote_category,
        );

        if (category && category.length === 1) {
          return `${
            category[0].category_description
          }, (${category[0].suggested_values
            .map((el: ISuggestedValue) => el.suggested_value)
            .join(', ')}) `;
        }
        break;
      }
      case property_types.PARENT: {
        if (item.children && item.children.length > 0) {
          if (horizontal) {
            return (
              <Stack tokens={stackTokens5} horizontal>
                {item.value || ''}
                {item.children.map((child: IProperty) => renderChild(child))}
              </Stack>
            );
          }
          return (
            <Stack tokens={stackTokens5}>
              {item.value || ''}
              {item.children.map((child: IProperty) => renderChild(child))}
            </Stack>
          );
        }
        break;
      }
      default:
        break;
    }
  };

  return renderRemarks(item) === '' ? null : <span>{renderRemarks(item)}</span>;
};
export default ConstructionSheetRemarks;

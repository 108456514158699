import { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
  IComboBoxOption,
  Label,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import moment from 'moment';
import useDebounce from '../../components/hooks/useDebounce';
import {
  convertSalesRepsToComboBoxOptions,
  default_page_size,
  GET_SALES_REPS,
  ISalesRep,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import {
  GET_QUOTE_REFERENCES,
  IQuoteReference,
} from '../../utils/QuoteReference';
import { ComboboxWithFilter } from '../../components/parts';
import DateView from '../../components/parts/DateView';
import QuotesOverview from './components/QuotesOverview';

interface IPagedQuote {
  quotes: IQuoteReference[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const Quotes = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedQuote>({
    quotes: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'quote_no',
  });

  const [isReport, setIsReport] = useState(false);
  const [filter, setFilter] = useState('');
  const [salesRep, setSalesRep] = useState<number>(2);
  const [salesReps, setSalesReps] = useState<ISalesRep[]>([]);
  const [dateFilter, setDateFilter] = useState({
    start_date: '',
    end_date: '',
  });

  useQuery(GET_SALES_REPS, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setSalesReps(x.findManySalesReps);
    },
  });

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, quotes: [], skip: 0 }));
  }, [filter, dateFilter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');
    const filters: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { quote_no: { contains: filterArray[i] } },
          { description: { contains: filterArray[i] } },
          {
            lead: {
              OR: [
                { first_name1: { contains: filterArray[i] } },
                { last_name1: { contains: filterArray[i] } },
                { first_name2: { contains: filterArray[i] } },
                { last_name2: { contains: filterArray[i] } },
                {
                  sales_rep: {
                    OR: [
                      { first_name: { contains: filterArray[i] } },
                      { last_name: { contains: filterArray[i] } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      };

      filters.AND.push(filterValue);
    }

    return filters;
  };

  const getReportFilters = (filter: string) => {
    const filters: any = {
      AND: [
        {
          lead: {
            sales_rep: {
              id: { equals: salesRep !== 0 ? +salesRep : undefined },
            },
          },
        },
      ],
    };

    return filters;
  };

  const {
    loading,
    error,
    refetch: refetchQuotes,
  } = useQuery(GET_QUOTE_REFERENCES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter:
        debouncedValue && debouncedValue.length > 0
          ? isReport
            ? getReportFilters(debouncedValue)
            : getFilters(debouncedValue)
          : isReport
          ? {
              lead: {
                sales_rep: {
                  id: { equals: salesRep !== 0 ? +salesRep : undefined },
                },
              },
              quote_date:
                dateFilter.start_date && !dateFilter.end_date
                  ? { gte: dateFilter.start_date }
                  : dateFilter.end_date && !dateFilter.start_date
                  ? { lte: dateFilter.end_date }
                  : {},
              AND:
                dateFilter.start_date && dateFilter.end_date
                  ? [
                      {
                        quote_date: {
                          gte: moment(
                            new Date(dateFilter.start_date),
                          ).toISOString(),
                        },
                      },
                      {
                        quote_date: {
                          lte: moment(
                            new Date(dateFilter.end_date),
                          ).toISOString(),
                        },
                      },
                    ]
                  : [],
            }
          : {},
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      if (
        x &&
        x.findManyQuoteReferences &&
        x.findManyQuoteReferences.length > 0
      ) {
        setPagedState(prevState => ({
          ...prevState,
          quotes: pagedState.quotes.concat(x.findManyQuoteReferences),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(x.findManyQuoteReferences.length < default_page_size),
        }));
      } else if (x && x.findManyQuoteReferences) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearQuotes();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearQuotes = () => {
    setPagedState(prevState => ({
      ...prevState,
      quotes: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.quotes.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  const showDossiersSold = () => {
    const dossiers: number[] = [];
    pagedState.quotes.map(({ lead }) => {
      if (lead.status === 'SOLD' && !dossiers.includes(lead.id)) {
        dossiers.push(lead.id);
      }
    });
    return dossiers.length;
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>
        {isReport ? 'Rapport offertes' : 'Offertes'}
      </h3>

      <PrimaryButton
        onClick={() => {
          clearQuotes();
          setIsReport(!isReport);
        }}
        style={{ width: 200 }}
      >
        {!isReport ? 'Bekijk rapportage' : 'Bekijk overzicht'}
      </PrimaryButton>

      {isReport ? (
        <Stack
          style={{ flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <StackItem>
            <Stack style={{ flexDirection: 'row', marginBottom: 5 }}>
              {salesReps && salesReps.length > 0 && (
                <StackItem style={{ width: 300, marginRight: 25 }}>
                  <ComboboxWithFilter
                    label='Verkoper'
                    options={convertSalesRepsToComboBoxOptions(salesReps)}
                    value={salesRep || ''}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        setSalesRep(newValue[0].key as number);
                        clearQuotes();
                        refetchQuotes();
                      }
                    }}
                  />
                </StackItem>
              )}
            </Stack>

            <Stack
              style={{
                flexDirection: 'row',
              }}
            >
              <Stack style={{ marginBottom: 5, marginRight: 15 }}>
                <DateView
                  item={dateFilter}
                  setItem={setDateFilter}
                  date={dateFilter && dateFilter.start_date}
                  label='Startdatum'
                  field='start_date'
                  onChangeDate={() => clearQuotes()}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <DateView
                  item={dateFilter}
                  setItem={setDateFilter}
                  date={dateFilter && dateFilter.end_date}
                  label='Einddatum'
                  field='end_date'
                  onChangeDate={() => clearQuotes()}
                />
              </Stack>
            </Stack>
          </StackItem>
          <StackItem style={{ textAlign: 'right' }}>
            <StackItem>
              <Label>Aantal offertes gemaakt: {pagedState.quotes.length}</Label>
            </StackItem>
            <StackItem>
              <Label>Aantal dossiers verkocht: {showDossiersSold()}</Label>
            </StackItem>
          </StackItem>
        </Stack>
      ) : (
        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />
      )}

      <QuotesOverview
        quotes={pagedState.quotes}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchQuotes={refetchQuotes}
        clearQuotes={clearQuotes}
        isReport={isReport}
      />
    </Stack>
  );
};

export default Quotes;

import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Checkbox,
  Selection,
} from '@fluentui/react';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import {
  convertConstructionSitesToComboBoxOptions,
  GET_CONSTRUCTION_SITES,
  GET_CONSTRUCTION_SITE_BY_ID,
  IConstructionSite,
  modalContentStyles,
} from '../../../utils';
import {
  ComboboxWithFilter,
  CommandBarSticky,
} from '../../../components/parts';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import {
  ADD_CUSTOMER_CONTRACT_UPDATE,
  GET_CUSTOMER_CONTRACT_UPDATES,
  GET_CUSTOMER_CONTRACT_UPDATE_BY_ID,
  ICustomerContractUpdate,
  UPDATE_CUSTOMER_CONTRACT_UPDATE,
} from '../../../utils/CustomerContractUpdate';
import { ICustomerContractUpdateLineItem } from '../../../utils/CustomerContractUpdateLineItem';
import { commandBarTheme } from '../../../theme';
import useDebounce from '../../../components/hooks/useDebounce';
import {
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  IConstructionSiteCreateUpdate,
} from '../../../utils/ConstructionSite';
import ContractUpdateDetail from './ContractUpdateDetail';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  customerContractUpdateSource: ICustomerContractUpdate;
  constructionSite?: IConstructionSite | IConstructionSiteCreateUpdate;
  skipFetchConstructionSites?: boolean;
};

const CustomerContractUpdateDetail = ({
  isOpen,
  dismissPanel,
  customerContractUpdateSource,
  constructionSite,
  skipFetchConstructionSites,
}: Props) => {
  const [customerContractUpdate, setCustomerContractUpdate] = useState(
    customerContractUpdateSource,
  );

  const { data } = useQuery(GET_CUSTOMER_CONTRACT_UPDATE_BY_ID, {
    variables: customerContractUpdateSource &&
      customerContractUpdateSource.id && {
        where: { id: customerContractUpdateSource.id },
      },
    skip: !customerContractUpdateSource || !customerContractUpdateSource.id,
    onCompleted: data => {
      setCustomerContractUpdate(data.findOneCustomerContractUpdate);
    },
  });

  // Save contract update
  const [addCustomerContractUpdate] = useMutation(
    ADD_CUSTOMER_CONTRACT_UPDATE,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id:
                skipFetchConstructionSites &&
                constructionSite &&
                constructionSite.id
                  ? constructionSite.id
                  : customerContractUpdate.construction_site
                  ? customerContractUpdate.construction_site.id
                  : undefined,
            },
          },
        },
      ],
    },
  );
  const [modifyCustomerContractUpdate] = useMutation(
    UPDATE_CUSTOMER_CONTRACT_UPDATE,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id:
                skipFetchConstructionSites &&
                constructionSite &&
                constructionSite.id
                  ? constructionSite.id
                  : customerContractUpdate.construction_site
                  ? customerContractUpdate.construction_site.id
                  : undefined,
            },
          },
        },
      ],
    },
  );

  const saveCustomerContractUpdate = () => {
    if (customerContractUpdate) {
      const constructionSiteID =
        skipFetchConstructionSites && constructionSite && constructionSite.id
          ? constructionSite.id
          : customerContractUpdate.construction_site
          ? customerContractUpdate.construction_site.id
          : undefined;

      if (!constructionSiteID) return; // notification??
      const allInput = {
        status: customerContractUpdate.status || 'NEW',
        summary: customerContractUpdate.summary,
        construction_site: {
          connect: {
            id: constructionSiteID,
          },
        },
      };

      if (customerContractUpdate.id) {
        modifyCustomerContractUpdate({
          variables: {
            id: customerContractUpdate.id,
            data: allInput,
          },
        }).then(() => {
          // refetchConstructionSite(); -> refetch construction_site
          dismissPanel();
        });
      } else {
        addCustomerContractUpdate({
          variables: {
            data: allInput,
          },
        }).then(() => {
          // refetchConstructionSite();
          dismissPanel();
        });
      }
    }
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCustomerContractUpdate((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  // contract update detail
  const [isContractUpdateDetailPanelOpen, setIsContractUpdateDetailPanelOpen] =
    useState(false);
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  // Selection line items
  const [contractUpdateDetail, setContractUpdateDetail] = useState<
    ICustomerContractUpdateLineItem | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setContractUpdateDetail(currentSelection[0]);
    } else {
      setContractUpdateDetail(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openLineItemDetail = (newLineItem?: boolean) => {
    if (newLineItem) {
      setContractUpdateDetail({});
    }
    setIsContractUpdateDetailPanelOpen(true);
  };

  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');
  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { data: constructionSitesData } = useQuery(GET_CONSTRUCTION_SITES, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        name: {
          contains: debouncedFilter,
        },
      },
    },
    skip: !!skipFetchConstructionSites,
  });

  const constructionSites =
    constructionSitesData && constructionSitesData.findManyConstructionSites;

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        customerContractUpdate && customerContractUpdate.id
          ? 'Min-/meerwerk wijzigen'
          : 'Min-/meerwerk toevoegen'
      }
      type={PanelType.custom}
      customWidth='1000px'
    >
      {contractUpdateDetail && isContractUpdateDetailPanelOpen && (
        <ContractUpdateDetail
          isOpen={isContractUpdateDetailPanelOpen}
          dismissPanel={() => {
            setIsContractUpdateDetailPanelOpen(false);
          }}
          contractUpdateDetailSource={contractUpdateDetail}
          customerContractUpdate={customerContractUpdate}
        />
      )}

      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {customerContractUpdate && (
            <Label>ID: {customerContractUpdate.id}</Label>
          )}

          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <Label>Document no: {customerContractUpdate.document_no}</Label>
            </Stack>

            <Stack
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <StackItem style={{ width: '49%' }}>
                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='summary'
                    label='Omschrijving'
                    multiline
                    value={
                      customerContractUpdate && customerContractUpdate.summary
                        ? customerContractUpdate.summary
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                    required
                  />
                </Stack>

                <Stack
                  style={{
                    marginBottom: 10,
                  }}
                >
                  {constructionSites ? (
                    <ComboboxWithFilter
                      label='Werf'
                      options={convertConstructionSitesToComboBoxOptions(
                        skipFetchConstructionSites
                          ? [constructionSite]
                          : constructionSites,
                      )}
                      allowFreeForm
                      value={
                        skipFetchConstructionSites &&
                        constructionSite &&
                        constructionSite.id
                          ? constructionSite.id
                          : customerContractUpdate &&
                            customerContractUpdate.construction_site
                          ? customerContractUpdate.construction_site.id
                          : ''
                      }
                      multiline={false}
                      callBack={(newValue: IComboBoxOption[]) => {
                        if (newValue && newValue.length > 0) {
                          const result = { ...customerContractUpdate };

                          result.construction_site = {
                            id: newValue[0].key as number,
                          };

                          setCustomerContractUpdate(result);
                        }
                      }}
                      setFilter={(value: string) => {
                        setConstructionSiteFilter(value);
                      }}
                      required
                    />
                  ) : (
                    <>
                      <Label>Werf</Label>
                      <p>
                        {(constructionSite && constructionSite.name) ||
                          (customerContractUpdate &&
                            customerContractUpdate.construction_site &&
                            customerContractUpdate.construction_site.name)}
                      </p>
                    </>
                  )}
                </Stack>

                <Stack
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <ComboboxWithFilter
                    label='Status'
                    options={[
                      { key: 'NEW', text: 'Nieuw' },
                      { key: 'CANCELLED', text: 'Geannuleerd' },
                      { key: 'INVOICED', text: 'Op factuur' },
                      { key: 'COMPLETED', text: 'Afgesloten' },
                    ]}
                    value={
                      customerContractUpdate && customerContractUpdate.status
                        ? customerContractUpdate.status
                        : 'NEW'
                    }
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...customerContractUpdate };

                        result.status = newValue[0].key as string;

                        setCustomerContractUpdate(result);
                      }
                    }}
                  />
                </Stack>
              </StackItem>

              <StackItem style={{ width: '49%' }}>
                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='intro'
                    label='Inleidende tekst'
                    multiline
                    value={
                      customerContractUpdate && customerContractUpdate.intro
                        ? customerContractUpdate.intro
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='outro'
                    label='Afsluitende tekst'
                    multiline
                    value={
                      customerContractUpdate && customerContractUpdate.outro
                        ? customerContractUpdate.outro
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </StackItem>
            </Stack>
          </Stack>

          <Stack style={{ marginBottom: 10, marginTop: 30 }}>
            {customerContractUpdate && customerContractUpdate.id ? (
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                <AccordionItem
                  key='accordion-invoice-contract-updates'
                  id='accordion-invoice-contract-updates'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>Detail</h3>
                    </Stack>
                  }
                >
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openLineItemDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openLineItemDetail(),
                        theme: commandBarTheme,
                        disabled:
                          !contractUpdateDetail || !contractUpdateDetail.id,
                      },
                    ]}
                    theme={commandBarTheme}
                    width='1200px'
                    maxWidth='1200px'
                  />
                  <ShimmeredDetailsList
                    items={
                      data && data.findOneCustomerContractUpdate
                        ? data.findOneCustomerContractUpdate
                            .customer_contract_update_line_items
                        : []
                    }
                    columns={[
                      {
                        key: 'column1',
                        name: 'Omschrijving',
                        fieldName: 'description',
                        minWidth: 160,
                        maxWidth: 160,
                        isRowHeader: true,
                        onRender: (line_item: any) => (
                          <span>{line_item.description}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Aantal',
                        fieldName: 'units',
                        minWidth: 20,
                        maxWidth: 20,
                        isRowHeader: true,
                        onRender: (line_item: any) => (
                          <span>{line_item.units}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Eenheidsprijs',
                        fieldName: 'unit_price',
                        minWidth: 70,
                        maxWidth: 70,
                        isRowHeader: true,
                        onRender: (line_item: any) => (
                          <span>&euro; {line_item.unit_price.toFixed(2)}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'BTW %',
                        fieldName: 'percentage_vat',
                        minWidth: 30,
                        maxWidth: 30,
                        isRowHeader: true,
                        onRender: (line_item: any) => (
                          <span>
                            {line_item.percentage_vat < 1
                              ? `${line_item.percentage_vat * 100}%`
                              : `${line_item.percentage_vat}%`}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column5',
                        name: 'Totaal excl. BTW',
                        fieldName: 'excl_vat',
                        minWidth: 80,
                        maxWidth: 80,
                        isRowHeader: true,
                        onRender: (line_item: any) => (
                          <span>
                            &euro;{' '}
                            {(line_item.unit_price * line_item.units).toFixed(
                              2,
                            )}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column6',
                        name: 'Totaal incl. BTW',
                        fieldName: 'incl_vat',
                        minWidth: 80,
                        maxWidth: 80,
                        isRowHeader: true,
                        onRender: (line_item: any) => (
                          <span>
                            &euro;{' '}
                            {!line_item.percentage_vat ||
                            +line_item.percentage_vat === 0
                              ? (
                                  line_item.unit_price * line_item.units
                                ).toFixed(2)
                              : +line_item.percentage_vat < 1
                              ? (
                                  line_item.unit_price *
                                  line_item.units *
                                  (1 + line_item.percentage_vat)
                                ).toFixed(2)
                              : (
                                  line_item.unit_price *
                                  line_item.units *
                                  (1 + line_item.percentage_vat / 100)
                                ).toFixed(2)}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column7',
                        name: 'Enkel totaal',
                        fieldName: 'show_only_total',
                        minWidth: 70,
                        maxWidth: 70,
                        isRowHeader: true,
                        onRender: (line_item: any) => (
                          <span>
                            <Checkbox
                              disabled
                              checked={line_item.show_only_total === true}
                            />
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selection}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                  />
                </AccordionItem>
              </Accordion>
            ) : (
              <>
                <Label>
                  Na opslaan kan je het detail van het min-/meerwerk aanpassen.
                </Label>
                <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={() => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key=''
                      id=''
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Detail
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                </Stack>
              </>
            )}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                onClick={saveCustomerContractUpdate}
                style={{ marginRight: 10 }}
                disabled={
                  !customerContractUpdate ||
                  (customerContractUpdate && !customerContractUpdate.summary) ||
                  (!customerContractUpdate.construction_site &&
                    !constructionSite)
                }
              >
                Opslaan
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default CustomerContractUpdateDetail;

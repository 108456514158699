import React, { useState } from 'react';
import {
  Checkbox,
  DefaultButton,
  Icon,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
// import SuggestedSectorView from './SuggestedSectorView';
import SuggestedView from '../../../components/parts/SuggestedView';
import { iconProps, modalContentStyles } from '../../../utils';
import Accordion, { AccordionItem } from '../../../components/parts/Accordion';
import { getDefaultContactPerson } from '../../../utils/ContactPerson';

const CostCenterDetail = ({
  isOpen,
  dismissPanel,
  saveCostCenter,
  costCenter,
  setCostCenter,
}: any) => {
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCostCenter((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const onChangeContactTextFieldValue = (
    index: number,
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (costCenter) {
      const contactPersons = costCenter.contact_persons;
      contactPersons[index] = {
        ...contactPersons[index],
        [(event.target as HTMLTextAreaElement).name]: newValue || '',
        dirty: true,
      };
      setCostCenter((prevState: any) => ({
        ...prevState,
        contact_persons: contactPersons,
      }));
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        costCenter && costCenter.id
          ? 'Boekhoudpost wijzigen'
          : 'Boekhoudpost toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='name'
                label='Naam'
                value={costCenter && costCenter.name ? costCenter.name : ''}
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  costCenter && !costCenter.name ? 'Dit veld is verplicht' : ''
                }
              />
            </Stack>

            <Stack style={{ marginTop: 10, marginBottom: 10 }}>
              <Checkbox
                name='active'
                label='Actief?'
                checked={costCenter && costCenter.active}
                onChange={() => {
                  setCostCenter((prevState: any) => ({
                    ...prevState,
                    active: costCenter.active ? !costCenter.active : true,
                  }));
                }}
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <Checkbox
                name='customer_specific'
                label='Werfpost?'
                checked={costCenter && costCenter.customer_specific}
                onChange={() => {
                  setCostCenter((prevState: any) => ({
                    ...prevState,
                    customer_specific: costCenter.customer_specific
                      ? !costCenter.customer_specific
                      : true,
                  }));
                }}
              />
            </Stack>
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                onClick={saveCostCenter}
                style={{ marginRight: 10 }}
                disabled={!costCenter || (costCenter && !costCenter.name)}
              >
                Opslaan
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default CostCenterDetail;

import { ICommandBarItemProps } from '@fluentui/react';
import React from 'react';
import { CommandBarSticky } from '../../../components/parts';
import { commandBarTheme } from '../../../theme';

const ConstructionSitesCommandBar = ({
  modifyFn,
  modifyDisabled = false,
  loadExecutionListFn,
  loadExecutionListDisabled = false,
  loadConstructionSheetFn,
  loadConstructionSheetDisabled = false,
  loadNewPurchaseOrderFn,
  loadNewPurchaseOrderDisabled = false,
  deleteFn,
  deleteDisabled = false,
}: any) => {
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => modifyFn(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => modifyFn(),
      disabled: modifyDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'executionlist',
      text: 'Uitvoeringslijst',
      iconProps: { iconName: 'NumberedList' },
      onClick: () => loadExecutionListFn(),
      disabled: loadExecutionListDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'constructionsheet',
      text: 'Werffiche',
      iconProps: { iconName: 'ClipboardList' },
      onClick: () => loadConstructionSheetFn(),
      disabled: loadConstructionSheetDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'newPurchaseOrder',
      text: 'Bestelbon',
      iconProps: { iconName: 'AddToShoppingList' },
      onClick: () => loadNewPurchaseOrderFn(),
      disabled: loadNewPurchaseOrderDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'delete',
      text: 'Verwijder',
      iconProps: { iconName: 'delete' },
      onClick: () => deleteFn(),
      disabled: deleteDisabled,
      theme: commandBarTheme,
    },
  ];

  return (
    <CommandBarSticky
      items={commandBaritems}
      theme={commandBarTheme}
      width='1034px'
      maxWidth='1034px'
    />
  );
};

export default ConstructionSitesCommandBar;

import {
  BlobServiceClient,
  HttpRequestBody,
  BlockBlobUploadOptions,
} from '@azure/storage-blob';

class BlobStorage {
  client: BlobServiceClient;

  constructor(account = '', sas = '') {
    if (account !== '' && sas !== '') {
      this.client = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sas}`,
      );
    } else {
      this.client = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/`,
      );
    }
  }

  async containerExists(containerName: string) {
    await this.getContainerList().then(
      (x: string[]) => x.indexOf(containerName) > -1,
    );
  }

  async getContainerList() {
    const containers = this.client.listContainers();
    const result: string[] = [];

    Object.keys(containers).map((container: any) => {
      result.push(container.name);
      return true;
    });

    // for await (const container of containers) {
    //   result.push(container.name);
    // }

    return result;
  }

  async createContainer(containerName = `newcontainer${new Date().getTime()}`) {
    const containerClient = this.client.getContainerClient(containerName);
    const createContainerResponse = await containerClient.createIfNotExists();
    console.log(
      `Create container ${containerName} successfully`,
      createContainerResponse.requestId,
    );
  }

  async deleteContainer(containerName: string) {
    const containerClient = this.client.getContainerClient(containerName);
    const deleteContainerResponse = await containerClient.deleteIfExists();
    console.log(
      `Delete container ${containerName} successfully`,
      deleteContainerResponse.requestId,
    );
  }

  async uploadBlob(
    containerName: string,
    blobName: string,
    content: HttpRequestBody,
    length: number,
    options?: BlockBlobUploadOptions,
  ) {
    const containerClient = this.client.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.upload(
      content,
      length,
      options,
    );

    console.log(
      `Upload block blob ${blobName} successfully`,
      uploadBlobResponse.requestId,
    );
  }

  async deleteBlob(containerName: string, blobName: string) {
    const containerClient = this.client.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const deleteBlobResponse = await blockBlobClient.deleteIfExists();
    console.log(
      `Deleted block blob ${blobName} successfully`,
      deleteBlobResponse.requestId,
    );
  }

  async getBlobsInsideContainerList(containerName: string) {
    const containerClient = this.client.getContainerClient(containerName);
    const blobs = containerClient.listBlobsFlat();
    const result: string[] = [];

    Object.keys(blobs).map((blob: any) => {
      result.push(blob.name);
      return true;
    });

    // for await (const blob of blobs) {
    //   result.push(blob.name);
    // }

    return result;
  }

  async downloadBlob(containerName: string, blobName: string) {
    const containerClient = this.client.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);

    // Get blob content from position 0 to the end
    // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
    const downloadBlockBlobResponse = await blobClient.download();

    const blobBody = await downloadBlockBlobResponse.blobBody;

    if (!blobBody) return;

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blobBody);
    a.setAttribute('download', blobName);
    a.click();
  }
}

export default BlobStorage;

import React, { FormEvent } from 'react';
import {
  PrimaryButton,
  TextField,
  Stack,
  DefaultButton,
  IComboBoxOption,
  StackItem,
} from '@fluentui/react';
import {
  convertArchitectsToComboBoxOptions,
  convertSalesRepsToComboBoxOptions,
  convertEmployeesToComboBoxOptions,
  stackTokens20,
  textFieldStyles500,
  convertTemplatesToComboBoxOptions,
  IArchitect,
  IEmployee,
  IConstructionSite,
  modalContentStyles,
} from '../../../../utils';
import { SpinnerDefault, TextFieldDefault } from '../../../../components/parts';
import ComboboxWithFilter from '../../../../components/parts/ComboboxWithFilter';
import { ILead, convertLeadsToComboBoxOptions } from '../../../../utils/Lead';

const comboBoxMultiStyle = {
  maxWidth: 500,
  display: 'block',
  marginTop: '10px',
};

interface ConstructionSiteForm {
  architects: IArchitect[];
  leads: ILead[];
  epbreporters: IEmployee[];
  salesreps: IEmployee[];
  safetycoordinators: IEmployee[];
  projectmanagers: IEmployee[];
  constructionsitemanagers: IEmployee[];
  constructionsite: IConstructionSite;
  templates: any;
  saveConstructionSite: any;
  updateConstructionSite: any;
  inProgress: boolean;
  hasValidZipCode: boolean;
  setComboBoxState: React.Dispatch<
    React.SetStateAction<{ [key: string]: string | undefined }>
  >;
  comboBoxState: { [key: string]: string | undefined };
  compact?: boolean;
}

const ConstructionSiteForm: React.FC<ConstructionSiteForm> = ({
  architects = [],
  leads = [],
  epbreporters = [],
  salesreps = [],
  safetycoordinators = [],
  projectmanagers = [],
  constructionsitemanagers = [],
  constructionsite,
  templates,
  saveConstructionSite,
  updateConstructionSite,
  inProgress = false,
  hasValidZipCode = true,
  setComboBoxState,
  comboBoxState,
  compact = false,
}) => {
  const getTemplateId = () => {
    if (constructionsite && constructionsite.template_id) {
      return constructionsite.template_id;
    }
    return -1;
  };

  const addEmptyOptionToTemplates = () => {
    let result: IComboBoxOption[] = [
      {
        key: -1,
        text: '<geen>',
      },
    ];
    if (templates) {
      result = result.concat(convertTemplatesToComboBoxOptions(templates));
    }
    return result;
  };

  const updateConstructionSiteStringValue = (
    newValue: string,
    index: string,
  ) => {
    const newConstructionSite = { ...constructionsite };
    // @ts-ignore
    newConstructionSite[index] = newValue;
    updateConstructionSite(newConstructionSite);
  };

  const updateConstructionSiteZipValue = (newValue: string) => {
    const newConstructionSite = { ...constructionsite };
    // @ts-ignore
    newConstructionSite.zip_code = parseInt(newValue, 10);
    updateConstructionSite(newConstructionSite);
  };

  const updateConstructionSiteNumberValue = (
    newValue: number | string | undefined,
    index: string,
  ) => {
    const newConstructionSite = { ...constructionsite };

    // @ts-ignore
    newConstructionSite[`${index}_id`] = newValue;
    updateConstructionSite(newConstructionSite);
  };

  const onComboBoxPendingChange = (
    value: string | undefined,
    field: string,
  ) => {
    setComboBoxState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    updateConstructionSiteNumberValue(undefined, field);
  };

  return (
    <div>
      <div className={modalContentStyles.header} />
      <div className={modalContentStyles.body}>
        <Stack tokens={stackTokens20}>
          {inProgress ? <SpinnerDefault /> : null}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Contact</h3>

              <Stack
                style={{
                  marginBottom: 10,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <StackItem style={{ width: '100%' }}>
                  <TextField
                    name='name'
                    label='Naam'
                    value={
                      constructionsite && constructionsite.name
                        ? constructionsite.name
                        : ''
                    }
                    // onChange={onChangeTextFieldValue}
                    required
                    errorMessage={
                      constructionsite && !constructionsite.name
                        ? 'Dit veld is verplicht'
                        : ''
                    }
                  />
                </StackItem>
              </Stack>
              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='address'
                  label='Adres'
                  value={
                    constructionsite && constructionsite.address
                      ? constructionsite.address
                      : ''
                  }
                  // onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 10,
                }}
              >
                <StackItem style={{ width: '25%' }}>
                  <TextField
                    name='zip_code'
                    placeholder='Postcode'
                    type='text'
                    value={
                      constructionsite && constructionsite.zip_code
                        ? constructionsite.zip_code.toString()
                        : undefined
                    }
                    // onChange={onChangeTextFieldValue}
                  />
                </StackItem>
                <StackItem style={{ width: '74%' }}>
                  <TextField
                    name='city'
                    placeholder='Gemeente'
                    value={
                      constructionsite && constructionsite.city
                        ? constructionsite.city
                        : ''
                    }
                    // onChange={onChangeTextFieldValue}
                  />
                </StackItem>
              </Stack>

              {constructionsite && !constructionsite.id && (
                <ComboboxWithFilter
                  label='Sjabloon:'
                  options={addEmptyOptionToTemplates()}
                  value={getTemplateId()}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      updateConstructionSiteNumberValue(newValue[0].key as number, 'template');
                    }
                  }}
                  required
                />
              )}

              <Stack style={{ marginBottom: 10 }}>
                <h3>Algemene opmerkingen</h3>
                <Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='comments'
                      multiline
                      value={
                        constructionsite && constructionsite.comments
                          ? constructionsite.comments
                          : ''
                      }
                      // onChange={onChangeTextFieldValue}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </StackItem>
          </Stack>
          <Stack
            horizontal={!compact}
            tokens={{
              childrenGap: compact ? undefined : 20,
            }}
          >
            {/* <Stack
              grow={compact ? 1 : undefined}
              style={{ width: compact ? '100%' : undefined }}
            > */}
            {/* <TextFieldDefault
                fullWidth={compact ? true : false}
                label='Naam:'
                value={
                  constructionsite && constructionsite.name
                    ? constructionsite.name
                    : ''
                }
                callBack={(newValue: string) =>
                  updateConstructionSiteStringValue(newValue, 'name')
                }
                required
              />

              {constructionsite && !constructionsite.id && (
                <ComboBox
                  label='Sjabloon:'
                  onSelect={(
                    keys: string | string[] | number | undefined,
                    value: string | undefined,
                  ) => {
                    if (keys && !Array.isArray(keys)) {
                      updateConstructionSiteNumberValue(keys, 'template');
                    }
                  }}
                  options={addEmptyOptionToTemplates()}
                  //allowFreeForm
                  selectedKey={getTemplateId()}
                />
              )}

              <TextFieldDefault
                fullWidth={compact ? true : false}
                label='Adres:'
                value={
                  constructionsite && constructionsite.address
                    ? constructionsite.address
                    : ''
                }
                callBack={(newValue: string) =>
                  updateConstructionSiteStringValue(newValue, 'address')
                }
              />

              <TextFieldDefault
                fullWidth={compact ? true : false}
                label='Postcode:'
                value={
                  constructionsite && constructionsite.zip_code
                    ? constructionsite.zip_code.toString()
                    : ''
                }
                callBack={(newValue: string) =>
                  updateConstructionSiteZipValue(newValue)
                }
                errorMessage={hasValidZipCode ? '' : 'Postcode ongeldig!'}
              />

              <TextFieldDefault
                fullWidth={compact ? true : false}
                label='Stad:'
                value={constructionsite ? constructionsite.city : ''}
                callBack={(newValue: string) =>
                  updateConstructionSiteStringValue(newValue, 'city')
                }
              />

              <TextField
                label='Opmerkingen:'
                styles={
                  compact
                    ? { fieldGroup: { width: '100%' } }
                    : textFieldStyles500
                }
                value={constructionsite ? constructionsite.comments : ''}
                onChange={(
                  event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                  newValue?: string,
                ) =>
                  updateConstructionSiteStringValue(
                    newValue !== undefined ? newValue : '',
                    'comments',
                  )
                }
                multiline
                rows={11}
              />
            </Stack> */}

            <Stack
              grow={compact ? 1 : undefined}
              style={{ width: compact ? '100%' : undefined }}
            >
              <Stack.Item>
                <ComboboxWithFilter
                  label='Klant:'
                  options={convertLeadsToComboBoxOptions(leads)}
                  value={
                    constructionsite && constructionsite.lead_id
                      ? constructionsite.lead_id
                      : undefined
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      updateConstructionSiteNumberValue(newValue[0].key as number, 'lead');
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    onComboBoxPendingChange(value, 'customer');
                  }}
                  required
                />
              </Stack.Item>
              <Stack.Item>
                <ComboboxWithFilter
                  label='Architect:'
                  options={convertArchitectsToComboBoxOptions(architects)}
                  value={
                    constructionsite && constructionsite.architect_id
                      ? constructionsite.architect_id
                      : undefined
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      updateConstructionSiteNumberValue(newValue[0].key as number, 'architect');
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    onComboBoxPendingChange(value, 'architect');
                  }}
                  required
                />
              </Stack.Item>
              <Stack.Item>
                <ComboboxWithFilter
                  label='Verkoper:'
                  options={convertSalesRepsToComboBoxOptions(salesreps)}
                  value={
                    constructionsite && constructionsite.sales_rep_id
                      ? constructionsite.sales_rep_id
                      : undefined
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      updateConstructionSiteNumberValue(newValue[0].key as number, 'sales_rep');
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    onComboBoxPendingChange(value, 'sales_rep');
                  }}
                  required
                />
              </Stack.Item>
              <Stack.Item>
                <ComboboxWithFilter
                  label='EPB verslaggever:'
                  options={convertEmployeesToComboBoxOptions(epbreporters)}
                  value={
                    constructionsite && constructionsite.epb_reporter_id
                      ? constructionsite.epb_reporter_id
                      : undefined
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      updateConstructionSiteNumberValue(newValue[0].key as number, 'epb_reporter');
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    onComboBoxPendingChange(value, 'epb_reporter');
                  }}
                  required
                />
              </Stack.Item>
              <Stack.Item>
                <ComboboxWithFilter
                  label='Veiligheidscoordinator:'
                  options={convertEmployeesToComboBoxOptions(
                    safetycoordinators,
                  )}
                  value={
                    constructionsite && constructionsite.safety_coordinator_id
                      ? constructionsite.safety_coordinator_id
                      : undefined
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      updateConstructionSiteNumberValue(newValue[0].key as number, 'safety_coordinator');
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    onComboBoxPendingChange(value, 'safety_coordinator');
                  }}
                  required
                />
              </Stack.Item>
              <Stack.Item>
                <ComboboxWithFilter
                  label='Projectbeheerder:'
                  options={convertEmployeesToComboBoxOptions(projectmanagers)}
                  value={
                    constructionsite && constructionsite.project_manager_id
                      ? constructionsite.project_manager_id
                      : undefined
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      updateConstructionSiteNumberValue(newValue[0].key as number, 'project_manager');
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    onComboBoxPendingChange(value, 'project_manager');
                  }}
                  required
                />
              </Stack.Item>
              <Stack.Item>
                <ComboboxWithFilter
                  label='Werfopvolger:'
                  options={convertEmployeesToComboBoxOptions(
                    constructionsitemanagers,
                  )}
                  value={
                    constructionsite && constructionsite.manager_id
                      ? constructionsite.manager_id
                      : undefined
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      updateConstructionSiteNumberValue(newValue[0].key as number, 'manager');
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    onComboBoxPendingChange(value, 'manager');
                  }}
                  required
                />
              </Stack.Item>
            </Stack>
          </Stack>

          <Stack horizontal reversed tokens={stackTokens20}>
            <DefaultButton
              text='Annuleer'
              style={comboBoxMultiStyle}
              href='/construction-sites'
            />
            <PrimaryButton
              text='Opslaan'
              style={comboBoxMultiStyle}
              onClick={saveConstructionSite}
              disabled={
                !!(!constructionsite.name || !constructionsite.lead_id)
              }
            />
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

export default ConstructionSiteForm;

import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { displayNameLead } from '../../../utils/Lead';
import { displayNameEmployee } from '../../../utils';
import {
  ADD_QUOTE_REFERENCE,
  GET_QUOTE_REFERENCE,
  IQuoteReference,
  UPDATE_QUOTE_REFERENCE,
} from '../../../utils/QuoteReference';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import QuoteDetail from './QuoteDetail';

const QuotesOverview = ({
  quotes,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  refetchQuotes,
  clearQuotes,
  isReport,
}: any) => {
  const [quote, setQuote] = useState<IQuoteReference | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    IQuoteReference | undefined
  >();

  useEffect(() => {
    console.log('loading', loading);
  }, [loading]);

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  useQuery(GET_QUOTE_REFERENCE, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setQuote(data.findOneQuoteReference);
    },
  });

  const openQuoteDetail = (newQuote?: boolean) => {
    if (selectionDetails && !newQuote) {
      //  console.log('getting the quote');
      //  getQuote({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setQuote(undefined);
    }
    setIsPanelOpen(true);
  };

  const [addQuote] = useMutation(ADD_QUOTE_REFERENCE);
  const [modifyQuote] = useMutation(UPDATE_QUOTE_REFERENCE);

  const saveQuote = () => {
    if (quote) {
      const allInput = {
        quote_no: quote.quote_no,
        quote_date: quote.quote_date,
        description: quote.description,
        comments: quote.comments,
        lead: { connect: { id: quote.lead.id } },
      };

      if (quote.id) {
        modifyQuote({
          variables: {
            id: quote.id,
            data: allInput,
          },
        }).then(() => {
          clearQuotes();
          refetchQuotes();
          setIsPanelOpen(false);
        });
      } else {
        addQuote({
          variables: {
            data: allInput,
          },
        }).then(() => {
          clearQuotes();
          refetchQuotes();
          setIsPanelOpen(false);
        });
      }
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    console.log(event, column);
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          console.log(column.fieldName, sortedColumn);
          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Offerte nr',
      fieldName: 'quote_no',
      minWidth: 70,
      maxWidth: 600,
      isRowHeader: true,
      onRender: (quote: IQuoteReference) => <span>{quote.quote_no}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorteer van laag tot hoog',
      sortDescendingAriaLabel: 'Sorteer van hoog tot laag',
    },
    {
      key: 'column2',
      name: 'Prospect',
      fieldName: 'lead',
      minWidth: 250,
      maxWidth: 600,
      isRowHeader: true,
      onRender: (quote: IQuoteReference) => (
        <span>{displayNameLead(quote.lead)}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Omschrijving',
      fieldName: 'description',
      minWidth: 250,
      maxWidth: 600,
      isRowHeader: true,
      onRender: (quote: IQuoteReference) => <span>{quote.description}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Datum',
      fieldName: 'quote_date',
      minWidth: 100,
      maxWidth: 600,
      isRowHeader: true,
      onRender: (quote: IQuoteReference) => (
        <span>{moment(new Date(quote.quote_date)).format('DD/MM/YYYY')}</span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Verkoper',
      fieldName: 'description',
      minWidth: 150,
      maxWidth: 600,
      isRowHeader: true,
      onRender: (quote: IQuoteReference) => (
        <span>
          {quote.lead &&
            quote.lead.sales_rep_employee &&
            displayNameEmployee(quote.lead.sales_rep_employee)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openQuoteDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openQuoteDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (quote && isPanelOpen) {
      document.title = `3bouw | Offerte - ${quote.quote_no}`;
    } else {
      document.title = '3bouw | Offertes';
    }
  }, [quote, isPanelOpen]);

  return (
    <>
      {!isReport && (
        <>
          <CommandBarSticky
            items={commandBaritems}
            theme={commandBarTheme}
            width='1200px'
            maxWidth='1200px'
          />

          <QuoteDetail
            isOpen={isPanelOpen}
            dismissPanel={() => {
              setIsPanelOpen(false);
            }}
            quote={quote || {}}
            saveQuote={saveQuote}
            setQuote={setQuote}
          />
        </>
      )}

      {quotes && (
        <>
          <ShimmeredDetailsList
            items={quotes}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selection={selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {/* {loading && !initialLoad && (
        <div style={{ textAlign: 'center' }}>Laden....</div>
      )} */}
      {!loading && !showLoadMore && quotes.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default QuotesOverview;

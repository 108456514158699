import React, { FormEvent } from 'react';
import { Stack, TextField } from '@fluentui/react';
import _ from 'lodash';
import {
  IConstructionSheetRemark,
  stackTokens15,
  textFieldStyles1000,
} from '../../../utils';

const ConstructionListRemarks = ({ remarks, callBackRemarks }: any) => {
  // Functions
  const updateValues = (id: number, newValue?: string) => {
    const newValues = remarks.map((el: IConstructionSheetRemark) => {
      const remark = _.cloneDeep(el);
      if (remark.id === id && newValue) remark.remarks = newValue;
      return remark;
    });
    callBackRemarks(newValues);
  };

  return remarks && remarks.length > 0 ? (
    <Stack tokens={stackTokens15}>
      {remarks.map((el: IConstructionSheetRemark) => (
        <Stack horizontal disableShrink>
          <TextField
            key={el.id}
            label={el.label}
            value={el.remarks}
            multiline
            rows={5}
            styles={textFieldStyles1000}
            onChange={(
              event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string,
            ) => updateValues(el.id, newValue)}
          />
        </Stack>
      ))}
    </Stack>
  ) : null;
};

export default ConstructionListRemarks;

// import { useAuth0 } from '@auth0/auth0-react';
// import { Stack, TextField } from '@fluentui/react';
import React from 'react';
// import { useQuery } from '@apollo/react-hooks';
import { Heading } from '../../components/typography';
// import { GET_STORAGE_TOKEN, stackTokens10, stackTokens5 } from '../../utils';
// import { BlobStorage } from '../blob-storage';

const Dashboard = () => <h3>Dashboard</h3>;

// const Dashboard = () => {

//     const { isAuthenticated } = useAuth0(); //Do we need this check since it is already builtin the routing mechanism?

//     const { loading, error, data } = useQuery(GET_STORAGE_TOKEN);

//     const testModalRef = useRef(null)
//     const fileInput = useRef<HTMLInputElement>(null);

//     const [containersInStorage, setContainersInStorage] = useState<string[]>();
//     const [filesInStorage, setFilesInStorage] = useState<string[]>();
//     const [newContainerName, setNewContainerName] = useState("");

//     if (!isAuthenticated) return <p>Verboden</p>;
//     if (loading) return <p>Laden...</p>;
//     if (error) return <p>Fout :(</p>;

//     const wrapper = new BlobStorage(data.createStorageToken.accountName, data.createStorageToken.sasToken);

//     const listContainers = () => {
//         wrapper.getContainerList().then((x: any) => setContainersInStorage(x));
//     };

//     const createContainer = (containerName: string) => {
//         wrapper.createContainer(containerName).then((x: any) => {
//             alert("container aangemaakt. Zie console voor details");
//             listContainers();
//         });
//     };

//     const deleteContainer = (containerName: string) => {
//         wrapper.deleteContainer(containerName).then((x: any) => {
//             alert("container verwijderd. Zie console voor details");
//             listContainers();
//         });
//     };

//     const uploadDemo = () => {
//         const demoContent = "Hello World";
//         wrapper.uploadBlob("temp", "newblob" + new Date().getTime(), demoContent, demoContent.length).then((x: any) => {
//             alert("Demo blob opgeladen. Zie console voor details");
//             listBlobsInContainer();
//         });
//     };

//     const deleteBlob = (containerName: string, blobName: string) => {
//         wrapper.deleteBlob(containerName, blobName).then((x: any) => {
//             alert("Demo blob verwijderd. Zie console voor details");
//             if (filesInStorage) {
//                 listBlobsInContainer();
//             }
//         });
//     };

//     const listBlobsInContainer = () => {
//         wrapper.getBlobsInsideContainerList("temp").then((x: any) => setFilesInStorage(x));
//     };

//     const downloaBlob = (containerName: string, blobName: string) => {
//         wrapper.downloadBlob(containerName, blobName).then((x: any) => alert("Blob gedownload. Zie console voor inhoud"));
//     };

//     const handleFileUpload = () => {
//         if (fileInput.current && fileInput.current.files && fileInput.current.files.length > 0) {
//             fileInput.current.files[0].arrayBuffer().then((x: any) => {
//                 wrapper.uploadBlob("temp", fileInput.current!.files![0].name, x, x.byteLength).then((x: any) => {
//                     alert("Bestand opgeladen. Zie console voor details");
//                     listBlobsInContainer();
//                 });
//             });
//         }
//     };

//     return(
//         <Stack tokens={stackTokens5}>
//             <Heading as="h3">Dashboard</Heading>

//             <Stack tokens={stackTokens10}>
//                 <Heading as="h3">Bestanden</Heading>
//                 <Heading as="h5">Account: {wrapper.client.accountName}</Heading>

//                 <ul>
//                     <li><button onClick={listContainers}>List containers</button></li>
//                     <li><button onClick={uploadDemo}>Upload demo blob</button></li>
//                     <li><button onClick={listBlobsInContainer}>List blobs in container</button></li>
//                     <li><button onClick={() => downloaBlob("temp", "newblob1602157862853")}>Download demo blob</button></li>
//                 </ul>
//                 <div>
//                     <h5>Container</h5>
//                     <TextField
//                         label="Naam"
//                         value={newContainerName}
//                         onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
//                             if (newValue)
//                                 setNewContainerName(newValue);
//                             }}
//                         />
//                     <button onClick={() => createContainer(newContainerName)}>Maak</button>
//                 </div>
//                 <div>
//                     <h5>Bestand</h5>
//                     <input ref={fileInput} type="file" accept="*" name="ChooseFile" /><br />
//                     <input type="submit" value="Bestand opladen" onClick={handleFileUpload} />
//                 </div>
//                 <div>
//                     <h5>Overzicht containers</h5>
//                     <ul>
//                         {
//                             containersInStorage?.map((container: string) => <li>
//                                 {container}
//                                 <button onClick={() => deleteContainer(container)}>Delete</button>
//                             </li>)
//                         }
//                     </ul>
//                 </div>
//                 <div>
//                     <h5>Overzicht container "temp"</h5>
//                     <ul>
//                         {
//                             filesInStorage?.map((name: string) => <li>
//                                 {name}
//                                 <button onClick={() => downloaBlob("temp", name)}>Download</button>
//                                 <button onClick={() => deleteBlob("temp", name)}>Delete</button>
//                             </li>)
//                         }
//                     </ul>
//                 </div>
//             </Stack>
//         </Stack>
//     );
// }

export default Dashboard;

import { gql } from '@apollo/client';
import { ISupplier } from './Supplier';

export const GET_INCOMING_INVOICES = gql`
  query getIncomingInvoices(
    $filter: IncomingInvoiceFilterInput
    $take: Int
    $skip: Int
    $orderBy: IncomingInvoiceOrderByInput
  ) {
    findManyIncomingInvoices(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      date_created
      due_date
      id
      internal_invoice_no
      invoice_date
      invoice_no
      paid
      paid_in_wings
      total_excl_vat
      total_excl_vat_correction
      total_incl_vat
      total_incl_vat_correction
      supplier_id
      supplier {
        id
        name
      }
    }
  }
`;

export const GET_INCOMING_INVOICE_BY_ID = gql`
  query getIncomingInvoiceById($where: IncomingInvoiceWhereInput) {
    findOneIncomingInvoice(where: $where) {
      blocked
      comments
      date_created
      date_paid
      due_date
      id
      internal_invoice_no
      invoice_date
      invoice_no
      paid
      paid_in_wings
      payment_terms
      supplier_id
      supplier_ref
      total_excl_vat
      total_excl_vat_correction
      total_incl_vat
      total_incl_vat_correction
      version
      wings_invoice

      supplier {
        id
        name
      }

      incoming_invoice_line_items {
        id
        description
        comment
        amount
        amount_vat
        cost_center {
          name
          id
        }
        construction_site {
          name
          id
        }
      }
    }
  }
`;

export const ADD_INCOMING_INVOICE = gql`
  mutation AddIncomingInvoice($data: IncomingInvoiceCreateInput!) {
    createIncomingInvoice(data: $data) {
      blocked
      comments
      date_created
      date_paid
      due_date
      id
      internal_invoice_no
      invoice_date
      invoice_no
      paid
      paid_in_wings
      payment_terms
      supplier_id
      supplier_ref
      total_excl_vat
      total_excl_vat_correction
      total_incl_vat
      total_incl_vat_correction
      version
      wings_invoice

      supplier {
        id
        name
      }
    }
  }
`;

export const UPDATE_INCOMING_INVOICE = gql`
  mutation UpdateIncomingInvoice(
    $id: Int!
    $data: IncomingInvoiceUpdateInput!
  ) {
    updateIncomingInvoice(id: $id, data: $data) {
      id
    }
  }
`;

export interface IIncomingInvoice {
  blocked: boolean;
  comments: string;
  date_created: Date;
  date_paid: Date;
  due_date: Date;
  id: number;
  internal_invoice_no: string;
  invoice_date: Date;
  invoice_no: string;
  paid: boolean;
  paid_in_wings: boolean;
  payment_terms: number;
  supplier_id: number;
  supplier_ref: string;
  total_excl_vat: number;
  total_excl_vat_correction: number;
  total_incl_vat: number;
  total_incl_vat_correction: number;
  version: number;
  wings_invoice: boolean;

  supplier: ISupplier;
  // incoming_invoice_line_items: [Incoming_invoice_line_item]
}

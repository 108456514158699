import { useRef, useState, useEffect } from 'react';

// Hook
function useElement(
  name: 'clientHeight' = 'clientHeight',
  initial: any = null,
) {
  const [value, setValue] = useState(initial);

  const ref = useRef<HTMLElement>();

  useEffect(
    () => {
      const node = ref.current;

      if (node) {
        // const attributeNames = node.getAttributeNode('clientHeight');
        // console.log(JSON.stringify(attributeNames));
        const propValue = node[name];
        if (propValue) {
          setValue(propValue);
        }
      }
    },
    [name], // Recall only if ref changes
  );

  return [ref, value];
}

export default useElement;

import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useBoolean } from '@fluentui/react-hooks';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_EMPLOYEE_BY_ID,
  IEmployee,
  displayNameEmployee,
} from '../../../utils/Employee';
import { CREATE_USER } from '../../../utils';
import EmployeeDetail from '../../employees/components/EmployeeDetail';
import AppUserModal from '../../employees/components/AppUserModal';

const SalesRepsOverview = ({
  salesReps,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  clearSalesReps,
  refetchSalesReps,
}: any) => {
  const [salesRep, setSalesRep] = useState<IEmployee | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [addSalesRep] = useMutation(ADD_EMPLOYEE);
  const [modifySalesRep] = useMutation(UPDATE_EMPLOYEE);

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    IEmployee | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { refetch: refetchSalesRep } = useQuery(GET_EMPLOYEE_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setSalesRep(data.findOneEmployee);
    },
  });

  const openSalesRepDetail = (newSalesRep?: boolean) => {
    if (newSalesRep) {
      setSalesRep(undefined);
    }
    setIsPanelOpen(true);
  };

  // App user modal
  const [modalUser, setModalUser] = useState<IEmployee | undefined>(undefined);
  const [hideModal, { toggle: toggleHideModal }] = useBoolean(true);

  const [createUser, { data: newUserData, loading: newUserLoading }] =
    useMutation(CREATE_USER, {
      onCompleted() {
        refetchSalesReps();
      },
    });

  const openNewUserModal = (salesRep: IEmployee) => {
    setModalUser(salesRep);
    toggleHideModal();
  };

  // Save salesRep
  const saveSalesRep = () => {
    if (salesRep) {
      const allInput = {
        first_name: salesRep.first_name,
        last_name: salesRep.last_name,
        address: salesRep.address,
        city: salesRep.city,
        zip_code: salesRep.zip_code,
        email: salesRep.email,
        active: salesRep.id ? salesRep.active : true,
        function: salesRep.function,
        mobile_v2: salesRep.mobile_v2,
        private_mobile_v2: salesRep.private_mobile_v2,
        phone_v2: salesRep.phone_v2,
        birthday: salesRep.birthday,
        iban: salesRep.iban,
        comments: salesRep.comments,
        diploma: salesRep.diploma,
        start_date: salesRep.start_date,
        end_date: salesRep.end_date,
        hourly_rate: salesRep.hourly_rate,
        hourly_rate_for_calculation: salesRep.hourly_rate_for_calculation
          ? +salesRep.hourly_rate_for_calculation
          : 0,
        monthly_rate: salesRep.monthly_rate || 0,
        driver: salesRep.driver,
        customer_manager: salesRep.customer_manager,
        construction_site_manager: salesRep.construction_site_manager,
        passport_no: salesRep.passport_no,
        sis_no: salesRep.sis_no,
        employment_method: salesRep.employment_method,
        category: salesRep.category,
        name_partner: salesRep.name_partner,
        mobile_partner_v2: salesRep.mobile_partner_v2,
        birthday_partner: salesRep.birthday_partner,
        children: salesRep.children ? +salesRep.children : null,
        jacket_size: salesRep.jacket_size,
        pants_size: salesRep.pants_size,
        shirt_size: salesRep.shirt_size,
        shoe_size: salesRep.shoe_size,
        sweater_size: salesRep.sweater_size,
        sales_rep: salesRep.sales_rep,
        epb_reporter: salesRep.epb_reporter,
        safety_coordinator: salesRep.safety_coordinator,
      };

      if (salesRep.id) {
        modifySalesRep({
          variables: {
            id: salesRep.id,
            data: allInput,
          },
        }).then(() => {
          clearSalesReps();
          refetchSalesReps();
          setIsPanelOpen(false);
        });
      } else {
        addSalesRep({
          variables: {
            data: allInput,
          },
        }).then(() => {
          clearSalesReps();
          refetchSalesReps();
          setIsPanelOpen(false);
        });
      }
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Achternaam',
      fieldName: 'last_name',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (salesRep: IEmployee) => <span>{salesRep.last_name}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Voornaam',
      fieldName: 'first_name',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (salesRep: IEmployee) => <span>{salesRep.first_name}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Functie',
      fieldName: 'function',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (salesRep: IEmployee) => <span>{salesRep.function}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'GSM Werk',
      fieldName: 'mobile_v2',
      minWidth: 130,
      maxWidth: 130,
      isRowHeader: true,
      onRender: (salesRep: IEmployee) => <span>{salesRep.mobile_v2}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'GSM Privé',
      fieldName: 'private_mobile_v2',
      minWidth: 130,
      maxWidth: 130,
      isRowHeader: true,
      onRender: (salesRep: IEmployee) => (
        <span>{salesRep.private_mobile_v2}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Actief',
      fieldName: 'active',
      minWidth: 30,
      maxWidth: 30,
      isRowHeader: true,
      onRender: (salesRep: IEmployee) => (
        <span>
          <Checkbox disabled defaultChecked={salesRep.active} />
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openSalesRepDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openSalesRepDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (salesRep && isPanelOpen) {
      document.title = `3bouw | Verkoper - ${displayNameEmployee(salesRep)}`;
    } else {
      document.title = '3bouw | Verkopers';
    }
  }, [salesRep, isPanelOpen]);

  return (
    salesReps && (
      <>
        <AppUserModal
          hideModal={hideModal}
          toggleHideModal={toggleHideModal}
          modalUser={modalUser}
          newUserLoading={newUserLoading}
          newUserData={newUserData}
          createUser={createUser}
        />

        <CommandBarSticky
          items={commandBaritems}
          theme={commandBarTheme}
          width='1200px'
          maxWidth='1200px'
        />

        <EmployeeDetail
          isOpen={isPanelOpen}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          employee={salesRep || {}}
          saveEmployee={saveSalesRep}
          setEmployee={setSalesRep}
          refetchEmployee={refetchSalesRep}
          openNewUserModal={openNewUserModal}
        />

        <ShimmeredDetailsList
          items={salesReps}
          columns={columns}
          getKey={getKey}
          enableShimmer={loading && initialLoad}
          ariaLabelForShimmer='Content is being fetched'
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible
          selection={selection}
          selectionMode={SelectionMode.single}
          selectionPreservedOnEmptyClick
        />
        {!loading && !showLoadMore && salesReps.length === 0 && (
          <div
            style={{
              textAlign: 'center',
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Geen resultaten
          </div>
        )}
        {showLoadMore && (
          <Stack
            style={{
              marginTop: '15px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            horizontal
            horizontalAlign='center'
          >
            <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
          </Stack>
        )}
        <Stack style={{ minHeight: '50px' }} />
      </>
    )
  );
};

export default SalesRepsOverview;

import { useState, useEffect, useRef } from 'react';

interface IDimensions {
    clientWidth: number;
    clientHeight: number;
    clientBoundingRect: DOMRect;
    offsetWidth: number;
    offsetHeight: number;
}

const useDimensions = (defaultState = false): [React.MutableRefObject<HTMLElement | undefined>, IDimensions | undefined] => {
  const ref = useRef<HTMLElement>();
  const [state, setState] = useState<IDimensions | undefined>();

  useEffect(() => {
    const node = ref.current;

    if (node) {
      const clientWidth = node?.clientWidth;
      const clientHeight = node?.clientHeight;
      const clientBoundingRect = node.getBoundingClientRect();
      const offsetWidth = node.offsetWidth;
      const offsetHeight = node.offsetHeight;

      setState({
        clientWidth,
        clientHeight,
        clientBoundingRect,
        offsetHeight,
        offsetWidth,
      });
    }
  }, [ref]);

  return [ref, state];
};

export default useDimensions;

export { useDimensions };

import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  SelectableOptionMenuItemType,
} from '@fluentui/react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  convertLeadsToTimesheetComboBoxOptions,
  GET_LEADS,
  ILead,
} from '../../../utils/Lead';
import { IConstructionSite, ICustomer } from '../../../utils';
import {
  convertConstructionSitesToTimesheetComboBoxOptions,
  GET_CONSTRUCTION_SITES,
} from '../../../utils/ConstructionSite';
import useDebounce from '../../../components/hooks/useDebounce';

// value can be a single number (id) or an array of numbers (id's) when multiselect is true
// the callBack function has a single number (id) as parameter or an array of numbers (id's) as parameter when multiselect is true
// callBack(id: number)
// callBack(ids: number[])

type ComboBoxWithFilterProps = {
  callBack: ([string]: IComboBoxOption[]) => void;
  errorMessage?: string;
  placeholder?: string;
  value?: string[] | number[] | number | string;
  required?: boolean;
  initCustomers: ILead[];
  initProjects: IConstructionSite[];
  data?: (ICustomer | ILead | IConstructionSite)[];
};

function ComboboxProject({
  callBack,
  errorMessage = '',
  placeholder = '',
  value = [],
  required = false,
  initCustomers = [],
  initProjects = [],
  data,
}: ComboBoxWithFilterProps) {
  const combobox = useRef<IComboBox>(null);
  const [selectedKey, setSelectedKey] = React.useState<
    string | number | undefined
  >();
  const [options, setOptions] = React.useState<IComboBoxOption[]>([]);
  const [filter, setFilter] = useState('');

  const [projects, setProjects] = useState(initProjects);
  const [customers, setCustomers] = useState(initCustomers);

  const debouncedValue = useDebounce(filter, 500, (d: string) => {
    if (d && d.length > 0) {
      getLeads();
      getConstructionSites();
    } else {
      setProjects(initProjects);
      setCustomers(initCustomers);
    }
  });

  const onPendingValueChanged = (
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    currentValue?: string | undefined,
  ) => {
    if (currentValue !== undefined) {
      setFilter(currentValue);
      combobox.current?.focus(true);
    }
  };

  const [getLeads] = useLazyQuery(GET_LEADS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        OR: [
          { first_name1: { contains: filter } },
          { first_name2: { contains: filter } },
          { last_name1: { contains: filter } },
          { last_name2: { contains: filter } },
        ],
      },
      take: 20,
    },
    onCompleted: (x: any) => {
      if (x.findManyLeads && x.findManyLeads.length > 0) {
        setCustomers(x.findManyLeads);
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [getConstructionSites] = useLazyQuery(GET_CONSTRUCTION_SITES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        name: { contains: filter },
      },
      take: 20,
    },
    onCompleted: (x: any) => {
      if (
        x.findManyConstructionSites
        && x.findManyConstructionSites.length > 0
      ) {
        setProjects(x.findManyConstructionSites);
      }
    },
    fetchPolicy: 'no-cache',
  });

  const filterOptions = useCallback(() => {
    const currentData: IComboBoxOption[] = [];
    data
      && data.length > 0
      && data.map((x: any, i: number) => {
        if (x) {
          currentData.push({
            key: `${
              // eslint-disable-next-line no-underscore-dangle
              x && x.__typename ? x.__typename.toLowerCase() : 'customer'
            }_${x.id}`,
            text: `${x.first_name1} ${x.last_name1} - ${x.first_name2} ${x.last_name2}`,
          });
        }
      });

    let options = [
      ...currentData,
      {
        key: 'Header1',
        text: 'Klanten',
        itemType: SelectableOptionMenuItemType.Header,
      },
    ];

    const customersList: any = convertLeadsToTimesheetComboBoxOptions(customers);
    // .filter((oneOption: IComboBoxOption) => {
    //   return oneOption.text.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    // });
    options = options.concat(customersList);

    options.push({
      key: 'divider',
      text: '-',
      itemType: SelectableOptionMenuItemType.Divider,
    });
    options.push({
      key: 'Header2',
      text: 'Projecten',
      itemType: SelectableOptionMenuItemType.Header,
    });

    const projectsList: any = convertConstructionSitesToTimesheetComboBoxOptions(projects);
    // .filter((oneOption: IComboBoxOption) => {
    //   console.log(oneOption, filter);
    //   return (
    //     oneOption.text &&
    //     oneOption.text.toLowerCase().indexOf(filter && filter.toLowerCase()) >
    //       -1
    //   );
    // });
    options = options.concat(projectsList);

    return options;
  }, [data, customers, projects]);

  const onChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
  ): void => {
    if (option) {
      const key = option?.key;
      setFilter('');
      setSelectedKey(key);
    }
  };

  useEffect(() => {
    if (selectedKey) {
      const options = filterOptions();
      const values: IComboBoxOption[] = options.filter((option) => {
        const key: string | number = option.key;
        if (selectedKey === key) {
          return true;
        }
      });
      callBack(values);
    }
  }, [selectedKey]);

  return (
    <ComboBox
      componentRef={combobox}
      placeholder={placeholder}
      allowFreeform
      autoComplete='on'
      options={filterOptions()}
      onChange={onChange}
      errorMessage={errorMessage}
      onPendingValueChanged={onPendingValueChanged}
      required={required}
      selectedKey={value}
    />
  );
}

export default ComboboxProject;

import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import {
  ADD_CUSTOMER_PAYMENT_TRANSACTION,
  ICustomerPaymentTransaction,
  UPDATE_CUSTOMER_PAYMENT_TRANSACTION,
} from '../../../utils/CustomerPaymentTransaction';
import { displayNameCustomer, GET_CONSTRUCTION_SITES } from '../../../utils';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import useDebounce from '../../../components/hooks/useDebounce';
import PaymentTransactionDetail from './CustomerPaymentTransactionDetail';
import PaymentTransactionInvoiceDetail from './PaymentTransactionInvoiceDetail';

const CustomerPaymentTransactionsOverview = ({
  customerPaymentTransactions,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
}: // savePaymentTransaction,
any) => {
  const [paymentTransaction, setPaymentTransaction] = useState<
    ICustomerPaymentTransaction | undefined
  >(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isInvoicePanelOpen, setIsInvoicePanelOpen] = useState(false);
  const [groups, setGroups] = useState<any[]>([]);
  const [constructionSites, setConstructionSites] = useState([]);
  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');

  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { id: paymentTransactionId } = useParams();

  useQuery(GET_CONSTRUCTION_SITES, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        name: {
          contains: debouncedFilter,
        },
      },
    },
    onCompleted: (x: any) => {
      setConstructionSites(x.findManyConstructionSites);
    },
  });

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setPaymentTransaction(currentSelection[0]);
    } else {
      setPaymentTransaction(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openPaymentTransactionDetail = (newPaymentTransaction?: boolean) => {
    if (newPaymentTransaction) {
      setPaymentTransaction({});
    }
    setIsPanelOpen(true);
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Werf',
      fieldName: 'construction_site',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      onRender: () => <span />,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Omschrijving',
      fieldName: 'description',
      minWidth: 440,
      maxWidth: 440,
      isRowHeader: true,
      onRender: (customerPaymentTransaction: ICustomerPaymentTransaction) => (
        <span>
          {!customerPaymentTransaction.construction_site &&
            paymentTransaction &&
            paymentTransaction.customer &&
            `${displayNameCustomer(paymentTransaction.customer)}: `}
          {customerPaymentTransaction.description}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Datum',
      fieldName: 'payment_date',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (customerPaymentTransaction: ICustomerPaymentTransaction) => (
        <span>
          {customerPaymentTransaction.payment_date &&
            moment(new Date(customerPaymentTransaction.payment_date)).format(
              'YYYY-MM-DD',
            )}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Factuur',
      fieldName: 'customer_invoice',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (customerPaymentTransaction: ICustomerPaymentTransaction) => (
        <span>
          {customerPaymentTransaction.customer_invoices &&
            customerPaymentTransaction.customer_invoices.map(invoice => {
              if (invoice.invoice_paid) return 'Betaald';
              return 'Niet betaald';
            })}
          {(!customerPaymentTransaction.customer_invoices ||
            customerPaymentTransaction.customer_invoices.length === 0) &&
            'Geen factuur'}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Percentage',
      fieldName: 'percentage',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      onRender: (customerPaymentTransaction: ICustomerPaymentTransaction) => (
        <span>
          {customerPaymentTransaction.percentage &&
            `${customerPaymentTransaction.percentage}%`}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Bedrag',
      fieldName: 'contract_amount',
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      onRender: (customerPaymentTransaction: ICustomerPaymentTransaction) => (
        <span>
          {customerPaymentTransaction.construction_site &&
            customerPaymentTransaction.construction_site.contract_amount &&
            customerPaymentTransaction.percentage &&
            `€ ${
              customerPaymentTransaction.construction_site.contract_amount *
              (customerPaymentTransaction.percentage / 100)
            }`}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Groups
  function groupsGenerator(itemsList: any, fieldName: any) {
    // Array of group objects
    const groupObjArr: any[] = [];

    // Get the group names from the items list
    const groupNames = [
      ...new Set(
        itemsList.map((item: any) => {
          if (item.construction_site) {
            return item.construction_site?.id;
          }
        }),
      ),
    ];

    // Iterate through each group name to build proper group object
    groupNames.forEach(gn => {
      // Count group items
      const groupLength = itemsList.filter(
        (item: any) => item.construction_site?.id === gn,
      ).length;

      // Find the first group index
      const groupIndex = itemsList
        .map((item: any) => item.construction_site?.id)
        .indexOf(gn);

      // Generate a group object
      groupObjArr.push({
        key: gn,
        // name: itemsList[groupIndex].customer
        //   ? displayNameCustomer(itemsList[groupIndex].customer)
        //   : '',
        name: itemsList[groupIndex].construction_site
          ? itemsList[groupIndex].construction_site.name
          : 'Geen werf gekoppeld',
        level: 0,
        count: groupLength,
        startIndex: groupIndex,
      });
    });

    // The final groups array returned
    return groupObjArr;
  }

  useEffect(() => {
    setGroups(groupsGenerator(customerPaymentTransactions, 'customer_id'));
  }, [customerPaymentTransactions]);

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openPaymentTransactionDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openPaymentTransactionDetail(),
      theme: commandBarTheme,
      disabled: !paymentTransaction || !paymentTransaction.id,
    },
    {
      key: 'modify',
      text: 'Factuur aanmaken',
      iconProps: { iconName: 'Pdf' },
      onClick: () => setIsInvoicePanelOpen(true),
      theme: commandBarTheme,
      disabled:
        !paymentTransaction ||
        !paymentTransaction.id ||
        (paymentTransaction.customer_invoices &&
          paymentTransaction.customer_invoices.length > 0),
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (paymentTransaction && isPanelOpen) {
      document.title = '3bouw | Betalingsschijf';
    } else {
      document.title = '3bouw | Betalingsschijven';
    }
  }, [paymentTransaction, isPanelOpen]);

  useEffect(() => {
    if (paymentTransactionId) {
      setIsPanelOpen(true);
    }
  }, []);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />

      <PaymentTransactionDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
          setPaymentTransaction(undefined);
        }}
        customerPaymentTransactionSource={paymentTransaction || {}}
        paymentTransactionId={
          paymentTransactionId ? +paymentTransactionId : undefined
        }
      />

      {paymentTransaction && isInvoicePanelOpen && (
        <PaymentTransactionInvoiceDetail
          isOpen={isInvoicePanelOpen}
          dismissPanel={() => {
            setIsInvoicePanelOpen(false);
          }}
          paymentTransaction={paymentTransaction}
          setPaymentTransaction={setPaymentTransaction}
        />
      )}

      {customerPaymentTransactions && (
        <>
          <ShimmeredDetailsList
            items={customerPaymentTransactions || []}
            columns={columns}
            groups={groups}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selection={selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && customerPaymentTransactions.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default CustomerPaymentTransactionsOverview;

import { IDropdownOption } from '@fluentui/react';
import _ from 'lodash';
import { IProperty, defaultProperty } from '../../../utils/Property';
import { IPropertySupplier } from '../../../utils/PropertySupplier';
import { IPropertyPropertyListType } from '../../../utils/PropertyPropertyListTypes';
import { IConstructionSite } from '../../../utils/ConstructionSite';
import { IConstructionSheetRemark } from '../../../utils/ConstructionSheetRemark';

const reorderItems = (
  currentItems: IProperty[],
  newItem: IProperty,
  newExecutionPosition?: number,
  newConstructionPosition?: number,
): IProperty[] => {
  const newWeightForConstruction = newConstructionPosition && newConstructionPosition > -1
    ? newConstructionPosition
    : newItem.weight_for_construction_sheet;
  const newWeightForExecution = newExecutionPosition && newExecutionPosition > -1
    ? newExecutionPosition
    : newItem.weight_for_execution_list;
  if (currentItems && currentItems.length > 0) {
    // insert for executionlist
    for (let i = 0; i < currentItems.length; i++) {
      const currentItem = currentItems[i];
      // let nextItem = currentItems[i + 1];
      if (
        currentItem.weight_for_execution_list >= newWeightForExecution
                && currentItem.id !== newItem.id
      ) {
        currentItem.weight_for_execution_list += 1;

        currentItems[i] = currentItem;
      }
    }

    // insert for constructionSheet
    for (let i = 0; i < currentItems.length; i++) {
      const currentItem = currentItems[i];
      // let nextItem = currentItems[i + 1];
      if (
        currentItem.weight_for_construction_sheet >= newWeightForConstruction
                && currentItem.id !== newItem.id
      ) {
        currentItem.weight_for_construction_sheet += 1;

        currentItems[i] = currentItem;
      }
    }

    currentItems.sort((a, b) => a.weight_for_execution_list - b.weight_for_execution_list);

    return currentItems;
  }
  return [];
};

const getPropertyListTypesUpdateData = (
  listTypes: IPropertyPropertyListType[],
) => {
  const newListTypes = listTypes
    .filter((item) => item.draft && !item.delete)
    .map((item) => ({
      property_list_type_id: item.list_type.id,
    }));

  const deleteListTypes = listTypes
    .filter((item) => item.delete)
    .map((item) => ({
      id: item.id,
    }));

  if (newListTypes.length === 0 && deleteListTypes.length === 0) return undefined;

  return {
    create: newListTypes.length > 0 ? newListTypes : undefined,
    delete: deleteListTypes.length > 0 ? deleteListTypes : undefined,
  };
};

const getPropertyChildrenCreateData = (properties: IProperty[]) => {
  // console.log(properties);
  const newChildren = properties
    .filter((item) => item.isNew && !item.delete)
    .map((item) => getPropertyCreateData(item));

  return newChildren;
};

const getPropertyCreateData = (property: IProperty) => {
  let propertyData = {};

  propertyData = {
    name: property.name,
    type: {
      connect: {
        id: property.type.id,
      },
    },
    nested_type: property.nested_type
      ? {
        connect: {
          id: property.nested_type.id,
        },
      }
      : undefined,
    show_in_execution_list:
            property.weight_for_execution_list > 0,
    weight_for_execution_list: property.weight_for_execution_list,
    show_in_construction_sheet:
            property.weight_for_construction_sheet > 0,
    weight_for_construction_sheet: property.weight_for_construction_sheet,
    is_not_applicable: property.is_not_applicable,
    marked: property.marked || false,
    value: property.value,
    needs_water_points: property.needs_water_points,
    remote_category: property.remote_category,
    send_alert: property.send_alert,
  };

  if (property.value) {
    propertyData = {
      ...propertyData,
      value: property.value,
    };
  }

  propertyData = {
    ...propertyData,
    remote_category: property.remote_category,
  };

  if (property.suggested_value && property.suggested_value.id) {
    propertyData = {
      ...propertyData,
      suggested_value: {
        connect: {
          id: property.suggested_value.id,
        },
      },
    };
  }

  if (
    property.document
        && property.document.blob_name
        && property.document.blob_name.length > 4
  ) {
    propertyData = {
      ...propertyData,
      document: {
        create: {
          name: property.name,
          src: property.document.src ? property.document.src : null,
          extension: property.document.extension
            ? property.document.extension
            : null,
          blob_name: property.document.blob_name
            ? property.document.blob_name
            : null,
          is_external: !!property.document.is_external,
        },
      },
    };
  }

  if (property.list_types) {
    propertyData = {
      ...propertyData,
      list_types: getPropertyListTypesUpdateData(property.list_types),
    };
  }

  if (property.children) {
    propertyData = {
      ...propertyData,
      children: {
        create: getPropertyChildrenCreateData(property.children),
      },
    };
  }

  // Dates
  if (property.order_date) {
    propertyData = {
      ...propertyData,
      order_date: property.order_date,
    };
  }

  if (property.due_date) {
    propertyData = {
      ...propertyData,
      due_date: property.due_date,
    };
  }

  if (property.execution_date) {
    propertyData = {
      ...propertyData,
      execution_date: property.execution_date,
    };
  }

  // Employees
  if (property.employee && property.employee.id) {
    if (property.employee.id === -1) {
      propertyData = {
        ...propertyData,
        employee: {
          disconnect: true,
        },
      };
    } else {
      propertyData = {
        ...propertyData,
        employee: {
          connect: {
            id: property.employee.id,
          },
        },
      };
    }
  }

  // Sales Reps
  if (property.sales_rep && property.sales_rep.id) {
    if (property.sales_rep.id === -1) {
      propertyData = {
        ...propertyData,
        sales_rep: {
          disconnect: true,
        },
      };
    } else {
      propertyData = {
        ...propertyData,
        sales_rep: {
          connect: {
            id: property.sales_rep.id,
          },
        },
      };
    }
  }

  if (property.suppliers) {
    propertyData = {
      ...propertyData,
      suppliers: getSuppliersData(property),
    };
  }

  return propertyData;
};

const getPropertyChildrenUpdateData = (properties: IProperty[]) => {
  const newChildren = properties
    .filter((item) => item.isNew && !item.delete)
    .map((item) => ({
      ...getPropertyCreateData(item),
    }));

  const updatedChildren = properties
    .filter((item) => item.isDirty && !item.delete && !item.isNew)
    .map((child) => {
      const updateData = getPropertyUpdateData(child);
      return {
        data: updateData,
        where: {
          id: child.id,
        },
      };
    });
  const deletedChildren = properties
    .filter((item) => item.delete)
    .map((child) => ({
      id: child.id,
    }));

  return {
    create: newChildren,
    update: updatedChildren,
    delete: deletedChildren,
  };
};

const getPropertyUpdateData = (property: IProperty) => {
  let propertyData = {};

  propertyData = {
    ...propertyData,
    value: property.value,
    weight_for_construction_sheet: property.weight_for_construction_sheet,
    weight_for_execution_list: property.weight_for_execution_list,
    is_not_applicable: property.is_not_applicable,
    needs_water_points: property.needs_water_points,
    remote_category: property.remote_category,
    name: property.name,
    marked: property.marked || false,
    send_alert: property.send_alert,
    type: {
      connect: {
        id: property.type.id,
      },
    },
    nested_type: property.nested_type
      ? {
        connect: {
          id: property.nested_type.id,
        },
      }
      : undefined,
  };

  if (property.suggested_value && property.suggested_value.id) {
    propertyData = {
      ...propertyData,
      suggested_value: {
        connect: {
          id: property.suggested_value.id,
        },
      },
    };
  }

  if (property.document) {
    if (property.document.dirty && property.id) {
      propertyData = {
        ...propertyData,
        document: {
          update: {
            name: property.document.name ? property.document.name : '',
            src: property.document.src ? property.document.src : '',
            extension: property.document.extension
              ? property.document.extension
              : null,
            blob_name: property.document.blob_name
              ? property.document.blob_name
              : '',
            is_external: !!property.document.is_external,
          },
        },
      };
    } else if (
      property.document.blob_name
            && property.document.blob_name.length > 4
    ) {
      propertyData = {
        ...propertyData,
        document: {
          create: {
            name: property.name,
            src: property.document.src ? property.document.src : null,
            extension: property.document.extension
              ? property.document.extension
              : null,
            blob_name: property.document.blob_name
              ? property.document.blob_name
              : null,
            is_external: !!property.document.is_external,
          },
        },
      };
    }
  }

  if (property.children) {
    propertyData = {
      ...propertyData,
      children: getPropertyChildrenUpdateData(property.children),
    };
  }

  if (property.list_types) {
    propertyData = {
      ...propertyData,
      list_types: getPropertyListTypesUpdateData(property.list_types),
    };
  }

  // Dates
  if (property.order_date) {
    propertyData = {
      ...propertyData,
      order_date: property.order_date,
    };
  }

  if (property.due_date) {
    propertyData = {
      ...propertyData,
      due_date: property.due_date,
    };
  }

  if (property.execution_date) {
    propertyData = {
      ...propertyData,
      execution_date: property.execution_date,
    };
  }

  // Employees
  if (property.employee && property.employee.id) {
    if (property.employee.id === -1) {
      propertyData = {
        ...propertyData,
        employee: {
          disconnect: true,
        },
      };
    } else {
      propertyData = {
        ...propertyData,
        employee: {
          connect: {
            id: property.employee.id,
          },
        },
      };
    }
  }

  // Sales Reps
  if (property.sales_rep && property.sales_rep.id) {
    if (property.sales_rep.id === -1) {
      propertyData = {
        ...propertyData,
        sales_rep: {
          disconnect: true,
        },
      };
    } else {
      propertyData = {
        ...propertyData,
        sales_rep: {
          connect: {
            id: property.sales_rep.id,
          },
        },
      };
    }
  }

  if (property.suppliers) {
    propertyData = {
      ...propertyData,
      suppliers: getSuppliersData(property),
    };
  }

  return propertyData;
};

const getSuppliersData = (property: IProperty) => {
  const createdPropertySuppliers = property && property.suppliers
    ? property.suppliers
      .filter(
        (propertySupplier: IPropertySupplier) => propertySupplier.id === 0,
      )
      .map((propertySupplier: IPropertySupplier) => ({
        supplier: {
          connect: {
            id: propertySupplier.supplier.id,
          },
        },
      }))
    : [];

  const deletedPropertySuppliers = property && property.deletedSuppliers
    ? property.deletedSuppliers
      .filter(
        (propertySupplier: IPropertySupplier) => propertySupplier.id > 0,
      )
      .map((propertySupplier: IPropertySupplier) => ({
        id: propertySupplier.id,
      }))
    : [];

  let result = {};

  if (createdPropertySuppliers.length > 0) {
    result = {
      ...result,
      create: createdPropertySuppliers,
    };
  }

  if (deletedPropertySuppliers.length > 0) {
    result = {
      ...result,
      delete: deletedPropertySuppliers,
    };
  }

  if (
    createdPropertySuppliers.length > 0
        || deletedPropertySuppliers.length > 0
  ) {
    return {
      suppliers: result,
    };
  }

  return {};
};

const getNextWeight = (
  type: 'construction' | 'execution',
  constructionSite?: IConstructionSite,
) => {
  if (
    constructionSite
        && constructionSite.properties
        && constructionSite.properties.length > 0
  ) {
    // select next weight (at back of the list)
    return (
      Math.max(
        0,
        ...constructionSite.properties.map((item: IProperty) => (type === 'construction'
          ? item.weight_for_construction_sheet
          : item.weight_for_execution_list)),
      ) + 1
    );
  }
  return 1;
};

// Functions
/*
const addNewProperty = (
    property: IProperty,
    constructionSite: IConstructionSite,
    setConstructionSite: React.Dispatch<
        React.SetStateAction<IConstructionSite | undefined>
    >,
) => {
    if (constructionSite) {
        property.id =
            Math.max(
                0,
                ...constructionSite.properties.map((prop: IProperty) => prop.id),
            ) + 1;
        property.weight_for_execution_list = 0;
        property.weight_for_construction_sheet = 0;

        if (property.show_in_execution_list) {
            property.weight_for_execution_list =
                Math.max(
                    0,
                    ...constructionSite.properties.map(
                        (prop: IProperty) => prop.weight_for_execution_list,
                    ),
                ) + 1;
        }

        if (property.show_in_construction_sheet) {
            property.weight_for_construction_sheet =
                Math.max(
                    0,
                    ...constructionSite.properties.map(
                        (prop: IProperty) => prop.weight_for_construction_sheet,
                    ),
                ) + 1;
        }

        const newConstructionSite = { ...constructionSite };
        newConstructionSite.properties = [...constructionSite.properties, property];
        setConstructionSite(newConstructionSite);
    }
};
*/
const updateListValue = (
  option: IDropdownOption | undefined,
  currentSelection: IProperty,
  setCurrentSelection: React.Dispatch<
        React.SetStateAction<IProperty | undefined>
    >,
) => {
  // console.log(option);
  const currentProperty = _.cloneDeep(currentSelection);
  const list_types = currentProperty ? currentProperty.list_types : undefined;

  if (!currentProperty) return;
  if (option && option.selected && typeof option.key === 'number') {
    if (!list_types) {
      currentProperty.list_types = [
        { draft: true, list_type: { id: option.key, name: option.text } },
      ];
    } else {
      let exists = false;
      for (let i = 0; i < list_types.length; i++) {
        if (list_types[i] && list_types[i].list_type.id === option.key) {
          exists = true;
          list_types[i].delete = false;
        }
      }
      if (!exists) {
        list_types.push({
          draft: true,
          list_type: {
            id: option.key,
            name: option.text,
          },
        });
      }
    }
  } else if (option && !option.selected && typeof option.key === 'number') {
    if (list_types) {
      for (let i = 0; i < list_types.length; i++) {
        if (
          list_types[i]
                    && list_types[i]
                    && list_types[i].list_type.id === option.key
        ) {
          if (list_types[i].draft) {
            const index = i;
            if (index > -1) {
              list_types.splice(index, 1);
            }

            // delete list_types[i];
          } else {
            list_types[i].delete = true;
          }
        }
      }
    }
  }

  if (list_types) {
    currentProperty.list_types = list_types;
  }

  currentProperty.isDirty = true;

  setCurrentSelection(currentProperty);
};

const saveNewField = (
  { currentSelection, newProperty, constructionSite }: {currentSelection?: number, newProperty: IProperty, constructionSite: IConstructionSite},
  setConstructionSite: React.Dispatch<
        React.SetStateAction<IConstructionSite | undefined>
    >,
  setNewProperty: React.Dispatch<React.SetStateAction<IProperty>>,
  toggleModalNewItem: () => void,
) => {
  const currentNewProperty = _.cloneDeep(newProperty);
  currentNewProperty.isNew = true;

  const currentConstructionSite = _.cloneDeep(constructionSite);

  if (!currentConstructionSite) return;

  if (currentSelection) {
    console.log(currentSelection);
    // let currentSelectedProperty = _.cloneDeep(currentSelection);
    const currentSelectedProperty: IProperty | undefined = currentConstructionSite.properties.filter((item) => {
      if (currentSelection === item.id) return true;
      return false;
    })[0];

    console.log(currentSelectedProperty);
    if (currentSelectedProperty) {
      currentNewProperty.weight_for_construction_sheet = currentSelectedProperty.weight_for_construction_sheet;
      currentNewProperty.weight_for_execution_list = currentSelectedProperty.weight_for_execution_list;

      const currentItems = currentConstructionSite.properties;
      currentItems.push(currentNewProperty);
      const newItems = reorderItems(currentItems, currentNewProperty);

      currentConstructionSite.properties = newItems;

      setConstructionSite(currentConstructionSite);
      setNewProperty(defaultProperty);
      toggleModalNewItem();
    }
  } else {
    currentNewProperty.weight_for_construction_sheet = getNextWeight('construction');
    currentNewProperty.weight_for_execution_list = getNextWeight('execution');

    const currentConstructionSite = _.cloneDeep(constructionSite);

        currentConstructionSite!.properties.push(currentNewProperty);
        setConstructionSite(currentConstructionSite);
        setNewProperty(defaultProperty);
        toggleModalNewItem();
  }
};

const markItem = (
  { selectedKey, value, constructionSite }: { selectedKey?: number, constructionSite?: IConstructionSite, value: boolean },
  setConstructionSite: React.Dispatch<React.SetStateAction<IConstructionSite | undefined>>,
) => {
  if (!selectedKey || !constructionSite) return;

  setConstructionSite((prevState) => {
    const newState = _.cloneDeep(prevState);
    if (newState) {
      for (let i = 0; i < newState.properties.length; i++) {
        if (newState.properties[i].id === selectedKey) {
          newState.properties[i].marked = value;
          newState.properties[i].isDirty = true;
        }
      }
      return newState;
    }
    return prevState;
  });
};

const deleteItem = (
  { selectedKey, constructionSite }: { selectedKey?: number, constructionSite?: IConstructionSite},
  setConstructionSite: React.Dispatch<React.SetStateAction<IConstructionSite | undefined>>,
) => {
  if (!selectedKey || !constructionSite) return;

  setConstructionSite((prevState) => {
    const newState = _.cloneDeep(prevState);
    if (newState) {
      for (let i = 0; i < newState.properties.length; i++) {
        if (newState.properties[i].id === selectedKey) {
          newState.properties[i].delete = !newState.properties[i].delete;
          newState.properties[i].isDirty = true;
        }
      }
      return newState;
    }
    return prevState;
  });
};

const updateConstructionSheetRemarks = (
  { remarks, constructionSheetRemarks }: {
        remarks: IConstructionSheetRemark[],
        constructionSheetRemarks?: IConstructionSheetRemark[]
    },
  setConstructionSheetRemarks: React.Dispatch<React.SetStateAction<IConstructionSheetRemark[] | undefined>>,
) => {
  if (constructionSheetRemarks) {
    const updatedIds = remarks.map(
      (remark: IConstructionSheetRemark) => remark.id,
    );
    const newRemarks = constructionSheetRemarks.filter(
      (remark: IConstructionSheetRemark) => updatedIds.indexOf(remark.id) === -1,
    );
    setConstructionSheetRemarks(newRemarks.concat(remarks));
  }
};

export {
  reorderItems,
  getPropertyChildrenCreateData,
  getPropertyCreateData,
  getPropertyUpdateData,
  getPropertyListTypesUpdateData,
  getPropertyChildrenUpdateData,
  // addNewProperty,
  getNextWeight,
  markItem,
  deleteItem,
  updateListValue,
  saveNewField,
  updateConstructionSheetRemarks,
};

import * as React from 'react';
import CSS from 'csstype';
import { Stack, IStackProps } from '@fluentui/react/lib/Stack';

export interface Props extends IStackProps {
  children?: React.ReactNode;
  position?: CSS.PositionProperty;
  wrapperStyle?: React.CSSProperties;
  fullWidth?: boolean;
  className?: string;
}

const Container: React.FC<Props> = ({
  children,
  position = 'relative',
  wrapperStyle,
  fullWidth,
  ...props
}) => (
  <div
    style={{
      maxWidth: fullWidth ? '100%' : '1280px',
      width: '100%',
      margin: '0 auto',
      color: '#605e5c',
      justifyContent: 'center',
      position,
      ...wrapperStyle,
    }}
  >
    <Stack {...props}>{children}</Stack>
  </div>
);

export default Container;

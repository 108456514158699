import { useAuth0 } from '@auth0/auth0-react';
import { Stack, TextField } from '@fluentui/react';
import { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { useAppDispatch } from '../../redux/hooks';
import { throwError, SeverityLevel } from '../../redux/error/errorSlice';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  GET_CONSTRUCTION_SITES,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import ConstructionSitesOverview from './components/ConstructionSitesOverview';

interface IPagedConstructionSites {
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
  lastCall?: number;
}

const ConstructionSites = () => {
  const { isAuthenticated } = useAuth0();
  const dispatch = useAppDispatch();
  const [pagedState, setPagedState] = useState<IPagedConstructionSites>({
    skip: 0,
    take: 5,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'creation_date',
  });

  const [filter, setFilter] = useState('');

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({
      ...prevState,
      constructionSites: [],
      skip: 0,
    }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { name: { contains: filterArray[i] } },
          { address: { contains: filterArray[i] } },
          { city: { contains: filterArray[i] } },
        ],
      };

      const numericValue = parseInt(filterArray[i], 10);
      if (numericValue) {
        filterValue.OR.push({ zip_code: { equals: +numericValue } });
      }

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const variables = {
    filter: getFilters(debouncedValue),
    take: pagedState.take,
    skip: 0,
    orderBy: getOrderBy(),
  };

  const {
    data: dataConstructionSites,
    loading,
    error,
    fetchMore,
  } = useQuery(GET_CONSTRUCTION_SITES, {
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: 0,
      orderBy: getOrderBy(),
    },
    onError: error => {
      dispatch(
        throwError({
          module: 'constructionsiteOverview',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearConstructionSites();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearConstructionSites = () => {
    setPagedState(prevState => ({
      ...prevState,
      constructionSites: [],
      skip: 0,
    }));
  };

  const fetchMoreConstructionSites = () => {
    fetchMore({
      variables: {
        filter: getFilters(debouncedValue),
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Werven</h3>

      <TextField
        label='Zoeken ...'
        value={filter}
        styles={textFieldStyles300}
        onChange={onSearchValueChange}
      />

      <ConstructionSitesOverview
        constructionSites={
          dataConstructionSites
            ? dataConstructionSites.findManyConstructionSites
            : []
        }
        // filter={refPagedState.current.filter}
        loading={false}
        // filterChange={debouncedFilterChange}
        setSorting={setSorting}
        // refetchConstructionSites={refetchConstructionSites}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore
        loadMore
        loadMoreCallback={fetchMoreConstructionSites}
        lazyLoading
        listFilters={variables}
      />
    </Stack>
  );
};

export default ConstructionSites;

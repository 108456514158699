import React, { useState } from 'react';
import { Container } from '../layout';
import Header, { InnerHeader } from './Header';
import Navigation from './Navigation';
// import { Header, InnerHeader, Navigation } from '.';

const TopBarWithNavigation = ({ headerRef, headerHeight }: any) => {
  const [showNavigation, setShowNavigation] = useState(false);

  return (
    <div>
      <Container
        fullWidth
        position='absolute'
        wrapperStyle={{ top: 0, left: 0, right: 0 }}
      >
        <div ref={headerRef} style={{ width: '100%', position: 'relative' }}>
          <Header>
            <InnerHeader
              toggleNavigation={() => setShowNavigation(!showNavigation)}
            />
          </Header>
        </div>
      </Container>
      <Navigation
        show={showNavigation}
        headerHeight={headerHeight}
        toggleNavigation={() => setShowNavigation(!showNavigation)}
      />
    </div>
  );
};

export default TopBarWithNavigation;

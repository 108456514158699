import React, { FormEvent } from 'react';
import { Stack, TextField } from '@fluentui/react';
import { stackTokens5 } from '../../../utils';
import { ITemplateItem } from '../../../utils/TemplateItem';

const TemplateTextItem = ({
  item,
  callBack,
}: {
  item: ITemplateItem,
  callBack: any,
}) => {
  const updateItem = (newValue?: string) => {
    const newItem = { ...item };

    newItem.value = newValue || '';

    callBack(newItem);
  };

  return (
    <Stack tokens={stackTokens5}>
      <TextField
        label='Waarde'
        placeholder='Voer een standaard waarde in'
        value={item.value}
        name='value'
        multiline
        rows={5}
        required={item.type && item.type.id === 13}
        onChange={(
          event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ) => {
          updateItem(newValue);
        }}
      />
    </Stack>
  );
};

export default TemplateTextItem;

/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  DefaultButton,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import moment from 'moment';
import { useParams } from 'react-router';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { ICustomerInvoice } from '../../../utils/CustomerInvoice';
import { cancelIcon, iconButtonStyles, stackTokens5 } from '../../../utils';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { displayNameLead } from '../../../utils/Lead';
import CustomerInvoiceDetail from './CustomerInvoiceDetail';
import CustomerInvoiceDownloadTemplate from './CustomerInvoiceDownloadTemplate';

const CustomerInvoicesOverview = ({
  customerInvoices,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
}: any) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [customerInvoice, setCustomerInvoice] = useState<
    ICustomerInvoice | undefined
  >();

  const [showPdf, setShowPdf] = useState(false);

  const { id: customerInvoiceId } = useParams();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();
    if (currentSelection.length > 0) {
      setCustomerInvoice(currentSelection[0]);
    } else {
      setCustomerInvoice(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openCustomerInvoiceDetail = (newCustomerInvoice?: boolean) => {
    if (newCustomerInvoice) {
      setCustomerInvoice(undefined);
    }
    setIsPanelOpen(true);
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Factuurdatum',
      fieldName: 'invoice_date',
      minWidth: 85,
      maxWidth: 85,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>
          {customerInvoice.invoice_date &&
            moment(new Date(customerInvoice.invoice_date)).format('YYYY-MM-DD')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Factuur nr',
      fieldName: 'invoice_no',
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>{customerInvoice && customerInvoice.invoice_no}</span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Klant',
      fieldName: 'lead',
      minWidth: 175,
      maxWidth: 175,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>
          {customerInvoice.lead && displayNameLead(customerInvoice.lead)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Betaalbaar voor',
      fieldName: 'invoice_due_date',
      minWidth: 90,
      maxWidth: 90,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>
          {customerInvoice.invoice_due_date &&
            moment(new Date(customerInvoice.invoice_due_date)).format(
              'YYYY-MM-DD',
            )}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Betaald',
      fieldName: 'invoice_paid',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        /* const invoiceAmount = customerInvoice.customer_invoice_line_items
          .reduce(
            (previousValue: any, currentValue: any) =>
              previousValue +
              (!currentValue.percentage_vat ||
              +currentValue.percentage_vat === 0
                ? currentValue.unit_price * currentValue.units
                : +currentValue.percentage_vat < 1
                ? currentValue.unit_price *
                  currentValue.units *
                  (1 + currentValue.percentage_vat)
                : currentValue.unit_price *
                  currentValue.units *
                  (1 + currentValue.percentage_vat / 100)),
            0,
          )
          .toFixed(2);

        const invoiceAmountPayment = customerInvoice.customer_invoice_payments
          .reduce(
            (previousValue: any, currentValue: any) =>
              previousValue + currentValue.amount,
            0,
          )
          .toFixed(2); */

        <span>
          <Checkbox disabled checked={!!customerInvoice.invoice_paid} />
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Totaal excl. BTW',
      fieldName: 'unit_price',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>
          &euro;{' '}
          {customerInvoice.customer_invoice_line_items
            .reduce(
              (previousValue, currentValue) =>
                previousValue + currentValue.unit_price,
              0,
            )
            .toFixed(2)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'Totaal incl. BTW',
      fieldName: 'unit_price_with_vat',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>
          &euro;{' '}
          {customerInvoice.customer_invoice_line_items
            .reduce(
              (previousValue, currentValue) =>
                previousValue +
                (!currentValue.percentage_vat ||
                +currentValue.percentage_vat === 0
                  ? currentValue.unit_price * currentValue.units
                  : +currentValue.percentage_vat < 1
                  ? currentValue.unit_price *
                    currentValue.units *
                    (1 + currentValue.percentage_vat)
                  : currentValue.unit_price *
                    currentValue.units *
                    (1 + currentValue.percentage_vat / 100)),
              0,
            )
            .toFixed(2)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column8',
      name: 'Open saldo',
      fieldName: 'open_saldo',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => {
        const invoiceAmount = customerInvoice.customer_invoice_line_items
          .reduce(
            (previousValue: any, currentValue: any) =>
              previousValue +
              (!currentValue.percentage_vat ||
              +currentValue.percentage_vat === 0
                ? currentValue.unit_price * currentValue.units
                : +currentValue.percentage_vat < 1
                ? currentValue.unit_price *
                  currentValue.units *
                  (1 + currentValue.percentage_vat)
                : currentValue.unit_price *
                  currentValue.units *
                  (1 + currentValue.percentage_vat / 100)),
            0,
          )
          .toFixed(2);

        const invoiceAmountPayment = customerInvoice.customer_invoice_payments
          .reduce(
            (previousValue: any, currentValue: any) =>
              previousValue + currentValue.amount,
            0,
          )
          .toFixed(2);

        return (
          <span
            style={{
              color:
                +(invoiceAmount - invoiceAmountPayment).toFixed(2) < 0
                  ? 'orange'
                  : '',
            }}
          >
            &euro; {(invoiceAmount - invoiceAmountPayment).toFixed(2)}
          </span>
        );
      },
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column9',
      name: 'Betalingsschijf',
      fieldName: 'unit_price',
      minWidth: 85,
      maxWidth: 85,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => {
        if (
          customerInvoice &&
          customerInvoice.customer_payment_transaction &&
          customerInvoice.customer_payment_transaction.id
        ) {
          return (
            <a
              href={`/payment-transactions/${customerInvoice.customer_payment_transaction.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <DefaultButton>Bekijk schijf</DefaultButton>
            </a>
          );
        }
      },
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openCustomerInvoiceDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openCustomerInvoiceDetail(),
      theme: commandBarTheme,
      disabled: !customerInvoice || !customerInvoice.id,
    },
    {
      key: 'download',
      text: 'PDF',
      iconProps: { iconName: 'Download' },
      onClick: () => setShowPdf(true),
      theme: commandBarTheme,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (customerInvoiceId) {
      setIsPanelOpen(true);
    }
  }, []);

  useEffect(() => {
    if (customerInvoice && isPanelOpen) {
      document.title = `3bouw | Factuur - ${displayNameLead(
        customerInvoice.lead,
      )}`;
    } else {
      document.title = '3bouw | Uitgaande facturen';
    }
  }, [customerInvoice, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />

      {isPanelOpen && (
        <CustomerInvoiceDetail
          isOpen={isPanelOpen}
          customerInvoiceId={customerInvoiceId ? +customerInvoiceId : undefined}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          customerInvoiceSource={customerInvoice || {}}
        />
      )}

      {customerInvoices && (
        <>
          <ShimmeredDetailsList
            items={customerInvoices}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selectionMode={SelectionMode.single}
            selection={selection}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && customerInvoices.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}

      {showPdf && customerInvoice && (
        <Stack
          styles={{
            root: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              marginTop: '0!important',
              display: 'flex',
              padding: '20px',
            },
          }}
        >
          <Stack
            horizontal
            horizontalAlign='space-between'
            styles={{ root: { marginBottom: 10 } }}
          >
            <Stack.Item>
              <h2 style={{ marginTop: 0 }}>PDF Bekijken</h2>
              <Stack tokens={stackTokens5} horizontal>
                <Stack.Item>
                  <Stack horizontalAlign='end'>
                    <PDFDownloadLink
                      document={
                        <CustomerInvoiceDownloadTemplate
                          customerInvoice={customerInvoice}
                        />
                      }
                      fileName={'factuur{orderNo}.pdf'.replace(
                        '{orderNo}',
                        customerInvoice && customerInvoice.invoice_no
                          ? `-${customerInvoice?.invoice_no}`
                          : '',
                      )}
                    >
                      {({ blob, url, loading, error }) => {
                        loading ? '' : <PrimaryButton text='Pdf downloaden' />;
                      }}
                    </PDFDownloadLink>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel='Sluit venster'
                onClick={() => {
                  setShowPdf(false);
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <CustomerInvoiceDownloadTemplate
                customerInvoice={customerInvoice}
              />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};

export default CustomerInvoicesOverview;

import { gql } from '@apollo/client';
import { IEmployee } from '.';

export interface ICivilStatus {
  id: number;
  number_of_dependent_children: number;
  current_status: boolean;
  employee_id: number;
  end_date: Date;
  start_date: Date;
  version: number;

  employee: IEmployee;
}

export const GET_EMPLOYEE_DEPENDENT_CHILDREN = gql`
  query getEmployeeDependentChildren(
    $filter: EmployeeDependentChildrenFilterInput
  ) {
    findManyEmployeeDependentChildren(filter: $filter) {
      id
    }
  }
`;

export const GET_EMPLOYEE_DEPENDENT_CHILDREN_BY_ID = gql`
  query getEmployeeDependentChildrenById(
    $where: EmployeeDependentChildrenWhereInput
  ) {
    findOneEmployeeDependentChildren(where: $where) {
      id
      number_of_dependent_children
      start_date
      end_date
      current_status
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const ADD_EMPLOYEE_DEPENDENT_CHILDREN = gql`
  mutation AddEmployeeDependentChildren(
    $data: EmployeeDependentChildrenCreateInput!
  ) {
    createEmployeeDependentChildren(data: $data) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE_DEPENDENT_CHILDREN = gql`
  mutation UpdateEmployeeDependentChildren(
    $id: Int!
    $data: EmployeeDependentChildrenUpdateInput!
  ) {
    updateEmployeeDependentChildren(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_EMPLOYEE_DEPENDENT_CHILDREN = gql`
  mutation deleteEmployeeDependentChildren(
    $where: EmployeeDependentChildrenWhereInput
  ) {
    deleteEmployeeDependentChildren(where: $where) {
      id
    }
  }
`;

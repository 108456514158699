import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  GET_PURCHASE_ORDER_TEMPLATES,
  IPurchaseOrderTemplate,
  stackTokens15,
} from '../../utils';
import PurchaseOrderTemplatesOverview from './components/PurchaseOrderTemplatesOverview';

const PurchaseOrderTemplates = () => {
  const { isAuthenticated } = useAuth0(); // Do we need this check since it is already builtin the routing mechanism?

  const [purchaseOrderTemplates, setPurchaseOrderTemplates] = useState<
    IPurchaseOrderTemplate[]
  >([]);

  const { loading, error, refetch } = useQuery(GET_PURCHASE_ORDER_TEMPLATES, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    onCompleted: (x: any) =>
      setPurchaseOrderTemplates(x.findManyPurchaseOrderTemplates),
  });

  if (!isAuthenticated) return <p>Verboden</p>;
  if (loading) return <p>Laden...</p>;
  if (error) return <p>Fout :(</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Bestelbon sjablonen</h3>

      <PurchaseOrderTemplatesOverview
        purchaseOrderTemplates={purchaseOrderTemplates}
        refetch={refetch}
        setPurchaseOrderTemplates={setPurchaseOrderTemplates}
      />
    </Stack>
  );
};

export default PurchaseOrderTemplates;

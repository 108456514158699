import React, { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { Stack, TextField, Toggle } from '@fluentui/react';
import moment from 'moment';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import {
  GET_CUSTOMER_INVOICES,
  ICustomerInvoice,
} from '../../utils/CustomerInvoice';
import DateView from '../../components/parts/DateView';
import CustomerInvoicesOverview from './components/CustomerInvoicesOverview';

interface IPagedCustomerInvoice {
  customerInvoices: ICustomerInvoice[];
  skip: number;
  take: number;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const CustomerInvoices = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedCustomerInvoice>({
    customerInvoices: [],
    skip: 0,
    take: default_page_size,
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'invoice_date',
  });

  const [filter, setFilter] = useState('');
  const [isPaid, setIsPaid] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    start_date: '',
    end_date: '',
  });
  const today = new Date().toDateString();

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({
      ...prevState,
      customerInvoices: [],
      skip: 0,
    }));
  }, [filter, dateFilter, isPaid]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string

    if (pagedState.sortedField === 'invoice_date') {
      const secondOrderByObject: any = {}; // deliberately kept any as type to index by string

      orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';

      // eslint-disable-next-line dot-notation
      secondOrderByObject['invoice_no'] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';

      return [
        orderByObject,
        secondOrderByObject,
      ];
    }

    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (
    filterString: string,
    dates: {
      start_date: string;
      end_date: string;
    },
  ) => {
    const filterArray = filterString.split(' ');

    const filters: any = {
      AND: [
        {
          invoice_paid: isExpired ? false : isPaid === true ? true : undefined,
        },
      ],
    };

    if (dates.start_date || dates.end_date) {
      filters.AND.push({
        invoice_date: {
          gte: dates.start_date
            ? moment(new Date(dates.start_date))
            : undefined,
          lte: dates.end_date
            ? moment(new Date(dates.end_date)).endOf('days')
            : undefined,
        },
      });
    }

    if (isExpired) {
      filters.AND.push({
        invoice_due_date: {
          lte: moment(new Date(today)),
        },
      });
    }

    if (filterArray.length !== 0) {
      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [
            { invoice_no: { contains: filterArray[i] } },
            {
              lead: {
                OR: [
                  { first_name1: { contains: filterArray[i] } },
                  { first_name2: { contains: filterArray[i] } },
                  { last_name1: { contains: filterArray[i] } },
                  { last_name2: { contains: filterArray[i] } },
                ],
              },
            },
            { construction_site: { name: { contains: filterArray[i] } } },
          ],
        };
        filters.AND.push(filterValue);
      }
    }
    return filters;
  };

  const {
    loading,
    error,
    refetch: refetchCustomerInvoices,
  } = useQuery(GET_CUSTOMER_INVOICES, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, dateFilter),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      if (
        x &&
        x.findManyCustomerInvoices &&
        x.findManyCustomerInvoices.length > 0
      ) {
        setPagedState(prevState => ({
          ...prevState,
          customerInvoices: pagedState.customerInvoices.concat(
            x.findManyCustomerInvoices,
          ),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(
            x.findManyCustomerInvoices.length < default_page_size
          ),
        }));
      } else if (x && x.findManyCustomerInvoices) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearCustomerInvoices();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const onChangeFilterPaid = () => {
    setIsPaid(!isPaid);
    setIsExpired(false);
    clearCustomerInvoices();
    refetchCustomerInvoices();
  };

  const onChangeFilterExpired = () => {
    setIsPaid(false);
    setIsExpired(!isExpired);
    clearCustomerInvoices();
    refetchCustomerInvoices();
  };

  const clearCustomerInvoices = () => {
    setPagedState(prevState => ({
      ...prevState,
      customerInvoices: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.customerInvoices.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Uitgaande facturen</h3>

      <TextField
        label='Zoeken...'
        value={filter}
        styles={textFieldStyles300}
        onChange={onSearchValueChange}
      />

      <Stack style={{ flexDirection: 'row' }}>
        <Stack style={{ flexDirection: 'row', marginRight: 70 }}>
          <Stack style={{ marginBottom: 5, marginRight: 15 }}>
            <DateView
              item={dateFilter}
              setItem={setDateFilter}
              date={dateFilter && dateFilter.start_date}
              label='Startdatum'
              field='start_date'
              onChangeDate={(date: string) =>
                setDateFilter(prevState => ({ ...prevState, start_date: date }))
              }
            />
          </Stack>

          <Stack style={{ marginBottom: 5 }}>
            <DateView
              item={dateFilter}
              setItem={setDateFilter}
              date={dateFilter && dateFilter.end_date}
              label='Einddatum'
              field='end_date'
              onChangeDate={(date: string) =>
                setDateFilter(prevState => ({ ...prevState, end_date: date }))
              }
            />
          </Stack>
        </Stack>

        <Stack style={{ width: 160 }}>
          <Toggle
            label='Betaald?'
            checked={isPaid}
            onText='Betaald'
            offText='Alle'
            onChange={onChangeFilterPaid}
          />
        </Stack>

        <Toggle
          label='Vervallen?'
          checked={isExpired}
          onText='Vervallen'
          offText='Niet vervallen'
          onChange={onChangeFilterExpired}
        />
      </Stack>

      <CustomerInvoicesOverview
        customerInvoices={pagedState.customerInvoices}
        setCustomerInvoices={setPagedState}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchCustomerInvoices={refetchCustomerInvoices}
        clearCustomerInvoices={clearCustomerInvoices}
      />
    </Stack>
  );
};

export default CustomerInvoices;

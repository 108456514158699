import { RefObject } from 'react';

export const getRefElement = <T>(
    element?: RefObject<Element> | T,
  ): Element | T | undefined | null => {
    if (element && typeof element === 'object' && 'current' in element) {
      return element.current;
    }

    return element;
  };

  export const isSSR = !(
    typeof window !== 'undefined' && window.document?.createElement
  );

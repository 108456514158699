import React, { useState, FormEvent } from 'react';
import {
  TextField,
  IDropdownOption,
  Dropdown,
  Stack,
  Checkbox,
  Label,
  StackItem,
  Icon,
  Link,
} from '@fluentui/react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import {
  callOutClasses,
  GET_STORAGE_TOKEN,
  iconLastProps,
  stackTokens5,
} from '../../../utils';
import { BlobStorage, UploadFile } from '../../blob-storage';
import { CalloutDefault } from '../../../components/parts';
import { ITemplateItem } from '../../../utils/TemplateItem';
import { IPropertyType } from '../../../utils/PropertyType';

const TemplateDocumentItem = ({
  item,
  callBack,
  type,
  // hasNewItemName = false,
  template = undefined,
}: {
  item: ITemplateItem;
  callBack: any;
  type: IPropertyType;
  // hasNewItemName: boolean;
  template: undefined | number;
}) => {
  // const name = item ? item.name : '';
  const blobName = item && item.document ? item.document.blob_name : '';

  const { isAuthenticated } = useAuth0();

  // Queries
  const { loading, error, data } = useQuery(GET_STORAGE_TOKEN);

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const downloadFile = () => {
    if (blobName) {
      fileStore.downloadBlob(
        process.env.NODE_ENV === 'production' ? 'prod' : 'temp',
        blobName,
      );
    }
  };

  const toggleCallOut = () => {
    setIsCalloutVisible(!isCalloutVisible);
  };

  const deleteDocument = () => {
    setIsCalloutVisible(false);

    const newItem = { ...item };
    newItem.document = {
      is_external: false,
      blob_name: '',
      name: '',
      dirty: true,
      draft: !(newItem.document && newItem.document.id),
      id: newItem.document ? newItem.document.id : undefined,
    };
    return callBack(newItem);
  };

  const updateBlobName = (blobName: string, originalName?: string) => {
    const newItem = { ...item };
    // newItem.type = type; // waarom moet dit er staan?

    newItem.document = {
      is_external: false,
      blob_name: blobName,
      name: originalName || blobName || '',
      draft: true,
    };

    return callBack(newItem);
  };

  if (loading) return <p>Laden...</p>;

  const fileStore = new BlobStorage(
    data.createStorageToken.accountName,
    data.createStorageToken.sasToken,
  );

  return (
    <Stack tokens={stackTokens5}>
      <Stack tokens={stackTokens5}>
        {blobName && (
          <Label>
            Gekozen bestand{' '}
            <div className={callOutClasses.buttonArea}>
              <Icon
                iconName='Cancel'
                styles={iconLastProps}
                onClick={() => toggleCallOut()}
              />
            </div>
          </Label>
        )}
        {blobName && (
          <Stack
            horizontal
            tokens={stackTokens5}
            disableShrink={false}
            style={{ marginBottom: '10px' }}
          >
            <StackItem>
              <Link href='#' onClick={() => downloadFile()}>
                {blobName}
              </Link>
            </StackItem>
            <StackItem>
              <CalloutDefault
                actionConfirm={() => deleteDocument()}
                actionCancel={() => toggleCallOut()}
                actionText='Verwijderen'
                message='Bent u zeker het bestand te willen verwijderen? Het bestand zal niet meer gekoppeld worden aan deze template.'
                target={`.${callOutClasses.buttonArea}`}
                title='Bestand verwijderen?'
                visible={isCalloutVisible}
              />
            </StackItem>
          </Stack>
        )}
        <UploadFile
          // container='temp'
          updateName={updateBlobName}
          templateID={template}
        />
      </Stack>
    </Stack>
  );
};

export default TemplateDocumentItem;

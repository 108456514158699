import { IComboBoxOption } from '@fluentui/react';
import { gql } from '@apollo/client';
import { IContactPerson } from './ContactPerson';
import { ISupplier } from './Supplier';
import { IPurchaseOrderLine } from './PurchaseOrderLine';

export const GET_PURCHASE_ORDER_TEMPLATES = gql`
  query getPurchaseOrderTemplates($orderBy: PurchaseOrderTemplateOrderByInput) {
    findManyPurchaseOrderTemplates(orderBy: $orderBy) {
      id
      name
    }
  }
`;

export const GET_PURCHASE_ORDER_TEMPLATES_ALL_IN = gql`
  query getPurchaseOrderTemplates($orderBy: PurchaseOrderTemplateOrderByInput) {
    findManyPurchaseOrderTemplates(orderBy: $orderBy) {
      id
      name
      is_default_contact_person
      remarks
      contact_person {
        id
        first_name
        last_name
      }
      supplier {
        id
        name
        address
        zip_code
        city
        phone
        fax
        email
        contact
        contact_persons {
          id
          first_name
          last_name
        }
      }
      line_items {
        id
        name
        description
        sku
        unit_price
        comment
        quantity
        weight
        measuring_unit {
          id
          name
        }
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_TEMPLATE_BY_ID = gql`
  query getPurchaseOrderTemplateById($where: PurchaseOrderTemplateWhereInput) {
    findOnePurchaseOrderTemplate(where: $where) {
      id
      name
      is_default_contact_person
      remarks
      contact_person {
        id
        first_name
        last_name
      }
      supplier {
        id
        name
        address
        zip_code
        city
        phone
        fax
        email
        contact
        contact_persons {
          id
          first_name
          last_name
        }
      }
      line_items {
        id
        name
        description
        sku
        unit_price
        comment
        quantity
        weight
        measuring_unit {
          id
          name
        }
      }
    }
  }
`;

export const ADD_PURCHASE_ORDER_TEMPLATE = gql`
  mutation addPurchaseOrderTemplate($data: PurchaseOrderTemplateCreateInput!) {
    createPurchaseOrderTemplate(data: $data) {
      id
      name
      is_default_contact_person
      remarks
      contact_person {
        id
        first_name
        last_name
      }
      supplier {
        id
        name
        address
        zip_code
        city
        phone
        fax
        email
        contact
        contact_persons {
          id
          first_name
          last_name
        }
      }
      line_items {
        id
        name
        description
        sku
        unit_price
        comment
        quantity
        weight
        measuring_unit {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_TEMPLATE = gql`
  mutation updatePurchaseOrderTemplate(
    $id: Int!
    $data: PurchaseOrderTemplateUpdateInput
  ) {
    updatePurchaseOrderTemplate(id: $id, data: $data) {
      id
      name
      is_default_contact_person
      remarks
      contact_person {
        id
        first_name
        last_name
      }
      supplier {
        id
        name
        address
        zip_code
        city
        phone
        fax
        email
        contact
        contact_persons {
          id
          first_name
          last_name
        }
      }
      line_items {
        id
        name
        description
        sku
        unit_price
        comment
        quantity
        weight
        measuring_unit {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_PURCHASE_ORDER_TEMPLATE = gql`
  mutation deletePurchaseOrderTemplate(
    $where: PurchaseOrderTemplateWhereInput
  ) {
    deletePurchaseOrderTemplate(where: $where) {
      id
      name
    }
  }
`;

export const convertPurchaseOrderTemplatesToComboBoxOptions = (
  templates: IPurchaseOrderTemplate[],
) => {
  const result: IComboBoxOption[] = templates.map(
    (template: IPurchaseOrderTemplate): IComboBoxOption => ({
      key: template.id,
      text: template.name || '',
    }),
  );
  return result;
};

export interface IPurchaseOrderTemplate {
  id: number;
  is_default_contact_person: boolean;
  name?: string;
  remarks?: string;
  contact_person?: IContactPerson;
  contact_person_changed?: boolean;
  line_items: IPurchaseOrderLine[];
  supplier?: ISupplier;
}

export const default_template: IPurchaseOrderTemplate = {
  id: -1,
  is_default_contact_person: true,
  contact_person_changed: false,
  line_items: [],
  name: '',
  remarks: '',
};

export const getDefaultTemplate = () => default_template;

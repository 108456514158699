import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import { IEmployee } from './Employee';

export const GET_LEADS = gql`
  query getLeads(
    $filter: LeadFilterInput
    $take: Int
    $skip: Int
    $orderBy: LeadOrderByInput
  ) {
    findManyLeads(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile1_v2
      mobile2
      mobile2_v2
      phone1
      phone1_v2
      phone2
      phone2_v2
      title1
      title2
      zip_code1
      preferred_way_of_contact
      status
      sales_rep_employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const GET_LEAD_BY_ID = gql`
  query getLeadById($where: LeadWhereInput) {
    findOneLead(where: $where) {
      id
      address1
      budget_estimate_high
      budget_estimate_low
      city1
      close_follow_up
      comments
      competitors
      construction_site_address
      construction_site_available
      construction_site_city
      construction_site_comments
      construction_site_wanted_location
      construction_site_zip_code
      contact_method
      country
      created_by {
        id
        first_name
        last_name
      }
      date_created
      date_documentation_sent
      date_last_info_visit
      date_last_updated
      date_visit_exhibition_house
      date_visit_exposition
      degree_of_completion
      dirty_object
      email1
      email1opt_out
      email2
      email2opt_out
      first_name1
      first_name2
      known_by
      last_name1
      last_name2
      last_updated_by {
        id
        first_name
        last_name
      }
      mobile1
      mobile1_v2
      mobile1search
      mobile2
      mobile2_v2
      mobile2search
      phone1
      phone1_v2
      phone1search
      phone2
      phone2_v2
      phone2search
      reason_declined
      status
      title1
      title2
      type_of_home
      vat
      version
      preferred_way_of_contact
      year_to_start_construction
      zip_code1
      wings_code
      wings_account_code
      invoices {
        id
      credit_note
      customer_id
      intro
      invoice_date
      invoice_due_date
      invoice_no
      invoice_paid
      sent
      outro
      version
      customer {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
      lead {
        id
        first_name1
        first_name2
        last_name1
        last_name2
        address1
        city1
        zip_code1
        phone1
        phone1_v2
        phone2
        phone2_v2
        preferred_way_of_contact
        mobile1
        mobile1_v2
        mobile2
        mobile2_v2
        email1
      }
      customer_invoice_line_items {
        comments
        customer_invoice_id
        description
        id
        percentage_vat
        show_only_total
        units
        unit_price
      }
      customer_invoice_payments {
        amount
        comments
        customer_invoice_id
        id
        payment_date
      }
      customer_payment_transaction {
        id
      }
      construction_site {
        name
        id

        lead {
          id
          first_name1
          first_name2
          last_name1
          last_name2
          address1
          city1
          zip_code1
          phone1
          phone1_v2
          phone2
          phone2_v2
          preferred_way_of_contact
          mobile1
          mobile1_v2
          mobile2
          mobile2_v2
        }
      }
      }

      sales_rep_employee {
        id
      }
      log_items {
        id
        title
        text
        action_required
        action_required_by
        last_updated_by_id
        last_updated
        sales_rep {
          id
          first_name
          last_name
        }
      }
      quote_references {
        id
        quote_no
        quote_date
        description
      }

      construction_sites {
        id
        name
        address
        zip_code
        city
      }
    }
  }
`;

export const ADD_LEAD = gql`
  mutation addLead($data: LeadCreateInput!) {
    createLead(data: $data) {
      id
      address1
      budget_estimate_high
      budget_estimate_low
      city1
      close_follow_up
      comments
      competitors
      construction_site_address
      construction_site_available
      construction_site_city
      construction_site_comments
      construction_site_wanted_location
      construction_site_zip_code
      contact_method
      country
      created_by {
        id
        first_name
        last_name
      }
      log_items {
        id
        title
        text
        action_required
        action_required_by
        last_updated_by_id
        last_updated
        sales_rep {
          id
          first_name
          last_name
        }
      }
      date_created
      date_documentation_sent
      date_last_info_visit
      date_last_updated
      date_visit_exhibition_house
      date_visit_exposition
      degree_of_completion
      dirty_object
      email1
      email1opt_out
      email2
      email2opt_out
      first_name1
      first_name2
      known_by
      last_name1
      last_name2
      last_updated_by {
        id
        first_name
        last_name
      }
      mobile1
      mobile1_v2
      mobile1search
      mobile2
      mobile2_v2
      mobile2search
      phone1
      phone1_v2
      phone1search
      phone2
      phone2_v2
      phone2search
      reason_declined
      status
      title1
      title2
      type_of_home
      vat
      version
      year_to_start_construction
      zip_code1
      wings_code
      wings_account_code

      sales_rep_employee {
        id
      }
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation updateLead($id: Int!, $data: LeadUpdateInput) {
    updateLead(id: $id, data: $data) {
      id
      address1
      budget_estimate_high
      budget_estimate_low
      city1
      close_follow_up
      comments
      competitors
      construction_site_address
      construction_site_available
      construction_site_city
      construction_site_comments
      construction_site_wanted_location
      construction_site_zip_code
      contact_method
      country
      created_by {
        id
        first_name
        last_name
      }
      date_created
      date_documentation_sent
      date_last_info_visit
      date_last_updated
      date_visit_exhibition_house
      date_visit_exposition
      degree_of_completion
      dirty_object
      email1
      email1opt_out
      email2
      email2opt_out
      first_name1
      first_name2
      known_by
      last_name1
      last_name2
      last_updated_by {
        id
        first_name
        last_name
      }
      log_items {
        id
        title
        text
        action_required
        action_required_by
        last_updated_by_id
        last_updated
        sales_rep {
          id
          first_name
          last_name
        }
      }
      mobile1
      mobile1_v2
      mobile1search
      mobile2
      mobile2_v2
      mobile2search
      phone1
      phone1_v2
      phone1search
      phone2
      phone2_v2
      phone2search
      reason_declined
      status
      title1
      title2
      type_of_home
      vat
      version
      year_to_start_construction
      zip_code1
      wings_code
      wings_account_code
      sales_rep_employee {
        id
      }
      log_items {
        id
        title
        text
        action_required
        last_updated_by_id
        last_updated
        app_user {
          id
        }
      }
      quote_references {
        id
        quote_no
        quote_date
        description
      }
    }
  }
`;

export const DELETE_LEAD = gql`
  mutation deleteLead($where: LeadWhereInput) {
    deleteLead(where: $where) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile1_v2
      mobile2
      mobile2_v2
      phone1
      phone1_v2
      phone2
      phone2_v2
      title1
      title2
      zip_code1
      status
    }
  }
`;

export interface ICreateLead {
  id?: number;
  address1?: string;
  budget_estimate_high?: number;
  budget_estimate_low?: number;
  city1?: string;
  close_follow_up?: boolean;
  comments?: string;
  competitors?: string;
  construction_site_address?: string;
  construction_site_available?: boolean;
  construction_site_city?: string;
  construction_site_comments?: string;
  construction_site_wanted_location?: string;
  construction_site_zip_code?: number;
  contact_method?: string;
  country?: string;
  created_by_user_id?: number;
  customer_ref_id?: number;
  date_created?: Date;
  date_documentation_sent?: Date;
  date_last_info_visit?: Date;
  date_last_updated?: Date;
  date_visit_exhibition_house?: Date;
  date_visit_exposition?: Date;
  degree_of_completion?: string;
  dirty_object?: string;
  email1?: string;
  email1opt_out?: boolean;
  email2?: string;
  email2opt_out?: boolean;
  fax1?: string;
  fax2?: string;
  first_name1?: string;
  first_name2?: string;
  known_by?: string;
  last_name1?: string;
  last_name2?: string;
  last_updated_by_user_id?: number;
  mobile1?: string;
  mobile1_v2?: string;
  mobile1search?: string;
  mobile2?: string;
  mobile2_v2?: string;
  mobile2search?: string;
  phone1?: string;
  phone1_v2?: string;
  phone1search?: string;
  phone2?: string;
  phone2_v2?: string;
  phone2search?: string;
  preferred_way_of_contact?: string;
  reason_declined?: string;
  sales_rep_id?: number;
  status?: string;
  title1?: string;
  title2?: string;
  type_of_home?: string;
  vat?: string;
  version?: number;
  year_to_start_construction?: number;
  zip_code1?: number;
  sales_rep_employee?: IEmployee;
  created_by?: any;
  wings_code?: number;
  wings_account_code?: number;
}

export interface ILead {
  id: number;
  address1: string;
  budget_estimate_high: number;
  budget_estimate_low: number;
  city1: string;
  close_follow_up: boolean;
  comments: string;
  competitors: string;
  construction_site_address: string;
  construction_site_available: boolean;
  construction_site_city: string;
  construction_site_comments: string;
  construction_site_wanted_location: string;
  construction_site_zip_code: number;
  contact_method: string;
  country: string;
  created_by_user_id: number;
  customer_ref_id: number;
  date_created: Date;
  date_documentation_sent: Date;
  date_last_info_visit: Date;
  date_last_updated: Date;
  date_visit_exhibition_house: Date;
  date_visit_exposition: Date;
  degree_of_completion: string;
  dirty_object: string;
  email1: string;
  email1opt_out: boolean;
  email2: string;
  email2opt_out: boolean;
  fax1: string;
  fax2: string;
  first_name1: string;
  first_name2: string;
  known_by: string;
  last_name1: string;
  last_name2: string;
  last_updated_by_user_id: number;
  mobile1: string;
  mobile1_v2: string;
  mobile1search: string;
  mobile2: string;
  mobile2_v2: string;
  mobile2search: string;
  phone1: string;
  phone1_v2: string;
  phone1search: string;
  phone2: string;
  phone2_v2: string;
  phone2search: string;
  preferred_way_of_contact?: string;
  reason_declined: string;
  sales_rep_id: number;
  status: string;
  title1: string;
  title2: string;
  type_of_home: string;
  vat: string;
  version: number;
  year_to_start_construction: number;
  zip_code1: number;
  sales_rep_employee: IEmployee;
  created_by: any;
  wings_code?: number;
  wings_account_code?: number;
}

export interface ILeadCreateUpdate {
  id?: number;
  address1?: string;
  budget_estimate_high?: number;
  budget_estimate_low?: number;
  city1?: string;
  close_follow_up?: boolean;
  comments?: string;
  competitors?: string;
  construction_site_address?: string;
  construction_site_available?: boolean;
  construction_site_city?: string;
  construction_site_comments?: string;
  construction_site_wanted_location?: string;
  construction_site_zip_code?: number;
  contact_method?: string;
  country?: string;
  created_by_user_id?: number;
  customer_ref_id?: number;
  date_created?: Date;
  date_documentation_sent?: Date;
  date_last_info_visit?: Date;
  date_last_updated?: Date;
  date_visit_exhibition_house?: Date;
  date_visit_exposition?: Date;
  degree_of_completion?: string;
  dirty_object?: string;
  email1?: string;
  email1opt_out?: boolean;
  email2?: string;
  email2opt_out?: boolean;
  fax1?: string;
  fax2?: string;
  first_name1?: string;
  first_name2?: string;
  known_by?: string;
  last_name1?: string;
  last_name2?: string;
  last_updated_by_user_id?: number;
  mobile1?: string;
  mobile1_v2?: string;
  mobile1search?: string;
  mobile2?: string;
  mobile2_v2?: string;
  mobile2search?: string;
  phone1?: string;
  phone1_v2?: string;
  phone1search?: string;
  phone2?: string;
  phone2_v2?: string;
  phone2search?: string;
  reason_declined?: string;
  sales_rep_id?: number;
  status?: string;
  title1?: string;
  title2?: string;
  type_of_home?: string;
  vat?: string;
  version?: number;
  year_to_start_construction?: number;
  zip_code1?: number;
  sales_rep_employee?: IEmployee;
  created_by?: any;
  wings_code?: number;
  wings_account_code?: number;
}

export const convertLeadsToComboBoxOptions = (leads: ILead[]) => {
  if (leads) {
    const result: IComboBoxOption[] = leads.map(
      (lead: ILead): IComboBoxOption => ({
        key: lead.id,
        text: displayNameLead(lead),
      }),
    );
    return result;
  }

  return [];
};

export const convertLeadsToTimesheetComboBoxOptions = (leads: ILead[]) => {
  if (leads) {
    const result: IComboBoxOption[] = leads.map(
      (lead: ILead): IComboBoxOption => ({
        key: `lead_${lead.id}`,
        text: displayNameLead(lead),
      }),
    );
    return result;
  }

  return [];
};

export const displayNameLead = (lead?: ILead | ICreateLead) => {
  if (!lead) return '';
  return '{first_name1}{last_name1}{optionalfirst_name2}{optionallast_name2}'
    .replace(
      '{first_name1}',
      lead.first_name1 && lead.first_name1 !== null
        ? `${lead.first_name1}`
        : '',
    )
    .replace(
      '{last_name1}',
      lead.last_name1 && lead.last_name1 !== null ? ` ${lead.last_name1}` : '',
    )
    .replace(
      '{optionalfirst_name2}',
      lead.first_name2 && lead.first_name2 !== null
        ? ` - ${lead.first_name2}`
        : '',
    )
    .replace(
      '{optionallast_name2}',
      lead.last_name2 && lead.last_name2.trim() !== ''
        ? lead.first_name2 && lead.first_name2 !== null
          ? ` ${lead.last_name2}`
          : ` - ${lead.last_name2}`
        : '',
    );
};

import React, { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
  Stack,
  StackItem,
  TextField,
  Toggle,
  TextStyles,
} from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import {
  GET_CUSTOMER_PAYMENT_TRANSACTIONS,
  ICustomerPaymentTransaction,
} from '../../utils/CustomerPaymentTransaction';
import CustomerPaymentTransactionsOverview from './components/CustomerPaymentTransactionOverview';

interface IPagedCustomerPaymentTransaction {
  customerPaymentTransactions: ICustomerPaymentTransaction[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const CustomerPaymentTransactions = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] =
    useState<IPagedCustomerPaymentTransaction>({
      customerPaymentTransactions: [],
      skip: 0,
      take: 50,
      filter: '',
      showLoadMore: true,
      isFiltered: false,
      initialLoad: true,
      isSortedAsc: true,
      sortedField: 'construction_site_id',
    });

  const [filter, setFilter] = useState('');
  const [activeFilter, setActiveFilter] = useState(true);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({
      ...prevState,
      customerPaymentTransactions: [],
      skip: 0,
    }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string, active: boolean) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };
    if (active) {
      filterObject.AND.push(
        {
          customer_invoices: {
            none: {},
          },
        },
      );
    }

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { description: { contains: filterArray[i] } },
          {
            construction_site: {
              OR: [{ name: { contains: filterArray[i] } }],
            },
          },
        ],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    loading,
    error,
    refetch: refetchCustomerPaymentTransactions,
  } = useQuery(GET_CUSTOMER_PAYMENT_TRANSACTIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, activeFilter),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      if (
        x &&
        x.findManyCustomerPaymentTransactions &&
        x.findManyCustomerPaymentTransactions.length > 0
      ) {
        setPagedState(prevState => ({
          ...prevState,
          customerPaymentTransactions:
            pagedState.customerPaymentTransactions.concat(
              x.findManyCustomerPaymentTransactions,
            ),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(
            x.findManyCustomerPaymentTransactions.length < default_page_size
          ),
        }));
      } else if (x && x.findManyCustomerPaymentTransactions) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearCustomerPaymentTransactions();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearCustomerPaymentTransactions = () => {
    setPagedState(prevState => ({
      ...prevState,
      customerPaymentTransactions: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.customerPaymentTransactions.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  const onChangeActiveFilter = () => {
    setActiveFilter(!activeFilter);
    clearCustomerPaymentTransactions();
    refetchCustomerPaymentTransactions();
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Overzicht betalingsschijven</h3>

      <Stack style={{ flexDirection: 'row' }}>
        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />

        <StackItem style={{ marginLeft: 70 }}>
          <Toggle
            label='Openstaand?'
            defaultChecked={activeFilter}
            onText='Openstaand'
            offText='Afgesloten'
            onChange={onChangeActiveFilter}
          />
        </StackItem>
      </Stack>

      <CustomerPaymentTransactionsOverview
        customerPaymentTransactions={pagedState.customerPaymentTransactions}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchCustomerPaymentTransactions={refetchCustomerPaymentTransactions}
        clearCustomerPaymentTransactions={clearCustomerPaymentTransactions}
      />
    </Stack>
  );
};

export default CustomerPaymentTransactions;

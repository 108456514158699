import { useState, useEffect, useCallback } from 'react';
import {
  Checkbox,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useBoolean } from '@fluentui/react-hooks';
import _ from 'lodash';
import {
  ADD_CAR,
  GET_CAR_BY_ID,
  UPDATE_CAR,
  DELETE_MANY_CARS,
  ICar,
  GET_CARS,
} from '../../../utils/Car';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { GET_EMPLOYEES, GET_SALES_REPS } from '../../../utils';
import { useAppDispatch } from '../../../redux/hooks';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import CarDetail from './CarDetail';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  cars: ICar[],
  // filter,
  // filterChange?: (filter: string) => void,
  setSorting: (isSortedAsc: boolean, sortedField: string) => void,
  isSortedAsc?: boolean,
  sortedField?: string,
  showLoadMore?: boolean,
  listFilters: any,
}

const CarsOverview = ({
  cars,
  // filter,
  loading,
  // filterChange = (filter: string) => {},
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  listFilters,
}: Props) => {
  const dispatch = useAppDispatch();
  const [car, setCar] = useState<ICar | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [deleteDialogOpen, { toggle: toggleDelete }] = useBoolean(false);
  const [salesReps, setSalesReps] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [addCar] = useMutation(ADD_CAR, {
    onError: error => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveCar',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // ADD ITEM TO LIST IN CACHE, BUG -> MERGE DOES NOT REPLACE THE INCOMMING 'UPDATED' LIST WITH existing list. (apollo cache in app.tsx);
    /* fetchPolicy: 'no-cache',
    update(cache, { data }) {
      try {
        const createdCar = data.createCar;
        const existingCars = cache.readQuery<{
          findManyCars: ICar[];
        }>({
          query: GET_CARS,
          variables: listFilters,
        });

        const findManyCars = existingCars
          ? _.cloneDeep(existingCars?.findManyCars)
          : null;

        console.log(findManyCars);
        console.log(createdCar);

        if (findManyCars && createdCar) {
          console.log('befor new car', findManyCars);
          findManyCars.unshift(createdCar);
          console.log('after new car', findManyCars);
          cache.writeQuery({
            query: GET_CARS,
            variables: listFilters,
            data: {
              findManyCars,
            },
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    }, */
  });

  const [modifyCar] = useMutation(UPDATE_CAR, {
    onError: error => {
      dispatch(dismissNotification());
      dispatch(
        throwError({
          module: 'executionList.saveCar',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // no need for writeQuery since apollo updates cache automatically when executing a update mutation
  });

  const [deleteManyCars] = useMutation(DELETE_MANY_CARS, {
    onError: error => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveCar',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // REMOVE ITEM FROM LIST IN CACHE, BUG -> MERGE DOES NOT REPLACE THE INCOMMING 'UPDATED' LIST WITH existing list. (apollo cache in app.tsx);
    /* fetchPolicy: 'no-cache',
    update(cache) {
      const existingCars = cache.readQuery<{
        findManyCars: ICar[];
      }>({
        query: GET_CARS,
        variables: listFilters,
      });

      const findManyCars = existingCars
        ? _.cloneDeep(existingCars?.findManyCars)
        : null;

      // delete each selected car in findMany cars list and replace in cache
      if (findManyCars && selectionDetails) {
        for (let i = 0; i < selectionDetails.length; i++) {
          for (let j = 0; j < findManyCars.length; j++) {
            if (findManyCars[j].id === selectionDetails[i].id) {
              console.log('found, remove from manyCars list');
              findManyCars.splice(j, 1);
            }
          }
        }

       // console.log(findManyCars);

        cache.writeQuery({
          query: GET_CARS,
          variables: listFilters,
          data: {
            findManyCars,
          },
        });
      }
    }, */
  });

  useQuery(GET_SALES_REPS, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setSalesReps(x.findManySalesReps);
    },
  });

  useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ICar[] | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();
    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openCarDetail = (newCar?: boolean) => {
    if (selectionDetails && !newCar) {
      // getCar({
      //   variables: {
      //     where: { id: selectionDetails.id },
      //     orderInvoiceLineItemBy: { id: 'desc' },
      //   },
      // });
    } else {
      setCar(undefined);
    }
    setIsPanelOpen(true);
  };

  const { refetch: refetchCar } = useQuery(GET_CAR_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables:
      selectionDetails && selectionDetails.length > 0
        ? { where: { id: selectionDetails[0].id } }
        : undefined,
    skip: !selectionDetails || selectionDetails.length > 1,
    onCompleted: data => {
      setCar(data.findOneCar);
    },
  });

  const saveCar = () => {
    if (car) {
      console.log('save car', car);
      const allInput = {
        name: car.name,
        car_type: car.car_type,
        car_comments: car.car_comments,
        chassis_number: car.chassis_number,
        fuel_type: car.fuel_type,
        license_plate: car.license_plate,
        first_use_date: car.first_use_date,
        lease_start_date: car.lease_start_date,
        lease_stop_date: car.lease_stop_date,
        purchase_date: car.purchase_date,
        tank_card_code: car.tank_card_code,
        tank_card_comments: car.tank_card_comments,
        tank_card_number: car.tank_card_number,
        tank_card_number2: car.tank_card_number2,
        sold_date: car.sold_date,
        active: car.active,
      };

      if (car.id) {
        modifyCar({
          variables: {
            id: car.id,
            data: allInput,
          },
        }).then(() => {
          setIsPanelOpen(false);
        });
      } else {
        addCar({
          variables: {
            data: allInput,
          },
        }).then((x: any) => {
          if (x && x.data && x.data.createCar) setCar(x.data.createCar); // set new car details to current car
          // refetchCars();
          // setIsPanelOpen(false); -> create car and allow adding drivers
        });
      }
    }
  };

  const deleteSelection = useCallback(() => {
    // delete cars after confirmation
    if (!selectionDetails) {
      toggleDelete();
      return;
    }
    deleteManyCars({
      variables: {
        where: {
          id: {
            in: selectionDetails.map(car => car.id),
          },
        },
      },
    }).then(() => {
      // refetchCars();
      toggleDelete();
    });
  }, [selectionDetails]);

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Benaming',
      fieldName: 'name',
      minWidth: 250,
      maxWidth: 250,
      isRowHeader: true,
      onRender: (car: ICar) => <span>{car.name}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Type',
      fieldName: 'car_type',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (car: ICar) => <span>{car.car_type}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Nummerplaat',
      fieldName: 'license_plate',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (car: ICar) => <span>{car.license_plate}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Telefoon',
      fieldName: 'car_phone',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (car: ICar) => <span>{car.car_phone}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Bestuurder',
      fieldName: 'driver_id',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (car: ICar) =>
        car &&
        car.car_driver &&
        car.car_driver.length > 0 &&
        car.car_driver.map((car_driver: any) => {
          if (car_driver.current_driver) {
            return (
              <span>{`${car_driver.employee.last_name} ${car_driver.employee.first_name}`}</span>
            );
          }
        }),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'In gebruik?',
      fieldName: 'active',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      onRender: (car: ICar) => (
        <span>
          <Checkbox disabled checked={!!car.active} />
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openCarDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openCarDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails || selectionDetails.length > 1,
    },
    {
      key: 'delete',
      text: 'Verwijderen',
      iconProps: { iconName: 'Trash' },
      onClick: () => toggleDelete(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
       // loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (car && isPanelOpen) {
      document.title = `3bouw | Auto - ${car.license_plate}`;
    } else {
      document.title = "3bouw | Auto's";
    }
  }, [car, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />
      {isPanelOpen && (
        <CarDetail
          isOpen={isPanelOpen}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          car={car || {}}
          saveCar={saveCar}
          setCar={setCar}
          refetchCar={refetchCar}
          employees={employees}
          listFilters={listFilters}
          salesReps={salesReps}
        />
      )}
      {deleteDialogOpen && selectionDetails && selectionDetails.length > 0 && (
        <Dialog
          hidden={!deleteDialogOpen}
          onDismiss={toggleDelete}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Wagens verwijderen',
            closeButtonAriaLabel: 'Close',
          }}
        >
          <p>
            <strong>
              Ben je zeker dat je de volgende wagens wil verwijderen?
            </strong>
          </p>
          <p>
            <ul>
              {selectionDetails.map(car => (
                <li>{car.license_plate}</li>
              ))}
            </ul>
          </p>
          <p>Deze actie kan niet ongedaan gemaakt worden.</p>
          <DialogFooter>
            <PrimaryButton text='Verwijderen' onClick={deleteSelection} />
            <DefaultButton text='Annuleren' onClick={toggleDelete} />
          </DialogFooter>
        </Dialog>
      )}

      {cars && (
        <>
          <ShimmeredDetailsList
            items={cars}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading} // && initialLoad
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selection={selection}
            selectionMode={SelectionMode.multiple}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMoreCallback()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && cars.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default CarsOverview;

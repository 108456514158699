import { gql } from '@apollo/client';
import moment from 'moment';
import { IDocument } from './Document';
import { ISuggestedValue } from './SuggestedValue';
import { ISalesRep } from './SalesRep';
import { IPropertySupplier } from './Supplier';
import { IPropertyType } from './PropertyType';
import { IEmployee } from './Employee';
import { IPropertyPropertyListType } from './PropertyPropertyListTypes';
import { ITemplateItem } from './TemplateItem';

export const GET_WATER_POINTS_BY_CONSTRUCTION_SITE = gql`
  query GetWaterPoinsByConstructionSite($filter: PropertyWhereInput) {
    findManyProperties(filter: $filter) {
      id
      name
      value
      tap_water_points
      rain_water_points
    }
  }
`;

export const ADD_PROPERTY = gql`
  mutation AddProperty($data: PropertyCreateInput!) {
    createProperty(data: $data) {
      id
      name
    }
  }
`;

export const UPDATE_PROPERTY = gql`
  mutation UpdateProperty($id: Int!, $data: PropertyUpdateInput) {
    updateProperty(id: $id, data: $data) {
      id
      value
    }
  }
`;

export const DELETE_PROPERTY = gql`
  mutation DeleteProperty($where: PropertyWhereInput) {
    deleteProperty(where: $where) {
      id
      name
    }
  }
`;

export const sortPropertiesOnExecutionList = (a: IProperty, b: IProperty) => sortPropertiesOn('weight_for_execution_list', a, b);

export const sortPropertiesOnConstructionSheet = (
  a: IProperty,
  b: IProperty,
) => sortPropertiesOn('weight_for_construction_sheet', a, b);

export const updateWeightsOnProperties = (
  weight: string,
  properties: any[],
) => {
  for (let i = 0; i < properties.length; i++) {
    if (properties[i][weight] !== i + 1) {
      properties[i][weight] = i + 1;
      properties[i].isDirty = true;
    }
  }
  return properties;
};

export const defaultProperty: IProperty = {
  children: [],
  type_children: [],
  is_ordered: false,
  is_not_applicable: false,
  needs_water_points: false,
  list_types: [],
  name: 'nieuwe eigenschap',
  show_in_construction_sheet: false,
  show_in_execution_list: true,
  marked: false,
  tap_water_points: 0,
  rain_water_points: 0,
  type: {
    id: 9,
    name: 'string',
    label: 'Tekst',
  },
  weight_for_construction_sheet: -1,
  weight_for_execution_list: -1,
  isNew: true,
  id: moment().unix(), // temp -> should not be send to DB!!
};

export interface IProperty {
  children: IProperty[];
  type_children: IProperty[];
  document?: IDocument;
  due_date?: Date;
  execution_date?: Date;
  id: number;
  is_ordered: boolean;
  is_not_applicable: boolean;
  list_types: IPropertyPropertyListType[];
  name: string;
  order_date?: Date;
  rain_water_points: number;
  remote_category?: string;
  send_alert?: boolean;
  show_in_construction_sheet: boolean;
  show_in_execution_list: boolean;
  suggested_value?: ISuggestedValue;
  tap_water_points: number;
  type: IPropertyType;
  nested_type?: IPropertyType;
  value?: string;
  weight_for_construction_sheet: number;
  weight_for_execution_list: number;
  marked?: boolean;
  employee?: IEmployee;
  sales_rep?: ISalesRep;
  suppliers?: IPropertySupplier[];
  deletedSuppliers?: IPropertySupplier[];
  reporting_item?: ITemplateItem;
  template_item_multi_value?: ITemplateItem;
  needs_water_points: boolean;
  isEdit?: boolean;
  isDirty?: boolean;
  isNew?: boolean;
  delete?: boolean;
}

const sortPropertiesOn = (attribute: string, a: any, b: any) => {
  if (a[attribute] && b[attribute]) {
    if (a[attribute] < b[attribute]) {
      return -1;
    }
    if (a[attribute] > b[attribute]) {
      return 1;
    }
  }
  return 0;
};

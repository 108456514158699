import { TextField } from '@fluentui/react';
import React, { FormEvent } from 'react';

const InlineEditableLabel = ({
  isEdit,
  value,
  callBack,
  noScroll,
  isMultiline,
  ...props
}: any) => {
  const onChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    // const newVal = newValue || '';
    callBack(newValue);
  };

  const noScrollHandler = (event: React.WheelEvent<HTMLInputElement>) => {
    event.currentTarget.blur();
  };

  return isEdit ? (
    <TextField
      multiline={isMultiline && value && value.length > 10}
      autoAdjustHeight={isMultiline}
      value={value}
      onChange={onChange}
      tabIndex={0}
      onWheel={noScroll ? noScrollHandler : undefined}
      style={isMultiline ? { minHeight: '20px' } : {}}
      {...props}
    />
  ) : (
    <span>{value}</span>
  );
};

export default InlineEditableLabel;

import React, { FormEvent, useRef, useState } from 'react';
import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  Stack,
  TextField,
  Icon,
  PrimaryButton,
} from '@fluentui/react';
import moment from 'moment';
import _, { property } from 'lodash';
import {
  convertPropertyTypeToComboBoxOptions,
  stackTokens5,
  iconProps,
  property_types,
} from '../../../utils';
import { ITemplateItem } from '../../../utils/TemplateItem';
import { defaultProperty, IProperty } from '../../../utils/Property';
import { Heading } from '../../../components/typography';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { IPropertyType } from '../../../utils/PropertyType';
import { ISuggestedValueCategory } from '../../../utils/SuggestedValueCategory';
import TemplateMultiValueValue from './TemplateMultiValueValue';
import TemplateSuggestedValueItem from './TemplateSuggestedValueItem';

type ITemplateMultiValueItem = {
  item: ITemplateItem;
  updateName: (name: string) => void;
  updateType: (type: IPropertyType) => void;
  updateValue: (value: string) => void;
  updateItem: (item: ITemplateItem) => void;
  types: IPropertyType[];
  // hasNewItemName: boolean;
  suggestedValuecategories?: ISuggestedValueCategory[];
};

const TemplateMultiValueItem = ({
  item,
  updateName,
  updateType,
  updateValue,
  updateItem,
  types,
  // hasNewItemName = false,
  suggestedValuecategories,
}: ITemplateMultiValueItem) => {
  const name = item ? item.name : '';
  const value = item ? item.value : '';
  const type = item ? item.type : undefined;

  const [showMaxLengthName, setShowMaxLengthName] = useState(false);
  const [showMaxLengthValue, setShowMaxLengthValue] = useState(false);

  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const [selectedKeyPropertyType, setSelectedKeyPropertyType] = useState(0);

  const comboboxPropertyType = useRef<IComboBox>(null);

  const changeSubType = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption,
    //   index?: number,
    //   value?: string,
  ) => {
    if (option) {
      setSelectedKeyPropertyType(+option.key);
      const selectedType = types.filter((t) => {
        if (t.id === +option.key) return true;
      })[0];

      if (selectedType) {
        updateType(selectedType);
      }
    }
  };

  const addNewValue = (type: number) => {
    const currentItem = _.cloneDeep(item);
    const children = currentItem.children;
    const values: IProperty[] = currentItem.default_multi_value_children || [];
    const parentProperty = _.cloneDeep(defaultProperty);

    const currentType = types.filter((item) => {
      if (item.id === type) return true;
    })[0];

    parentProperty.name = 'nieuwe waarde';
    parentProperty.id = moment().unix();
    parentProperty.remote_category = currentItem.suggested_value_category
      ? currentItem.suggested_value_category.category_code
      : undefined;
    parentProperty.type = currentType;
    const propertyChildren: IProperty[] = [];
    if (currentItem && children) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];

        const newItem = _.cloneDeep(defaultProperty);
        newItem.type = child.type;
        newItem.name = child.name;
        newItem.value = child.value;
        newItem.document = child.document;
        newItem.remote_category = child.suggested_value_category
          ? child.suggested_value_category.category_code
          : undefined;

        newItem.needs_water_points = child.needs_water_points || false;
        propertyChildren.push(newItem);
      }
    }
    parentProperty.children = propertyChildren;

    let newSortKey = 0;

    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        const child = values[i];
        if (newSortKey < child.weight_for_execution_list) newSortKey = child.weight_for_execution_list;
      }
    }

    newSortKey++; // add 1 to the maximum number

    parentProperty.weight_for_execution_list = newSortKey;

    values.push(parentProperty);

    currentItem.default_multi_value_children = values;

    updateItem(currentItem);
  };

  const updateMultiValueValue = (index: number, value: IProperty) => {
    // update value parent
    const currentItem = _.cloneDeep(item);
    const multiValueValues = currentItem.default_multi_value_children;

    if (multiValueValues && multiValueValues.length > 0) {
      if (multiValueValues[index]) {
        multiValueValues[index] = value;
        currentItem.default_multi_value_children = multiValueValues;
        currentItem.dirty = true;

        updateItem(currentItem);
      }
    }
  };

  const moveChildDown = (index: number) => {
    const currentItem = _.cloneDeep(item);
    if (currentItem && currentItem.default_multi_value_children) {
      const children = currentItem.default_multi_value_children;
      const currentChildSortKey = children[index]
        ? children[index].weight_for_execution_list
        : undefined;
      const nextChildSortKey = children[index + 1]
        ? children[index + 1].weight_for_execution_list
        : undefined;

      if (
        typeof currentChildSortKey !== 'undefined'
        && typeof nextChildSortKey !== 'undefined'
      ) {
        children[index].weight_for_execution_list = nextChildSortKey;
        children[index].isDirty = true;
        children[index + 1].weight_for_execution_list = currentChildSortKey;
        children[index + 1].isDirty = true;
      }

      const newChildren = children.sort((a, b) => a.weight_for_execution_list - b.weight_for_execution_list);

      currentItem.default_multi_value_children = newChildren;

      updateItem(currentItem);
    }
  };

  const moveChildUp = (index: number) => {
    const currentItem = _.cloneDeep(item);
    if (currentItem && currentItem.default_multi_value_children) {
      const children = currentItem.default_multi_value_children;
      const currentChildSortKey = children[index]
        ? children[index].weight_for_execution_list
        : undefined;
      const prevChildSortKey = children[index - 1]
        ? children[index - 1].weight_for_execution_list
        : undefined;

      if (
        typeof currentChildSortKey !== 'undefined'
        && typeof prevChildSortKey !== 'undefined'
      ) {
        children[index].weight_for_execution_list = prevChildSortKey;
        children[index].isDirty = true;
        children[index - 1].weight_for_execution_list = currentChildSortKey;
        children[index - 1].isDirty = true;
      }

      const newChildren = children.sort((a, b) => a.weight_for_execution_list - b.weight_for_execution_list);

      currentItem.default_multi_value_children = newChildren;

      updateItem(currentItem);
    }
  };

  const toggleDeleteChild = (index: number) => {
    const currentItem = _.cloneDeep(item);
    if (currentItem && currentItem.default_multi_value_children) {
      if (currentItem.default_multi_value_children[index]) {
        currentItem.default_multi_value_children[index].delete = !currentItem.default_multi_value_children[index].delete;
        currentItem.default_multi_value_children[index].isDirty = true;
      }
    }

    updateItem(currentItem);
  };

  return (
    <Stack tokens={stackTokens5}>
      <TextField
        label='Omschrijving'
        placeholder='Voer een standaard waarde in'
        value={value}
        name='value'
        multiline
        rows={5}
        onChange={(
          event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ) => {
          if (newValue && newValue.length > 255) {
            setShowMaxLengthValue(true);
          } else {
            updateValue(newValue || '');
            setShowMaxLengthValue(false);
          }
        }}
        errorMessage={
          showMaxLengthValue
            ? 'Ingevoerde waarde is te lang! (max 255 karakters)'
            : ''
        }
      />
      <ComboBox
        componentRef={comboboxPropertyType}
        selectedKey={
          item.nested_type ? item.nested_type.id : selectedKeyPropertyType
        }
        disabled={!!item.id && !item.draft && !item.editType}
        label='Subtype'
        allowFreeform
        autoComplete='on'
        onChange={changeSubType}
        options={convertPropertyTypeToComboBoxOptions(
          types.filter((item: IPropertyType) => {
            if (item.name === 'parent' || item.name === 'free-multivalue') {
              return true;
            }
            return false;
          }),
        )}
        required
      />

      {item.nested_type
        && item.nested_type.name === property_types.SUGGESTED_VALUE && (
          <Stack.Item>
            <TemplateSuggestedValueItem
              item={item}
              callBack={(item) => updateItem(item)}
              type={item.type}
              categories={suggestedValuecategories}
              // hasNewItemName={hasNewItemName}
            />
          </Stack.Item>
      )}

      {(item.default_multi_value_children || item.nested_type) && (
        <Stack style={{ marginTop: '15px!important' }}>
          <Stack.Item style={{ marginBottom: '10px' }}>
            <h3>Onderliggende waarden</h3>
          </Stack.Item>
          <Stack.Item>
            <Accordion
              selectedKey={selectedValueItem}
              defaultKey={undefined}
              toggleItem={(key: string | number) => {
                if (selectedValueItem === key) setSelectedValueItem(undefined);
                else setSelectedValueItem(key);
              }}
            >
              {item.default_multi_value_children
                && item.default_multi_value_children.length > 0
                && item.default_multi_value_children
                  .sort(
                    (a, b) => a.weight_for_execution_list - b.weight_for_execution_list,
                  )
                  .map((child, index) => (
                    <AccordionItem
                      key={child.id}
                      id={child.id}
                      title={(
                        <Stack horizontal>
                          <Stack.Item
                            onClick={() => {
                              if (selectedValueItem === child.id) setSelectedValueItem(undefined);
                              else setSelectedValueItem(child.id);
                            }}
                            style={{
                              textDecoration: child.delete
                                ? 'line-through'
                                : undefined,
                              marginRight: '10px',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              maxWidth: '200px',
                            }}
                          >
                            {child.isNew && <Icon iconName="LocationDot" />}
                            {child.name}
                          </Stack.Item>
                          <Stack.Item>
                            <Icon
                              iconName={child.delete ? 'Refresh' : 'Delete'}
                              styles={iconProps}
                              onClick={() => {
                                toggleDeleteChild(index);
                              }}
                            />
                            <Icon
                              iconName='Up'
                              styles={iconProps}
                              onClick={() => {
                                moveChildUp(index);
                              }}
                            />
                            <Icon
                              iconName='Down'
                              styles={iconProps}
                              onClick={() => {
                                moveChildDown(index);
                              }}
                            />
                          </Stack.Item>
                        </Stack>
                        )}
                    >
                      <TemplateMultiValueValue
                        item={child}
                        type={item.type ? item.type.id : 8}
                        types={types}
                        suggestedValuecategories={suggestedValuecategories}
                        updateItem={(newItem) => {
                          updateMultiValueValue(index, newItem);
                        }}
                      />
                    </AccordionItem>
                  ))}
            </Accordion>
          </Stack.Item>
          <Stack.Item style={{ marginTop: '10px' }}>
            <PrimaryButton
              text='Voeg een waarde toe'
              onClick={() => {
                addNewValue(item.nested_type!.id);
              }}
            />
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
};

export default TemplateMultiValueItem;

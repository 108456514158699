import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import { ISalesRep } from './SalesRep';

export const GET_CUSTOMERS = gql`
  query getCustomers(
    $filter: CustomerFilterInput
    $take: Int
    $skip: Int
    $orderBy: CustomerOrderByInput
    $cursor: CustomerWhereInput
  ) {
    findManyCustomers(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
      cursor: $cursor
    ) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile2
      phone1
      phone2
      title1
      title2
      zip_code1
      status
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query getCustomerById($where: CustomerWhereInput) {
    findOneCustomer(where: $where) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile2
      phone1
      phone2
      title1
      title2
      zip_code1
      status
    }
  }
`;

export const ADD_CUSTOMER = gql`
  mutation addCustomer($data: CustomerCreateInput!) {
    createCustomer(data: $data) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile2
      phone1
      phone2
      title1
      title2
      zip_code1
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($id: Int!, $data: CustomerUpdateInput) {
    updateCustomer(id: $id, data: $data) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile2
      phone1
      phone2
      title1
      title2
      zip_code1
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($where: CustomerWhereInput) {
    deleteCustomer(where: $where) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile2
      phone1
      phone2
      title1
      title2
      zip_code1
      status
    }
  }
`;

export interface ICustomer {
  id: number;
  address1: string;
  city1: string;
  country: string;
  email1: string;
  email1opt_out: boolean;
  email2: string;
  email2opt_out: boolean;
  first_name1: string;
  first_name2: string;
  last_name1: string;
  last_name2: string;
  mobile1: string;
  mobile2: string;
  phone1: string;
  phone2: string;
  title1: string;
  title2: string;
  zip_code1: number;
  status: string;
  sales_rep: ISalesRep;
  comments: string;
}

export const convertCustomersToComboBoxOptions = (customers: ICustomer[]) => {
  if (customers) {
    const result: IComboBoxOption[] = customers.map(
      (customer: ICustomer): IComboBoxOption => ({
        key: customer.id,
        text: displayNameCustomer(customer),
      }),
    );
    return result;
  }

  return [];
};

export const displayNameCustomer = (customer: ICustomer) => '{first_name1}{last_name1}{optionalfirst_name2}{optionallast_name2}'
  .replace(
    '{first_name1}',
    customer.first_name1 && customer.first_name1 !== null
      ? `${customer.first_name1} `
      : '',
  )
  .replace('{last_name1}', customer.last_name1)
  .replace(
    '{optionalfirst_name2}',
    customer.first_name2 && customer.first_name2 !== null
      ? ` - ${customer.first_name2}`
      : '',
  )
  .replace(
    '{optionallast_name2}',
    customer.last_name2 && customer.last_name2.trim() !== ''
      ? customer.first_name2 && customer.first_name2 !== null
        ? ` ${customer.last_name2}`
        : ` - ${customer.last_name2}`
      : '',
  );

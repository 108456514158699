import { IComboBoxOption } from '@fluentui/react';
import { gql } from '@apollo/client';
import { ITemplateTemplateItem } from './TemplateTemplateItem';

export const GET_TEMPLATES = gql`
  query getTemplates($orderBy: TemplateOrderByInput) {
    findManyTemplates(orderBy: $orderBy) {
      id
      name
    }
  }
`;

export const GET_TEMPLATES_WITH_ITEMS = gql`
  query getTemplates($orderBy: TemplateOrderByInput) {
    findManyTemplates(orderBy: $orderBy) {
      id
      name
      template_template_items {
        weight_for_construction_sheet
        weight_for_execution_list
        template {
          id
        }
        template_item {
          id
          is_reporting_param
          list_types
          name
          needs_water_points
          reporting_label
          value
          type {
            id
            name
          }
          nested_type {
            id
            name
            label
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          suggested_value_category_id
          suggested_value_category {
            id
            category_code
            category_code_with_prefix
            category_description
          }
          children {
            id
            name
            value
            child_pos_construction_sheet
            child_pos_execution_list
            type {
              id
              name
            }
            document {
              id
              name
              src
              extension
            }
            suggested_value_category_id
            suggested_value_category {
              id
              category_code
              category_description
            }
          }
        }
      }
    }
  }
`;

export const GET_TEMPLATE_BY_ID = gql`
  query getTemplateById($where: TemplateWhereInput) {
    findOneTemplate(where: $where) {
      id
      name
      template_template_items {
        template_item_id
        template_id
        weight_for_construction_sheet
        weight_for_execution_list
        template {
          id
        }
        template_item {
          id
          is_reporting_param
          list_types
          name
          needs_water_points
          reporting_label
          value
          type {
            id
            name
            label
          }
          nested_type {
            id
            name
            label
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          suggested_value_category_id
          suggested_value_category {
            id
            category_code
            category_description
          }
          children {
            id
            name
            value
            child_pos_construction_sheet
            child_pos_execution_list
            type {
              id
              name
              label
            }
            document {
              id
              name
              src
              extension
              is_external
              blob_name
            }
            suggested_value_category_id
            suggested_value_category {
              id
              category_code
              category_description
            }
          }

          default_multi_value_children {
            id
            name
            remote_category
            value
            order_date
            is_ordered
            is_not_applicable
            send_alert
            due_date
            execution_date
            weight_for_construction_sheet
            weight_for_execution_list
            type {
              id
              name
            }
            list_types {
              id
              list_type {
                id
                name
              }
            }
            document {
              id
              name
              src
              extension
              is_external
              blob_name
            }
            children {
              id
              name
              value
              parent {
                id
              }
              weight_for_construction_sheet
              weight_for_execution_list
              remote_category
              type {
                id
                name
              }
              suggested_value {
                id
                suggested_value
                suggested_value_code
              }
              document {
                id
                name
                src
                extension
                is_external
                blob_name
              }
              reporting_item {
                id
                document {
                  id
                }
                reporting_label
                template_template_items {
                  template {
                    id
                    name
                  }
                }
              }
            }
            suggested_value {
              id
              suggested_value
              suggested_value_code
            }
            reporting_item {
              id
              document {
                id
              }
              reporting_label
              template_template_items {
                template {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_TEMPLATE = gql`
  mutation addTemplate($data: TemplateCreateInput!) {
    createTemplate(data: $data) {
      id
      name
      template_template_items {
        weight_for_construction_sheet
        weight_for_execution_list
        template {
          id
        }
        template_item {
          id
          is_reporting_param
          list_types
          name
          needs_water_points
          reporting_label
          value
          type {
            id
            name
            label
          }
          nested_type {
            id
            name
            label
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          suggested_value_category {
            id
            category_code
            category_description
          }
          children {
            id
            name
            value
            child_pos_construction_sheet
            child_pos_execution_list
            type {
              id
              name
              label
            }
            document {
              id
              name
              src
              extension
            }
            suggested_value_category {
              id
              category_code
              category_description
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($id: Int!, $data: TemplateUpdateInput) {
    updateTemplate(id: $id, data: $data) {
      id
      name
      template_template_items {
        template_item_id
        template_id
        weight_for_construction_sheet
        weight_for_execution_list
        template {
          id
        }
        template_item {
          id
          is_reporting_param
          list_types
          name
          needs_water_points
          reporting_label
          value
          type {
            id
            name
            label
          }
          nested_type {
            id
            name
            label
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          suggested_value_category_id
          suggested_value_category {
            id
            category_code
            category_description
          }
          children {
            id
            name
            value
            child_pos_construction_sheet
            child_pos_execution_list
            type {
              id
              name
              label
            }
            document {
              id
              name
              src
              extension
              is_external
              blob_name
            }
            suggested_value_category_id
            suggested_value_category {
              id
              category_code
              category_description
            }
          }

          default_multi_value_children {
            id
            name
            remote_category
            value
            order_date
            is_ordered
            is_not_applicable
            send_alert
            due_date
            execution_date
            weight_for_construction_sheet
            weight_for_execution_list
            type {
              id
              name
            }
            list_types {
              id
              list_type {
                id
                name
              }
            }
            document {
              id
              name
              src
              extension
              is_external
              blob_name
            }
            children {
              id
              name
              value
              parent {
                id
              }
              weight_for_construction_sheet
              weight_for_execution_list
              remote_category
              type {
                id
                name
              }
              suggested_value {
                id
                suggested_value
                suggested_value_code
              }
              document {
                id
                name
                src
                extension
                is_external
                blob_name
              }
              reporting_item {
                id
                document {
                  id
                }
                reporting_label
                template_template_items {
                  template {
                    id
                    name
                  }
                }
              }
            }
            suggested_value {
              id
              suggested_value
              suggested_value_code
            }
            reporting_item {
              id
              document {
                id
              }
              reporting_label
              template_template_items {
                template {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($where: TemplateWhereInput) {
    deleteTemplate(where: $where) {
      id
      name
    }
  }
`;

export interface ITemplate {
  id?: number;
  name: string;
  template_template_items: ITemplateTemplateItem[];
}

export interface ITemplateReference {
  id?: number;
  name?: string;
  template_template_items?: ITemplateTemplateItem[];
}

export type ITemplateType =
  | 'weight_for_construction_sheet'
  | 'weight_for_execution_list';

export const convertTemplatesToComboBoxOptions = (templates: ITemplate[]) => {
  const result: IComboBoxOption[] = templates.map(
    (template: ITemplate): IComboBoxOption => ({
      key: template.id || '', // to fix, no good solution @TP
      text: template.name,
    }),
  );
  return result;
};

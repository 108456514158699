import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  ADD_LEAD,
  displayNameLead,
  GET_LEAD_BY_ID,
  UPDATE_LEAD,
  ILead,
} from '../../../utils/Lead';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { GET_EMPLOYEES } from '../../../utils';
import CustomerDetail from './CustomerDetail';

const CustomersOverview = ({
  customers,
  setCustomers,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  refetchCustomers,
  clearCustomers,
}: any) => {
  const [customer, setCustomer] = useState<ILead | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [employees, setEmployees] = useState([]);

  const [addCustomer] = useMutation(ADD_LEAD);
  const [modifyCustomer] = useMutation(UPDATE_LEAD);

  useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<ILead | undefined>();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  useQuery(GET_LEAD_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setCustomer(data.findOneLead);
    },
  });

  const openCustomerDetail = (newCustomer?: boolean) => {
    if (selectionDetails && !newCustomer) {
      // getCustomer({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setCustomer(undefined);
    }
    setIsPanelOpen(true);
  };

  const saveCustomer = () => {
    if (customer) {
      const allInput = {
        address1: customer.address1,
        budget_estimate_high: customer.budget_estimate_high
          ? +customer.budget_estimate_high
          : null,
        budget_estimate_low: customer.budget_estimate_low
          ? +customer.budget_estimate_low
          : null,
        city1: customer.city1,
        close_follow_up: customer.close_follow_up,
        comments: customer.comments,
        competitors: customer.competitors,
        construction_site_address: customer.construction_site_address,
        construction_site_available: customer.construction_site_available,
        construction_site_city: customer.construction_site_city,
        construction_site_comments: customer.construction_site_comments,
        construction_site_wanted_location:
          customer.construction_site_wanted_location,
        construction_site_zip_code: customer.construction_site_zip_code
          ? +customer.construction_site_zip_code
          : null,
        contact_method: customer.contact_method,
        country: customer.country,
        date_created: customer.date_created,
        date_documentation_sent: customer.date_documentation_sent,
        date_last_info_visit: customer.date_last_info_visit,
        date_last_updated: customer.date_last_updated,
        date_visit_exhibition_house: customer.date_visit_exhibition_house,
        date_visit_exposition: customer.date_visit_exposition,
        degree_of_completion: customer.degree_of_completion,
        email1: customer.email1,
        email1opt_out: customer.email1opt_out,
        email2: customer.email2,
        email2opt_out: customer.email2opt_out,
        fax1: customer.fax1,
        fax2: customer.fax2,
        first_name1: customer.first_name1,
        first_name2: customer.first_name2,
        known_by: customer.known_by,
        last_name1: customer.last_name1,
        last_name2: customer.last_name2,
        mobile1_v2: customer.mobile1_v2,
        mobile2_v2: customer.mobile2_v2,
        phone1_v2: customer.phone1_v2,
        phone2_v2: customer.phone2_v2,
        reason_declined: customer.reason_declined,
        status: 'SOLD',
        title1: customer.title1,
        title2: customer.title2,
        type_of_home: customer.type_of_home,
        vat: customer.vat,
        year_to_start_construction: customer.year_to_start_construction
          ? +customer.year_to_start_construction
          : null,
        zip_code1: customer.zip_code1 ? +customer.zip_code1 : null,
        sales_rep_employee: {
          connect: { id: customer.sales_rep_employee.id },
        },
        wings_code: customer.wings_code ? +customer.wings_code : null,
        wings_account_code: customer.wings_account_code
          ? +customer.wings_account_code
          : null,
      };

      if (customer.id) {
        modifyCustomer({
          variables: {
            id: customer.id,
            data: allInput,
          },
        }).then(() => {
          clearCustomers();
          refetchCustomers();
          setIsPanelOpen(false);
        });
      } else {
        addCustomer({
          variables: {
            data: allInput,
          },
        }).then(() => {
          clearCustomers();
          refetchCustomers();
          setIsPanelOpen(false);
        });
      }
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'last_name1',
      minWidth: 250,
      maxWidth: 600,
      isRowHeader: true,
      onRender: (customer: ILead) => <span>{displayNameLead(customer)}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Adres',
      fieldName: 'address',
      minWidth: 230,
      maxWidth: 600,
      isRowHeader: true,
      onRender: (customer: ILead) => (
        <span>
          {customer.address1}
          <br />
          {customer.zip_code1} {customer.city1}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'GSM',
      fieldName: 'mobile',
      minWidth: 120,
      maxWidth: 350,
      isRowHeader: true,
      onRender: (customer: ILead) => (
        <span>{customer.mobile1_v2 || customer.mobile2_v2}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Telefoon',
      fieldName: 'phone',
      minWidth: 120,
      maxWidth: 350,
      isRowHeader: true,
      onRender: (customer: ILead) => (
        <span>{customer.phone1_v2 || customer.phone2_v2}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Verkoper',
      fieldName: 'sales_rep_employee',
      minWidth: 120,
      maxWidth: 350,
      isRowHeader: true,
      onRender: (customer: ILead) => (
        <span>
          {customer.sales_rep_employee &&
            `${customer.sales_rep_employee.first_name} ${customer.sales_rep_employee.last_name}`}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openCustomerDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openCustomerDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (customer && isPanelOpen) {
      document.title = `3bouw | Klant - ${displayNameLead(customer)}`;
    } else {
      document.title = '3bouw | Klanten';
    }
  }, [customer, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />

      <CustomerDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        customer={customer}
        saveCustomer={saveCustomer}
        setCustomer={setCustomer}
        employees={employees}
      />

      {customers && (
        <>
          <ShimmeredDetailsList
            items={customers}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selection={selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && customers.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default CustomersOverview;

import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  getTheme,
  IconButton,
  IIconProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  Stack,
  TextField,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { debounce } from 'throttle-debounce';
import {
  default_page_size,
  DELETE_TEMPLATE_ITEM,
  GET_TEMPLATES_ITEMS,
  ITemplateItem,
  ITemplateTemplateItem,
  modalContentStyles,
  stackTokens5,
} from '../../../utils';
import useDebounce from '../../../components/hooks/useDebounce';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIcon: IIconProps = { iconName: 'Add' };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const deleteIcon: IIconProps = { iconName: 'Delete' };

const controlStyles = {
  root: {
    margin: '0 30px 20px 0',
    maxWidth: '300px',
  },
};

const modelProps = {
  isBlocking: false,
  styles: { main: { maxWidth: 450 } },
};

const dialogRemoveConfirmationProps = {
  type: DialogType.largeHeader,
  title: 'Verwijder item',
  subText:
    'Bent u zeker dat het item wilt verwijderen? Deze actie kan niet ongedaan worden!',
};

interface IPagedTemplateItems {
  templateItems: ITemplateItem[];
  skip: number;
  take: number;
  filter: string;
  isSortedAsc: boolean;
  sortedField: string;
}

const TemplateAddExistingItem = ({
  addItem,
  toggleModal,
  forModal = false,
  template,
}: any) => {
  const { isAuthenticated } = useAuth0();

  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const [pagedState, setPagedState] = useState<IPagedTemplateItems>({
    templateItems: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    isSortedAsc: true,
    sortedField: 'name',
  });

  // const refPagedState = useRef(pagedState);

  const changeFilter = (filter: string) => {
    setPagedState(prevState => ({
      ...prevState,
      filter,
    }));
  };

  const debouncedFilter = useDebounce(pagedState.filter, 1000);

  useEffect(() => {
    setPagedState(prevState => ({
      ...prevState,
      templateItems: [],
      skip: 0,
    }));
  }, [debouncedFilter]);

  /* const debouncedScroll = useCallback(
    debounce(100, (skipAmount: number) => {
      updatePagedState({
        ...refPagedState.current,
        skip: skipAmount,
      });
    }),
    [], // will be created only once initially
  ); */

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    setPagedState(prevState => ({
      ...prevState,
      templateItems: [],
      skip: 0,
      isSortedAsc,
      sortedField,
    }));
  };

  const getFilter = () => {
    let filter = {};
    filter = {
      ...filter,
      parent_id: null,
      name: {
        contains: debouncedFilter,
      },
    };

    if (forModal) {
      const newNotSavedItems = template.template_template_items.map(
        (el: ITemplateTemplateItem) => el.template_item.id,
      );

      if (newNotSavedItems.length > 0) {
        filter = {
          ...filter,
          id: {
            notIn: newNotSavedItems,
          },
        };
      }

      filter = {
        ...filter,
        template_template_items: {
          every: {
            NOT: {
              template_id: template.id,
            },
          },
        },
      };
    }

    return filter;
  };

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const {
 data, loading, error, fetchMore,
} = useQuery(GET_TEMPLATES_ITEMS, {
    variables: {
      filter: getFilter(),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    /* onCompleted: (x: any) => {
      if (x && x.findManyTemplateItems && x.findManyTemplateItems.length > 0) {
        updatePagedState({
          ...refPagedState.current,
          templateItems: refPagedState.current.templateItems.concat(
            x.findManyTemplateItems,
          ),
        });
        setPagedState(prevState => ({
          ...prevState,
          templateItems: refPagedState.current.templateItems.concat(
            x.findManyTemplateItems,
          ),
        }));
      }
    }, */
  });

  const loadMore = () => {
    fetchMore({
      variables: {
        filter: getFilter(),
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });
    setPagedState(prevState => ({
      ...prevState,
      templateItems: [],
      skip: prevState.skip + prevState.take,
    }));
  };

  const [deleteTemplateItem] = useMutation(DELETE_TEMPLATE_ITEM);

  const [, setSelection] = useState<any[]>([]);

  const onSelectionChanged = () => {
    const selection = _selection.getSelection();
    setSelection(selection);
  };

  const [_selection] = useState(
    new Selection({
      onSelectionChanged,
    }),
  );

  const deleteItem = () => {
    const selectedItems = _selection.getSelectedIndices();
    const idsToDelete: number[] = [];
    for (let i = 0; i < selectedItems.length; i++) {
      const itemToBeDeleted = data && data.findManyTemplateItems && data.findManyTemplateItems[selectedItems[i]];
      if (itemToBeDeleted.template_template_items.length === 0) {
        idsToDelete.push(itemToBeDeleted.id);
        deleteTemplateItem({
          variables: {
            where: {
              id: itemToBeDeleted.id,
            },
          },
          // eslint-disable-next-line no-loop-func
        }).then(() => {
          if (i === selectedItems.length) {
            toggleConfirmationDialog();
            // refetchQuery();
            _selection.setAllSelected(false);
          }
        });
      } else {
        toggleConfirmationDialog();
      }
    }
  };

  const deleteSelectedItems = () => {
    toggleConfirmationDialog();
  };

  const getKey = (item: any) => {
    if (item && item.id) {
      return item.id;
    }
    return 0;
  };

  const onChangeText = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    changeFilter(newValue || '');
  };

  const onColumnClick = (event: any, column: any) => {
    let fieldName = '';
    let sortDescending = false;

    const newColumns = columns.map((col: any) => {
      if (col.key === column.key) {
        fieldName = col.fieldName;
        col.isSortedDescending = !col.isSortedDescending;
        col.isSorted = true;
        sortDescending = col.isSortedDescending;
      } else {
        col.isSortedDescending = false;
        col.isSorted = false;
      }
      return col;
    });

    setColumns(newColumns);
    setSorting(!sortDescending, fieldName);
  };

  const onItemInvoked = () => {};

  const onRender = (item: ITemplateItem) => (
    <span>
      {item.children
        ? item.children.map((child: ITemplateItem) => <div>{child.name}</div>)
        : null}
    </span>
  );

  const onRenderActions = (item: ITemplateItem) => (
    <span>
      {forModal ? (
        <IconButton
          iconProps={addIcon}
          title='Toevoegen'
          ariaLabel='Toevoegen'
          onClick={() => { addItem(item); }}
        />
      ) : item.template_template_items.length === 0
          && item.properties
          && item.properties.length === 0 ? (
          // eslint-disable-next-line react/jsx-indent
          <IconButton
            iconProps={deleteIcon}
            title='Verwijderen'
            ariaLabel='Verwijderen'
            onClick={() => deleteSelectedItems()}
          />
        ) : null}
    </span>
  );

  const onRenderTemplate = (item: ITemplateItem) => (item.template_template_items
      && item.template_template_items.length > 0 ? (
      // eslint-disable-next-line react/jsx-indent
      <span>
        {item.template_template_items
          .map((item: ITemplateTemplateItem) => item.template.name)
          .join(', ')}
      </span>
    ) : null);

  /* const refetchQuery = () => {
    refetch().then((x: any) => {
      if (
        x.data.findManyTemplateItems
        && x.data.findManyTemplateItems.length > 0
      ) {
        updatePagedState({
          ...refPagedState.current,
          skip: 0,
          templateItems: [],
        });
      }
    });
  }; */
  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
  };

  const initialColumns = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      minWidth: 175,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Gesorteerd van A tot Z',
      sortDescendingAriaLabel: 'Gesorteerd van Z tot A',
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Opmerkingen',
      fieldName: 'value',
      minWidth: 175,
      maxWidth: 350,
      isMultiline: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      onColumnClick: () => {},
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Sub items',
      fieldName: 'value',
      minWidth: 175,
      maxWidth: 350,
      isMultiline: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      onColumnClick: () => {},
      onRender,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Template',
      fieldName: 'template',
      minWidth: 175,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      onColumnClick: () => {},
      onRender: onRenderTemplate,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'ID',
      fieldName: 'template',
      minWidth: 175,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      onColumnClick: () => {},
      onRender: (item: any) => <span>{item.id}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: '',
      fieldName: '',
      minWidth: 35,
      maxWidth: 35,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      onColumnClick: () => {},
      onRender: onRenderActions,
      data: '',
      isPadded: true,
    },
  ];

  const [columns, setColumns] = useState(initialColumns);
/*
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        debouncedScroll(refPagedState.current.templateItems.length);
      }
    });
  });
*/
  if (!isAuthenticated) return <p>Verboden</p>;
  if (
    loading
    && pagedState.skip === 0
    && pagedState.filter === ''
  ) return <p>Laden...</p>;
  if (error) return <p>Fout :(</p>;

  return forModal ? (
    <div>
      <div className={modalContentStyles.header}>
        <span>Kies item(s)</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel='Sluit modal'
          onClick={toggleModal}
        />
      </div>
      <div className={modalContentStyles.body}>
        <Stack tokens={stackTokens5}>
          <Stack.Item>
            <TextField
              value={pagedState.filter}
              label='Zoek op naam:'
              onChange={onChangeText}
              styles={controlStyles}
            />
          </Stack.Item>
          <Stack.Item>
            <DetailsList
              items={data && data.findManyTemplateItems ? data.findManyTemplateItems : []}
              compact
              columns={columns}
              selectionMode={SelectionMode.multiple}
              selection={_selection}
              getKey={getKey}
              setKey='none'
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible
              onItemInvoked={onItemInvoked}
            />
          </Stack.Item>
          <Stack.Item>
            <Stack horizontalAlign='center'>
              <PrimaryButton
                onClick={() => { loadMore(); }}
                text='Meer laden'
              />
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontalAlign="end">
              <PrimaryButton
                disabled={_selection.count === 0}
                // onClick={addSelectedItems}
                text="Toevoegen"
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </div>
    </div>
  ) : (
    <Stack tokens={stackTokens5}>
      <Stack.Item>
        <TextField
          value={pagedState.filter}
          label='Zoek op naam:'
          onChange={onChangeText}
          styles={controlStyles}
        />
      </Stack.Item>
      <Stack.Item>
        <DetailsList
          items={data && data.findManyTemplateItems ? data.findManyTemplateItems : []}
          compact
          columns={columns}
          selectionMode={SelectionMode.multiple}
          selection={_selection}
          getKey={getKey}
          setKey='none'
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible
          onItemInvoked={onItemInvoked}
        />
      </Stack.Item>
      {/* <Stack.Item>
        <Stack horizontalAlign="end">
          {forModal ? (
            <PrimaryButton
              disabled={_selection.count === 0}
              onClick={addSelectedItems}
              text="Toevoegen"
            />
          ) : (
            <PrimaryButton
              disabled={_selection.count === 0}
              onClick={deleteSelectedItems}
              text="Verwijderen"
            />
          )}
        </Stack>
      </Stack.Item> */}
      <Stack.Item>
        <Stack horizontalAlign='center'>
          <PrimaryButton
            onClick={() => { loadMore(); }
            }
            text='Meer laden'
          />
        </Stack>
      </Stack.Item>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationProps}
        modalProps={modelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteItem} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};

export default TemplateAddExistingItem;

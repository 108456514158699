import { gql } from '@apollo/client';
import { IAppUser } from './AppUser';
import { ILead } from './Lead';
import { IEmployee } from '.';

export interface ICivilStatus {
  id: number;
  civil_class: string;
  current_status: boolean;
  employee_id: number;
  end_date: Date;
  start_date: Date;
  version: number;

  employee: IEmployee;
}

export const GET_CIVIL_CLASS_STATUSES = gql`
  query getCivilClassStatuses($filter: CivilClassStatusFilterInput) {
    findManyCivilClassStatuses(filter: $filter) {
      id
    }
  }
`;

export const GET_CIVIL_CLASS_STATUS_BY_ID = gql`
  query getCivilClassStatusById($where: CivilClassStatusWhereInput) {
    findOneCivilClassStatus(where: $where) {
      id
      civil_class
      start_date
      end_date
      current_status
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const ADD_CIVIL_CLASS_STATUS = gql`
  mutation AddCivilClassStatus($data: CivilClassStatusCreateInput!) {
    createCivilClassStatus(data: $data) {
      id
    }
  }
`;

export const UPDATE_CIVIL_CLASS_STATUS = gql`
  mutation UpdateCivilClassStatus(
    $id: Int!
    $data: CivilClassStatusUpdateInput!
  ) {
    updateCivilClassStatus(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_CIVIL_CLASS_STATUS = gql`
  mutation deleteCivilClassStatus($where: CivilClassStatusWhereInput) {
    deleteCivilClassStatus(where: $where) {
      id
    }
  }
`;

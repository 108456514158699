import React, { FormEvent } from 'react';
import { IComboBoxOption, Stack, TextField } from '@fluentui/react';
import _ from 'lodash';
import { ComboboxWithFilter as ComboBox } from '../../../components/parts';
import {
  convertSuggestedValueCategoriesToComboBoxOptions,
  ISuggestedValueCategory,
  ITemplateItem,
  IPropertyType,
  stackTokens5,
} from '../../../utils';

interface ITemplateSuggestedValueItem {
  item: ITemplateItem;
  callBack: (item: ITemplateItem) => void;
  categories: ISuggestedValueCategory[] | undefined;
  type: IPropertyType;
  // hasNewItemName?: boolean;
}

const TemplateSuggestedValueItem: React.FC<ITemplateSuggestedValueItem> = ({
  item,
  callBack,
  categories,
  type,
  // hasNewItemName = false,
}) => {
  const name = item ? item.name : '';
  const selectedKey = item && item.suggested_value_category
    ? item.suggested_value_category.id
    : 0;

  const setSuggestedValueID = (key: string | number) => {
    if (categories) {
      const currentItem = _.cloneDeep(item);
      for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === key) currentItem.suggested_value_category = categories[i];
      }

      callBack(currentItem);
    }
  };

  return (
    <Stack tokens={stackTokens5}>
      <ComboBox
        label='Voorgestelde waarde categorie'
        options={
          categories
            ? convertSuggestedValueCategoriesToComboBoxOptions(categories)
            : []
        }
        callBack={(value: IComboBoxOption[]) => {
          if (value && value.length > 0) setSuggestedValueID(value[0].key);
        }}
        required
        value={[selectedKey]}
      />
    </Stack>
  );
};

export default TemplateSuggestedValueItem;

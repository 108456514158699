import React, { FormEvent, useState } from 'react';
import { Stack, StackItem, TextField } from '@fluentui/react';
import moment from 'moment';

import { DatePickerNL } from '../../../components/parts';
import { overviewControlStyles, property_types, stackTokens5 } from '../../../utils';
import { IProperty } from '../../../utils/Property';

interface ITextView {
  property: IProperty;
  callBack: (item: IProperty) => void;
  edit?: boolean;
  compact?: boolean;
}

const TextView = ({
  property,
  callBack,
  edit = true,
  compact = false,
}: ITextView) => {
  const [hasErrors, setHasErrors] = useState(false);

  return (
    <Stack>

      <TextField
        value={property.value || ''}
        label="Waarde"
        multiline
        onChange={(
          event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ) => {
          const newItem = { ...property };
          newItem.value = newValue || '';
          newItem.isDirty = true;
          callBack(newItem);
        }}
        errorMessage={
          hasErrors ? 'Ingevoerde waarde is te lang! (max 255 karakters)' : ''
        }
      />
    </Stack>
  );
};

export default TextView;

import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading } from '../../components/typography';
import {
  // GET_CUSTOMERS,
  GET_SALES_REPS,
  GET_ARCHITECTS,
  GET_EMPLOYEES,
  GET_TEMPLATES,
  ADD_CONSTRUCTION_SITE,
  UPDATE_CONSTRUCTION_SITE,
  GET_CONSTRUCTION_SITE_BY_ID_EDIT,
  IConstructionSite,
} from '../../utils';
import { GET_LEADS } from '../../utils/Lead';
import { useAppDispatch } from '../../redux/hooks';
import {
  dismissNotification,
  sendNotification,
} from '../../redux/notification/notificationSlice';
import { throwError, SeverityLevel } from '../../redux/error/errorSlice';

import ConstructionSiteForm from './components/_old/ConstructionSiteForm';

const ConstructionSiteView = ({ ...props }: any) => {
  const [comboBoxState, setComboBoxState] = useState<{
    [key: string]: string | undefined;
  }>({});

  const dispatch = useAppDispatch();

  const { id: constructionSiteURLID } = useParams();

  const constructionSiteId = constructionSiteURLID && constructionSiteURLID !== 'add' ? +constructionSiteURLID : props.constructionSiteID;

  const orderByLastNameASC = {
    last_name: 'asc',
  };

  const { isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  const gotoOverview = () => {
    navigate('/construction-sites');
  };

  const [addConstructionSite] = useMutation(ADD_CONSTRUCTION_SITE, {
    onError: (error) => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveConstructionSite',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });
  const [modifyConstructionSite] = useMutation(UPDATE_CONSTRUCTION_SITE, {
    onError: (error) => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveConstructionSite',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });

  const [constructionSite, setConstructionSite] = useState<IConstructionSite>(
    props.constructionSite && props.constructionSite.id
      ? props.constructionSite
      : {
        template_id: -1, // fix if we want to have a standard set of templates
      },
  );

  const [inProgress, setInProgress] = useState(false);

  const filterAndSortEmployeesByName = (
    filterExpression: string,
    additionalCondition: any,
    id: number | undefined,
  ) => {
    const employeeFilter = {
      filter: {
        OR: [
          { first_name: { contains: filterExpression } },
          { last_name: { contains: filterExpression } },
        ],
        active: true,
        ...additionalCondition,
      },
      orderBy: orderByLastNameASC,
    };

    if (id) {
      employeeFilter.filter.OR.push({
        id: { equals: id },
      });
    }

    return employeeFilter;
  };

  const reloadQueryParamsArchitects = (
    filterExpression: string,
    id: number | undefined,
  ) => {
    const architectFilter: any = {
      filter: {
        OR: [
          { first_name: { contains: filterExpression } },
          { last_name: { contains: filterExpression } },
          { company: { contains: filterExpression } },
        ],
      },
      orderBy: orderByLastNameASC,
      take: 20,
    };

    if (id) {
      architectFilter.filter.OR.push({
        id: { equals: id },
      });
    }

    return architectFilter;
  };

  const reloadQueryParamsConstructionSiteManagers = (
    filterExpression: string,
  ) => filterAndSortEmployeesByName(
    filterExpression,
    {
      construction_site_manager: true,
    },
    constructionSite.manager_id,
  );

  const reloadQueryParamsLeads = (filterExpression: string) => {
    const leadFilter: any = {
      filter: {
        OR: [
          { first_name1: { contains: filterExpression } },
          { first_name2: { contains: filterExpression } },
          { last_name1: { contains: filterExpression } },
          { last_name2: { contains: filterExpression } },
        ],
      },
      orderBy: {
        last_name1: 'asc',
      },
      take: 30,
    };

    if (constructionSite && constructionSite.lead_id) {
      leadFilter.filter.OR.push({
        id: { equals: constructionSite.lead_id },
      });
    }

    return leadFilter;
  };

  const reloadQueryParamsCustomerManagers = (filterExpression: string) => filterAndSortEmployeesByName(
    filterExpression,
    {
      customer_manager: true,
    },
    constructionSite.project_manager_id,
  );

  const reloadQueryParamsEPBReporters = (filterExpression: string) => filterAndSortEmployeesByName(
    filterExpression,
    {
      epb_reporter: true,
    },
    constructionSite.epb_reporter_id,
  );

  const reloadQueryParamsSalesReps = (
    filterExpression: string,
    id: number | undefined,
  ) => {
    const salesRepFilter: any = {
      filter: {
        OR: [
          { first_name: { contains: filterExpression } },
          { last_name: { contains: filterExpression } },
        ],
        active: true,
      },
      orderBy: orderByLastNameASC,
    };

    if (id) {
      salesRepFilter.filter.OR.push({
        id: { equals: id },
      });
    }

    return salesRepFilter;
  };

  const reloadQueryParamsSafetyCoordinators = (filterExpression: string) => filterAndSortEmployeesByName(
    filterExpression,
    {
      safety_coordinator: true,
    },
    constructionSite.safety_coordinator_id,
  );

  const { loading, error } = useQuery(GET_CONSTRUCTION_SITE_BY_ID_EDIT, {
    variables: {
      where: {
        id: +constructionSiteId,
      },
      whereProps: {
        parent_id: null,
      },
    },
    skip:
      constructionSiteId === undefined
      || (props.constructionSite && props.constructionSite.id),
    onCompleted: (x: any) => {
      if (x && x.findOneConstructionSite) setConstructionSite(x.findOneConstructionSite);
    },
  });

  const { data: dataArchitects } = useQuery(GET_ARCHITECTS, {
    variables: {
      ...reloadQueryParamsArchitects(
        comboBoxState && comboBoxState.architect ? comboBoxState.architect : '',
        constructionSite.architect_id,
      ),
    },
    fetchPolicy: 'no-cache',
    // onCompleted: (x: any) => setArchitects(x.findManyArchitects),
  });

  const { data: dataLeads } = useQuery(GET_LEADS, {
    variables: {
      ...reloadQueryParamsLeads(
        comboBoxState && comboBoxState.lead ? comboBoxState.lead : '',
      ),
    },
    fetchPolicy: 'no-cache',
    // onCompleted: (x: any) => setCustomers(x.findManyCustomers),
  });

  const { data: dataSalesReps } = useQuery(GET_SALES_REPS, {
    variables: {
      ...reloadQueryParamsSalesReps(
        comboBoxState && comboBoxState.sales_rep ? comboBoxState.sales_rep : '',
        constructionSite.sales_rep_id,
      ),
    },
    fetchPolicy: 'no-cache',
    // onCompleted: (x: any) => setSalesReps(x.findManySalesReps),
  });

  // EPB Reporters
  const { data: dataEPBReporters } = useQuery(GET_EMPLOYEES, {
    variables: {
      ...reloadQueryParamsEPBReporters(
        comboBoxState && comboBoxState.epb_reporter
          ? comboBoxState.epb_reporter
          : '',
      ),
    },
    fetchPolicy: 'no-cache',
    // onCompleted: (x: any) => setEpbReporters(x.findManyEmployees),
  });

  // Safety Coordinators
  const {
    data: dataSafetyCoordinators,

    error: errorSafetyCoordinators,
  } = useQuery(GET_EMPLOYEES, {
    variables: {
      ...reloadQueryParamsSafetyCoordinators(
        comboBoxState && comboBoxState.safety_coordinator
          ? comboBoxState.safety_coordinator
          : '',
      ),
    },
    fetchPolicy: 'no-cache',
    // onCompleted: (x: any) => setSafetyCoordinators(dataSafetyCoordinators.findManyEmployees),
  });

  // Customer Managers
  const {
    loading: loadingProjectManagers,
    error: errorProjectManagers,
    data: dataProjectManagers,
  } = useQuery(GET_EMPLOYEES, {
    variables: {
      ...reloadQueryParamsCustomerManagers(
        comboBoxState && comboBoxState.project_manager
          ? comboBoxState.project_manager
          : '',
      ),
    },
    fetchPolicy: 'no-cache',
    // onCompleted: (x: any) => setCustomerManagers(x.findManyEmployees),
  });

  // Construction Site Manager
  const {
    data: dataConstructionSiteManager,
    error: errorConstructionSiteManager,
  } = useQuery(GET_EMPLOYEES, {
    variables: {
      ...reloadQueryParamsConstructionSiteManagers(
        comboBoxState && comboBoxState.manager ? comboBoxState.manager : '',
      ),
    },
    fetchPolicy: 'no-cache',
    // onCompleted: (x: any) => setConstructionSiteManagers(x.findManyEmployees),
  });

  const {
    loading: loadingTemplates,
    error: errorTemplates,
    data: templates,
  } = useQuery(GET_TEMPLATES, {
    variables: {
      whereItems: {
        template_item: {
          parent_id: null,
        },
      },
      orderBy: {
        name: 'asc',
      },
    },
  });

  const saveConstructionSite = () => {
    if (constructionSite) {
      const noName = constructionSite.name === '' || constructionSite.name === undefined;
      const noLead = constructionSite.lead_id === undefined
        || constructionSite.lead_id === 0;
      const noValidZip = constructionSite.zip_code && Number.isNaN(+constructionSite.zip_code);

      if (noName || noLead || noValidZip) {
        return;
      }

      const data = {
        name: constructionSite.name,
        lead: {
          connect: {
            id: constructionSite.lead_id,
          },
        },
        address: constructionSite.address,
        comments: constructionSite.comments,
        city: constructionSite.city,
        zip_code: constructionSite.zip_code,
        architect: constructionSite.architect_id
          ? { connect: { id: constructionSite.architect_id } }
          : undefined,
        site_manager: constructionSite.manager_id
          ? { connect: { id: constructionSite.manager_id } }
          : undefined,
        epb_reporter: constructionSite.epb_reporter_id
          ? { connect: { id: constructionSite.epb_reporter_id } }
          : undefined,
        project_manager: constructionSite.project_manager_id
          ? {
            connect: { id: constructionSite.project_manager_id },
          }
          : undefined,
        sales_rep: constructionSite.sales_rep_id
          ? { connect: { id: constructionSite.sales_rep_id } }
          : undefined,
        safety_coordinator: constructionSite.safety_coordinator_id
          ? {
            connect: { id: constructionSite.safety_coordinator_id },
          }
          : undefined,
        template_id:
          constructionSite.template_id === -1
            ? 0
            : constructionSite.template_id,
      };

      if (constructionSite.id) {
        dispatch(
          sendNotification({
            message: 'Bezig met opslaan.',
            level: 0,
            module: 'template.updateConstructionSite',
            spinner: true,
          }),
        );

        modifyConstructionSite({
          variables: {
            id: constructionSite.id,
            data,
          },
        }).then(() => {
          dispatch(dismissNotification());
          if (props && props.dismissPanel) {
            props.dismissPanel();
          } else if (gotoOverview) {
            gotoOverview();
          }
        });
      } else {
        dispatch(
          sendNotification({
            message: 'Bezig met opslaan. We maken op de achtergrond alle eigenschappen van de uitvoeringslijst & werffiche aan.',
            level: 0,
            module: 'template.addConstructionsite',
            spinner: true,
          }),
        );

        addConstructionSite({
          variables: {
            data,
          },
        }).then(() => {
          dispatch(dismissNotification());

          if (props && props.dismissPanel) {
            props.dismissPanel();
          } else if (gotoOverview) {
            gotoOverview();
          }
        });
      }
    }
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (loading || loadingTemplates) return <p>Laden...</p>;
  if (error || errorTemplates) return <p>Fout :(</p>;

  return (
    <div>
      {constructionSiteId === undefined && (
        <h3>Werf toevoegen</h3>
      )}
      <ConstructionSiteForm
        comboBoxState={comboBoxState}
        setComboBoxState={setComboBoxState}
        architects={dataArchitects && dataArchitects.findManyArchitects}
        leads={dataLeads && dataLeads.findManyLeads}
        epbreporters={dataEPBReporters && dataEPBReporters.findManyEmployees}
        projectmanagers={
          dataProjectManagers && dataProjectManagers.findManyEmployees
        }
        salesreps={dataSalesReps && dataSalesReps.findManySalesReps}
        safetycoordinators={
          dataSafetyCoordinators && dataSafetyCoordinators.findManyEmployees
        }
        constructionsitemanagers={
          dataConstructionSiteManager
          && dataConstructionSiteManager.findManyEmployees
        }
        constructionsite={constructionSite}
        templates={templates.findManyTemplates}
        saveConstructionSite={saveConstructionSite}
        updateConstructionSite={setConstructionSite}
        inProgress={inProgress}
        hasValidZipCode={
          constructionSite
            ? constructionSite.zip_code
              ? !Number.isNaN(+constructionSite.zip_code)
              : true
            : true
        }
        compact={props.compact || undefined}
      />
    </div>
  );
};

export default ConstructionSiteView;

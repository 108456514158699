import React from 'react';
import { Stack, Link } from '@fluentui/react';

// import { useHover } from '../hooks';
import { classes } from '../../utils';

interface INavItemProps {
  icon: any;
  children: any;
  href: any;
  // isFavorite?: boolean;
  navID: any;
  // setFavorite: () => void;
  disableHover: boolean;
}

const NavItem = ({
  icon,
  children,
  href,
  // isFavorite = false,
  //   navID,
  // setFavorite,
  // disableHover = false,
  ...props
}: INavItemProps) => (
  <Stack.Item
    className={classes.item}
    style={{ paddingLeft: 16 }}
    align='stretch'
    {...props}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Link className={classes.innerItem} href={href}>
        <div>{icon}</div>
        <div>{children}</div>
      </Link>
      {/* {isFavorite && (
          <div className={classes.favoriteStar} onClick={() => setFavorite()}>
            <Icon iconName='FavoriteStarFill' className={classes.icon} />
          </div>
        )}
        {!isFavorite && isHovered && !disableHover && (
          <div className={classes.favoriteStar} onClick={() => setFavorite()}>
            <Icon iconName='FavoriteStar' className={classes.icon} />
          </div>
        )} */}
    </div>
  </Stack.Item>
);

export default NavItem;

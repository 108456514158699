import { DefaultButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { stackTokens15 } from '../../../utils';
import {
  GET_TIMESHEETS,
  GET_TIMESHEETS_BY_CUSTOMER,
} from '../../../utils/Timesheet';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';
import NewTimesheetEmployee from './NewTimesheetEmployee';
import NewTimesheetProject from './NewTimesheetProject';

function NewTimesheet() {
  const [timesheetViaEmployee, setTimesheetViaEmployee] = useState(true);
  const [timesheet, setTimesheet] = useState<ITimesheetEntry[]>([]);

  const [getTimesheetByEmployee] = useLazyQuery(GET_TIMESHEETS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      duration: 6,
    },
    onCompleted: (x: any) => {
      setTimesheet(x.findTimesheetsByEmployee);
    },
    fetchPolicy: 'no-cache',
  });

  const [getTimesheetByCustomer] = useLazyQuery(GET_TIMESHEETS_BY_CUSTOMER, {
    notifyOnNetworkStatusChange: true,
    variables: {
      duration: 6,
    },
    onCompleted: (x: any) => {
      setTimesheet(x.findTimesheetsByCustomer);
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0, paddingLeft: 10 }}>
        Werkuren ingeven via {timesheetViaEmployee ? 'werknemer' : 'project'}
      </h3>

      <Stack style={{ flexDirection: 'row', padding: 10 }}>
        {timesheetViaEmployee ? (
          <DefaultButton
            disabled={!timesheetViaEmployee}
            onClick={() => {
              setTimesheetViaEmployee(false);
              setTimesheet([]);
            }}
          >
            Ingeven via project
          </DefaultButton>
        ) : (
          <DefaultButton
            onClick={() => {
              setTimesheetViaEmployee(true);
              setTimesheet([]);
            }}
          >
            Ingeven via medewerker
          </DefaultButton>
        )}
      </Stack>
      {timesheetViaEmployee ? (
        <NewTimesheetEmployee
          timesheet={timesheet}
          setTimesheet={setTimesheet}
          getTimesheet={getTimesheetByEmployee}
        />
      ) : (
        <NewTimesheetProject
          timesheet={timesheet}
          setTimesheet={setTimesheet}
          getTimesheet={getTimesheetByCustomer}
        />
      )}
    </Stack>
  );
}

export default NewTimesheet;

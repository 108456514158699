import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import { IAppUserRole } from './AppUserRole';

export const GET_APP_USERS = gql`
  query getAppUsers($filter: AppUserFilterInput) {
    findManyAppUsers(filter: $filter) {
      account_locked
      auth0_id
      date_created
      email
      id
      last_updated
      username
      version
      app_user_roles {
        id
        name
        description
      }
    }
  }
`;

export const GET_APP_ROLES = gql`
  query getAppRoles {
    findManyAppRoles {
      id
      name
      description
    }
  }
`;

export const GET_APP_USER_BY_ID = gql`
  query getAppUserById($where: AppUserWhereInput) {
    findOneAppUser(where: $where) {
      auth0_id
    }
  }
`;

export interface IAppUser {
  account_locked: boolean;
  app_user_roles: IAppUserRole[];
  auth0_id: string;
  dirty?: boolean;
  email: string;
  id: number;
}

export const convertAppUsersToComboBoxOptions = (app_users: IAppUser[]) => {
  if (app_users) {
    const result: IComboBoxOption[] = app_users.map(
      (app_user: IAppUser): IComboBoxOption => ({
        key: app_user.id,
        text: `${app_user.email}`,
      }),
    );
    return result;
  }

  return [];
};

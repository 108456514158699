import { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { DefaultButton, Stack } from '@fluentui/react';
import moment from 'moment';
import useDebounce from '../../components/hooks/useDebounce';
import { default_page_size, stackTokens15 } from '../../utils';
import {
  GET_TIMESHEET_ENTRIES,
  ITimesheetEntry,
} from '../../utils/TimesheetEntry';
import TimesheetsOverview from './components/TimesheetsOverview';

interface IPagedQuote {
  timesheets: ITimesheetEntry[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

type IStatus = 'today' | 'yesterday' | 'thisweek' | 'lastweek';

const Timesheets = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedQuote>({
    timesheets: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'entry_date',
  });

  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState<IStatus | undefined>(undefined);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, timesheets: [], skip: 0 }));
  }, [status]);

  const getFilters = (filterString: string, filterStatus?: string) => {
    // const filterArray = filterString.split(' ');

    const filters: any = {
      AND: [],
    };

    if (filterStatus) {
      switch (filterStatus) {
        case 'today':
          filters.AND.push({
            OR: [{ entry_date: { equals: new Date() } }],
          });
          break;
        case 'yesterday':
          filters.AND.push({
            OR: [{ entry_date: { equals: moment(new Date()).add(-1, 'd') } }],
          });
          break;
        case 'thisweek':
          filters.AND.push({
            OR: [{ entry_date: null }],
          });
          break;
        case 'lastweek':
          filters.AND.push({
            OR: [
              {
                construction_sites: {
                  some: {
                    entry_date: {
                      id: 4,
                    },
                  },
                },
              },
            ],
          });
          break;
        default:
          break;
      }
    } else {
      // filters.AND.push({
      //   OR: [],
      // });
    }

    // for (let i = 0; i < filterArray.length; i++) {
    //   const filterValue: any = {
    //     OR: [
    //       { employee: { contains: filterArray[i] } },
    //       { customer: { contains: filterArray[i] } },
    //       { last_name1: { contains: filterArray[i] } },
    //       { last_name2: { contains: filterArray[i] } },
    //       { address1: { contains: filterArray[i] } },
    //       { city1: { contains: filterArray[i] } },
    //       {
    //         sales_rep: {
    //           OR: [
    //             { first_name: { contains: filterArray[i] } },
    //             { last_name: { contains: filterArray[i] } },
    //           ],
    //         },
    //       },
    //     ],
    //   };

    //   const numericValue = parseInt(filterArray[i], 10);
    //   if (numericValue) filterValue.OR.push({ zip_code1: +numericValue });

    //   filters.AND.push(filterValue);
    // }

    console.log('filters', filters);
    return filters;
  };

  const {
    loading,
    error,
    refetch: refetchTimesheets,
  } = useQuery(GET_TIMESHEET_ENTRIES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, status),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      if (x.findManyTimeSheetEntries && x.findManyTimeSheetEntries.length > 0) {
        setPagedState(prevState => ({
          ...prevState,
          timesheets: pagedState.timesheets.concat(x.findManyTimeSheetEntries),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(
            x.findManyTimeSheetEntries.length < default_page_size
          ),
        }));
      } else if (x.findManyTimeSheetEntries) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearTimesheets();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearTimesheets = () => {
    setPagedState(prevState => ({
      ...prevState,
      timesheets: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.timesheets.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Werkuren</h3>

      <div>
        {/* <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        /> */}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: '15px',
          }}
        >
          <DefaultButton
            text='Alle'
            onClick={() => {
              setStatus(undefined);
            }}
            primary={status === undefined}
          />
          <DefaultButton
            text='Vandaag'
            styles={{ root: { marginLeft: '10px' } }}
            onClick={() => {
              setStatus('today');
            }}
            primary={status === 'today'}
          />
          <DefaultButton
            text='Gisteren'
            styles={{ root: { marginLeft: '10px' } }}
            onClick={() => {
              setStatus('yesterday');
            }}
            primary={status === 'yesterday'}
          />
          <DefaultButton
            text='Deze week'
            styles={{ root: { marginLeft: '10px' } }}
            onClick={() => {
              setStatus('thisweek');
            }}
            primary={status === 'thisweek'}
          />
          <DefaultButton
            text='Vorige week'
            styles={{ root: { marginLeft: '10px' } }}
            onClick={() => {
              setStatus('lastweek');
            }}
            primary={status === 'lastweek'}
          />
        </div>
      </div>

      <TimesheetsOverview
        timesheets={pagedState.timesheets}
        loading={loading}
        initialLoad={pagedState.initialLoad}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={pagedState.showLoadMore}
        refetchTimesheets={refetchTimesheets}
        clearTimesheets={clearTimesheets}
      />
    </Stack>
  );
};

export default Timesheets;
